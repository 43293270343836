<template>
	<span
		:class="[buttonClasses, {'opacity-50 hover:cursor-not-allowed': disabled}]"
		class="whitespace-nowrap text-sm min-[450px]:max-w-[300px] min-[450px]:text-base inline-block text-white py-2 mb-2 rounded cursor-pointer self-start transition-colors duration-300 text-center justify-center items-center shrink"
		@click="handleClick"
	>
		<font-awesome-icon
			v-if="(arrow && type == 'previous') || (arrow && arrowPosition == 'before')"
			:icon="['far', buttonTypeIcon]"
		/>
		<div
			:class="[
				{'max-[460px]:hidden': type == 'delete'},
				{'mr-2': arrow && type != 'previous'},
				{'ml-2 max-sm:hidden': arrow && type == 'previous'},
				{'ml-2': arrow && arrowPosition == 'before'},
			]"
			class="inline-block"
		>
			{{ buttonTypeLabel }}
			<slot></slot>
		</div>
		<font-awesome-icon
			v-if="arrow && type != 'previous' && arrowPosition != 'before'"
			:icon="['far', buttonTypeIcon]"
		/>
	</span>
</template>

<script>
export default {
	name: "Button",
	props: {
		type: {
			type: String,
			default: "",
		},
		customClasses: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		icon: {
			type: String,
			default: "arrow-right",
		},
		arrow: {
			type: Boolean,
			default: true,
		},
		arrowPosition: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		lg: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		buttonClasses() {
			return this.buttonTypeClasses + " " + this.buttonSizeClasses;
		},
		buttonTypeLabel() {
			if (this.type === "cancel" && this.label == "") return this.$t("mol.general.button.Cancel");
			if (this.type === "previous" && this.label == "") return this.$t("mol.general.button.Previous");
			return this.label;
		},
		buttonTypeClasses() {
			if (this.customClasses) return this.customClasses;
			if (this.type == "blue" || this.type == "primary") return "bg-tdc-blue-500 hover:bg-tdc-blue-400";
			if (this.type === "green" || this.type == "success" || this.type == "save")
				return "bg-tdc-green-500 dark:bg-tdc-green-600 hover:bg-tdc-green-400 dark:hover:bg-tdc-green-500";
			if (this.type === "orange" || this.type == "warning") return "bg-tdc-orange-400 hover:bg-tdc-orange-300";
			if (this.type === "red" || this.type == "danger" || this.type == "delete")
				return "bg-red-400 hover:bg-red-500 dark:bg-red-500 dark:hover:bg-red-600";
			if (this.type === "gray" || this.type == "disabled") return "bg-slate-400 hover:bg-slate-300";
			if (this.type === "cancel" || this.type === "previous") return "bg-slate-400 hover:bg-slate-300";
			return "bg-tdc-blue-500 hover:bg-tdc-blue-400";
		},
		buttonTypeIcon() {
			if (this.type === "cancel" && this.icon == "arrow-right") return "times";
			if (this.type === "previous" && this.icon == "arrow-right") return "arrow-left";
			return this.icon;
		},
		buttonSizeClasses() {
			if (this.lg) return "px-16";
			return "px-3 sm:px-4";
		},
	},
	methods: {
		handleClick(evt) {
			if (!this.disabled) {
				this.$emit("click", evt);
			}
		},
	},
};
</script>
