<template>
	<div class="text-nowrap">
		<font-awesome-icon v-for="fullStar in fullStars" :key="'full-' + fullStar" :icon="['fas', 'star']" />
		<font-awesome-icon v-for="halfStar in halfStars" :key="'half-' + halfStar" :icon="['fas', 'star-half-alt']" />
		<font-awesome-icon v-for="emptyStar in emptyStars" :key="'empty-' + emptyStar" :icon="['far', 'star']" />
	</div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
	name: "StarRating",
	components: {
		FontAwesomeIcon,
	},
	props: {
		rating: {
			type: Number,
			default: 1,
		},
		highestRating: {
			type: Number,
			default: 10,
		},
	},
	computed: {
		cleanRating: function () {
			return this.highestRating == 5 ? this.rating * 2 : this.rating;
		},
		fullStars: function () {
			return this.cleanRating % 2 == 0 ? this.cleanRating / 2 : Math.floor(this.cleanRating / 2);
		},
		halfStars: function () {
			return this.cleanRating % 2 == 0 ? 0 : 1;
		},
		emptyStars: function () {
			return this.cleanRating % 2 == 0 ? 5 - this.fullStars : 4 - this.fullStars;
		},
	},
};
</script>
