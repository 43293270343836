<template>
	<span>
		<InputItem
			v-model="contact.Salutation"
			inputName="Salutation"
			:inputLabel="$t('mol.contactForm.label.Salutation')"
			inputType="radio"
			:choices="[
				{value: 'mevr.', label: $t('mol.contactForm.label.Madam')},
				{value: 'dhr.', label: $t('mol.contactForm.label.Sir')},
			]"
			:gridCols="2"
		/>
		<InputItem
			v-model="contact.FirstName"
			inputName="FirstName"
			:inputLabel="$t('mol.contactForm.label.First name')"
			:error="formErrors.contactErrorFirstName"
			:errorMessage="$t('mol.contactForm.message.Please enter a first name or initial')"
			@keypress="formErrors.contactErrorFirstName = false"
		/>
		<InputItem
			v-model="contact.LastName"
			inputName="LastName"
			:inputLabel="$t('mol.contactForm.label.Last name')"
			:error="formErrors.contactErrorLastName"
			:errorMessage="$t('mol.contactForm.message.Please enter a last name')"
			@keypress="formErrors.contactErrorLastName = false"
		/>
		<InputItem v-model="contact.Title" :inputLabel="$t('mol.contactForm.label.Title')" />
		<InputItem
			v-model="contact.Email"
			inputName="Email"
			:inputLabel="$t('mol.contactForm.label.Email')"
			:error="formErrors.contactErrorEmail"
			:errorMessage="$t('mol.contactForm.message.Please enter a valid email address')"
			@keypress="formErrors.contactErrorEmail = false"
		/>
		<InputItem
			v-model="contact.Phone"
			ref="phone"
			inputName="Phone"
			inputType="tel"
			:inputLabel="$t('mol.contactForm.label.Phone')"
			:error="formErrors.contactErrorPhone"
			:errorMessage="$t('mol.contactForm.message.Please enter a valid phone number')"
			@keypress="formErrors.contactErrorPhone = false"
		/>
		<InputItem
			v-if="!onboarding"
			v-model="contact.isMain"
			:inputLabel="$t('mol.contactForm.label.Main contact')"
			inputType="checkbox"
			:disabled="contact.Id == mainContactId"
			:choices="[{value: true, label: $t('mol.contactForm.label.Set as main contact')}]"
			extraSpaceTop
		/>
	</span>
</template>

<script>
import InputItem from "@/components/location/InputItem";

export default {
	name: "ContactForm",
	components: {
		InputItem,
	},
	props: {
		contact: {},
		formErrors: {},
		mainContactId: {
			type: String,
			default: "",
		},
		edit: {
			type: Boolean,
			default: false,
		},
		create: {
			type: Boolean,
			default: false,
		},
		onboarding: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
