<template>
	<div :class="responsiveClasses" class="text-center lead border-bottom border-primary mb-4 mt-n2 stike-through-title">
		<span style="bottom: -15px" class="m-0 p-0 d-inline-block position-relative px-3 h3 stike-through-title-text"
			><strong class="text-primary px-2" style="background-color: #f6f6f6"><slot /></strong
		></span>
	</div>
</template>

<script>
export default {
	name: "StrikeThroughTitle",
	props: {
		alwaysVisible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		responsiveClasses: function () {
			if (!this.alwaysVisible) return "d-none d-lg-block";
			return "d-block";
		},
	},
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(xs) {
	.stike-through-title.border-bottom.border-primary {
		border-bottom: none !important;
		.stike-through-title-text {
			bottom: 0 !important;
			margin-top: 1rem !important;
			font-size: 1.4rem !important;
			strong {
				background: none !important;
			}
		}
	}
}
</style>
