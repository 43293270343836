<template>
	<div>
		<FormGridWrapper class="">
			<template slot="left">
				<span :class="{'text-amber-600 underline decoration-2 decoration-amber-500': formErrors.bookingErrorDate}">{{
					$t("mol.bookADjForm.label.Event date")
				}}</span></template
			>
			<template slot="right">
				<div class="relative w-full">
					<div class="absolute inset-y-0 left-0 flex items-center pl-3 z-40">
						<font-awesome-icon :icon="['far', 'calendar']" class="text-slate-400 h-4 w-4" />
					</div>
					<Datepicker
						v-model="newBooking.date"
						inputName="date"
						id="date"
						wrapper-class="w-full"
						:input-class="[
							{'border border-amber-500': formErrors.bookingErrorDate},
							{
								'pl-9 scroll-mt-36 w-full text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600': true,
							},
						]"
						calendar-class="dark:text-slate-900"
						:placeholder="$t('mol.bookADjForm.string.Please choose a date')"
						:language="dateLocale"
						:disabled-dates="disabledDates"
						:higlighted="initialChosenDate"
						:error="formErrors.bookingErrorDate"
						:errorMessage="formErrors.bookingErrorDateMessage"
						@click.native="formErrors.bookingErrorDate = false"
					/>
				</div>
				<div v-if="formErrors.bookingErrorDate" class="mt-2 text-xs text-amber-600">
					{{ formErrors.bookingErrorDateMessage }}
				</div>
			</template>
		</FormGridWrapper>
		<FormGridWrapper class="!pb-0">
			<template slot="left">
				<span
					:class="{'text-amber-600 underline decoration-2 decoration-amber-500': formErrors.bookingErrorPartyTimes}"
					>{{ $t("mol.bookADjForm.label.Event times") }}</span
				></template
			>
			<template slot="right">
				<div class="grid grid-cols-2 mb-0 w-full gap-x-2">
					<InputItem
						v-model="newBooking.startTime"
						inputName="startTime"
						inputType="select"
						:inputIcon="startTimeIcon"
						:inputLabel="$t('mol.bookADjForm.label.Start time')"
						noInputLabel
						:error="formErrors.bookingErrorPartyTimes"
						:placeholder="$t('mol.bookADjForm.string.Choose a start time')"
						:choices="timeChoices('08:00')"
						@click.native="formErrors.bookingErrorPartyTimes = false"
					/>
					<InputItem
						v-model="newBooking.endTime"
						inputName="endTime"
						inputType="select"
						:inputIcon="endTimeIcon"
						:inputLabel="$t('mol.bookADjForm.label.End time')"
						noInputLabel
						:error="formErrors.bookingErrorPartyTimes"
						:placeholder="$t('mol.bookADjForm.string.Choose an end time')"
						:choices="timeChoices('13:00')"
						@click.native="formErrors.bookingErrorPartyTimes = false"
					/>
				</div>
				<div v-if="formErrors.bookingErrorPartyTimes" class="-mt-2 mb-2 text-xs text-amber-600">
					{{ formErrors.bookingErrorPartyTimesMessage }}
				</div>
			</template>
		</FormGridWrapper>
		<InputItem
			v-model="newBooking.guests"
			inputName="Select"
			inputType="select"
			:inputLabel="$t('mol.bookADjForm.label.Guests')"
			inputIcon="user"
			:error="formErrors.bookingErrorGuestCount"
			:errorMessage="formErrors.bookingErrorGuestCountMessage"
			@click.native="formErrors.bookingErrorGuestCount = false"
			:choices="guestChoices"
		/>
		<InputItem
			v-model="newBooking.partyType"
			inputName="partyType"
			inputType="select"
			:inputLabel="$t('mol.bookADjForm.label.Event type')"
			:inputIcon="partyTypeIcon"
			:placeholder="$t('mol.bookADjForm.string.Choose an event type')"
			:choices="partyTypeChoices"
			:error="formErrors.bookingErrorPartyType"
			:errorMessage="formErrors.bookingErrorPartyTypeMessage"
			@click.native="formErrors.bookingErrorPartyType = false"
		/>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import InputItem from "@/components/location/InputItem";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Datepicker from "vuejs-datepicker";
import {nl, gb} from "vuejs-datepicker/dist/locale";
import {timeChoices} from "@/utilities";

export default {
	name: "PriceForm",
	data() {
		return {
			disabledDates: {
				to: new Date(new Date().setDate(new Date().getDate() - 1)),
			},
			nl: nl,
			gb: gb,
		};
	},
	components: {
		InputItem,
		FormGridWrapper,
		Datepicker,
	},
	props: {
		newBooking: {},
		formErrors: {},
		guestChoices: {},
		partyTypeChoices: {},
	},
	computed: {
		...mapGetters({
			moTld: "getTld",
		}),
		dateLocale() {
			const tld = this.moTld;
			if (tld === "nl" || tld === "be") return this.nl;
			return this.gb;
		},
		startTimeIcon() {
			return this.timeIcon(this.newBooking.startTime);
		},
		endTimeIcon() {
			return this.timeIcon(this.newBooking.endTime);
		},
		partyTypeIcon() {
			if (this.newBooking.partyType == "wedding") {
				return "rings-wedding";
			}
			if (this.newBooking.partyType == "birthday") {
				return "cake";
			}
			return "party-horn";
		},
		initialChosenDate() {
			let dates = [this.newBooking.date];
			return dates;
		},
	},
	methods: {
		timeChoices: timeChoices,
		timeIcon(time) {
			let t = time.split(":");
			let icon = "clock";
			switch (t[0]) {
				case "00":
				case "12":
					icon += "-twelve";
					break;
				case "01":
				case "13":
					icon += "-one";
					break;
				case "02":
				case "14":
					icon += "-two";
					break;
				case "03":
				case "15":
					icon += "-three";
					break;
				case "04":
				case "16":
					icon += "-four";
					break;
				case "05":
				case "17":
					icon += "-five";
					break;
				case "06":
				case "18":
					icon += "-six";
					break;
				case "07":
				case "19":
					icon += "-seven";
					break;
				case "08":
				case "20":
					icon += "-eight";
					break;
				case "09":
				case "21":
					icon += "-nine";
					break;
				case "10":
				case "22":
					icon += "-ten";
					break;
				case "11":
				case "23":
					icon += "-eleven";
					break;
			}
			if (t[1] == "30") {
				icon += "-thirty";
			}
			return icon;
		},
	},
};
</script>
<style lang="scss">
.vdp-datepicker__calendar .cell.selected {
	border-radius: 2px !important;
}
</style>
