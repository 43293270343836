import store from "./store";

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function parseDateStr(val) {
	if (!val || typeof val !== "string") return null;
	let groups = val.match(/^(\d\d\d\d)-(\d\d)-(\d\d)(T00:00:00.000Z)?$/);
	if (!groups) return null;
	let year = parseInt(groups[1], 10);
	let month = parseInt(groups[2], 10);
	let day = parseInt(groups[3], 10);
	return new Date(year, month - 1, day);
}
export function parseIsoDatetime(dtstr) {
	// "2019-04-29T14:55:41.281Z"
	if (!dtstr || !dtstr.match(/^\d\d+-\d\d-\d\d([T ]\d\d:\d\d(:\d\d(.\d+(Z|\+0+)?)?)?)?$/)) return null;
	let dt = dtstr.split(/[: T-]/).map(parseFloat);
	if (dtstr.endsWith("Z") || dtstr.match(/\+0+$/))
		// it's UTC
		return new Date(Date.UTC(dt[0], dt[1] - 1, dt[2], dt[3] || 0, dt[4] || 0, dt[5] || 0, 0));
	return new Date(dt[0], dt[1] - 1, dt[2], dt[3] || 0, dt[4] || 0, dt[5] || 0, 0);
}

export function timeChoices(startTime) {
	let times = [];
	for (let i = 1; i <= 47; i++) {
		let time = i % 2 == 0 ? (i / 2).toString() + ":00" : ((i + 1) / 2 - 1).toString() + ":30";
		if (time.length < 5) {
			time = "0" + time;
		}
		times.push({value: time, label: time});
	}
	times.push({value: "00:00", label: "00:00"});
	if (!startTime) {
		return times;
	} else {
		let splitIndex = times.findIndex((t) => t.value === startTime);
		let split1 = times.slice(0, splitIndex);
		let split2 = times.slice(splitIndex, times.length);
		return split2.concat(split1);
	}
}

export function getLocalDateString(d) {
	if (!(d instanceof Date)) return null;
	return d.getFullYear() + "-" + pad2(d.getMonth() + 1) + "-" + pad2(d.getDate());
}

export function pad2(n) {
	if (typeof n === "string") n = parseInt(n, 10);
	return (n < 10 ? "0" : "") + n;
}

export function formatDate(date) {
	return Intl.DateTimeFormat(store.getters.getMolLangRegion, {year: "numeric", day: "numeric", month: "long"}).format(
		parseDateStr(date)
	);
}
export function formatDateTime(dt) {
	return Intl.DateTimeFormat(store.getters.getMolLangRegion, {
		year: "numeric",
		day: "numeric",
		month: "long",
		hour12: false,
		hour: "2-digit",
		minute: "2-digit",
	}).format(dt);
}

export function average(arr) {
	let total = 0;
	let count = 0;
	for (let i = 0; i < arr.length; i++) {
		if (typeof arr[i] === "number") {
			total += arr[i];
			count += 1;
		} else if (typeof arr[i] === "string") {
			const numval = parseFloat(arr[i]);
			if (!isNaN(numval)) {
				total += numval;
				count += 1;
			}
		}
	}
	return (total / count).toFixed(1).replace(".0", "");
}

export function copyObject(o) {
	if (o === null) return null;
	if (o === undefined) return undefined;
	return JSON.parse(JSON.stringify(o));
}

export function uuid() {
	if (crypto.randomUUID) {
		return crypto.randomUUID();
	}
	// Javascript waar je van moet huilen:
	// een array met één nummer + een nummer => een string
	// een array met één nummer - een nummer => een nummer
	// Daarom wordt hier een array gemaakt waar een negatief nummer bij op wordt geteld
	// zodat er een string met van cijfers met streepjes ertussen uit komt
	// de cijfers worden vervolgens vervangen door random hex characters
	let rand = crypto.getRandomValues(new Uint8Array(32));
	let i = 0;
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (rand[i++] & (15 >> (c / 4)))).toString(16)
	);
}

export function getCookie(name) {
	return document.cookie.replace(new RegExp("(?:(?:^|.*;\\s*)" + name + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1");
}

export function setCookie(name, value) {
	document.cookie = `${name}=${value}`;
}

export const emailRegex = // eslint-disable-next-line no-control-regex
	/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const EVALUATION_ID_PREFIX = "a02";
