<template>
	<div>
		<PageTitle
			:title="$t('mol.couponCodePage.title.Coupon code')"
			:subTitle="$t('mol.couponCodePage.title.75 extra discount for guests')"
			:breadcrumbs="[$t('mol.couponCodePage.title.Coupon code')]"
		/>
		<p class="mb-4">{{ $t("mol.couponCodePage.string.Intro", {code: firstCouponInformation.Code__c}) }}</p>
		<ul class="mb-6 block">
			<li
				v-for="(item, index) in uspItems"
				:key="index"
				class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2"
			>
				<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
				{{ item }}
			</li>
		</ul>
		<div
			class="max-w-[1000px] p-2 md:p-4 lg:p-6 mb-3 md:mb-6 flex flex-col bg-slate-200 border-t-4 border-sky-500 dark:bg-sky-800 bg-no-repeat bg-right-bottom comon bg-cover"
		>
			<div class="dark:text-white text-base font-bold">{{ $t("mol.couponCodePage.string.Coupon code") }}:</div>
			<div class="dark:text-white text-2xl font-bold text-tdc-blue-500">
				{{ firstCouponInformation.Code__c }}
			</div>
		</div>
		<h2 class="mb-3 font-bold text-lg tracking-tight">
			{{ $t("mol.couponCodePage.title.How do guests get their coupon") }}
		</h2>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">1</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.couponCodePage.string.Give guests the coupon code") }}
				</h2>
				<p>{{ $t("mol.couponCodePage.string.Give the unique code", {code: firstCouponInformation.Code__c}) }}</p>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">2</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.couponCodePage.string.Calculate price online") }}
				</h2>
				<p>{{ $t("mol.couponCodePage.string.Guests can calculate price online") }}</p>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">3</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.couponCodePage.string.Book a DJ") }}
				</h2>
				<ul>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("common.usp.string.No cure, no pay") }}
						</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("common.usp.string.Free cancellation 14 days before party date") }}
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">4</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.couponCodePage.string.We receive the code via email, whatsapp or phone") }}
				</h2>
				<ul>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("mol.couponCodePage.string.Your guest give us the code") }}
						</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("mol.couponCodePage.string.Via email, whatsapp or phone") }}
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">5</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.couponCodePage.string.Discount is applied") }}
				</h2>
				<ul>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("mol.couponCodePage.string.Your guest received 75 extra discount") }}
						</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>
							{{ $t("mol.couponCodePage.string.Directly on the final invoice") }}
						</span>
					</li>
				</ul>
			</div>
		</div>
		<p>
			<strong>{{ $t("mol.couponCodePage.title.Faq") }}</strong>
		</p>
		<div v-for="(faq, index) in faqs" :key="index">
			<h2 class="mb-0" :id="'heading' + index">
				<button
					@click="toggleAccordion(index)"
					class="group relative flex w-full items-center border-0 py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-accordion-collapsed])]:text-slate-700 [&:not([data-accordion-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-accordion-collapsed])]:text-slate-300 dark:[&:not([data-accordion-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
					type="button"
					:data-accordion-target="'#collapse' + index"
					:data-accordion-collapsed="isOpen === index"
					:aria-expanded="isOpen"
					:aria-controls="'collapse' + index"
				>
					<div>
						{{ faq.question }}
					</div>
					<span
						class="ml-auto h-5 w-5 shrink-0 hidden dark:group-[[data-accordion-collapsed]]:text-white"
						:class="{'!block': isOpen === index}"
					>
						<font-awesome-icon class="h-5 w-5" :icon="['fal', 'minus']" fa-fw />
					</span>
					<span
						class="ml-auto h-5 w-5 shrink-0 block dark:group-[[data-accordion-collapsed]]:text-white"
						:class="{'!hidden': isOpen === index}"
					>
						<font-awesome-icon class="h-5 w-5 text-tdc-blue-500" :icon="['fal', 'plus']" fa-fw />
					</span>
				</button>
			</h2>

			<div
				:class="{'max-h-[500px]': isOpen === index}"
				:id="'collapse' + index"
				class="max-h-0 overflow-hidden transition-all duration-500"
				:aria-labelledby="'heading' + index"
				data-accordion-parent="#faqs"
			>
				<div class="p-3 border-l-4 border-slate-300 mb-4 dark:bg-slate-800/80">
					{{ faq.answer }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";

export default {
	name: "LocationDiscountCode",
	components: {
		PageTitle,
	},
	data() {
		return {
			isOpen: 0,
		};
	},
	computed: {
		...mapGetters({
			location: "getLocationInformation",
			couponCount: "getCouponCount",
			couponInformation: "getCouponInformation",
			firstCouponInformation: "getFirstCouponInformation",
		}),
		uspItems() {
			const usps = this.$t("mol.couponCodePage.usp.usps");
			return usps.split("✂️");
		},
		faqs() {
			var faqs = [
				{
					question: this.$t("mol.couponCodePage.string.question 1"),
					answer: this.$t("mol.couponCodePage.string.answer 1"),
				},
				{
					question: this.$t("mol.couponCodePage.string.question 2"),
					answer: this.$t("mol.couponCodePage.string.answer 2"),
				},
				{
					question: this.$t("mol.couponCodePage.string.question 3"),
					answer: this.$t("mol.couponCodePage.string.answer 3"),
				},
				{
					question: this.$t("mol.couponCodePage.string.question 4"),
					answer: this.$t("mol.couponCodePage.string.answer 4"),
				},
				{
					question: this.$t("mol.couponCodePage.string.question 5"),
					answer: this.$t("mol.couponCodePage.string.answer 5"),
				},
			];
			return faqs;
		},
	},
	methods: {
		toggleAccordion(key) {
			this.isOpen = this.isOpen === key ? false : key;
		},
	},
};
</script>
