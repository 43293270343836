<template>
	<div
		id="FormGridWrapper"
		:class="[
			{' md:flex-row max-w-[900px]': !labelAlwaysTop},
			{'md:max-w-[calc(900px+140px)] lg:max-w-[calc(900px+150px)] xl:max-w-[calc(900px+180px)]': labelHidden},
		]"
		class="w-full flex flex-col scroll-m-[100px] sm:scroll-m-[110px] lg:scroll-m-[135px] pb-2 max-w-[900px]"
	>
		<div
			v-if="!labelHidden"
			:class="[{'': labelAlwaysTop}, {'md:w-[140px] lg:w-[150px] xl:w-[180px]': !labelAlwaysTop}]"
			class="w-full mb-1 md:mb-0 md:justify-end md:mr-3 items-center text-slate-500 dark:text-slate-400 text-sm md:text-base md:text-right relative align-top md:pt-2"
		>
			<slot name="left"></slot>
		</div>
		<div
			:class="[
				{'md:w-[calc(100%-130px)] lg:w-[calc(100%-150px)] xl:w-[calc(100%-180px)]': !labelAlwaysTop && !labelHidden},
			]"
			class="w-full flex items-start justify-start flex-col"
		>
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "FormGridWrapper",
	props: {
		labelAlwaysTop: {
			type: Boolean,
			default: false,
		},
		labelHidden: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
