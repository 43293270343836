<template>
	<div class="d-flex flex-row justify-content-start w-100">
		<font-awesome-icon v-if="hasIcon" class="option-icon-primary" :icon="['far', icon]" fixed-width />
		<div class="option-label">
			<span v-if="hasPrimaryLabel" class="option-label-primary">
				<strong>{{ primary }}</strong>
			</span>
			<span v-if="hasSecondaryLabel" class="option-label-secondary">
				{{ secondary }}
			</span>
		</div>
		<font-awesome-icon v-if="radio" class="option-icon-secondary option-icon-active" :icon="['far', 'circle-dot']" />
		<font-awesome-icon v-if="radio" class="option-icon-secondary option-icon-inactive" :icon="['far', 'circle']" />
		<font-awesome-icon
			v-if="checkbox"
			class="option-icon-secondary option-icon-active"
			:icon="['far', 'check-square']"
		/>
		<font-awesome-icon v-if="checkbox" class="option-icon-secondary option-icon-inactive" :icon="['far', 'square']" />
		<div class="option-msg option-saving-msg">
			<font-awesome-icon :icon="['fad', 'spinner-third']" spin />bezig met opslaan
		</div>
		<div class="option-msg option-saved-msg"><font-awesome-icon :icon="['far', 'check-circle']" />opgeslagen</div>
	</div>
</template>

<script>
export default {
	name: "OptionLabel",
	props: {
		radio: {
			type: Boolean,
			default: false,
		},
		checkbox: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: "",
		},
		primary: {
			type: String,
			default: "",
		},
		secondary: {
			type: String,
			default: "",
		},
	},
	computed: {
		hasIcon: function () {
			return this.icon != "" ? true : false;
		},
		hasPrimaryLabel: function () {
			return this.primary != "" ? true : false;
		},
		hasSecondaryLabel: function () {
			return this.secondary != "" ? true : false;
		},
	},
};
</script>

<style lang="scss">
.option-label {
	z-index: 2;
}
.option-msg {
	position: absolute;
	z-index: 9;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	@extend .flex-column;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 13px;
	border-radius: 4px;
	opacity: 0;
	@include transition(opacity 0.2s ease-in-out);
}
.option-saved-msg {
	background: rgba($tdc-green, 0.85);
}
.option-saving-msg {
	background: rgba($tdc-blue, 0.85);
}
.option-saving,
.input-saving {
	.active {
		.option-saving-msg {
			opacity: 1;
			z-index: 9;
		}
	}
}
.option-saved,
.input-saved {
	.active {
		.option-saved-msg {
			opacity: 1;
			z-index: 9;
		}
	}
}
</style>
