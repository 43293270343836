var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"title":_vm.$t('mol.reviewsPage.title.Reviews'),"subTitle":_vm.subTitle,"breadcrumbs":_vm.breadcrumbs,"adaptSubnav":true}}),_c('SubNav',{attrs:{"subNavItems":_vm.subNavItems}},[_c('template',{slot:"subNavTabContent"},[(!_vm.reviewOnDisplay || _vm.molSubnavHash == 'average')?_c('div',[(_vm.reviewTotalCount == 0)?_c('p',[_vm._v(_vm._s(_vm.$t("mol.reviewsPage.string.No reviews currently")))]):(_vm.reviewTotalCount == 1)?_c('p',[_vm._v(_vm._s(_vm.$t("mol.reviewsPage.string.You have 1 review")))]):_c('div',[_c('p',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("mol.reviewsPage.string.The DJ Company scores an average", { score: _vm.averageReviewScore, reviewCount: _vm.reviewTotalCount, location: _vm.location.Name, }))+" ")]),_c('div',{staticClass:"flex align-middle mb-4"},[_c('StarRating',{staticClass:"text-2xl flex align-middle items-center",attrs:{"rating":_vm.averageReviewScore}}),_c('div',{staticClass:"text-3xl font-bold ml-2"},[_vm._v(" "+_vm._s(_vm.averageReviewScore)+" "),_c('span',{staticClass:"text-base text-slate-500"},[_vm._v(" /10")])])],1),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs ability to read the room') +
							' (' +
							_vm.$t('mol.reviewsPage.label.average') +
							')',"dataValue":_vm.averageCategoryScore('dj_played_according_to_atmosphere_rating__c')}}),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs musical ability') + ' (' + _vm.$t('mol.reviewsPage.label.average') + ')',"dataValue":_vm.averageCategoryScore('dj_played_music_i_requested_rating__c')}}),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs friendliness and service') +
							' (' +
							_vm.$t('mol.reviewsPage.label.average') +
							')',"dataValue":_vm.averageCategoryScore('dj_service_level_rating__c')}}),_vm._l((5),function(n,i){return _c('div',{key:i},[_c('div',{staticClass:"flex flex-row items-stretch my-4"},[_c('div',{staticClass:"w-[130px] text-sm flex mr-2"},[_c('StarRating',{staticClass:"text-2xl flex align-middle items-center text-xs",attrs:{"rating":(i + 1) * 2}})],1),_c('div',{staticClass:"w-full flex align-middle"},[_c('div',{staticClass:"my-auto w-full bg-slate-200 rounded-full h-1 dark:bg-slate-700"},[_c('div',{staticClass:"bg-tdc-blue-500 h-1 rounded-full bg-tdc-blue-500",style:({width: _vm.starRatingPercentage(i + 1) + '%'})})])]),_c('div',{staticClass:"ml-2 w-[100px] text-xs"},[_vm._v(" "+_vm._s(_vm.starRatingPercentage(i + 1))+"% ("+_vm._s(_vm.starRatingCount(i + 1))+") ")])])])})],2)]):(_vm.reviewOnDisplay)?_c('div',[_c('div',{staticClass:"flex align-middle"},[_c('StarRating',{staticClass:"text-2xl flex align-middle items-center",attrs:{"rating":_vm.averageScore([
								_vm.reviewOnDisplay.dj_played_according_to_atmosphere_rating__c,
								_vm.reviewOnDisplay.dj_played_music_i_requested_rating__c,
								_vm.reviewOnDisplay.dj_service_level_rating__c ])}}),_c('div',{staticClass:"text-3xl font-bold ml-2"},[_vm._v(" "+_vm._s(_vm.averageScore([ _vm.reviewOnDisplay.dj_played_according_to_atmosphere_rating__c, _vm.reviewOnDisplay.dj_played_music_i_requested_rating__c, _vm.reviewOnDisplay.dj_service_level_rating__c ]))+" "),_c('span',{staticClass:"text-base text-slate-500"},[_vm._v(" /10")])])],1),_c('div',{staticClass:"text-xl italic mb-4"},[_vm._v(" \""+_vm._s(_vm.notEmpty(_vm.reviewOnDisplay.evaluation_quote__c, _vm.reviewOnDisplay.evaluation_quote_edit__c))+"\" ")]),_c('ContentTitle',[_vm._v(" "+_vm._s(_vm.$t("mol.reviewsPage.title.Client rating of The DJ Company"))+" ")]),_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Reviewer'),"dataValue":_vm.reviewOnDisplay.Name_of_evaluator__c}}),(_vm.reviewOnDisplay.Role_of_evaluator__c != '')?_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Role'),"dataValue":_vm.reviewOnDisplay.Role_of_evaluator__c}}):_vm._e(),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs ability to read the room'),"dataValue":_vm.reviewOnDisplay.dj_played_according_to_atmosphere_rating__c}}),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs musical ability'),"dataValue":_vm.reviewOnDisplay.dj_played_music_i_requested_rating__c}}),_c('DataItem',{attrs:{"starRating":"","dataLabel":_vm.$t('mol.reviewsPage.label.DJs friendliness and service'),"dataValue":_vm.reviewOnDisplay.dj_service_level_rating__c}}),_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Customer feedback'),"dataValue":_vm.notEmpty(
							_vm.reviewOnDisplay.general_comments_regarding_booking__c,
							_vm.reviewOnDisplay.general_comments_regarding_booking_edit__c
						)}}),_c('ContentTitle',[_vm._v(" "+_vm._s(_vm.$t("mol.reviewsPage.title.Event details"))+" ")]),_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Event date'),"dataValue":_vm.partyDate(_vm.reviewOnDisplay.party_dayte__c)}}),_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Event times'),"dataValue":_vm.reviewOnDisplay.Booking__r.begin_time__c + '-' + _vm.reviewOnDisplay.Booking__r.end_time__c}}),_c('DataItem',{attrs:{"dataLabel":_vm.$t('mol.reviewsPage.label.Guest count'),"dataValue":_vm.reviewOnDisplay.Booking__r.number_of_guests__c}})],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }