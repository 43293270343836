<template>
	<div class="flex h-full w-100 items-start flex-col">
		<div class="text-5xl text-slate-300 font-extrabold">404</div>
		<div class="text-xl text-slate-400">Oops! Sorry, we couldn't find the page you were looking for.</div>
	</div>
</template>

<script>
export default {
	name: "LocationNotFound",
};
</script>
