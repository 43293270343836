const urlParams = new URLSearchParams(window.location.search);
const urlHost = window.location.host;

const id = urlParams.get("id");
const ckey = urlParams.get("ckey");
const lkey = urlParams.get("lkey");
const dkey = urlParams.get("dkey");
const ekey = urlParams.get("ekey");
let key;
let type;
let sf_api = "";
if (ckey) {
	key = ckey;
	type = "customer";
	sf_api = "siteapi";
} else if (lkey) {
	key = lkey;
	type = "location";
	sf_api = "locationapi";
} else if (dkey) {
	key = dkey;
	type = "dj";
	sf_api = null; // todo
} else if (ekey) {
	key = ekey;
	type = "customerEvaluation";
	sf_api = "siteapi";
}

console.log(urlHost, "MO", key, type, sf_api);

export function getUserType() {
	return type;
}
export function userTypeIsCustomer() {
	return type === "customer";
}
export function userTypeIsLocation() {
	return type === "location";
}
export function userTypeIsDJ() {
	return type === "dj";
}
export function userTypeIsCustomerEvaluation() {
	return type === "customerEvaluation";
}
export function getId() {
	return id;
}
export function getKey() {
	return key;
}
export function getApi() {
	return sf_api;
}
export function getTld() {
	let tld = "nl";
	if (urlHost.indexOf(".nl") !== -1 || urlHost.indexOf(":3030") !== -1) {
		tld = "nl";
	} else if (urlHost.indexOf(".be") !== -1 || urlHost.indexOf(":3032") !== -1) {
		tld = "be";
	} else if (urlHost.indexOf(".uk") !== -1 || urlHost.indexOf(":3033") !== -1) {
		tld = "uk";
	}
	return tld;
}
