import Vue from "vue";
import App from "./App.vue";
import {BootstrapVue} from "bootstrap-vue";
import router from "./router";
import store from "./store";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";
import VueDayjs from "vue-dayjs-plugin";
import currency from "currency.js";
import "dayjs/locale/nl";
import "./icons";
import {i18n} from "@/i18n";
import VueGtm from "@gtm-support/vue2-gtm";
import {flare} from "@flareapp/flare-client";
import {flareVue} from "@flareapp/flare-vue";

flare.light(process.env.VUE_APP_FLARE_KEY);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

Vue.use(VueDayjs);
Vue.use(BootstrapVue);
Vue.use(flareVue);

Vue.config.productionTip = true;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
	methods: {
		$currency: (value, precision) => currency(value, {symbol: "", decimal: ",", separator: ".", precision: precision}),
	},
});

Vue.use(VueGtm, {
	id: "GTM-M32KDNP",
	debug: process.env.NODE_ENV !== "production", // Whether or not display console logs debugs (optional)
	vueRouter: router,
});

document.addEventListener("gesturestart", function (e) {
	e.preventDefault();
});
