<template>
	<div class="mb-5 pb-2 border-b border-slate-200 dark:border-slate-600 font-bold">
		<FormGridWrapper :labelHidden="!adaptForm" class="!pb-0">
			<template slot="right">
				<slot></slot>
			</template>
			>
		</FormGridWrapper>
	</div>
</template>

<script>
import FormGridWrapper from "@/components/location/FormGridWrapper";

export default {
	name: "ContentTitle",
	props: {
		adaptForm: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		FormGridWrapper,
	},
};
</script>
