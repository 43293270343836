import {getId, getKey, getApi} from "@/mo";
import store from "@/store";

import axios from "axios";
import qs from "qs";

export function setSalesForceDomain(env) {
	console.log("setSalesForceDomain", env);
	if (env === "production") {
		return "https://thedjcompany.my.salesforce-sites.com/services/apexrest/";
	} else if (env === "testing") {
		return "https://thedjcompany--partial.sandbox.my.salesforce-sites.com/services/apexrest/";
	}
	return "https://thedjcompany--dev.sandbox.my.salesforce-sites.com/services/apexrest/";
}

const sf_domain = setSalesForceDomain(process.env.NODE_ENV);

const url = sf_domain + getApi() + "?id=" + getId() + "&key=" + getKey();

if (!getId() || !getKey()) {
	``;
	window.location = "https://thedjcompany.nl";
}

export function getSalesForce(action, query = false, payload = false) {
	let get_url = url + "&action=" + action;
	if (query) {
		if (typeof query === "string") {
			get_url += "&query=" + query;
		} else if (typeof query === "object") {
			get_url += "&" + qs.stringify(query);
		}
	}
	if (!payload) {
		return axios.get(get_url).catch(function (err) {
			store.dispatch("handleError", err.response);
		});
	} else {
		return axios.get(get_url + "&" + qs.stringify(payload, {arrayFormat: "comma"})).catch(function (err) {
			store.dispatch("handleError", err.response);
		});
	}
}

export function postSalesForce(payload) {
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			responseEncoding: "utf8",
			charset: "UTF-8",
		},
	};
	const data = qs.stringify(payload.data);
	return axios.post(url + "&action=" + payload.action, data, config).catch(function (err) {
		store.dispatch("handleError", err);
		throw err;
	});
}
