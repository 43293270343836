<template>
	<div class="mb-4" v-if="showSpaces">
		<h3 v-if="!locationIsPrivate"><strong>Feestlocatie bijzonderheden en ruimtes</strong></h3>
		<h3 v-if="locationIsPrivate"><strong>Bijzonderheden feestlocatie</strong></h3>
		<div :class="{'p-2 p-md-3 bg-white rounded mb-3 w-100': !canEditSpecs}">
			<b-form-group v-if="spaces.length > 1" class="m-0 p-0 dashed-border mb-3">
				<b-form-select
					id="spaceSelect"
					class="ahref border-0 p-0 m-0 w-100"
					style="width: 100% !important"
					v-model="selectedSpace"
					:disabled="isConfirmed"
					@change="setSpace()"
				>
					<option
						:selected="available_space.Id == space.Id"
						:value="available_space.Id"
						v-for="available_space in spaces"
						:key="available_space.Id"
					>
						{{ available_space.Space_Name__c }}
					</option>
				</b-form-select>
			</b-form-group>

			<span v-if="!locationIsPrivate">
				<h3 v-if="spaces.length == 1 && space.Space_Name__c != 'Hoofd Ruimte'">Ruimte: {{ space.Space_Name__c }}</h3>
			</span>
			<b-overlay :show="isLoading" rounded="sm">
				<b-form>
					<div class="mb-3" :aria-hidden="isLoading ? 'true' : null">
						<div v-if="!canEditSpecs">
							<p><strong>Bijzonderheden</strong></p>
							<div v-if="!hasSpecs && !space.Details__c && !editingSpecs">Geen bijzonderheden vermeld</div>
							<ul class="list-unstyled">
								<li v-for="(spec, key) in specsSet" :key="key">
									<font-awesome-icon class="text-warning mr-1" :icon="['fas', 'exclamation-triangle']" />
									<span v-html="specs[spec].desc" />
								</li>
							</ul>
						</div>
						<b-form-group
							label="Bijzonderheden"
							label-for="Bijzonderheden"
							class="border-bottom pb-3"
							v-if="canEditSpecs"
						>
							<template slot="label"> Bijzonderheden </template>
							<div class="row row-cols-1 row-cols-md-2 mx-n1">
								<b-form-checkbox
									v-for="(spec, key) in specs"
									:key="key"
									:id="key"
									button
									v-model="specs[key].value"
									:value="true"
									name="ceremoniemeester"
									button-variant="outline-tdc"
									class="d-block mb-2 mdc-radio-check col px-1"
									:disabled="isConfirmed"
									:class="getInputClass(spec)"
									@change.native="updateSpecs(spec)"
								>
									<OptionLabel checkbox :primary="spec.title" :secondary="spec.desc" :icon="spec.icon" />
								</b-form-checkbox>
							</div>
						</b-form-group>
					</div>
					<div v-if="!locationIsPrivate" class="border-0">
						<div v-if="!hasSpecs && !space.Details__c">Geen bijzonderheden vermeld</div>
						<p v-if="space.Details__c">
							<label><strong>Bijzonderheden toelichting</strong></label
							><br />
							{{ space.Details__c }}
						</p>
					</div>

					<b-form-group
						v-if="canEditSpecs"
						label="Toelichting"
						label-for="Details__c"
						:class="getInputClass('Details__c')"
					>
						<template slot="label">
							Toelichting
							<b-form-text v-if="locationIsPrivate" class="text-left mb-3">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" />
								<strong>LET OP:</strong> Deze gegevens gebruiken wij ter voorbereiding op de boeking. Ze worden dus
								meestal niet direct doorgelezen. Heb je nu een vraag? Stel deze dan via
								<a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a> of via één van de andere
								contactopties onderaan deze pagina.
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="space.Details__c"
								@input.native="delayUpdateSpecs('Details__c')"
								rows="5"
								maxlength="500"
								id="Details__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
				</b-form>
			</b-overlay>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import OptionLabel from "@/components/customer/OptionLabel";
import InputFeedback from "@/components/customer/InputFeedback";

export default {
	name: "Spaces",
	components: {FontAwesomeIcon, OptionLabel, InputFeedback},
	computed: {
		...mapGetters({
			location: "getLocation",
			locationContact: "getLocationContact",
			booking: "getBooking",
			spaces: "getSpaces",
			isLoading: "getLoadingState",
			infoEmailAddress: "getInfoEmailAddress",
			isConfirmed: "isConfirmed",
			loadingInput: "getLoadingInput",
		}),
		space: function () {
			return this.booking.Location_Space__r;
		},
		canEditSpecs: function () {
			return this.locationIsPrivate;
		},
		showSpaces: function () {
			return "Location_Portal_First_Visit__c" in this.location || this.locationIsPrivate;
		},
		specsSet: function () {
			delete this.specs.Details__c;
			return Object.keys(this.specs).filter((key) => this.specs[key].value);
		},
		checkSpecs() {
			delete this.specs.Details__c;
			return Object.keys(this.specs);
		},
		hasSpecs: function () {
			return this.specsSet.length > 0;
		},
		locationIsPrivate: function () {
			return this.location.Location_private__c;
		},
	},
	data() {
		return {
			selectedSpace: "",
			editingSpecs: false,
			specs: {
				no_free_electric_network__c: {
					title: "Geen vrije stroomgroep",
					desc: "Er is niet minimaal één vrije stroomgroep beschikbaar",
					icon: "plug",
					value: false,
				},
				no_free_parking__c: {
					title: "Niet gratis parkeren",
					desc: "Er is bij de feestlocatie geen gratis parkeerplek voor een auto met aanhanger",
					icon: "ban-parking",
					value: false,
				},
				not_ground_floor__c: {
					title: "Niet op de begane grond",
					desc: "Het feest is niet op de begane grond.",
					icon: "building",
					value: false,
				},
				not_ground_floor_no_elevator__c: {
					title: "Geen lift",
					desc: "Het feest is niet op de begane grond en er is geen lift",
					icon: "elevator",
					value: false,
				},
				not_paved_road_to_unload__c: {
					title: "Geen verharde weg naar de laad- en loslocatie",
					desc: "Er loopt geen verharde weg naar de laad- en loslocatie.",
					icon: "road",
					value: false,
				},
				not_paved_road_unload_to_gig__c: {
					title: "Geen verharde weg naar waar we draaien",
					desc: "Er loopt geen verharde weg van de laad- en loslocatie naar de plek waar we mogen draaien.",
					icon: "truck-ramp-box",
					value: false,
				},
				small_doors__c: {
					title: "Smalle doorgangen",
					desc: "Er zijn smalle doorgangen op de route naar waar we mogen draaien.",
					icon: "compress",
					value: false,
				},
				unload_25_meter__c: {
					title: "Meer dan 25 meter van laad- en loslocatie naar waar we mogen draaien",
					desc: "De laad- en loslocatie is verder dan 25 meter verwijderd van de plaats waar we mogen draaien.",
					icon: "ruler",
					value: false,
				},
			},
		};
	},
	methods: {
		setSpace() {
			event.preventDefault();
			this.$store.dispatch("postBooking", {
				action: "setSpace",
				data: {spaceId: this.selectedSpace},
				setBooking: true,
			});
		},
		updateSpecs(input) {
			var new_specs = {};
			this.checkSpecs.forEach((spec) => {
				new_specs[spec] = this.specs[spec].value;
			});
			new_specs.Details__c = this.space.Details__c;
			this.$store.dispatch("postBooking", {
				action: "updateSpecs",
				data: new_specs,
				setBooking: true,
				input: input,
			});
			this.editingSpecs = false;
		},
		delayUpdateSpecs(input) {
			clearTimeout(this.delay);
			this.delay = setTimeout(
				function () {
					this.updateSpecs(input);
				}.bind(this),
				2000
			);
		},
		setSpecs() {
			Object.keys(this.specs).forEach((key) => {
				this.specs[key].value = this.space[key] ? true : false;
			});
		},
		getInputClass(input) {
			//var value = this.wishes[input]
			if (input == this.loadingInput.name) {
				if (this.loadingInput.status == "saving") return "input-saving";
				else if (this.loadingInput.status == "saved") {
					this.delay = setTimeout(
						function () {
							this.$root.$emit("bv::hide::popover");
						}.bind(this),
						4000
					);
					return "input-saved";
				}
			}
			return "";
		},
	},

	mounted() {
		this.selectedSpace = this.space ? this.space.Id : "";
		this.setSpecs();
	},
};
</script>
