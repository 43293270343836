<template>
	<div :class="parentClass">
		<ul v-if="!location.Location_private__c" class="list-unstyled usp-list mb-0">
			<li v-if="hasParties">
				<font-awesome-icon :icon="['far', 'check']" class="text-success" />
				<span v-if="location.Location_Leads_confirmed_total__c > 3"
					>{{ location.Location_Leads_confirmed_total__c }} feesten gedraaid bij {{ locationName }}</span
				>
				<span v-else>We draaien vaker bij {{ locationName }}</span>
			</li>
			<li v-if="locationReviewCount > 0">
				<font-awesome-icon :icon="['far', 'check']" class="text-success" />
				<span class="w-100"
					>Kortom, veel ervaring!
					<a target="_blank" :href="location.URL_external_website_location__c"
						>Lees klantervaringen van ons bij {{ locationName }}</a
					></span
				>
			</li>
			<li v-if="locationContact != '' && locationContact.Name != 'Hoofd Contact'">
				<font-awesome-icon :icon="['far', 'check']" class="text-success" />
				<span class="w-100">We kennen {{ locationContact.Name }}, onze contactpersoon bij {{ locationName }}</span>
			</li>
		</ul>
		<ul v-if="location.Location_private__c" class="list-unstyled usp-list mb-0">
			<li>
				<font-awesome-icon :icon="['far', 'check']" class="text-success" />
				<span
					>Meest geboekt op thuis- en privélocaties van <span v-if="isBelgian" v-text="'Vlaanderen'" /><span
						v-else
						v-text="'Nederland'"
				/></span>
			</li>
			<li>
				<font-awesome-icon :icon="['far', 'check']" class="text-success" />
				<span
					>Best beoordeeld op thuis- en privélocaties.
					<a target="_blank" href="https://thedjcompany.nl/klantervaringen/">Lees alle klantervaringen</a></span
				>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
	name: "LocationSpecs",
	props: ["parent"],
	components: {
		FontAwesomeIcon,
	},
	computed: {
		...mapGetters({
			location: "getLocation",
			hasLocation: "hasLocation",
			isLoading: "getLoadingState",
			locationContact: "getLocationContact",
			hasEvaluation: "hasEvaluation",
			getNumberOfShows: "getNumberOfShows",
			partyType: "getPartyType",
			isBelgian: "isBelgian",
		}),
		showImage: function () {
			return (
				this.parent == "sidebar" &&
				!this.location.Location_private__c &&
				"location_profile_thumb_url__c" in this.location
			);
		},
		locationRating: function () {
			return "LocationReviewsForLocScore__c" in this.location ? this.location.LocationReviewsForLocScore__c : 9.2;
		},
		locationReviewCount: function () {
			return "LocationReviewsForLocCount__c" in this.location ? this.location.LocationReviewsForLocCount__c : 0;
		},
		hasParties() {
			return (
				"Location_Leads_confirmed_total__c" in this.location && this.location.Location_Leads_confirmed_total__c > 0
			);
		},
		locationName: function () {
			return this.location.Location_private__c ? "Privé locatie" : this.location.Name;
		},
		parentClass: function () {
			return this.parent == "sidebar" ? "inside-sidebar" : "outside-sidebar";
		},
	},
};
</script>

<style lang="scss" scoped>
.inside-sidebar {
	p,
	li {
		@include media-breakpoint-up(md) {
			font-size: 90%;
		}
	}
	li {
		@extend .mb-1;
	}
}
</style>
