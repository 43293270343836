<template>
	<span v-if="booking.status_booking__c">
		<Alert v-if="error != '' && isActive" alertType="warning">
			{{ $t("common.pricing.message." + error, {phone: customPhoneNumber, whatsapp: whatsappnumber}) }}
		</Alert>
		<div :class="calculatorStyling">
			<span v-if="extendedStyling">
				<span class="font-weight-bold">Pas de prijzen aan</span>
				<small class="d-block border-bottom pb-3"
					>Kies een andere feestdatum of pas de feesttijden of het aantal gasten aan om andere prijzen te
					bekijken.</small
				>
			</span>
			<ul class="booking-summary-items">
				<li v-if="displayQuoteName" class="booking-summary-item d-inline-block">
					<small> offerte nr: </small>
					<div class="input-group">
						<span class="unset equal-element-style">{{ quote.Name }}</span>
					</div>
				</li>
				<li id="booking-summary-item-date" class="booking-summary-item">
					<small :class="{'text-warning': showDateFieldError}">
						<font-awesome-icon
							v-if="showDateFieldError"
							:icon="['fal', 'exclamation-triangle']"
							fa-fw
							class="mr-1"
						/>feestdatum
						<img
							v-if="showDateFieldError && !extendedStyling"
							src="~@/assets/images/error-arrow-left.png"
							class="error-arrow-left"
						/>
					</small>
					<span class="equal-element-style" :class="{'active-link': isActive}">
						<div class="w-100 d-flex">
							<span v-if="form.partyDate">{{ $date(form.partyDate).locale("nl").format("DD-MM-YYYY") }}</span>
							<span v-else>Kies een feestdatum</span>
							<font-awesome-icon
								v-if="isActive"
								size="xs"
								:class="[{'ml-1': !extendedStyling}, {'ml-auto mr-n1': extendedStyling}]"
								class="position-relative right-0 text-primary align-self-center"
								:icon="['far', 'chevron-down']"
								style="width: 8px; margin-top: -4px"
							/>
						</div>
					</span>
					<div class="date-selector">
						<Datepicker
							wrapper-class="w-100 h-100 position-absolute"
							input-class="w-100 h-100 position-absolute date-selector-input"
							v-model="form.partyDate"
							monday-first
							:language="nl"
							:disabled-dates="disabledDates"
							:disabled="!isActive"
							:higlighted="initialChosenDate"
						/>
					</div>
				</li>
				<li id="booking-summary-item-times" class="booking-summary-item">
					<small :class="{'text-warning': showTimesFieldError}">
						<img
							v-if="showTimesFieldError && !extendedStyling"
							src="~@/assets/images/error-arrow-right.png"
							class="error-arrow-right"
						/>
						<font-awesome-icon
							v-if="showTimesFieldError"
							:icon="['fal', 'exclamation-triangle']"
							fa-fw
							class="mr-1"
						/>feesttijden
					</small>
					<div class="input-group">
						<select
							:disabled="!isActive"
							:class="{'active-link': isActive}"
							class="unset equal-element-style"
							required
							v-model="form.startTime"
						>
							<option v-for="time in timeChoices('08:00')" :key="time.value">{{ time.value }}</option>
						</select>
						<font-awesome-icon
							v-if="isActive && !extendedStyling"
							size="xs"
							class="ml-1 text-primary align-self-center"
							:icon="['far', 'chevron-down']"
							style="width: 8px; margin-top: -4px"
						/>
						<span :class="{'text-primary': isActive}" class="d-inline-block ml-1 equal-element-style">-</span>
						<select
							:disabled="!isActive"
							:class="{'active-link': isActive}"
							class="ml-1 unset equal-element-style"
							required
							v-model="form.endTime"
						>
							<option v-for="time in timeChoices('13:00')" :key="time.value">{{ time.value }}</option>
						</select>
						<font-awesome-icon
							v-if="isActive && !extendedStyling"
							size="xs"
							class="ml-1 text-primary align-self-center"
							:icon="['far', 'chevron-down']"
							style="width: 8px; margin-top: -4px"
						/>
					</div>
				</li>
				<li class="booking-summary-item" :class="{'mr-0': extendedStyling}">
					<small :class="{'text-warning': showGuestsFieldError}">
						<img
							v-if="showGuestsFieldError && !extendedStyling"
							src="~@/assets/images/error-arrow-right.png"
							class="error-arrow-right"
						/>
						<font-awesome-icon
							v-if="showGuestsFieldError"
							:icon="['fal', 'exclamation-triangle']"
							fa-fw
							class="mr-1"
						/>aantal gasten
					</small>
					<div>
						<select
							:disabled="!isActive"
							:class="{'active-link': isActive}"
							class="unset equal-element-style"
							required
							v-model="form.nGuests"
						>
							<option value="40">Tot 40</option>
							<option value="50">Tot 50</option>
							<option value="75">Tot 75</option>
							<option value="100">Tot 100</option>
							<option value="150">Tot 150</option>
							<option value="200">Tot 200</option>
							<option value="300">Tot 300</option>
							<option value="301">300+</option>
						</select>
						<font-awesome-icon
							v-if="isActive && !extendedStyling"
							size="xs"
							class="ml-1 text-primary"
							:icon="['far', 'chevron-down']"
							style="width: 8px; margin-top: -4px"
						/>
					</div>
				</li>
			</ul>
		</div>
	</span>
</template>

<script>
import {mapGetters} from "vuex";
import Datepicker from "vuejs-datepicker";
import {nl} from "vuejs-datepicker/dist/locale";
import Alert from "@/components/customer/Alert";
import {timeChoices} from "@/utilities";

export default {
	components: {
		Alert,
		Datepicker,
	},
	props: {
		active: {
			type: Boolean,
			default: true,
		},
		displayQuoteDetails: {
			type: Boolean,
			default: false,
		},
		displayQuoteName: {
			type: Boolean,
			default: false,
		},
		quote: {
			type: Object,
			default: null,
		},
		extendedStyling: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			nl: nl,
			disabledDates: {
				to: new Date(new Date().setDate(new Date().getDate() - 1)),
			},
			form: {
				partyDate: "",
				startTime: "20:00",
				endTime: "23:00",
				nGuests: 75,
			},
			objectModel: [],
			error: "",
			wasValidated: false,
			showFields: false,
			showDateFieldError: false,
			showTimesFieldError: false,
			showGuestsFieldError: false,
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			prices: "getPrices",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			partyType: "getPartyType",
			fixedPrices: "isFixed",
			isOrderInProgress: "isOrderInProgress",
			isLoading: "getLoadingState",
			isBelgian: "isBelgian",
			infoEmailAddress: "getInfoEmailAddress",
			customPhoneNumber: "getCustomPhoneNumber",
		}),
		whatsappnumber() {
			return this.$t("common.general.contact.whatsapp number shown");
		},
		initialChosenDate() {
			let dates = [this.form.partyDate];
			return dates;
		},
		minDate: function () {
			return this.$dayjs().format("YYYY-MM-DD");
		},
		hasError: function () {
			return "PricingResult__c" in this.booking;
		},
		hasPartyDate: function () {
			return this.form.partyDate != "" ? true : false;
		},
		hasPartyGuests: function () {
			return this.form.nGuest != "" ? true : false;
		},
		hasPartyTimes: function () {
			return this.djHours != "" && this.djHours != "n.t.b.";
		},
		hasValidPartyTimes: function () {
			return this.djHours <= 800;
		},
		hasValidPartyGuests: function () {
			return this.form.nGuests <= 300;
		},
		djHours: function () {
			let start = this.form.startTime.replace(":", "");
			let end = this.form.endTime.replace("00:00", "24:00").replace(":", "");
			if (start > end) end = parseInt(end) + 2400;
			return end - parseInt(start);
		},
		isActive: function () {
			if (!this.active) return false;
			else if (this.fixedPrices) return false;
			else if (this.isOrderInProgress) return false;
			else if (this.displayQuoteDetails) return false;
			return true;
		},
		calculatorStyling: function () {
			return this.extendedStyling ? "extended-price-calculator-styling" : "minimal-price-calculator-styling";
		},
		pricingErrorCode() {
			if (this.hasPrices) {
				if (
					this.prices["p_junior"].p_errorCode === this.prices["p_a_class"].p_errorCode &&
					this.prices["p_a_class"].p_errorCode === this.prices["p_premium"].p_errorCode
				) {
					return this.prices["p_junior"].p_errorCode;
				}
			}
			return "";
		},
		hasPrices() {
			return this.prices.p_a_class !== undefined;
		},
	},
	methods: {
		timeChoices: timeChoices,
		validateFields() {
			this.wasValidated = true;
			this.error = "";
			this.showDateFieldError = false;
			this.showTimesFieldError = false;
			this.showGuestsFieldError = false;
			if (this.isActive) {
				if (this.pricingErrorCode === "DateChosen") {
					this.error = this.prices["p_junior"].p_message;
					this.showDateFieldError = true;
				} else if (this.form.partyDate == "" || this.form.partyDate < this.minDate) {
					this.error = "Select a valid date in the future";
					this.showDateFieldError = true;
				} else if (this.pricingErrorCode === "HoursChosen") {
					this.error = this.prices["p_junior"].p_message;
					this.showTimesFieldError = true;
				} else if (this.pricingErrorCode === "GuestCount") {
					this.error = this.prices["p_junior"].p_message;
					this.showGuestsFieldError = true;
				}
			}
		},
	},
	watch: {
		form: {
			handler: function () {
				this.$root.$emit("setDate", this.form.partyDate);
				this.$root.$emit("setStartTime", this.form.startTime);
				this.$root.$emit("setEndTime", this.form.endTime);
				this.$root.$emit("setNGuests", this.form.nGuests);

				if (this.isActive) {
					this.showFields = false;
				}
			},
			deep: true,
		},
		prices() {
			this.validateFields();
		},
	},
	mounted() {
		if (this.displayQuoteDetails) {
			this.objectModel = this.quote;
		} else {
			this.objectModel = this.booking;
		}
		if ("Party_Date__c" in this.objectModel) this.form.partyDate = this.objectModel.Party_Date__c;
		if ("begin_time__c" in this.objectModel) this.form.startTime = this.objectModel.begin_time__c;
		if ("end_time__c" in this.objectModel) this.form.endTime = this.objectModel.end_time__c;
		if ("number_of_guests__c" in this.objectModel) {
			if (this.objectModel.number_of_guests__c <= 40) {
				this.form.nGuests = 40;
			} else if (this.objectModel.number_of_guests__c <= 50) {
				this.form.nGuests = 50;
			} else if (this.objectModel.number_of_guests__c <= 75) {
				this.form.nGuests = 75;
			} else if (this.objectModel.number_of_guests__c <= 100) {
				this.form.nGuests = 100;
			} else if (this.objectModel.number_of_guests__c <= 150) {
				this.form.nGuests = 150;
			} else if (this.objectModel.number_of_guests__c <= 200) {
				this.form.nGuests = 200;
			} else if (this.objectModel.number_of_guests__c <= 300) {
				this.form.nGuests = 300;
			} else if (this.objectModel.number_of_guests__c > 300) {
				this.form.nGuests = 301;
			}
		}
		if (this.isActive) {
			this.$root.$on("setDate", (partyDate) => (this.form.partyDate = partyDate));
			this.$root.$on("setStartTime", (startTime) => (this.form.startTime = startTime));
			this.$root.$on("setEndTime", (endTime) => (this.form.endTime = endTime));
			this.$root.$on("setNGuests", (nGuests) => (this.form.nGuests = nGuests));
		}
		this.validateFields();
	},
};
</script>

<style lang="scss">
.equal-element-style {
	font-size: 1rem !important;
	padding: 0 !important;
	margin: 0 !important;
	line-height: 1rem !important;
	height: 1rem;
	height: 20px !important;
	@include media-breakpoint-up(md) {
		font-size: 1.2rem !important;
		height: 22px !important;
	}
}
.unset {
	background: none !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}
.active-link {
	@extend a;
	color: $tdc-blue !important;
	cursor: pointer !important;
	text-decoration: underline !important;
}
.date-selector {
	cursor: pointer;
	//opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
}
.date-selector-input {
	opacity: 0;
	cursor: pointer;
}
.vdp-datepicker__calendar {
	z-index: 9999 !important;
	&.forgotten-date-input {
		bottom: -50px;
		right: 0;
		@include media-breakpoint-down(sm) {
			right: -40px;
		}
	}
	.cell.selected {
		background: $tdc-blue !important;
		color: #fff;
		border-radius: 50%;
	}
}
input[type="date"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}
.booking-summary-items {
	@extend .list-unstyled;
	@extend .d-flex;
	@extend .justify-content-between;
	@extend .flex-wrap;
	@extend .mb-1;
	@extend .rounded;
	@extend .py-2;
	@extend .py-md-3;
	width: 100%;
	position: relative;
}
.booking-summary-item {
	@extend .d-flex;
	@extend .flex-column;
	@extend .justify-content-start;
	position: relative;
	padding-left: 0px;
	margin: 0 0px 10px 0;
	@include media-breakpoint-up(md) {
		margin: 0;
		line-height: 1.4rem;
	}
	svg {
		margin-right: 5px;
		@include media-breakpoint-up(md) {
			margin-right: 15px;
		}
	}
	small {
		font-size: 0.7rem;
		display: block;
		margin-bottom: 2px;
		@include media-breakpoint-up(md) {
			font-size: 0.8rem;
			margin-bottom: 5px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 0.9rem;
		}
		svg {
			font-size: 15px;
			margin-right: 5px;
		}
	}
	.error-arrow-left {
		position: absolute;
		top: -30px;
		max-width: 30px;
		@include media-breakpoint-up(md) {
			max-width: 36px;
			position: absolute;
			top: -30px;
			margin-left: 4px;
		}
	}
	.error-arrow-right {
		position: absolute;
		top: -30px;
		left: -32px;
		max-width: 30px;
		@include media-breakpoint-up(md) {
			max-width: 36px;
			position: absolute;
			top: -30px;
			left: -43px;
			margin-left: 4px;
		}
	}
}
.b-calendar {
	min-width: 298px;
}
.b-calendar-inner {
	width: 100% !important;
}
.b-calendar-nav {
	svg {
		font-size: 16px;
		margin: 0px;
	}
}
.b-calendar-grid {
	border-radius: 0px;
	border: none;
	border-top: 1px solid #dee2e6;
	border-radius: 0 0 0.3rem 0.3rem;
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
}
.b-calendar-grid-body {
	.btn {
		padding: 9px;
		font-weight: 400 !important;
		font-size: 0.8rem;
		border-radius: 0.3rem !important;
		display: block !important;
		width: 100%;
		color: $tdc-blue !important;
		text-decoration: underline;
		span {
		}
		@include media-breakpoint-up(sm) {
			font-size: 1rem;
			padding: 10px;
		}
		&.active {
			color: #fff !important;
		}
	}
}
.b-calendar-grid-help {
	@extend .d-none;
}
.minimal-price-calculator-styling {
	.booking-summary-items {
		@extend .flex-row;
		max-width: 450px;
		@include media-breakpoint-up(md) {
			max-width: 600px;
		}
	}
	.unset {
		all: unset;
		-webkit-appearance: none;
	}
}
.extended-price-calculator-styling {
	padding: 1em;
	padding-bottom: 0px;
	border-radius: 4px;
	background-color: #eeeeee;
	display: inline;
	#booking-summary-item-date {
		@include media-breakpoint-up(sm) {
			margin-right: 15px;
		}
	}
	#booking-summary-item-times {
		@include media-breakpoint-up(sm) {
			margin-right: 15px;
		}
		.input-group {
			@extend .d-flex;
			@extend .flex-row;
			select {
				@extend .flex-fill;
			}
		}
	}
	select:disabled {
		color: #000 !important;
	}

	@extend .d-flex;
	@extend .justify-content-center;
	@extend .justify-content-md-start;
	@extend .flex-column;
	.booking-summary-items {
		display: inline-flex !important;
		@extend .flex-column;
		@extend .flex-sm-row;
		.booking-summary-item {
			@extend .flex-fill;
		}
	}
	.active-link {
		box-sizing: border-box !important;
		background-color: #fff !important;
		border-radius: 4px !important;
		padding: 4px 10px !important;
		display: inline-block;
		color: $tdc-blue !important;
		font-size: 1rem !important;
		-webkit-appearance: menulist;
		@include media-breakpoint-up(md) {
			font-size: 1.2rem !important;
			height: 40px !important;
		}
	}
	.equal-element-style {
		line-height: 32px !important;
		height: 40px !important;
		@extend .d-flex;
		@extend .align-items-center;
	}
}
</style>
