<template>
	<div class="py-4 text-sm w-full">
		<div v-if="whatWeDo" class="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:grid-cols-4 text-center">
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-allinprijs-2.png" />
				{{ $t("mol.whatWeDo.string.All in pricing, no surprises") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-5050-2.png" />
				{{ $t("mol.whatWeDo.string.Settle invoice pre of post event") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-100gratis-2.png" />
				{{ $t("mol.whatWeDo.string.Free cancellation 14 days before party date") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-moneyback-2.png" />
				{{ $t("mol.whatWeDo.string.No cure, no pay") }}
			</div>
		</div>
		<div v-if="whatYouGet" class="grid grid-cols-2 gap-3 lg:grid-cols-4 text-center">
			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					alt="Huur licht en geluid"
					src="~@/assets/images/illustration-speaker.png"
				/>
				{{ $t("mol.whatYouGet.string.PA and light show equipment rental") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" alt="Reserve DJ" src="~@/assets/images/illustration-backup-dj.png" />
				{{ $t("mol.whatYouGet.string.Backup DJ") }}
			</div>

			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-speech.png"
					alt="Afstemming met feestlocatie"
				/>
				{{ $t("mol.whatYouGet.string.Arrange and fine tune with the venue") }}
			</div>

			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-parking.png"
					alt="Reis- en parkeerkosten"
				/>
				{{ $t("mol.whatYouGet.string.Travel and parking costs") }}
			</div>
			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-checklist.png"
					alt="Persoonlijk wensenformulier"
				/>
				{{ $t("mol.whatYouGet.string.Personal requirements form") }}
			</div>
			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-music-notes.png"
					alt="Muzikale voorbereiding"
				/>
				{{ $t("mol.whatYouGet.string.Musical preparation") }}
			</div>
			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-djbooth.png"
					alt="Op- en afbouw apparatuur"
				/>
				{{ $t("mol.whatYouGet.string.Construction and dismantling of equipment") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-shield.png" alt="Verzekeringen" />
				{{ $t("mol.whatYouGet.string.Book securely") }}
			</div>
			<div>
				<img class="w-20 mx-auto dark:contrast-50" src="~@/assets/images/illustration-21percent.png" alt="Btw" />
				{{ $t("mol.whatYouGet.string.vat") }}
			</div>
			<div>
				<img
					class="w-20 mx-auto dark:contrast-50"
					src="~@/assets/images/illustration-clock.png"
					alt="Non-stop draaien"
				/>
				{{ $t("mol.whatYouGet.string.Non-stop music") }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "BookingIncludes",
	props: {
		whatWeDo: {
			type: Boolean,
			default: false,
		},
		whatYouGet: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
