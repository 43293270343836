<template>
	<span>
		<InputItem
			v-if="!onboarding"
			v-model="space.Space_Name__c"
			inputName="Space_Name__c"
			:inputLabel="$t('mol.spaceForm.label.Name')"
			extraSpaceBottom
			:error="formErrors.spaceErrorSpace_Name__c"
			:errorMessage="$t('mol.spaceForm.message.Please enter a venue space name')"
			@keypress="formErrors.spaceErrorSpace_Name__c = false"
		/>
		<InputItem
			v-model="space.not_ground_floor__c"
			inputName="not_ground_floor__c"
			inputType="checkbox"
			:inputLabel="$t('mol.spaceForm.label.Space details')"
			:inputSubLabel="$t('mol.spaceForm.label.For internal use for the DJ booking only')"
			inputLabelStretch
			:choices="[{value: true, label: $t('mol.spaceForm.label.Setup area is not on the ground floor')}]"
		/>
		<InputItem
			v-model="space.not_ground_floor_no_elevator__c"
			inputName="not_ground_floor_no_elevator__c"
			inputType="checkbox"
			:choices="[
				{value: true, label: $t('mol.spaceForm.label.Space is not on the ground floor and there is no elevator')},
			]"
		/>
		<InputItem
			v-model="space.not_paved_road_to_unload__c"
			inputName="not_paved_road_to_unload__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Path to unloading area is not paved')}]"
		/>
		<InputItem
			v-model="space.not_paved_road_unload_to_gig__c"
			inputName="not_paved_road_unload_to_gig__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Unpaved path to the setup area')}]"
		/>
		<InputItem
			v-model="space.no_free_electric_network__c"
			inputName="no_free_electric_network__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.No free electrical outlet groups available')}]"
		/>
		<InputItem
			v-model="space.small_doors__c"
			inputName="small_doors__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Small entrance to setup area')}]"
		/>
		<InputItem
			v-model="space.unload_25_meter__c"
			inputName="unload_25_meter__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Distance to loading area is more than 25m')}]"
		/>
		<InputItem
			v-model="space.no_free_parking__c"
			inputName="no_free_parking__c"
			inputType="checkbox"
			:choices="[{value: true, label: $t('mol.spaceForm.label.No free parking spaces')}]"
		/>
		<InputItem
			v-model="space.Details__c"
			inputName="Details__c"
			:inputLabel="$t('mol.spaceForm.label.Additional info')"
			:inputSubLabel="$t('mol.spaceForm.label.For internal use for the DJ booking only')"
			inputType="textarea"
			extraSpaceBottom
			extraSpaceTop
		/>
		<FormGridWrapper>
			<template slot="right">
				<Alert>
					{{ $t("mol.spaceForm.string.We use the above information") }}
				</Alert>
			</template>
		</FormGridWrapper>
		<InputItem
			v-model="space.space_attr_size__c"
			inputType="number"
			inputName="space_attr_size__c"
			:inputLabel="$t('mol.spaceForm.label.Space size')"
			inputSubLabel="m2"
			:error="formErrors.spaceErrorspace_attr_size__c"
			:errorMessage="$t('mol.spaceForm.message.Please enter only numbers')"
			@keypress="formErrors.spaceErrorspace_attr_size__c = false"
		/>
		<InputItem
			v-model="space.space_attr_soundlimit__c"
			inputName="space_attr_soundlimit__c"
			inputType="checkbox"
			:inputLabel="$t('mol.spaceForm.label.Volume restrictions')"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Venue space has volume restrictions')}]"
		/>
		<InputItem
			v-model="space.space_attr_maxdb__c"
			inputType="number"
			inputName="space_attr_maxdb__c"
			:inputLabel="$t('mol.spaceForm.label.Max volume')"
			inputSubLabel="dB"
			:error="formErrors.spaceErrorspace_attr_maxdb__c"
			:errorMessage="$t('mol.spaceForm.message.Please enter only numbers')"
			@keypress="formErrors.spaceErrorspace_attr_maxdb__c = false"
			extraSpaceBottom
		/>
		<inputItem
			v-if="!onboarding"
			v-model="space.isMain"
			inputName="isMain"
			:inputLabel="$t('mol.spaceForm.label.Main space')"
			inputType="checkbox"
			:disabled="space.Id == mainSpaceId"
			:choices="[{value: true, label: $t('mol.spaceForm.label.Set as main venue space')}]"
		/>
	</span>
</template>

<script>
import Alert from "@/components/location/Alert";
import InputItem from "@/components/location/InputItem";
import FormGridWrapper from "@/components/location/FormGridWrapper";

export default {
	name: "SpaceForm",
	components: {
		InputItem,
		Alert,
		FormGridWrapper,
	},
	props: {
		space: {},
		formErrors: {},
		mainSpaceId: {
			type: String,
			default: "",
		},
		edit: {
			type: Boolean,
			default: false,
		},
		create: {
			type: Boolean,
			default: false,
		},
		onboarding: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
