export default {
	AD: "Andorra",
	AE: "Verenigde Arabische Emiraten",
	AF: "Afghanistan",
	AG: "Antigua en Barbuda",
	AI: "Anguilla",
	AL: "Albanië",
	AM: "Armenië",
	AO: "Angola",
	AQ: "Antarctica",
	AR: "Argentinië",
	AT: "Oostenrijk",
	AU: "Australië",
	AW: "Aruba",
	AX: "Ålandseilanden",
	AZ: "Azerbeidzjan",
	BA: "Bosnië en Herzegovina",
	BB: "Barbados",
	BD: "Bangladesh",
	BE: "België",
	BF: "Burkina Faso",
	BG: "Bulgarije",
	BH: "Bahrein",
	BI: "Burundi",
	BJ: "Benin",
	BL: "Saint-Barthélemy (Sint-Bartholomeus)",
	BM: "Bermuda",
	BN: "Brunei Darussalam, Sultanaat -",
	BO: "Bolivia, Plurinationale Staat -",
	BQ: "Bonaire, Sint-Eustatius en Saba",
	BR: "Brazilië",
	BS: "Bahama's",
	BT: "Bhutan",
	BV: "Bouveteiland",
	BW: "Botswana",
	BY: "Belarus (Wit-Rusland)",
	BZ: "Belize",
	CA: "Canada",
	CC: "Cocos- of Keelingeilanden",
	CD: "Congo, de Democratische Republiek -",
	CF: "Centraal-Afrikaanse Republiek",
	CG: "Congo",
	CH: "Zwitserland",
	CI: "Ivoorkust",
	CK: "Cookeilanden",
	CL: "Chili",
	CM: "Kameroen",
	CN: "China",
	CO: "Colombia",
	CR: "Costa Rica",
	CU: "Cuba",
	CV: "Kaapverdië",
	CW: "Curaçao",
	CX: "Christmaseiland",
	CY: "Cyprus",
	CZ: "Tsjechië",
	DE: "Duitsland",
	DJ: "Djibouti",
	DK: "Denemarken",
	DM: "Dominica",
	DO: "Dominicaanse Republiek",
	DZ: "Algerije",
	EC: "Ecuador",
	EE: "Estland",
	EG: "Egypte",
	EH: "Westelijke Sahara",
	ER: "Eritrea",
	ES: "Spanje",
	ET: "Ethiopië",
	FI: "Finland",
	FJ: "Fiji-eilanden",
	FK: "Falklandeilanden (Malvinas)",
	FO: "Faerøer(eilanden)",
	FR: "Frankrijk",
	GA: "Gabon",
	GB: "Verenigd Koninkrijk",
	GD: "Grenada",
	GE: "Georgië",
	GF: "Frans-Guyana",
	GG: "Guernsey",
	GH: "Ghana",
	GI: "Gibraltar",
	GL: "Groenland",
	GM: "Gambia",
	GN: "Guinea",
	GP: "Guadeloupe",
	GQ: "Equatoriaal-Guinea",
	GR: "Griekenland",
	GS: "Zuid-Georgië en de Zuidelijke Sandwicheilanden",
	GT: "Guatemala",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HM: "Heard- en McDonaldeilanden",
	HN: "Honduras",
	HR: "Kroatië",
	HT: "Haïti",
	HU: "Hongarije",
	ID: "Indonesië",
	IE: "Ierland",
	IL: "Israël",
	IM: "Man",
	IN: "India",
	IO: "Brits gebied in de Indische Oceaan",
	IQ: "Irak",
	IR: "Iran, Islamitische Republiek -",
	IS: "IJsland",
	IT: "Italië",
	JE: "Jersey",
	JM: "Jamaica",
	JO: "Jordanië",
	JP: "Japan",
	KE: "Kenia",
	KG: "Kirgizië",
	KH: "Cambodja",
	KI: "Kiribati",
	KM: "Comoren",
	KN: "Saint Kitts en Nevis",
	KP: "Korea, Democratische Volksrepubliek -",
	KR: "Korea, Republiek -",
	KW: "Koeweit",
	KY: "Caymaneilanden",
	KZ: "Kazachstan",
	LA: "Laos, Democratische Volksrepubliek -",
	LB: "Libanon",
	LC: "Sint-Lucia",
	LI: "Liechtenstein",
	LK: "Sri Lanka",
	LR: "Liberia",
	LS: "Lesotho",
	LT: "Litouwen",
	LU: "Luxemburg",
	LV: "Letland",
	LY: "Libië",
	MA: "Marokko",
	MC: "Monaco",
	MD: "Moldavië, Republiek -",
	ME: "Montenegro",
	MF: "Sint-Maarten (Frans)",
	MG: "Madagascar",
	MK: "Macedonië , de Voormalige Joegoslavische Republiek -",
	ML: "Mali",
	MM: "Myanmar",
	MN: "Mongolië",
	MO: "Macao",
	MQ: "Martinique",
	MR: "Mauritanië",
	MS: "Montserrat",
	MT: "Malta",
	MU: "Mauritius",
	MV: "Maldiven",
	MW: "Malawi",
	MX: "Mexico",
	MY: "Maleisië",
	MZ: "Mozambique",
	NA: "Namibië",
	NC: "Nieuw-Caledonië",
	NE: "Niger",
	NF: "Norfolk",
	NG: "Nigeria",
	NI: "Nicaragua",
	NL: "Nederland",
	NO: "Noorwegen",
	NP: "Nepal",
	NR: "Nauru",
	NU: "Niue",
	NZ: "Nieuw-Zeeland",
	OM: "Oman",
	PA: "Panama",
	PE: "Peru",
	PF: "Frans-Polynesië",
	PG: "Papoea-Nieuw-Guinea",
	PH: "Filippijnen",
	PK: "Pakistan",
	PL: "Polen",
	PM: "Saint-Pierre en Miquelon",
	PN: "Pitcairneilanden",
	PS: "Palestina",
	PT: "Portugal",
	PY: "Paraguay",
	QA: "Qatar",
	RE: "Réunion",
	RO: "Roemenië",
	RS: "Servië",
	RU: "Russische Federatie",
	RW: "Rwanda",
	SA: "Saudi-Arabië",
	SB: "Salomoneilanden",
	SC: "Seychellen",
	SD: "Sudan",
	SE: "Zweden",
	SG: "Singapore",
	SH: "Sint-Helena, Ascension en Tristan da Cunha",
	SI: "Slovenië",
	SJ: "Spitsbergen en Jan Mayen",
	SK: "Slowakije",
	SL: "Sierra Leone",
	SM: "San Marino",
	SN: "Senegal",
	SO: "Somalië",
	SR: "Suriname",
	SS: "Zuid-Sudan",
	ST: "Sao Tomé en Principe",
	SV: "El Salvador",
	SX: "Sint-Maarten (Nederlands)",
	SY: "Syrië, Arabische Republiek -",
	SZ: "Swaziland",
	TC: "Turks- en Caicoseilanden",
	TD: "Tsjaad",
	TF: "Franse Zuidelijke gebieden",
	TG: "Togo",
	TH: "Thailand",
	TJ: "Tadzjikistan",
	TK: "Tokelau",
	TL: "Oost-Timor",
	TM: "Turkmenistan",
	TN: "Tunesië",
	TO: "Tonga",
	TR: "Turkije",
	TT: "Trinidad en Tobago",
	TV: "Tuvalu",
	TW: "Taiwan",
	TZ: "Tanzania, Verenigde Republiek -",
	UA: "Oekraïne",
	UG: "Uganda",
	US: "Verenigde Staten",
	UY: "Uruguay",
	UZ: "Oezbekistan",
	VA: "Heilige Stoel (stadstaat Vaticaanstad)",
	VC: "Saint Vincent en de Grenadines",
	VE: "Venezuela, Bolivariaanse Republiek -",
	VG: "Maagdeneilanden (Britse)",
	VN: "Vietnam",
	VU: "Vanuatu",
	WF: "Wallis en Futuna",
	WS: "Samoa",
	YE: "Jemen",
	YT: "Mayotte",
	ZA: "Zuid-Afrika",
	ZM: "Zambia",
	ZW: "Zimbabwe",
};
