<template>
	<span class="text-tdc-blue-500 hover:text-tdc-blue-600 hover:underline dark:text-tdc-blue-400 cursor-pointer"
		>{{ label }}<slot></slot>
	</span>
</template>

<script>
export default {
	name: "Ahref",
	props: {
		label: {
			type: String,
			default: "",
		},
	},
};
</script>
