<template>
	<div>
		<Alert v-if="!isOrderInProgress && !hasQuotes" alertType="notification">
			Je hebt nog geen offertes aangevraagd. Bereken eerst de
			<router-link :to="{name: 'Prices'}">prijs voor jouw feest</router-link>.
		</Alert>
		<Alert v-else-if="isOrderInProgress && !hasQuotes && !isPaid" alertType="notification">
			Je hebt een bevestigde DJ boeking. Ga naar jouw DJ boeking en
			<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
		</Alert>
		<Alert v-else-if="isOrderInProgress && !hasQuotes && isPaid" alertType="notification">
			Je hebt een bevestigde DJ boeking. Bekijk hier <router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>.
		</Alert>
		<Alert v-else-if="isOrderInProgress && hasQuotes && !isPaid" alertType="warning">
			Je kunt geen offertes meer bevestigen omdat je een bevestigde DJ boeking hebt. Ga naar jouw DJ boeking en
			<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
		</Alert>
		<Alert v-else-if="isOrderInProgress && hasQuotes && isPaid" alertType="notification">
			Je kunt geen offertes meer bevestigen i.v.m. jouw DJ boeking. Bekijk hier
			<router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>.
		</Alert>
		<Alert v-else alertType="notification">
			Prijzen kunnen omhoog gaan óf jouw datum kan volgeboekt zijn. Bevestig jouw offerte om de datum en prijs te
			verzekeren. Je mag tot 14 dagen voor het feest gratis annuleren.
		</Alert>

		<div v-if="hasQuotes">
			<ul class="list-unstyled">
				<li
					v-for="(quote, index) in quotes"
					:key="index"
					class="flex-column d-flex rounded w-100 justify-content-start my-2 position-relative"
				>
					<QuoteSummaryItem
						:quote="quote"
						styleVariant="overviewList"
						class="bg-white p-3 p-md-4 border d-flex justify-content-between flex-fill align-items-center"
					>
						<template slot="end">
							<font-awesome-icon :icon="['far', 'chevron-right']" class="chevron-right" />
							<router-link
								class="position-absolute w-100 h-100 ml-n3 ml-md-n4"
								:to="{name: 'Quote', params: {id: quote.Id}}"
							></router-link>
						</template>
					</QuoteSummaryItem>
				</li>
			</ul>
		</div>
		<div v-if="!isOrderInProgress" class="d-flex flex-column align-items-center py-4">
			<div class="mx-auto" style="max-width: 450px">
				<router-link :to="{name: 'Prices'}" class="btn btn-outline-primary">Nieuwe offerte maken</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteSummaryItem from "@/components/customer/QuoteSummaryItem";
import Alert from "@/components/customer/Alert";

export default {
	name: "QuotesView",
	components: {
		QuoteSummaryItem,
		Alert,
	},
	computed: {
		...mapGetters({
			quotes: "getQuotes",
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
			isWedding: "isWedding",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
		}),
		hasQuotes: function () {
			return this.quotes.length > 0 ? true : false;
		},
		quoteCount() {
			return this.quotes.length;
		},
	},
	mounted() {
		this.quotes.sort(function (a, b) {
			var keyA = new Date(a.CreatedDate),
				keyB = new Date(b.CreatedDate);
			// Compare the 2 dates
			if (keyA > keyB) return -1;
			if (keyA < keyB) return 1;
			return 0;
		});
	},
};
</script>

<style lang="scss">
.dashed-border {
	@extend .pb-2;
	border-bottom: 1px dashed #ccc;
}
.chevron-right {
	//@extend .position-absolute;
	right: 0;
	font-size: 20px;
	color: $tdc-blue;
}
</style>
