<template>
	<div class="videoWrapper position-relative">
		<iframe
			style="max-width: 100%"
			:src="videoUrl"
			webkitallowfullscreen=""
			mozallowfullscreen=""
			allowfullscreen=""
			data-ready="true"
			width="700"
			height="396"
			frameborder="0"
			class="position-absolute top-0 left-0 w-100 h-100"
		>
		</iframe>
	</div>
</template>

<script>
export default {
	name: "Video",
	props: {
		videoId: {
			type: String,
			required: true,
		},
	},
	computed: {
		videoUrl: function () {
			return "//player.vimeo.com/video/" + this.videoId + "?title=0,badge=0";
		},
	},
};
</script>

<style lang="scss" scoped>
.videoWrapper {
	padding-bottom: 56.25%; /* 16:9 */
}
</style>
