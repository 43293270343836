<template>
	<div>
		<div :class="styleVariantClasses">
			<router-link class="d-block" :to="{name: 'Quote', params: {id: quote.Id}}">
				<div class="d-flex">
					<span v-if="booking.Business_Booking__c">
						<strong class="d-inline-block mr-1 mr-sm-2">
							<span class="mb-0 yellow-highlight text-body" v-text="formatPrice(quote.PriceExcVAT__c)" />
						</strong>
						(ex. BTW)
					</span>
					<span v-else>
						<strong class="d-inline-block mr-1 mr-sm-2">
							<span class="mb-0 yellow-highlight text-body" v-text="formatPrice(quote.PriceIncVAT__c)" />
						</strong>
					</span>

					<div class="mx-1" v-text="quote.DJClass__c + '-klasse DJ'" />
					<QuoteExpiresOn :quote="quote" class="flex-fill" :statusStyle="styleVariant" />
				</div>
			</router-link>
			<div class="d-flex flex-row">
				<div class="d-flex flex-row align-items-center mr-1 mr-sm-2">
					<font-awesome-icon :icon="['far', 'calendar-day']" class="mr-1 text-success" /><span
						v-text="$date(quote.Party_Date__c).locale('nl').format('DD/MM/YY')"
					/>
				</div>
				<div class="d-flex flex-row align-items-center mr-1 mr-sm-2">
					<font-awesome-icon :icon="['far', 'users']" class="mr-1 text-success" />
					<span
						v-if="quote.number_of_guests__c != '301'"
						v-html="'Tot&nbsp' + quote.number_of_guests__c + '&nbspgasten'"
					/>
					<span v-if="quote.number_of_guests__c == '301'" v-text="'300+&nbspgasten'" />
				</div>
				<div class="d-flex flex-row align-items-center mr-1 mr-sm-2">
					<font-awesome-icon :icon="['far', 'clock']" class="mr-1 text-success" />
					<span v-text="quote.begin_time__c + ' - ' + quote.end_time__c" />
				</div>
			</div>
		</div>
		<slot name="end" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteExpiresOn from "@/components/customer/QuoteExpiresOn";

export default {
	name: "quoteSummaryItem",
	components: {
		QuoteExpiresOn,
	},
	props: {
		quote: {
			type: Object,
			required: true,
		},
		styleVariant: {
			type: String,
			default: "",
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
		}),
		styleVariantClasses() {
			if (this.styleVariant == "overviewList")
				return "overview-quote-list d-flex flex-column flex-lg-row justify-content-between flex-fill";
			return "";
		},
	},
	methods: {
		formatPrice(price) {
			return this.$currency(price, 2).format().replace(",00", ",-");
		},
	},
};
</script>

<style lang="scss">
.overview-quote-list {
	@include media-breakpoint-down(sm) {
		font-size: 80%;
	}
}
</style>
