<template>
	<div class="lead">
		<h2 class="font-weight-bold m-0">
			<span class="txt-orange stars d-block mr-2 mr-md-0">
				<font-awesome-icon v-for="star in stars" :key="star" :icon="['fas', 'star']" />
			</span>
			<strong>{{ djClass }}-klasse DJ</strong>
		</h2>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "SelectedDj",
	computed: {
		...mapGetters({
			djClassDefault: "getCurrentDjClass",
			stars: "getStars",
			usp: "getCurrentDjClassUsp",
			icon: "getCurrentDjClassUspIconName",
		}),
	},
	data() {
		return {
			djClass: null,
		};
	},
	props: {
		dj: {
			type: String,
			default: null,
		},
	},
	mounted() {
		this.djClass = this.dj ? this.dj : this.djClassDefault;
	},
};
</script>
