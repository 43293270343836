<template>
	<div class="d-flex justify-content-center justify-content-md-start" v-if="!isPaid">
		<b-btn @click.prevent="goToPayment()" variant="link" class="py-4 p-md-0">
			Betaal je waarborgsom van 50%: <span v-text="formatPrice(down_payment, 2)"></span
			><font-awesome-icon class="ml-auto ml-sm-3" :icon="['far', 'chevron-right']" />
		</b-btn>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "PaymentLink",
	data() {
		return {
			accepted: false,
			submitted: false,
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isPaid: "getPaidStatus",
			down_payment: "getDownPayment",
			areTermsAndConditionsAccepted: "areTermsAndConditionsAccepted",
		}),
	},
	methods: {
		goToPayment() {
			this.$root.$emit("setSubmitted", true);
			if (this.areTermsAndConditionsAccepted) {
				this.$store.commit("SET_LOADING_STATE", true);
				window.location = this.booking.DownPaymentUrl__c;
			} else if (this.accepted) {
				this.$store.commit("SET_LOADING_STATE", true);
				this.$store
					.dispatch("postBooking", {
						action: "acceptTC",
						data: {},
						setBooking: true,
					})
					.then(() => {
						this.$store.commit("SET_LOADING_STATE", true);
						window.location = this.booking.DownPaymentUrl__c;
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
	mounted() {
		this.$root.$on("setTermsAccepted", (value) => (this.accepted = value));
	},
};
</script>
