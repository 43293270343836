<template>
	<span></span>
</template>

<script>
export default {
	name: "DisableTrengoChat",
	mounted() {
		document.body.classList.add("hide-trengo");
	},
	destroyed() {
		document.body.classList.remove("hide-trengo");
	},
};
</script>

<style lang="scss">
.hide-trengo {
	#trengo-web-widget,
	#trengo-widget {
		display: none !important ;
	}
}
</style>
