<template>
	<div
		:class="alertTypeClasses"
		class="border-l-4 p-2 md:p-4 text-sm md:text-base mb-3 md:mb-6 flex items-start w-full"
		role="alert"
	>
		<font-awesome-icon
			v-if="!iconOff"
			:icon="['far', alertTypeIcon]"
			:class="alertTypeIconClass"
			class="mt-[2px] md:mt-[2px] h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3"
			fa-fw
		/>
		<div>
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: "Alert",
	props: {
		type: {
			type: String,
			default: "info",
		},
		iconOff: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: "info-circle",
		},
	},
	computed: {
		alertTypeClasses() {
			if (this.type == "warning") return "bg-orange-100 border-orange-500 text-orange-700";
			else if (this.type == "success") return "bg-tdc-green-100 border-tdc-green-500 text-tdc-green-600";
			return "bg-sky-100 border-tdc-blue-500 text-tdc-blue-700";
		},
		alertTypeIcon() {
			if (this.type === "warning") return "exclamation-triangle";
			return this.icon;
		},
		alertTypeIconClass() {
			if (this.type == "warning") return "text-orange-500";
			if (this.type == "success") return "text-tdc-green-500";
			return "text-sky-500";
		},
	},
};
</script>
