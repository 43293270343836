<template>
	<div
		class="yellow-highlight"
		:style="{backgroundImage: 'url(' + require('@/assets/images/price-highlight.png') + ')'}"
	>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "HighlightedPrice",
};
</script>

<style lang="scss" scoped>
.yellow-highlight {
	background-repeat: no-repeat;
	background-size: 99% 95%;
	background-position: center center;
	padding-left: 7%;
	display: inline-block;
	padding-bottom: 1px;
}
</style>
