<template>
	<div v-if="quote">
		<DisableTrengoChat />
		<QuoteProgress :step="2" :steps="4" :steps_type="'quote'" />
		<StrikeThroughTitle>Bedankt, je hebt nu een offerte mét prijsgarantie</StrikeThroughTitle>
		<Alert v-if="!isOrderInProgress" alertType="info" dismissible class="pt-3 d-block">
			Jouw offerte blijft {{ daysAndHoursAndMinutesToTime }} geldig. Daarna kunnen prijzen omhoog gaan óf kan jouw datum
			volgeboekt zijn.
		</Alert>
		<div class="desktop-content-left-right-wrapper mb-4">
			<DjBlock
				template="DjBlockComplete"
				templateVariant="QuoteConfirmed"
				:sf_name="quote.DJClass__c"
				:quote="quote"
				:priceIncVat="quote.PriceIncVAT__c"
				:priceExcVat="quote.PriceExcVAT__c"
				:showDefaultCta="false"
				:showCustomCta="true"
				:hideAllLists="true"
			>
				<template slot="customCta">
					<div class="d-flex flex-column align-items-center align-items-md-end">
						<ConfirmBookingButton bookingType="quote" :djClass="quote.DJClass__c" :quoteId="quote.Id" />
					</div>
				</template>
			</DjBlock>
		</div>
		<MyQuotes />
		<BookingIncludes />
		<div class="d-flex flex-column align-items-center border-top border-success border-bottom py-4">
			<div class="mx-auto" style="max-width: 450px">
				<ConfirmBookingButton bookingType="quote" placement="bottom" :djClass="quote.DJClass__c" :quoteId="quote.Id" />
			</div>
		</div>
		<Faqs />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteProgress from "@/components/customer/QuoteProgress";
import BookingIncludes from "@/components/customer/BookingIncludes";
import MyQuotes from "@/components/customer/MyQuotes";
import Faqs from "@/components/customer/Faqs";
import ConfirmBookingButton from "@/components/customer/ConfirmBookingButton";
import StrikeThroughTitle from "@/components/customer/StrikeThroughTitle";
import Alert from "@/components/customer/Alert";
import DjBlock from "@/components/customer/DjBlock";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "QuoteConfirmed",
	components: {
		QuoteProgress,
		BookingIncludes,
		Faqs,
		ConfirmBookingButton,
		MyQuotes,
		Alert,
		DjBlock,
		StrikeThroughTitle,
		DisableTrengoChat,
	},
	data: function () {
		return {
			quote: null,
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			quotes: "getQuotes",
			isOrderInProgress: "isOrderInProgress",
		}),
		lightSoundLabel: function () {
			if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "nee") return "";
			else if (this.booking.light_show_needed__c == "ja" && this.booking.pa_system_needed__c == "nee")
				return "Lichtapparatuur";
			else if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "ja")
				return "Geluidsapparatuur";
			return "Licht- en geluidsapparatuur";
		},
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		msToTime: function () {
			return Date.parse(this.quote.ExpirationDate__c) - Date.now();
		},
		daysAndHoursAndMinutesToTime: function () {
			var minutes = Math.floor((this.msToTime / (1000 * 60)) % 60);
			var hours = Math.floor(this.msToTime / (1000 * 60 * 60));
			var days = Math.floor(this.msToTime / (1000 * 60 * 60 * 24));

			if (days > 1) return days + " dagen ";
			else if (days == 1) return "1 dag ";
			else if (hours > 1) return hours + " uren ";
			else if (hours == 1) return "1 uur ";
			else if (minutes == 1) return "1 minuut ";
			else return minutes + " minuten ";
		},
	},
	methods: {
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
	mounted() {
		this.quote = this.quotes.find((quote) => quote.Id == this.$route.params.id);
		if (Object.keys(this.$route.params).length === 0) this.$router.push({name: "Quotes"});
		this.$gtm.trackView("QuoteConfirmed", "/dj-huren/offerte/bevestiging");
	},
};
</script>
