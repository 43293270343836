<template>
	<div>
		<div v-if="!location.Location_private__c" class="col d-flex align-items-stretch">
			<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
				<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'map-marker-alt']" />
				<div class="d-flex flex-column w-100">
					<div class="d-flex justify-content-between">
						<strong class="flex-grow-1">Contactpersoon feestlocatie</strong>
					</div>
					<div class="w-100 flex-fill">
						<b-form-group v-if="locationContacts.length > 1" class="m-0 p-0 dashed-border">
							<b-form-select
								id="contactSelect"
								class="ahref border-0 p-0 m-0"
								style="width: 100% !important"
								v-model="selectedContact"
								:disabled="!canEditContact"
								@change="setContact"
							>
								<b-form-select-option :value="contact.Id" v-for="contact in locationContacts" :key="contact.id">
									{{ contact.Name }}
								</b-form-select-option>
							</b-form-select>
						</b-form-group>
						<div v-if="locationContact != ''">
							<p
								v-if="locationContacts.length == 1"
								class="text-dark mb-0 dashed-border pt-2"
								v-text="locationContact.Name"
							></p>
							<p
								v-if="locationContacts.Email != ''"
								class="text-dark mb-0 dashed-border pt-2"
								v-text="locationContact.Email"
							></p>
							<p v-else class="text-dark mb-0 dashed-border pt-2 text-muted" v-text="'Email onbekend'"></p>

							<p
								v-if="locationContacts.Phone != ''"
								class="text-dark mb-0 dashed-border pt-2"
								v-text="locationContact.Phone"
							></p>
							<p v-else class="text-dark mb-0 dashed-border pt-2 text-muted" v-text="'Telefoonnummer onbekend'"></p>
						</div>
						<div v-if="!isLoading">
							<div v-if="locationContacts.length == 0" class="mb-4">
								Er is nog geen contactpersoon bekend voor de locatie
							</div>
							<div class="ahref pt-2" v-if="canEditContact" @click="$bvModal.show('addContact')">
								<font-awesome-icon :icon="['far', 'user-plus']" /> Contactpersoon toevoegen
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			:hide-footer="true"
			header-bg-variant="white"
			size="lg"
			centered
			id="addContact"
			title="Contactpersoon toevoegen"
			content-class="shadow bg-light"
		>
			<b-overlay :show="isLoading">
				<b-form @submit="createContact" @reset="resetContact" :aria-hidden="isLoading ? 'true' : null">
					<b-form-group label="Aanhef" label-for="Salutation">
						<b-form-select id="Salutation" required v-model="newContact.Salutation">
							<b-form-select-option value="mevr.">Mevr.</b-form-select-option>
							<b-form-select-option value="dhr.">Dhr.</b-form-select-option>
						</b-form-select>
					</b-form-group>
					<b-form-group label="Voornaam" label-for="FirstName">
						<b-form-input
							placeholder=" "
							id="FirstName"
							required
							maxlength="100"
							v-model="newContact.FirstName"
						></b-form-input>
					</b-form-group>
					<b-form-group label="Achternaam" label-for="LastName">
						<b-form-input
							placeholder=" "
							id="LastName"
							required
							maxlength="100"
							v-model="newContact.LastName"
						></b-form-input>
					</b-form-group>
					<b-form-group label="Email" label-for="Email">
						<b-form-input placeholder=" " id="Email" required type="email" v-model="newContact.Email"></b-form-input>
					</b-form-group>
					<b-form-group label="Telefoonnummer" label-for="Phone">
						<b-form-input placeholder=" " id="Phone" maxlength="15" v-model="newContact.Phone"></b-form-input>
					</b-form-group>
					<div v-if="!isLoading" class="d-flex justify-content-end">
						<b-button type="submit" variant="primary"
							><font-awesome-icon :icon="['far', 'user-plus']" /> Contactpersoon toevoegen</b-button
						>
					</div>
					<div v-if="isLoading">Contactpersoon wordt aangemaakt</div>
				</b-form>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";
export default {
	name: "Contacts",
	components: {FontAwesomeIcon},
	computed: {
		...mapGetters({
			locationContact: "getLocationContact",
			locationContacts: "getLocationcontacts",
			isLoading: "getLoadingState",
			location: "getLocation",
			isConfirmed: "isConfirmed",
		}),
		canEditContact: function () {
			return !this.isConfirmed;
		},
	},
	data() {
		return {
			selectedContact: "",
			newContact: {
				Salutation: "",
				FirstName: "",
				LastName: "",
				Email: "",
				Phone: "",
			},
		};
	},
	methods: {
		setContact() {
			this.$store.dispatch("postBooking", {
				action: "setLocationContact",
				data: {contactId: this.selectedContact},
				setBooking: true,
			});
		},
		createContact(event) {
			event.preventDefault();
			this.newContact.AccountId = this.location.Id;
			this.$store.dispatch("postBooking", {
				action: "createContact",
				data: this.newContact,
				setBooking: true,
			});
			this.$bvModal.hide("addContact");
		},
		resetContact(event) {
			event.preventDefault();
			this.newContact.Salutation = "";
			this.newContact.FirstName = "";
			this.newContact.LastName = "";
			this.newContact.Email = "";
			this.newContact.Phone = "";
		},
	},
	mounted() {
		this.selectedContact = this.locationContact ? this.locationContact.Id : "";
	},
};
</script>
