<template>
	<div class="p-3 border mb-4" style="background: #f3f3f3">
		<p class="h3">
			<strong v-if="review.evaluation_quote_edit__c">"{{ review.evaluation_quote_edit__c }}"</strong>
			<strong v-else>"{{ review.evaluation_quote__c }}"</strong>
		</p>
		<span class="h3 txt-primary d-flex flex-row align-items-center">
			<StarRating :rating="review.Average_DJ_score__c" class="stars mr-3" />
			<span class="text-primary">
				<strong class="text-primary h2">{{ review.Average_DJ_score__c }}</strong>
				<small>/10</small>
			</span>
		</span>
		<div class="my-3 mx-0">
			<p v-if="review.dj_played_according_to_atmosphere_rating__c" class="d-flex flex-column flex-sm-row mb-1">
				<StarRating class="stars mr-2" :rating="review.dj_played_according_to_atmosphere_rating__c" />
				<span
					>Inspelen op sfeer <strong class="txt-blue">{{ review.dj_played_according_to_atmosphere_rating__c }}</strong
					><small class="txt-blue"> /10</small></span
				>
			</p>
			<p v-if="review.dj_service_level_rating__c" class="d-flex flex-column flex-sm-row mb-1">
				<StarRating class="stars mr-2" :rating="review.dj_service_level_rating__c" />
				<span
					>Vriendelijkheid &amp; service <strong class="txt-blue">{{ review.dj_service_level_rating__c }}</strong
					><small class="txt-blue"> /10</small></span
				>
			</p>
			<p v-if="review.dj_played_music_i_requested_rating__c" class="d-flex flex-column flex-sm-row mb-1">
				<StarRating class="stars mr-2" :rating="review.dj_played_music_i_requested_rating__c" />
				<span
					>Muziekkeuze <strong class="txt-blue">{{ review.dj_played_music_i_requested_rating__c }}</strong
					><small class="txt-blue"> /10</small></span
				>
			</p>
		</div>
		<div class="review-body row">
			<div class="col-sm-2 text-center">
				<img
					class="img-responsive author-img rounded-circle mw-100"
					src="~@/assets/images/profile-unknown-couple.jpg"
				/>
				<!-- double, single -->
				<div class="author-name">{{ review.Name_of_evaluator__c }}</div>
			</div>
			<div class="col-sm-10">
				<div class="review-textbody bg-white p-3 rounded">
					<div class="speech-bubble-pointer pointer-on-left"></div>
					<p>{{ review.general_comments_regarding_booking__c }}</p>
				</div>
			</div>
		</div>
		<div v-if="hasReply" class="review-body review-reaction row">
			<div class="col-sm-2 text-center order-sm-1">
				<img class="img-responsive author-img rounded-circle mw-100" :src="profileImageReplyAuthor" />
				<div class="author-name">{{ review.general_comments_react_author__c }}</div>
			</div>
			<div class="col-sm-10 order-sm-0">
				<div class="review-textbody bg-primary text-white p-3 rounded">
					<div class="speech-bubble-pointer pointer-on-right"></div>
					<p>{{ review.general_comments_regarding_booking_react__c }}</p>
				</div>
			</div>
		</div>
		<ul class="pl-0 d-flex flex-row flex-wrap list-unstyled border-top pb-2 pt-3">
			<li
				class="party-summary-item d-flex justify-content-start align-items-center"
				v-if="review.evaluation_title_edit__c"
			>
				<font-awesome-icon :icon="['fal', 'star']" fa-fw />
				<span>
					<small>feest</small>
					<p v-if="review.evaluation_title_edit__c">{{ review.evaluation_title_edit__c }}</p>
					<p v-else>{{ review.evaluation_title__c }}</p>
				</span>
			</li>
			<li class="party-summary-item d-flex justify-content-start align-items-center" v-if="review.party_dayte__c">
				<font-awesome-icon :icon="['fal', 'calendar-alt']" fa-fw />
				<span>
					<small>feestdatum</small>
					<p>{{ review.part_date_day__c }} {{ review.party_date_month__c }}</p>
				</span>
			</li>
			<li
				class="party-summary-item d-flex justify-content-start align-items-center"
				v-if="review.Booking__r.begin_time__c"
			>
				<font-awesome-icon :icon="['fal', 'clock']" fa-fw />
				<span>
					<small>feesttijden</small>
					<p>{{ review.Booking__r.begin_time__c }} - {{ review.Booking__r.end_time__c }}</p>
				</span>
			</li>
			<li
				class="party-summary-item d-flex justify-content-start align-items-center"
				v-if="review.Booking__r.number_of_guest__c || review.Booking__r.NumberOfGuestsRange__c"
			>
				<font-awesome-icon :icon="['fal', 'users']" fa-fw />
				<span>
					<small>aantal gasten</small>
					<p v-if="review.Booking__r.number_of_guest__c">{{ review.Booking__r.number_of_guest__c }}</p>
					<p v-if="review.Booking__r.NumberOfGuestsRange__c">{{ review.Booking__r.NumberOfGuestsRange__c }}</p>
				</span>
			</li>
			<li
				class="party-summary-item d-flex justify-content-start align-items-center"
				v-if="'DJ_first_name__c' in review"
			>
				<font-awesome-icon :icon="['fal', 'headphones-alt']" fa-fw />
				<span>
					<small>dj</small>
					<p v-if="'DJ__r' in review">DJ {{ review.DJ_first_name__c }}</p>
				</span>
			</li>
			<li
				v-if="isDutch && review.party_location_city__c"
				class="party-summary-item d-flex justify-content-start align-items-center"
			>
				<font-awesome-icon :icon="['fal', 'map-marker-alt']" fa-fw />
				<span>
					<small>plaats</small>
					<p>{{ review.party_location_city__c }}</p>
				</span>
			</li>
			<li v-if="isDutch" class="party-summary-item d-flex justify-content-start align-items-center">
				<font-awesome-icon :icon="['fal', 'map-signs']" fa-fw />
				<span>
					<small>feestlocatie</small>
					<p>{{ review.party_location_name__c }}</p>
				</span>
			</li>
			<li class="party-summary-item d-flex justify-content-start align-items-center">
				<font-awesome-icon :icon="['fal', 'tag']" fa-fw />
				<span>
					<small>feesttype</small>
					<p>{{ review.party_type__c }}</p>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import StarRating from "@/components/customer/StarRating";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
	name: "Review",
	components: {
		StarRating,
		FontAwesomeIcon,
	},
	computed: {
		...mapGetters({
			isBelgian: "isBelgian",
			isDutch: "isDutch",
		}),
		profileImageReplyAuthor: function () {
			return require("@/assets/images/profile-" + this.review.general_comments_react_author__c.toLowerCase() + ".jpg");
		},
		hasReply: function () {
			return "general_comments_react_author__c" in this.review;
		},
	},
	props: {
		review: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.review-body {
	margin: 15px 0 15px;
	.speech-bubble-pointer {
		background-image: url(~@/assets/images/speech-pointer-r.png);
	}
	.author-img {
		@include media-breakpoint-down(sm) {
			width: 80px;
			margin: 0 auto;
			margin-bottom: -69px;
			z-index: 1;
			position: relative;
		}
	}
	.author-name {
		position: relative;
		display: inline-block;
		width: 100%;
		text-align: center;
		font-size: 12px;
		@include media-breakpoint-down(sm) {
			top: 65px;
			z-index: 1;
		}
	}
	.review-textbody {
		position: relative;
		min-height: 110px;
		margin-right: 0px;
		@include media-breakpoint-up(sm) {
			margin-left: 20px;
		}
		@include media-breakpoint-down(xs) {
			padding-top: 70px !important;
		}
		p {
			margin: 6px 0;
		}
		.speech-bubble-pointer {
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 1;
			width: 30px;
			height: 30px;
			top: 40px;
			&.pointer-on-right {
				right: -30px;
			}
			&.pointer-on-left {
				left: -30px;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}
}
.review-reaction {
	.author-name {
		@include media-breakpoint-down(xs) {
			color: #fff;
		}
	}
	.review-textbody {
		@include media-breakpoint-up(sm) {
			margin-left: 0px;
			margin-right: 20px;
		}
	}
	.speech-bubble-pointer {
		background-image: url(~@/assets/images/speech-pointer-l-blue.png);
	}
}
.party-summary-item {
	padding-left: 0px;
	line-height: 18px;
	margin: 0 15px 15px 0;
	p {
		margin-bottom: 0px;
	}
	svg {
		font-size: 26px;
		color: #a4a4a4;
		margin-right: 10px;
	}
	small {
		color: #a4a4a4;
	}
}
.music-list {
	padding-left: 15px;
	font-size: 13px;
}
.gal-vid,
.gal-img {
	@extend .rounded;
	overflow: hidden;
	width: 32%;
	margin: 0.5%;
	float: left;
	position: relative;
	img {
		width: 100%;
	}
	.more-imgs-cover {
		background: rgba(0, 0, 0, 0.8);
		position: absolute;
		z-index: 2;
		font-size: 28px;
	}
}
</style>
