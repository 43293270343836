import axios from "axios";
import qs from "qs";
import store from "@/store";

const sf_domain =
	process.env.NODE_ENV !== "production"
		? "https://thedjcompany--partial.sandbox.my.salesforce-sites.com/services/apexrest/"
		: "https://thedjcompany.my.salesforce-sites.com/services/apexrest/";

const url = `${sf_domain}pricing`;

export function getPricing(query) {
	const get_url = `${url}?${qs.stringify(query)}`;
	return axios.get(get_url).catch(function (err) {
		store.dispatch("handleError", err.response);
	});
}
