<template>
	<div>
		<div class="hidden lg:block lg:h-12"></div>
		<div class="z-20 absolute top-0 right-0 w-1/2 lg:w-auto">
			<div
				@click="emitClick()"
				:class="{'opacity-50 hover:cursor-not-allowed': disabled}"
				class="py-4 px-2 text-tdc-blue-500 lg:text-white hover:bg-white lg:hover:bg-tdc-blue-400 md:hover:underline hover:dark:bg-tdc-blue-950 hover:dark:text-white flex items-center justify-end text-sm lg:text-base cursor-pointer lg:flex-none w-100 lg:w-auto lg:bg-tdc-blue-500 rounded lg:py-2 lg:px-3 hover:dark:border-tdc-blue-800"
			>
				<slot></slot>
				<font-awesome-icon :icon="['far', 'arrow-right']" class="text-slate-400 lg:text-white h-4 ml-2 block" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EditButton",
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		emitClick() {
			this.$emit("onClick");
		},
	},
};
</script>
