<template>
	<div>
		<PageTitle
			:title="$t('mol.contactsPage.title.Venue contacts')"
			:subTitle="subTitle"
			:breadcrumbs="breadcrumbs"
			:adaptSubnav="true"
		/>
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavEditing">
				<SlideOutPanel v-if="contactOnDisplay" :panelOpen="molEditingState">
					<template slot="outerContent">
						<EditButton @onClick="openEditingPanel()">
							{{ $t("mol.general.button.Edit") }}
						</EditButton>
					</template>
					<template slot="innerContent">
						<div v-if="contactOnDisplay">
							<div class="absolute right-0">
								<button
									@click="closeEditingPanel()"
									class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
								>
									<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
								</button>
							</div>
							<PageTitle
								:title="$t('mol.contactsPage.title.Venue contacts')"
								:subTitle="subTitle"
								:breadcrumbs="breadcrumbsEdit"
							/>
							<ContactForm
								ref="form"
								edit
								:mainContactId="mainContact.Id"
								:contact="editContact"
								:formErrors="formErrors"
							/>
							<FormGridWrapper>
								<template slot="right">
									<div class="w-full flex flex-row-reverse">
										<Button
											@click.native="saveContact(editContact)"
											type="green"
											icon="check"
											class="flex-grow"
											:label="$t('mol.general.button.Save')"
										/>
										<Button @click.native="closeEditingPanel()" class="mx-2" type="cancel" />
										<Button
											v-if="editContact.Id != mainContact.Id"
											@click.native="openConfirmRemovePanel()"
											type="delete"
											icon="trash"
											:label="$t('mol.general.button.Remove')"
										/>
										<Button
											v-else
											type="red"
											icon="trash-can-slash"
											disabled
											:label="$t('mol.general.button.Remove')"
										/>
									</div>
								</template>
							</FormGridWrapper>
						</div>
					</template>
				</SlideOutPanel>
				<SlideOutPanel :panelOpen="panelConfirmRemove" zIndexClass="z-50">
					<template slot="innerContent">
						<div class="h-full flex-col flex align-middle">
							<div class="mb-3 font-bold">
								{{
									$t("mol.contactsPage.string.Are you sure you want to remove contact", {
										name: displayContactName("", editContact.FirstName, editContact.LastName),
									})
								}}
							</div>
							<div class="flex flex-row">
								<Button @click.native="closeConfirmRemovePanel()" class="mr-2" type="cancel" />

								<Button
									@click.native="deleteContact(editContact)"
									type="red"
									icon="trash"
									:label="$t('mol.general.button.Remove')"
								/>
							</div>
						</div>
					</template>
				</SlideOutPanel>
			</template>
			<template slot="subNavTabContent">
				<div v-if="creatingContact">
					<ContactForm ref="form" create :contact="newContact" :formErrors="formErrors" />
					<FormGridWrapper>
						<template slot="right">
							<div class="w-full flex flex-row-reverse">
								<Button @click.native="saveContact(newContact)" icon="user-plus" class="w-full">
									{{ $t("mol.contactForm.button.Save new contact") }}
								</Button>
							</div>
						</template>
					</FormGridWrapper>
				</div>
				<div v-else-if="contactCount == 0">{{ $t("mol.contactsPage.string.No venue contacts found") }}</div>
				<div v-else-if="!contactOnDisplay">
					{{ $t("mol.contactsPage.string.Choose a venue contact to view more details or") }}
					<Ahref
						@click.native="$store.commit('SET_MOL_SUBNAV_HASH', '#create')"
						:label="$t('mol.contactsPage.link.create a new venue contact')"
					/>.
				</div>
				<div v-else>
					<DataItem
						:dataLabel="$t('mol.contactForm.label.Salutation')"
						:dataValue="salutation(contactOnDisplay.Salutation)"
					/>
					<DataItem :dataLabel="$t('mol.contactForm.label.First name')" :dataValue="contactOnDisplay.FirstName" />
					<DataItem :dataLabel="$t('mol.contactForm.label.Last name')" :dataValue="contactOnDisplay.LastName" />
					<DataItem :dataLabel="$t('mol.contactForm.label.Title')" :dataValue="contactOnDisplay.Title" />
					<DataItem :dataLabel="$t('mol.contactForm.label.Email')" :dataValue="contactOnDisplay.Email" />
					<DataItem :dataLabel="$t('mol.contactForm.label.Phone')" :dataValue="contactOnDisplay.Phone" />
					<DataItem :dataLabel="$t('mol.contactForm.label.Main contact')" :dataValue="isMainContactLabel" />
				</div>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import SubNav from "@/components/location/SubNav";
import DataItem from "@/components/location/DataItem";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import Button from "@/components/location/Button";
import EditButton from "@/components/location/EditButton";
import Ahref from "@/components/location/Ahref";
import ContactForm from "@/components/location/ContactForm";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import {emailRegex} from "@/utilities";

export default {
	name: "LocationContacts",
	components: {
		PageTitle,
		SubNav,
		DataItem,
		SlideOutPanel,
		Button,
		EditButton,
		Ahref,
		ContactForm,
		FormGridWrapper,
	},
	data() {
		return {
			formErrors: this.initialContactFormErrors(),
			editContact: [],
			panelConfirmRemove: false,
			newContact: this.initialNewContact(),
		};
	},
	computed: {
		...mapGetters({
			error: "getError",
			molSubnavHash: "getMolSubnavHash",
			molEditingState: "getMolEditingState",
			location: "getLocationInformation",
			mainContact: "getLocationMainContactDetails",
			contacts: "getLocationContacts",
		}),
		subNavItems() {
			let navItems = [];
			var createContactNavItem = {};
			createContactNavItem["type"] = "create";
			createContactNavItem["label"] = this.$t("mol.contactsPage.subNav.Create new contact");
			createContactNavItem["route"] = {
				name: "Contacts",
				hash: "#create",
			};
			createContactNavItem["title"] = "";
			createContactNavItem["subTitle"] = "";
			createContactNavItem["icon"] = "user-plus";

			navItems.push(createContactNavItem);
			this.contacts.forEach((contact) => {
				let contactItem = [];
				contactItem["type"] = "contact";
				contactItem["label"] = this.displayContactName(contact.Salutation, contact.FirstName, contact.LastName);
				contactItem["route"] = {name: "Contacts", hash: "#" + contact.Id};
				contactItem["title"] = this.displayContactName(contact.Salutation, contact.FirstName, contact.LastName);
				contactItem["subTitle"] = "";
				contactItem["icon"] = "user";
				contactItem["phone"] = contact.Phone;
				contactItem["email"] = contact.Email;
				contactItem["mainContact"] = contact.Id == this.mainContact.Id;
				navItems.push(contactItem);
			});
			return navItems;
		},
		contactCount() {
			return this.contacts.length;
		},
		contactOnDisplay() {
			let contactOnDisplayID = this.molSubnavHash.substring(1);
			let contactOnDisplay = null;
			if (this.contacts.length > 0) {
				contactOnDisplay = this.contacts.find((contact) => contact.Id === contactOnDisplayID);
			}
			return contactOnDisplay;
		},
		creatingContact() {
			return this.molSubnavHash == "#create";
		},
		isMainContactLabel() {
			return this.contactOnDisplay.Id == this.mainContact.Id
				? this.$t("mol.general.string.Yes")
				: this.$t("mol.general.string.No");
		},
		subTitle() {
			if (this.contactOnDisplay)
				return this.displayContactName(
					this.salutation(this.contactOnDisplay.Salutation),
					this.contactOnDisplay.FirstName,
					this.contactOnDisplay.LastName
				);
			if (this.creatingContact) return this.$t("mol.contactsPage.title.Create new contact");
			return this.$t("mol.contactsPage.title.All venue contacts");
		},
		breadcrumbs() {
			let items = [this.$t("mol.contactsPage.title.Venue contacts")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).label);
			}
			return items;
		},
		breadcrumbsEdit() {
			let editItem = this.$t("mol.general.label.Edit");
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			breadcrumbs.push(editItem);
			return breadcrumbs;
		},
	},
	methods: {
		displayContactName(salutation, firstName, lastname) {
			let name = "";
			name = salutation ? this.salutation(salutation) : "";
			name += firstName ? " " + firstName : "";
			name += lastname ? " " + lastname : "";
			return name;
		},
		initialContactFormErrors() {
			return {
				hasErrors: false,
				contactErrorFirstName: false,
				contactErrorLastName: false,
				contactErrorEmail: false,
				contactErrorPhone: false,
			};
		},
		initialNewContact() {
			return {
				Id: null,
				Salutation: "mevr.",
				FirstName: "",
				LastName: "",
				Title: "",
				Email: "",
				Phone: "",
				isMain: null,
			};
		},
		setContactToEdit() {
			this.editContact = JSON.parse(JSON.stringify(this.contactOnDisplay));
			if (this.editContact.Id == this.mainContact.Id) {
				this.$set(this.editContact, "isMain", true);
			} else {
				this.$set(this.editContact, "isMain", false);
			}
		},
		closeEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
		},
		openEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", true);
		},
		openConfirmRemovePanel() {
			return (this.panelConfirmRemove = true);
		},
		closeConfirmRemovePanel() {
			return (this.panelConfirmRemove = false);
		},
		validateContact(contact) {
			this.resetFormErrors();

			if (contact.FirstName == "") {
				this.formErrors.contactErrorFirstName = true;
			}
			if (contact.LastName == "") {
				this.formErrors.contactErrorLastName = true;
			}
			if (!emailRegex.test(contact.Email)) {
				this.formErrors.contactErrorEmail = true;
			}
			if (!this.$refs.form.$refs.phone.isValidPhoneNumber()) {
				this.formErrors.contactErrorPhone = true;
				this.formErrors.hasErrors = true;
			}
			if (contact.FirstName == "") {
				this.formErrors.hasErrors = true;
				document.getElementById("FirstName").scrollIntoView({behavior: "smooth"});
			} else if (contact.LastName == "") {
				this.formErrors.hasErrors = true;
				document.getElementById("LastName").scrollIntoView({behavior: "smooth"});
			} else if (!emailRegex.test(contact.Email)) {
				this.formErrors.hasErrors = true;
				document.getElementById("Email").scrollIntoView({behavior: "smooth"});
			} else if (!this.$refs.form.$refs.phone.isValidPhoneNumber()) {
				document.getElementById("Phone").scrollIntoView({behavior: "smooth"});
			}
		},
		saveContact(contact) {
			this.validateContact(contact);
			if (!this.formErrors.hasErrors) {
				this.$store
					.dispatch("upsertSFData", {
						action: "upsertContact",
						data: contact,
						successMessage: this.$t("mol.contactForm.message.Contact saved"),
						errorMessage: this.$t("mol.contactForm.message.Contact not saved"),
					})
					.then(contact.Id ? this.updateContact : this.insertContact)
					.catch((err) => {
						console.error(err);
					});
			}
		},
		updateContact() {},
		insertContact(data) {
			this.resetContact();
			let newContactHash = "#" + data.data.Contacts.records[data.data.Contacts.records.length - 1].Id;
			this.$router.push({name: "Contacts", hash: newContactHash});
		},
		deleteContact(contact) {
			this.$store.dispatch("upsertSFData", {
				action: "deleteContact",
				data: contact,
				successMessage: this.$t("mol.contactForm.message.Contact removed"),
				errorMessage: this.$t("mol.contactForm.message.Contact not removed"),
			});
		},
		resetContact() {
			this.newContact = this.initialNewContact();
		},
		resetFormErrors() {
			this.formErrors = this.initialContactFormErrors();
		},
		salutation(value) {
			if (value == "mevr.") return this.$t("mol.contactForm.label.MrsMs");
			if (value == "dhr.") return this.$t("mol.contactForm.label.Mr");
			return "";
		},
	},
	mounted() {
		this.newContact.AccountId = this.location.Id;
	},
	watch: {
		molEditingState: function () {
			if (this.molEditingState) {
				this.setContactToEdit();
			} else {
				this.panelConfirmRemove = false;
				this.resetContact();
			}
		},
		molSubnavHash: function () {
			this.resetFormErrors();
		},
	},
};
</script>
