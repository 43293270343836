<template>
	<ul class="progress-steps d-flex flex-row mb-md-5">
		<li class="progress-step progress-step-first" :class="currentStep(1)" :style="{width: stepWidth}">
			<div class="progress-step-nr"><font-awesome-icon :icon="['fal', 'check']" /></div>

			<div class="progress-step-lbl">DJ kiezen</div>
			<div class="progress-step-line"></div>
		</li>
		<li class="progress-step" :class="[currentStep(2), lastStep(2)]" :style="{width: stepWidth}">
			<div class="progress-step-nr"><font-awesome-icon :icon="['fal', 'check']" /></div>

			<span v-if="steps_type == 'quote'" v-text="'Offerte aanvragen'" class="progress-step-lbl" />
			<span v-if="steps_type == 'booking'" v-text="'Offerte bevestigen'" class="progress-step-lbl" />
			<div class="progress-step-line"></div>
		</li>
		<li v-if="steps == 4" class="progress-step" :class="currentStep(3)" :style="{width: stepWidth}">
			<div class="progress-step-nr"><font-awesome-icon :icon="['fal', 'check']" /></div>

			<span v-if="steps_type == 'quote'" v-text="'Offerte bevestigen'" class="progress-step-lbl" />
			<span v-if="steps_type == 'booking'" v-text="'50% betalen'" class="progress-step-lbl" />
			<div class="progress-step-line"></div>
		</li>
		<li v-if="steps == 4" class="progress-step" :class="[currentStep(4), lastStep(4)]" :style="{width: stepWidth}">
			<div class="progress-step-nr"><font-awesome-icon :icon="['fal', 'check']" /></div>

			<span v-if="steps_type == 'quote'" v-text="'50% betalen'" class="progress-step-lbl" />
			<span v-if="steps_type == 'booking'" v-text="'Jouw feest'" class="progress-step-lbl" />
			<div class="progress-step-line"></div>
		</li>
	</ul>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
	components: {
		FontAwesomeIcon,
	},
	name: "QuoteProgress",
	props: {
		steps: {
			type: Number,
			required: true,
		},
		step: {
			type: Number,
			required: true,
		},
		steps_type: {
			type: String,
			required: true,
		},
	},
	computed: {
		max: function () {
			return this.$route.name == "QuoteConfirmed" ? 4 : 2;
		},
		stepWidth: function () {
			return 100 / this.steps + "%";
		},
	},
	methods: {
		currentStep(step) {
			if (step <= this.step) return "progress-step-completed";
			if (step == this.step + 1) return "progress-step-next";
		},
		lastStep(step) {
			if (step == this.steps) return "progress-step-last";
		},
	},
};
</script>

<style lang="scss" scoped>
.progress-steps {
	max-width: 700px;
	padding-left: 0px;
	margin-top: 10px;
	margin-bottom: 5px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	li {
		display: inline-block;
		float: left;
		padding: 0;
		margin: 0;
		text-align: center;
		position: relative;
		a {
			&:hover,
			&:active,
			&:visited,
			&:focus {
				outline: none;
				text-decoration: none;
			}
		}
		.progress-step-nr {
			@extend .d-flex;
			@extend .align-items-center;
			@extend .justify-content-center;
			@extend .mx-auto;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 2px solid $brand-success;
			color: $brand-success;
			background: #fff;
			z-index: 3;
			position: relative;
			margin-top: 10px;
			svg {
				display: none;
				color: #fff;
				font-size: 20px;
				height: 25px;
				line-height: 25px;
			}
		}
		a:hover {
			.progress-step-nr {
				background-color: $brand-success;
			}
		}
		.progress-step-line {
			position: absolute;
			border: 1px solid $brand-success;
			top: 20px;
			z-index: 1;
			left: 79%;
			width: 40%;
			left: 50%;
			width: 100%;
		}
		.progress-step-lbl {
			margin-top: 6px;
			font-size: 13px;
			color: $brand-success;
			line-height: 13px;
			position: relative;
			z-index: 2;
		}
	}
	.progress-step-first {
		.progress-step-style-r {
			display: none;
		}
	}
	.progress-step-last {
		.progress-step-style-l,
		.progress-step-line {
			display: none;
		}
	}
	.progress-step-completed {
		.progress-step-nr {
			margin-top: 0;
			height: 40px;
			width: 40px;
			background-color: $brand-success;
			border: 1px solid $brand-success;
			span {
				display: none;
			}
			svg {
				display: block;
			}
		}
		.progress-step-lbl {
			color: $brand-success;
		}
		.progress-step-line {
			border: 1px solid $brand-success;
		}

		a:hover {
			.progress-step-nr {
				background-color: darken(#a6ce39, 5%) !important;
			}
		}
	}
	.progress-step-next {
		.progress-step-nr {
			border: 2px solid $brand-success;
			span {
				color: $brand-success;
			}
		}
		.progress-step-lbl {
			color: $brand-success;
		}
	}
}
</style>
