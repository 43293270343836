<template>
	<div>
		<Alert v-if="isConfirmed" alertType="notification">
			<strong>Let op!&nbsp;&nbsp;</strong>Je kunt hier nu niks meer aanpassen. We zijn namelijk al begonnen met
			voorbereiding op basis van wat is ingevuld. Toch nog iets doorgeven? Neem contact op via
			<a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a> of via één van de andere contactopties (zie
			onderaan pagina).
		</Alert>
		<Location v-if="hasLocation" />
		<NoLocation v-if="!hasLocation" />
		<Spaces v-if="hasLocation" />
		<Reviews id="reviews" v-if="hasLocation && !locationIsPrivate && reviews.length > 0" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Location from "@/components/customer/Location";
import NoLocation from "@/components/customer/NoLocation";
import Reviews from "@/components/customer/Reviews";
import Spaces from "@/components/customer/Spaces";
import Alert from "@/components/customer/Alert";

export default {
	name: "PartyLocation",
	components: {
		Location,
		NoLocation,
		Reviews,
		Spaces,
		Alert,
	},
	computed: {
		...mapGetters({
			reviews: "getReviews",
			location: "getLocation",
			hasLocation: "hasLocation",
			isConfirmed: "isConfirmed",
			isBelgian: "isBelgian",
			infoEmailAddress: "getInfoEmailAddress",
		}),
		locationIsPrivate() {
			return this.location.Location_private__c;
		},
	},
};
</script>

<style lang="scss" scoped>
.multiselect__option--highlight {
	background: $tdc-blue;
	&::after {
		background: $tdc-blue !important;
	}
}
</style>
