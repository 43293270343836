<template>
	<component :is="template" :templateVariant="templateVariant" :showDefaultCta="showDefaultCta">
		<template slot="customCta">
			<slot name="customCta"></slot>
		</template>
	</component>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import DjBlockSummary from "@/components/customer/DjBlockSummary";
import DjBlockComplete from "@/components/customer/DjBlockComplete";

export default {
	name: "DJ-Block",
	components: {
		FontAwesomeIcon,
		Alert,
		DjBlockComplete,
		DjBlockSummary,
	},
	props: {
		dj_class: String,
		name: String,
		type: String,
		type_icon: String,
		stars: Number,
		sf_name: String,
		priceIncVat: {
			type: Number,
			default: 0,
		},
		priceExcVat: {
			type: Number,
			default: 0,
		},
		quote: {
			type: Object,
			default: null,
		},
		downPayment: {
			type: Number,
			default: null,
		},
		template: {
			type: String,
			default: "DjBlockSummary",
		},
		templateVariant: {
			type: String,
			default: "",
		},
		showDefaultCta: {
			type: Boolean,
			default: true,
		},
		showCustomCta: {
			type: Boolean,
			default: false,
		},
		hideAllLists: {
			type: Boolean,
			default: false,
		},
		activeCalculator: {
			type: Boolean,
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isPreQuote: "isPreQuote",
			isPaid: "getPaidStatus",
			isOrderInProgress: "isOrderInProgress",
			hasGeo: "hasGeo",
			geo: "getGeo",
			isBelgian: "isBelgian",
		}),
		disableCta: function () {
			return this.isPreQuote || this.isBlocked || this.isOrderInProgress;
		},
		isBlocked: function () {
			return this.prices ? this.prices.p_errorCode != null : false;
		},
		displayQuoteDetails: function () {
			return this.quote == null ? false : true;
		},
		save: function () {
			return (
				((this.priceFrom - (this.booking.Business_Booking__c ? this.priceExcVat : this.priceIncVat)) / this.priceFrom) *
				100
			).toFixed(0);
		},
		priceFrom: function () {
			return (this.booking.Business_Booking__c ? this.priceExcVat : this.priceIncVat) + 150;
		},
		lightSoundLabel: function () {
			if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "nee") return "";
			else if (this.booking.light_show_needed__c == "ja" && this.booking.pa_system_needed__c == "nee")
				return "Incl. lichtshow";
			else if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "ja")
				return "Incl. geluidsapparatuur";
			return "Lichtshow en geluidsapparatuur";
		},
		djClassName: function () {
			if (this.sf_name == "Junior") return "Junior-klasse DJ ";
			else if (this.sf_name == "AClass") return "A-klasse DJ";
			else if (this.sf_name == "A") return "A-klasse DJ";
			else if (this.sf_name == "Premium") return "Premium-klasse DJ";
			return "";
		},
		djClassStars: function () {
			if (this.sf_name == "Junior") return 1;
			else if (this.sf_name == "AClass") return 3;
			else if (this.sf_name == "A") return 3;
			else if (this.sf_name == "Premium") return 5;
			return "";
		},
		djClassType: function () {
			if (this.sf_name == "Junior") return "Voordeligste keuze";
			else if (this.sf_name == "AClass") return "Meest gekozen";
			else if (this.sf_name == "A") return "Meest gekozen";
			else if (this.sf_name == "Premium") return "Veiligste keuze";
			return "";
		},
		bestPremiumDjFromCountryUsp: function () {
			if (this.sf_name == "Premium") {
				if (this.isBelgian) {
					return "De beste DJ's van Vlaanderen";
				} else {
					return "De beste DJ's van Nederland";
				}
			}
			return "";
		},
		djClassIcon: function () {
			if (this.sf_name == "Junior") return "percent";
			else if (this.sf_name == "AClass") return "trophy";
			else if (this.sf_name == "A") return "trophy";
			else if (this.sf_name == "Premium") return "shield";
			return "";
		},
		djBestChoice: function () {
			if (this.sf_name == "AClass") return true;
			else if (this.sf_name == "A") return true;
			return false;
		},
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		geoText: function () {
			return this.hasGeo ? "in " + this.geo : "";
		},
	},
	data() {
		return {
			usps: {
				Junior: [
					"Ervaren DJ",
					"Goede klantervaringen",
					"Licht- en geluidsapparatuur",
					"Incl. reis- en parkeerkosten",
					"Niet goed, geld terug",
				],
				AClass: [
					"Zeer ervaren DJ",
					"Klantervaringen met een 8.0 of hoger",
					"Aanbevolen voor o.a. bruiloften",
					"Licht- en geluidsapparatuur",
					"Incl. reis- en parkeerkosten",
					"Niet goed, geld terug",
				],
				Premium: [
					"Klantervaringen met een 9.0 of hoger",
					"Aanbevolen voor o.a. bruiloften",
					"Licht- en geluidsapparatuur",
					"Incl. reis- en parkeerkosten",
					"Niet goed, geld terug",
				],
			},
		};
	},
	methods: {
		formatPrice(price) {
			return this.$currency(price, 2).format().replace(",00", ",-");
		},
		bookDJ() {
			console.log("bookDJ");
			this.$store.commit("SET_CURRENT_DJ_CLASS", this.sf_name == "AClass" ? "A" : this.sf_name);
			this.$store.dispatch("postBooking", {
				action: "setDjClass",
				data: {
					CurrentDJClass__c: this.sf_name == "AClass" ? "A" : this.sf_name,
				},
				setBooking: true,
			});
			this.$router.push({name: "DirectBookingCart"});
		},
		requestQuote() {
			this.$store.commit("SET_CURRENT_DJ_CLASS", this.sf_name == "AClass" ? "A" : this.sf_name);
			this.$router.push({name: "QuoteCart", params: {djClass: this.sf_name == "AClass" ? "A" : this.sf_name}});
		},
	},
};
</script>

<style lang="scss">
p.text-muted {
	font-size: 0.95rem !important;
}
.btn-lg {
	@include media-breakpoint-only(xl) {
		font-size: 1.1rem;
	}
}

.most-selected {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 1) 75%,
		rgba(211, 231, 156, 0.5) 95%,
		rgba(166, 206, 57, 0.3) 100%
	);
}
</style>
