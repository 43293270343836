<template>
	<div>
		<div class="my-3">
			<h3><strong>Klantervaringen</strong></h3>
		</div>
		<div class="my-3">
			<Review v-for="review in visibleReviews" :key="review.Id" :review="review" />
			<div v-if="showMoreReviews">
				<Review v-for="review in modalReviews" :key="review.Id" :review="review" />
			</div>
		</div>
		<div class="border-bottom pb-4 d-flex justify-content-center">
			<b-button
				v-if="reviews.length > 2 && !showMoreReviews"
				@click="showMoreReviews = true"
				variant="primary"
				class="mx-auto"
			>
				Toon meer reviews
			</b-button>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import Review from "@/components/customer/Review";

export default {
	name: "Reviews",
	components: {
		Review,
	},
	data() {
		return {
			showMoreReviews: false,
		};
	},
	computed: {
		...mapGetters({
			reviews: "getReviews",
		}),
		visibleReviews: function () {
			return this.reviews.filter((review, index) => index < 2);
		},
		modalReviews: function () {
			return this.reviews.filter((review, index) => index >= 2);
		},
	},
};
</script>
