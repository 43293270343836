<template>
	<div>
		<DisableTrengoChat />
		<QuoteProgress :step="2" :steps="4" :steps_type="'booking'" />
		<PaymentNotification />
		<div v-if="!isLoading">
			<div class="desktop-content-left-right-wrapper mb-4">
				<DjBlock
					v-if="!isLoading"
					template="DjBlockComplete"
					templateVariant="DirectBookingConfirmed"
					:sf_name="djClass"
					:priceIncVat="priceInc"
					:priceExcVat="priceExc"
					:downPayment="downPayment"
					:showDefaultCta="false"
					:showCustomCta="true"
					:hideAllLists="true"
					:activeCalculator="false"
				>
					<template slot="customCta">
						<div class="d-flex flex-column align-items-end">
							<PaymentButton />
						</div>
					</template>
				</DjBlock>
			</div>
		</div>
		<BookingIncludes />
		<div
			class="d-flex flex-column align-items-center border-top border-bottom py-4"
			style="border-color: #cc0066 !important"
		>
			<div class="mx-auto" style="max-width: 450px">
				<PaymentButton />
			</div>
		</div>
		<Faqs :questionSet="[1, 2, 4, 5, 6]" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PaymentNotification from "@/components/customer/PaymentNotification";
import QuoteProgress from "@/components/customer/QuoteProgress";
import BookingIncludes from "@/components/customer/BookingIncludes";
import PaymentButton from "@/components/customer/PaymentButton";
import Faqs from "@/components/customer/Faqs";
import DjBlock from "@/components/customer/DjBlock";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "DirectBookingConfirmed",
	components: {
		PaymentNotification,
		QuoteProgress,
		BookingIncludes,
		PaymentButton,
		Faqs,
		DjBlock,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isOrderInProgress: "isOrderInProgress",
			isLoading: "getLoadingState",
			downPayment: "getDownPayment",
			priceInc: "getBookingPriceIncVat",
			priceExc: "getBookingPriceExcVat",
			djClass: "getCurrentDjClass",
			isPaid: "getPaidStatus",
			partyType: "getPartyType",
		}),
		normal_price: function () {
			return this.$currency(this.price + 150, 0);
		},
	},
	data() {
		return {
			accept: false,
			submitted: false,
		};
	},
	methods: {
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
	mounted() {
		if (!this.isOrderInProgress) this.$router.push({path: "/"});
		if (this.isPaid) this.$router.push({name: "DirectBookingPaid"});
		this.$gtm.trackView("DirectBookingConfirmed", "/dj-huren/boeken/bevestiging");
	},
};
</script>
