<template>
	<div class="relative w-full">
		<label :for="inputId" class="d-block">{{ label }}</label>
		<input
			ref="input"
			:id="inputId"
			:class="[
				{
					'pl-9 text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600':
						mol,
				},
				{'form-control': moc},
				{'border border-amber-500': error},
			]"
			type="tel"
			autocomplete="tel"
			@input="handleChange"
			@countrychange="handleCountryChange"
			@blur="handleBlur"
			@focus="handleFocus"
			@keyup="handleKeyUp"
			@keypress="handleKeyPress"
			v-on:input="checkInput($event.target.value)"
			:disabled="disabled"
		/>
		<div v-if="error" :class="errorclass">{{ error }}</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import countries from "@/store/modules/shared/countries";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import {uuid, getCookie, setCookie} from "@/utilities";

export default {
	name: "TelInput",
	props: {
		label: String,
		value: String,
		disabled: Boolean,
		errorclass: {
			type: String,
			default: "",
		},
		mol: {
			type: Boolean,
			default: false,
		},
		moc: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data() {
		return {
			inputId: uuid(),
			hasFocus: false,
			error: "",
			iti: null,
			countryCode: null,
			localPhone: null,
			validPhone: null,
			utils: null,
			// https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L148
			// TODO: make translatable
			errorMap: ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"],
			countries: countries,
		};
	},
	watch: {
		value: function watchValue(val) {
			if (this.iti && val) {
				this.setNumber(val);
			}
		},
		disabled: function watchDisabled(val) {
			if (!val) {
				this.hasFocus = false;
			}
		},
	},
	computed: {
		...mapGetters({
			domainTld: "getTld",
		}),
		initialCountryCode() {
			return this.countries[this.domainTld] !== null ? this.domainTld : "nl";
		},
	},
	methods: {
		handleChange() {
			this.setLocalValues();
			this.$emit("change", this.validPhone);
		},
		handleCountryChange() {
			this.setLocalValues();
			this.$emit("change", this.validPhone);
		},
		handleBlur() {
			if (this.hasFocus) {
				if (this.reportValidity()) {
					this.$emit("set", this.validPhone);
				}
			}
			this.hasFocus = false;
		},
		handleFocus() {
			this.hasFocus = true;
		},
		handleKeyUp(evt) {
			this.$emit("keyup", evt);
		},
		handleKeyPress(evt) {
			this.$emit("keypress", evt);
		},
		focus() {
			try {
				if (this.$ref.input) {
					this.$ref.input.focus();
				}
			} catch (err) {
				console.error(err);
			}
		},
		geoIpLookup(callback) {
			let cookie = getCookie("ipinfoCountry");
			if (cookie) {
				callback(cookie);
				return;
			} else {
				fetch("https://ipinfo.io", {
					headers: {
						Accept: "application/json",
					},
				})
					.then((response) => {
						return response.json();
					})
					.then((data) => {
						let country = data && data.country ? data.country : "";
						setCookie("ipinfoCountry", `${country};max-age=31536000;path=/;samesite=strict;secure`);
						callback(country);
					})
					.catch((err) => {
						console.error("iplookup error", err);
						let parts = window.location.hostname.split(".");
						let tld = parts[parts.length - 1];
						if (tld.length == 2) {
							callback(tld);
						}
						callback("nl");
					});
			}
		},
		checkValidity() {
			if (!this.iti) {
				return false;
			}
			this.phone = null;
			let num = this.iti.getNumber(this.utils.numberFormat.E164);
			if (!num && !this.required) return true;
			let valid = this.iti.isValidNumber();
			if (valid) {
				this.setNumber(num);
			}
			return valid;
		},
		reportValidity() {
			let rv = this.checkValidity();
			if (this.mol) {
				return true;
			}
			if (rv) {
				this.error = "";
			} else {
				let error = this.iti.getValidationError();
				if (typeof error === "number" && error > 0 && error < this.errorMap.length) {
					this.error = this.errorMap[error];
				} else {
					// fallback message
					this.error = "Enter a valid number";
				}
			}
			return rv;
		},
		checkInput() {
			this.$emit("input", this.iti.getNumber(this.utils.numberFormat.E164));
			if (this.reportValidity()) {
				//console.log("set", this.validPhone);
				this.$emit("set", this.validPhone);
			}
		},
		setNumber(val) {
			//console.log("setNumber", val);
			this.iti.setNumber(val);
			//console.log("isValidNumber", this.iti.isValidNumber(), this.iti.getNumber(this.utils.numberFormat.E164));
			this.setLocalValues();
		},
		setLocalValues() {
			this.countryCode = this.iti.getSelectedCountryData().dialCode;
			this.localPhone = this.$refs.input.value;
			if (this.iti.isValidNumber()) {
				this.validPhone = this.iti.getNumber(this.utils.numberFormat.E164);
			} else {
				this.validPhone = null;
			}
		},
	},
	mounted() {
		this.iti = intlTelInput(this.$refs.input, {
			// allowDropdown: false,
			// autoHideDialCode: false,
			// autoPlaceholder: "off",
			// dropdownContainer: document.body,
			// excludeCountries: ["us"],
			formatOnDisplay: true,
			geoIpLookup: this.geoIpLookup,
			// hiddenInput: "full_number",
			initialCountry: this.initialCountryCode,
			// localizedCountries: { 'de': 'Deutschland' },
			// nationalMode: false,
			// onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
			// placeholderNumberType: "MOBILE",
			preferredCountries: ["nl", "be", "de"],
			separateDialCode: true,
			utilsScript: "/intl-tel-input-utils.js",
		});
		this.iti.promise.then(() => {
			//console.log("iti loaded");
			this.utils = window.intlTelInputUtils;
			if (this.value) {
				this.setNumber(this.value);
			}
		});
	},
};
</script>

<style lang="scss">
.iti {
	width: 100% !important;
}
</style>
