import Vue from "vue";
import Vuex from "vuex";
import moSetup from "@/store/modules/shared/moSetup";

import locationData from "@/store/modules/location/locationData";

import customerEvaluationData from "@/store/modules/customerEvaluation/customerEvaluationData";

import customerBooking from "@/store/modules/customer/customerBooking";
import spotifyUser from "@/store/modules/customer/spotifyUser";
import spotifyTrack from "@/store/modules/customer/spotifyTrack";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		moSetup,
		locationData,
		customerEvaluationData,
		customerBooking,
		spotifyUser,
		spotifyTrack,
	},
});
