<template>
	<div>
		<DisableTrengoChat />
		<div class="d-none" style="display: none; opacity: 0">
			<div id="gtmBookingName">Feest: {{ booking.Name }}</div>
			<div id="gtmBookingDjClass">{{ currentDjClass }}-klasse DJ</div>
			<div id="gtmBookingPrice">{{ formatPrice(booking.Quote_price__c, 2) }}</div>
		</div>
		<QuoteProgress :step="3" :steps="4" :steps_type="'booking'" />
		<PaymentNotification />
		<div class="row">
			<div class="col">
				<h3 class="text-center">Vul direct je factuuradres in</h3>
				<CustomerInvoiceAddress></CustomerInvoiceAddress>
			</div>
		</div>
		<div class="row row-cols-1 row-cols-md-2">
			<div class="col">
				<div class="bg-white d-flex h-100 rounded">
					<div class="mb-3 mb-md-0">
						<div class="d-flex h-100 align-items-center justify-content-center justify-content-lg-start">
							<div class="p-2 pl-3 p-md-4">
								<div class="mb-3 d-flex justify-content-start w-100 unique-titles">
									<font-awesome-icon class="ml-n1 mr-2 mt-1 text-primary" :icon="['far', titleIcon]" />
									<div class="w-100">
										<p>
											<strong>
												<span v-if="isWedding"
													>{{ booking.bridal_couple_del__c }}'s {{ partyType }} op {{ partyDate }}</span
												>
												<span v-else>{{ booking.Account__r.FirstName }}'s {{ partyType }} op {{ partyDate }}</span>
											</strong>
										</p>
									</div>
								</div>
								<BookingSteps />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<BookingDetails v-if="!isLoading" />
				<MyPartyList class="d-block mt-5" />
			</div>
		</div>
		<Faqs :questionSet="[1, 2, 4, 5, 6]" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteProgress from "@/components/customer/QuoteProgress";
import PaymentNotification from "@/components/customer/PaymentNotification";
import BookingDetails from "@/components/customer/BookingDetails";
import BookingSteps from "@/components/customer/BookingSteps";
import MyPartyList from "@/components/customer/MyPartyList";
import Faqs from "@/components/customer/Faqs";
import CustomerInvoiceAddress from "@/components/customer/CustomerInvoiceAddress.vue";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "BookingPaid",
	components: {
		BookingSteps,
		PaymentNotification,
		QuoteProgress,
		BookingDetails,
		MyPartyList,
		Faqs,
		CustomerInvoiceAddress,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			partyType: "getPartyType",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
			isWedding: "isWedding",
			isPaid: "getPaidStatus",
			isOrderInProgress: "isOrderInProgress",
			loadingInput: "getLoadingInput",
			currentDjClass: "getCurrentDjClass",
		}),
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		titleIcon() {
			if (this.isBirthday) return "cake-candles";
			if (this.isWedding) return "champagne-glasses";
			if (this.isBusinessParty) return "face-party";
			return "balloons";
		},
	},
	methods: {
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
	mounted() {
		if (this.isOrderInProgress && !this.isPaid) this.$router.push({name: "DirectBookingConfirmed"});
		if (!this.isOrderInProgress) this.$router.push({path: "/"});
	},
};
</script>
