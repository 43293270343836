<template>
	<div :class="{'lg:ml-[320px] xl:ml-[400px]': adaptSubnav}">
		<h1
			:class="{'border-b border-slate-200 dark:border-slate-600 pb-2 md:pb-5': !breadcrumbs}"
			class="mb-4 md:mb-5 text-lg font-bold tracking-tight"
		>
			{{ formattedTitle }}<span v-html="formattedSubTitle" class="text-xl block font-light tracking-normal" />
		</h1>
		<Breadcrumbs
			v-if="breadcrumbs"
			:path="breadcrumbs"
			@show="clickedHash"
			:class="{'border-b border-slate-200 dark:border-slate-600 pb-2 md:pb-5': breadcrumbs}"
			class="mb-2 md:mb-5"
		/>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Breadcrumbs from "@/components/location/Breadcrumbs";

export default {
	name: "PageTitle",
	components: {
		Breadcrumbs,
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		subTitle: {
			type: String,
			default: "",
		},
		breadcrumbs: {
			type: Array,
		},
		adaptSubnav: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			olSubnavHash: "getMolSubnavHash",
		}),
		formattedSubTitle() {
			if (this.subTitle) return this.subTitle;
			return "";
		},
		formattedTitle() {
			if (this.subTitle) return this.title;
			return this.title;
		},
	},
	methods: {
		handleClick(event) {
			this.$root.$emit("click", event);
		},
		clickedHash(hash) {
			this.$emit("show", hash);
		},
	},
};
</script>
