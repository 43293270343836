import {getSalesForce, postSalesForce} from "@/services/SalesforceService";

const state = {
	booking: {},
	evaluation: {},
};

const getters = {
	getBookingInformation(state) {
		return state.booking;
	},
	getEvaluationInformation(state) {
		return state.evaluation;
	},
};

const mutations = {
	SET_BOOKING_INFORMATION(state, booking) {
		state.booking = booking;
	},
	SET_EVALUATION_INFORMATION(state, evaluation) {
		state.evaluation = evaluation;
	},
};

const actions = {
	checkEvaluation({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("checkEvaluation").then((response) => {
			if (response) {
				commit("SET_BOOKING_INFORMATION", response.data);
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	saveEvaluation({commit, dispatch}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_EVALUATION_INFORMATION", response.data);
				commit("SET_LOADING_STATE", false);
				return response;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				dispatch("displayErrorMessage", {error: true, message: payload.errorMessage});
				throw err;
			});
	},
	getEvaluation({commit}, query) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getEvaluation", query)
			.then((response) => {
				if (response !== undefined) {
					commit("SET_EVALUATION_INFORMATION", response.data);
				}
				commit("SET_LOADING_STATE", false);
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				throw err;
			});
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
