<template>
	<div>
		<div v-if="quote.Status__c == 'Declined'" :class="textClass" class="mx-auto mx-md-0">
			<div class="d-flex flex-row px-1" :class="[isOrderInProgress ? 'align-items-center' : 'align-items-start']">
				<span>Deze offerte is afgewezen</span>
			</div>
		</div>
		<span v-else-if="quote.Status__c == 'Accepted'">
			<span v-if="statusStyle == 'overviewList'">
				<span v-if="!isPaid" class="highlight highlight-orange text-warning"
					>Bevestigd. <router-link :to="{name: 'Booking'}">Betaal de waarborgsom</router-link>.
					<font-awesome-icon :icon="['far', 'chevron-right']" class="ml-1 text-warning" style="height: 12px" />
				</span>
				<span v-else-if="isPaid" class="highlight highlight-green text-success">
					Bevestigd. <router-link :to="{name: 'Booking'}">Bekijk boeking</router-link>.
					<font-awesome-icon :icon="['far', 'chevron-right']" class="ml-1 text-success" style="height: 12px" />
				</span>
			</span>
			<span v-else>
				<div v-if="!isPaid" class="highlight highlight-orange mx-auto mx-md-0 text-warning">
					Bevestigd! <router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>
				</div>
				<div v-if="isPaid" class="highlight highlight-green mx-auto mx-md-0 text-success">Bevestigd!</div>
			</span>
		</span>
		<div v-else :class="textClass" class="mx-auto mx-md-0">
			<div class="d-flex flex-row px-1" :class="[isOrderInProgress ? 'align-items-center' : 'align-items-start']">
				<font-awesome-icon v-if="showTimer" :icon="['fal', 'timer']" class="mr-2 mt-1" />
				<font-awesome-icon v-if="showCalendar" :icon="['fal', 'calendar-times']" class="mr-2" />
				<div v-if="!isOrderInProgress && timeTillExpired > 0">
					<span v-if="showShorterText">Verloopt</span>
					<span v-else>Offerte verloopt</span> in <span v-html="hoursAndMinutesToTime" />
				</div>
				<div v-if="!isOrderInProgress && timeTillExpired <= 0">
					<span v-if="showShorterText">Verlopen</span>
					<span v-else>Deze offerte is verlopen en niet meer geldig</span>
				</div>
				<div v-if="isOrderInProgress">
					<span v-if="showShorterText"
						>Niet meer geldig <span class="d-none d-sm-inline-block">i.v.m. DJ boeking</span></span
					>
					<span v-else>Deze offerte is niet meer geldig i.v.m. met jouw DJ boeking</span>
				</div>
			</div>
		</div>
		<font-awesome-icon
			v-if="statusStyle == 'overviewSteps'"
			:icon="['far', 'chevron-right']"
			:class="chevronClass"
			class="ml-1"
			style="height: 12px"
		/>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "QuoteExpiresOn",
	components: {},
	props: {
		quote: {
			type: Object,
			required: true,
		},
		statusStyle: {
			type: String,
			default: "default",
		},
	},
	computed: {
		...mapGetters({
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
		}),
		timeTillExpired: function () {
			return Date.parse(this.quote.ExpirationDate__c) - Date.now();
		},
		hoursAndMinutesToTime: function () {
			var minutes = Math.floor((this.timeTillExpired / (1000 * 60)) % 60),
				hours = Math.floor(this.timeTillExpired / (1000 * 60 * 60));
			return hours > 0 ? hours + " uur" : minutes + ' min<span class="d-none d-sm-inline-block">uten</span>';
		},
		showTimer() {
			if (this.statusStyle == "overviewSteps") return false;
			if (this.statusStyle == "overviewList") return false;
			if (!this.isOrderInProgress && this.statusStyle == "minimal") return true;

			return false;
		},
		showCalendar() {
			if (this.statusStyle == "overviewSteps") return false;
			if (this.statusStyle == "overviewList") return false;
			if (this.isOrderInProgress && this.statusStyle != "minimal") return true;
			return false;
		},
		showShorterText() {
			if (this.statusStyle == "minimal" || this.statusStyle == "overviewSteps" || this.statusStyle == "overviewList")
				return true;
			return false;
		},
		textClass: function () {
			if (this.statusStyle == "default" || this.statusStyle == "overviewSteps" || this.statusStyle == "overviewList") {
				if (this.isOrderInProgress || this.timeTillExpired <= 0) return "highlight text-muted pr-0";
				if (this.timeTillExpired / (1000 * 60 * 60) < 8) return "highlight highlight-red text-danger";
				if (this.timeTillExpired / (1000 * 60 * 60) < 24) return "highlight highlight-orange text-warning";
				return "highlight highlight-orange text-warning";
			}
			if (this.statusStyle == "minimal") {
				if (this.isOrderInProgress || this.timeTillExpired <= 0) return "small text-muted";
				if (this.timeTillExpired / (1000 * 60 * 60) < 8) return "small text-danger";
				if (this.timeTillExpired / (1000 * 60 * 60) < 24) return "small text-warning";
				return "small text-warning";
			}
			return "";
		},
		chevronClass() {
			if (this.isOrderInProgress || this.timeTillExpired <= 0) return "text-muted";
			if (this.timeTillExpired / (1000 * 60 * 60) < 8) return "text-danger";
			if (this.timeTillExpired / (1000 * 60 * 60) < 24) return "text-warning";
			return "text-warning";
		},
	},
};
</script>

<style lang="scss" scoped>
.quote-expires-on {
	text-decoration: none !important;
}
.highlight {
	background-repeat: no-repeat;
	background-size: 99% 95%;
	background-position: center center;
	position: relative;
	display: inline-block;
	padding-bottom: 1px;
	color: $body-color;
	font-weight: 900;
	svg {
		font-size: 90%;
	}
}
.highlight-green {
	background-image: url("~@/assets/images/green-highlight.png");
	color: darken($tdc-green, 15%) !important;
	svg {
		color: darken($tdc-green, 70%);
	}
}
.highlight-orange {
	background-image: url("~@/assets/images/orange-highlight.png");
	svg {
		color: darken($tdc-orange, 20%);
	}
}
.highlight-red {
	background-image: url("~@/assets/images/red-highlight.png");
	svg {
		color: darken($tdc-red, 20%);
	}
}
</style>
