<template>
	<div>
		<PageTitle :title="$t('mol.homepage.title.Welcome')" :subTitle="location.Name + ', ' + location.BillingCity" />
		<div
			class="p-2 md:p-4 lg:p-6 mb-3 md:mb-6 flex flex-col bg-slate-200 border-t-4 border-tdc-green-500 dark:border-tdc-green-600 dark:bg-tdc-green-700"
		>
			<div class="dark:text-white text-lg font-bold mb-2">
				{{ $t("mol.featureMsg.title.Review The DJ Company") }}
			</div>
			<p class="text-base dark:text-white mb-4">{{ $t("mol.featureMsg.string.Satisfied? Let us know") }}</p>
			<p class="text-base dark:text-white mb-4">
				{{ $t("mol.featureMsg.string.Create a review in 2 minutes") }}<br />
				<router-link :to="{name: 'ReviewUs'}"
					><Ahref>{{ $t("mol.featureMsg.string.See the review here") }}</Ahref></router-link
				>.
			</p>
			<router-link :to="{name: 'ReviewUs'}" class="inline-block w-fit">
				<Button type="green">{{ $t("mol.featureMsg.button.Fill in evaluation") }}</Button>
			</router-link>
		</div>

		<div
			class="p-2 md:p-4 lg:p-6 mb-3 md:mb-6 flex flex-col bg-slate-200 border-t-4 border-tdc-blue-500 dark:bg-sky-800 bg-no-repeat bg-right-bottom comon bg-cover"
		>
			<div class="dark:text-white text-lg font-bold mb-2">
				{{ $t("mol.homepage.title.Hire our DJ for your next event") }}
			</div>
			<ul class="mb-4">
				<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
					<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
					{{ $t("common.usp.string.No cure, no pay") }}
				</li>
				<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
					<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
					{{ $t("common.usp.string.Free cancellation 14 days before party date") }}
				</li>
				<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
					<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
					{{ $t("common.usp.string.100% match with your DJ") }}
				</li>
				<li class="text-base dark:text-white flex items-start justify-start mb-1 md:mb-2">
					<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-tdc-green-500" />
					{{ $t("mol.featureMsg.string.Settle invoice in advance or afterwards") }}
				</li>
			</ul>
			<router-link :to="{name: 'BookADJ'}" class="flex">
				<Button>{{ $t("mol.general.button.See pricing") }}</Button>
			</router-link>
		</div>
		<div
			class="p-2 md:p-4 lg:p-6 mb-3 md:mb-6 flex flex-col bg-slate-200 border-t-4 border-slate-500 dark:bg-slate-800 bg-no-repeat bg-right-bottom comon bg-cover"
		>
			<div class="dark:text-white text-lg font-bold mb-2">
				{{ $t("mol.homepage.title.Navigate to") }}
			</div>
			<ul
				class="divide-y lg:divide-y-0 lg:gap-x-10 xl:gap-x-16 divide-slate-300 divide-dashed [&_a]:flex [&_a]:flex-row flex flex-col lg:flex-row [&_a]:py-3"
			>
				<li>
					<router-link :to="{name: 'Profile', hash: '#upload-photos'}">
						<Ahref class="flex flex-row items-center w-full">
							<font-awesome-icon :icon="['far', 'link']" class="fa-fw -mt-0.5 mr-2 text-slate-500" />
							{{ $t("mol.mainNav.label.Venue profile") }}
							<font-awesome-icon
								:icon="['far', 'chevron-right']"
								class="justify-self-end fa-fw -mt-0.5 ml-auto text-slate-300 lg:hidden"
							/>
						</Ahref>
					</router-link>
				</li>
				<li>
					<router-link :to="{name: 'BookADJ'}">
						<Ahref class="flex flex-row items-center w-full">
							<font-awesome-icon :icon="['far', 'calendar-plus']" class="fa-fw -mt-0.5 mr-2 text-slate-500" />
							<div class="relative">
								<span class="flex items-center absolute text-[9px] -mt-[8px] text-amber-500">
									{{ $t("mol.mainNav.label.New").toUpperCase()
									}}<font-awesome-icon :icon="['far', 'lightbulb-on']" class="ml-[2px] -mt-[2px]"
								/></span>
								{{ $t("mol.mainNav.label.Book a DJ") }}
							</div>
							<font-awesome-icon
								:icon="['far', 'chevron-right']"
								class="justify-self-end fa-fw -mt-0.5 ml-auto text-slate-300 lg:hidden"
							/>
						</Ahref>
					</router-link>
				</li>
				<li>
					<router-link :to="{name: 'DJBookings'}">
						<Ahref class="flex flex-row items-center w-full">
							<font-awesome-icon :icon="['far', 'calendar-day']" class="fa-fw -mt-0.5 mr-2 text-slate-500" />
							{{ $t("mol.mainNav.label.DJ bookings") }}
							<font-awesome-icon
								:icon="['far', 'chevron-right']"
								class="justify-self-end fa-fw -mt-0.5 ml-auto text-slate-300 lg:hidden"
							/>
						</Ahref>
					</router-link>
				</li>
				<li>
					<router-link :to="{name: 'ReviewUs'}" class="w-full">
						<Ahref class="flex flex-row items-center w-full">
							<font-awesome-icon :icon="['far', 'hundred-points']" class="fa-fw -mt-0.5 mr-2 text-slate-500" />

							<div class="relative">
								<span class="flex items-center absolute text-[9px] -mt-[8px] text-amber-500">
									{{ $t("mol.mainNav.label.New").toUpperCase()
									}}<font-awesome-icon :icon="['far', 'lightbulb-on']" class="ml-[2px] -mt-[2px]"
								/></span>
								{{ $t("mol.mainNav.label.Evaluation") }}
							</div>
							<font-awesome-icon
								:icon="['far', 'chevron-right']"
								class="justify-self-end fa-fw -mt-0.5 ml-auto text-slate-300 lg:hidden"
							/>
						</Ahref>
					</router-link>
				</li>
			</ul>
		</div>

		<div
			v-if="showLocatieSpotterBlock"
			class="p-2 md:p-4 lg:p-6 flex flex-col bg-[#f5dbd0] border-t-4 border-t-[#f0814c]"
		>
			<div class="!text-[#f0814c] text-lg font-bold mb-2">
				{{ $t("mol.homepage.title.Introducing LocatieSpotter") }}
			</div>
			<p class="mb-2 dark:text-slate-900">
				{{ $t("mol.homepage.string.Over 5000 organizers") }}
				<router-link :to="{name: 'Profile', hash: '#more-leads'}">
					<Ahref class="text-[#f0814c] hover:text-[#db6e3a]">{{
						$t("mol.homepage.button.Read more about LocatieSpotter")
					}}</Ahref>
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import Button from "@/components/location/Button";
import Ahref from "@/components/location/Ahref";

export default {
	name: "LocationHome",
	components: {
		PageTitle,
		Button,
		Ahref,
	},
	computed: {
		...mapGetters({
			location: "getLocationInformation",
			tdcPhoneNumber: "getTdcPhoneNumber",
			countryCode: "getCountryCode",
		}),
		showLocatieSpotterBlock() {
			if (this.countryCode === "NL") return true;
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.common {
	background-image: url("~@/assets/images/background-dancing-transparent.png");
}
</style>
