import {postSpotify} from "@/services/SpotifyService";

const state = {
	tokens: {},
};

const getters = {
	getTokens(state) {
		return state.tokens;
	},
};

const mutations = {
	setToken(state, data) {
		state.tokens = data;
	},
};

const actions = {
	initClient({commit}) {
		postSpotify("initClient", "https://accounts.spotify.com/api/token", "grant_type=client_credentials", true).then(
			(response) => {
				commit("setToken", response.data);
			}
		);
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
