<template>
	<router-link :to="link" :class="[linkBlockClasses, fillHeight == true ? 'h-100' : '']" class="link-block">
		<div v-text="title" class="link-block-title"></div>
		<div class="link-block-body">
			<div class="d-flex flex-row align-items-center justify-content-start">
				<div class="mr-2 mr-md-3">
					<div
						v-if="image"
						style="width: 65px; height: 65px; border-radius: 50%; overflow: hidden; border: 3px solid #efefef"
						class="d-flex justify-content-center align-items-center"
					>
						<img :src="image" class="w-100 img-responsive" style="height: 70px" />
					</div>
					<font-awesome-icon v-else :class="iconClass" class="link-block-icon" :icon="[iconGroup, icon]" />
				</div>
				<div class="w-100 link-block-body">
					<slot />
				</div>
			</div>
		</div>
		<div class="link-block-footer">
			{{ linkText }} <font-awesome-icon class="ml-1" :icon="['far', 'chevron-right']" />
		</div>
	</router-link>
</template>

<script>
export default {
	name: "link-block",
	props: {
		title: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: false,
			default: "",
		},
		icon: {
			type: String,
			required: false,
		},
		iconGroup: {
			type: String,
			required: false,
			default: "far",
		},
		iconClass: {
			type: String,
			required: false,
			default: "",
		},
		link: {
			default: "/",
		},
		linkText: {
			type: String,
			default: "Bekijk",
		},
		linkBlockClasses: {
			type: String,
			required: false,
			default: "",
		},
		fillHeight: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.link-block {
	@include transition(0.2s ease-in-out);
	text-decoration: none !important;
	svg .fa-primary,
	svg .fa-secondary {
		@include transition(all 0.3s ease-in-out);
		color: $tdc-blue;
	}
	&:hover {
		@extend .shadow-lg;
		svg {
			&.fa-window-alt,
			&.fa-address-card {
				--fa-primary-color: #fff;
				--fa-secondary-color: #009fee;
				--fa-secondary-opacity: 1;
			}
			&.fa-music {
				--fa-primary-color: #a6ce39;
				--fa-secondary-color: #a6ce39;
				--fa-primary-opacity: 1;
				--fa-secondary-opacity: 1;
			}
			&.fa-calendar-check,
			&.fa-clipboard-list-check {
				--fa-primary-color: #a6ce39;
				--fa-secondary-color: #fff;
				--fa-primary-opacity: 1;
				--fa-secondary-opacity: 1;
			}
			&.fa-city {
				--fa-primary-color: #3b5998;
				--fa-secondary-color: #ffc107;
				--fa-primary-opacity: 1;
				--fa-secondary-opacity: 1;
			}
			&.fa-star {
				color: #ffd556 !important;
			}
			&.fa-heart-circle {
				--fa-primary-color: red;
				--fa-secondary-color: white;
				--fa-primary-opacity: 1;
				--fa-secondary-opacity: 1;
			}
			&.fa-badge-percent {
				--fa-primary-color: white;
				--fa-secondary-color: #009fee;
				--fa-primary-opacity: 1;
				--fa-secondary-opacity: 1;
			}
		}
	}
}
.link-block {
	@extend .d-flex;
	@extend .flex-column;
	@extend .bg-white;
	@extend .rounded;
	@extend .shadow-sm;
}
.link-block-title {
	@extend .h5;
	@extend .font-weight-bold;
	@extend .link-block-title;
	@extend .border-bottom;
	@extend .text-center;
	@extend .mb-2;
	@extend .bg-light;
	@extend .border-top;
	@extend .text-primary;
	@extend .p-2;
	@extend .rounded-top;
}
.link-block-footer {
	@extend .mt-auto;
	@extend .w-100;
	@extend .bg-light;
	@extend .border-top;
	@extend .text-primary;
	@extend .py-2;
	@extend .text-white;
	@extend .rounded-bottom;
	@extend .text-center;
}
.link-block-icon {
	margin-left: 10px;
	width: 45px;
	height: 45px;
	@include media-breakpoint-up(md) {
		width: 65px;
		height: 65px;
		margin-left: 0px;
	}
}
.link-block-body {
	@extend .p-1;
	@extend .p-md-3;
}
.overview-highlight {
	border: 1px $tdc-blue green !important;
	background-color: $tdc-blue !important;
	color: #fff;
	background: #1e5799;
	background: -moz-linear-gradient(45deg, #1e5799 0, #00aae9 100%);
	background: -webkit-linear-gradient(45deg, #1e5799 0, #00aae9 100%);
	background: linear-gradient(45deg, #1e5799 0, #00aae9 100%);
	background: url(~@/assets/images/blue-background-v3.png), linear-gradient(45deg, #1e5799 0, #00aae9 100%);
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: 100%;
	text-shadow: 0 0 10px rgb(0 0 0 / 40%);
	svg .fa-primary,
	svg .fa-secondary {
		color: #fff;
	}
	.link-block-title {
		border-color: darken($tdc-blue, 4%) !important;
		color: #fff !important;
		background: rgba(darken($tdc-blue, 5%), 0.5) !important;
	}
	.link-block-body {
		@extend .py-4;
		@extend .py-md-3;
	}
	.link-block-footer {
		border-color: darken($tdc-blue, 4%) !important;
		color: #fff !important;
		background: rgba(darken($tdc-blue, 5%), 0.5) !important;
	}
}
</style>
