<template>
	<div>
		<DisableTrengoChat />
		<QuoteProgress v-if="!isOrderInProgress" :step="1" :steps="2" :steps_type="'quote'" />
		<StrikeThroughTitle>Vraag jouw offerte nu aan</StrikeThroughTitle>
		<div class="desktop-content-left-right-wrapper">
			<DjBlock
				template="DjBlockComplete"
				templateVariant="QuoteCart"
				:sf_name="djName"
				:priceIncVat="priceInc"
				:priceExcVat="priceExc"
				:showDefaultCta="false"
				:showCustomCta="true"
				:activeCalculator="true"
			>
				<template slot="customCta">
					<div class="d-flex flex-column align-items-center align-items-md-end">
						<div v-if="disableCta" class="px-5 btn-lg btn btn-success mb-2 disabled">
							Vraag offerte aan <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
						</div>
						<div v-else class="px-5 btn-lg btn btn-success mb-2" @click="requestQuote()">
							Vraag offerte aan <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
						</div>
						<div class="d-flex justify-content-center align-items-center">
							<font-awesome-icon style="font-size: 110%" class="text-success mr-1" :icon="['fal', 'check']" />
							Leg deze prijs vast
						</div>
					</div>
				</template>
			</DjBlock>
		</div>
		<BookingIncludes />
		<div class="d-flex flex-column align-items-center border-top border-success border-bottom py-4">
			<div class="px-5 btn-lg btn btn-success" @click="requestQuote()">
				Vraag offerte aan <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteProgress from "@/components/customer/QuoteProgress";
import StrikeThroughTitle from "@/components/customer/StrikeThroughTitle";
import BookingIncludes from "@/components/customer/BookingIncludes";
import DjBlock from "@/components/customer/DjBlock";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "QuoteCart",
	components: {
		QuoteProgress,
		BookingIncludes,
		DjBlock,
		StrikeThroughTitle,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			prices: "getPrices",
			isOrderInProgress: "isOrderInProgress",
			isPreQuote: "isPreQuote",
			isPaid: "getPaidStatus",
		}),
		djPriceClass: function () {
			if (this.djClass === "Junior") {
				return "p_junior";
			} else if (this.djClass === "Premium") {
				return "p_premium";
			}
			return "p_a_class";
		},
		priceInc: function () {
			return this.prices[this.djPriceClass]?.p_totalAmount;
		},
		priceExc: function () {
			return this.prices[this.djPriceClass]?.p_exVatAmount;
		},
		price: function () {
			return !this.booking.Business_Booking__c ? this.priceInc : this.priceExc;
		},
		priceFrom: function () {
			return parseInt(this.price) + 150;
		},
		djClass: function () {
			return this.$route.params.djClass;
		},
		djName: function () {
			if (this.$route.params.djClass == "A") return "AClass";
			return this.$route.params.djClass;
		},
		disableCta: function () {
			return this.isPreQuote || this.isBlocked || this.isOrderInProgress;
		},
		isBlocked: function () {
			var dj = this.djClass == "A" ? "a_class" : this.djClass.toLowerCase();
			return this.prices["p_" + dj].p_errorCode ? this.prices["p_" + dj].p_errorCode != null : false;
		},
	},
	methods: {
		requestQuote() {
			this.$store.dispatch("requestQuote", {
				action: "requestQuote",
				data: {
					djClass: this.djClass,
					partyDate: this.booking.Party_Date__c,
					startTime: this.booking.begin_time__c,
					endTime: this.booking.end_time__c,
					numberOfGuests: this.booking.number_of_guests__c,
					usps: "", //this.usps[this.sf_name],
					price: this.price,
					priceFrom: this.priceFrom,
				},
				setBooking: true,
			});
		},
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
	mounted() {
		if (!this.$route.params.djClass) this.$router.push({name: "Prices"});
	},
};
</script>

<style></style>
