<template>
	<div>
		<PageTitle
			:title="$t('mol.contactUsPage.title.Contact us')"
			:subTitle="$t('mol.contactUsPage.title.Call or email us')"
			:breadcrumbs="[$t('mol.contactUsPage.title.Contact us')]"
		/>
		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
			<div v-for="(how, x) in howToContactUs" :key="'how' + x">
				<a :href="how.Url" target="_blank" class="flex items-center justify-start group">
					<div
						:class="[how.iconColor, how.iconHoverColor]"
						class="w-16 h-16 aspect-square rounded-full flex items-center justify-center mr-2"
					>
						<font-awesome-icon :icon="[how.iconStyle, how.icon]" class="text-white h-7 w-7 lg:h-10 lg:w-10" />
					</div>
					<div>
						{{ how.label }}<br />
						<div class="text-tdc-blue-500 group-hover:underline group-hover:text-sky-600">{{ how.subLabel }}</div>
					</div>
				</a>
			</div>
			<div class="col-span-full"></div>
			<div v-for="(who, i) in whoToContact" :key="'who' + i">
				<div class="p-3 sm:p-5 bg-white/70 dark:bg-slate-800/80 flex items-center justify-start group rounded">
					<div
						:style="{backgroundImage: 'url(' + require('@/assets/images/profile-' + who.name + '.jpg') + ')'}"
						class="w-16 h-16 rounded-full flex items-center justify-center mr-2 bg-cover"
					></div>
					<div>
						{{ capitalize(who.name) }}<br /><span class="text-sm text-tdc-blue-500">{{ who.role }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import {capitalize} from "@/utilities";

export default {
	name: "LocationContactUs",
	components: {
		PageTitle,
	},
	computed: {
		...mapGetters({
			location: "getLocationInformation",
			tdcPhoneNumber: "getTdcPhoneNumber",
		}),
		whoToContact() {
			var whoToContact = [
				{
					name: "koen",
					role: this.$t("mol.contactUsPage.label.Party planner"),
				},
				{
					name: "tim",
					role: this.$t("mol.contactUsPage.label.Party planner"),
				},
			];
			return whoToContact;
		},
		howToContactUs() {
			let howToContactUs = [
				{
					name: "Email",
					icon: "envelope",
					iconStyle: "fal",
					iconColor: "bg-red-600",
					iconHoverColor: "group-hover:bg-red-700",
					label: this.$t("mol.contactUsPage.label.Send us an email"),
					subLabel: this.$t("mol.general.contact.info mail address"),
					Url: "mailto:" + this.$t("mol.general.contact.info email address"),
				},
				{
					name: "Call",
					icon: "phone",
					iconStyle: "fal",
					iconColor: "bg-blue-500",
					iconHoverColor: "group-hover:bg-blue-600",
					label: this.$t("mol.contactUsPage.label.Call us"),
					subLabel: this.tdcPhoneNumber,
					Url: "tel:" + this.tdcPhoneNumber,
				},
				{
					name: "WhatsApp",
					icon: "whatsapp",
					iconStyle: "fab",
					iconColor: "bg-gradient-to-tr from-[#128c7e] to-[#25d366]",
					iconHoverColor: "group-hover:bg-green-700",
					label: this.$t("mol.contactUsPage.label.Send us a WhatsApp"),
					subLabel: this.$t("common.general.contact.whatsapp number shown"),
					Url: "https://wa.me/" + this.$t("mol.general.contact.whatsapp number"),
				},
				{
					name: "Calendly",
					icon: "calendar-day",
					iconStyle: "fal",
					iconColor: "bg-sky-500",
					iconHoverColor: "group-hover:bg-sky-600",
					label: this.$t("mol.contactUsPage.label.Schedule a callback"),
					subLabel: this.$t("mol.contactUsPage.label.Plan online"),
					Url: "https://calendly.com/thedjcompany/gesprek-met-the-dj-company",
				},
			];
			return howToContactUs;
		},
	},
	methods: {
		capitalize(string) {
			return capitalize(string);
		},
	},
};
</script>
