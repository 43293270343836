<template>
	<div>
		<div v-if="disableCta" class="px-5 btn-lg btn btn-success disabled mb-2">
			Boek direct <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
		</div>
		<div v-else class="px-5 btn-lg btn btn-success mb-2" @click="bookDJ()">
			Boek direct <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
		</div>
		<div>
			<small v-if="isPreQuote" class="position-relative">
				Dit is een vanaf prijs.
				<span v-if="!booking.Party_Date__c" class="ahref">Kies jouw feestdatum</span>
				<div class="date-selector">
					<Datepicker
						v-if="!booking.Party_Date__c"
						wrapper-class="w-100 h-100 position-absolute"
						calendar-class="forgotten-date-input"
						input-class="w-100 h-100 position-absolute date-selector-input"
						@selected="$root.$emit('setDate', $event.target.value)"
						:language="nl"
						:disabled-dates="disabledDates"
					/>
				</div>
			</small>
			<div v-else-if="!isOrderInProgress && !isBlocked" class="text-md-right">
				Optie plaatsen? <span class="ahref" @click="requestQuote()">Vraag een offerte aan</span>
			</div>
			<Alert alertType="warning" v-else-if="isOrderInProgress && !isPaid">
				Je kunt geen offerte aanvragen of DJ boeken. Ga naar jouw DJ boeking en
				<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
			</Alert>
			<Alert alertType="warning" v-else-if="isOrderInProgress && isPaid">
				Je kunt geen offerte aanvragen of DJ boeken i.v.m. jouw DJ boeking op {{ partyDate }} {{ geoText }}. Bekijk hier
				<router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>.</Alert
			>
			<Alert v-if="isBlocked && !isErrorCodeGlobal" alertType="warning">
				{{ $t("common.pricing.message." + prices.p_message, {phone: customPhoneNumber, whatsapp: whatsappnumber}) }}
			</Alert>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import Datepicker from "vuejs-datepicker";
import {nl} from "vuejs-datepicker/dist/locale";

export default {
	name: "Book-Button",
	components: {
		Alert,
		Datepicker,
	},
	props: {
		prices: {
			type: Object,
			default: null,
		},
		sf_name: String,
	},
	data() {
		return {
			nl: nl,
			disabledDates: {
				to: new Date(),
			},
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			preQuotePricing: "getPreQuotePricing",
			isPreQuote: "isPreQuote",
			isPaid: "getPaidStatus",
			isOrderInProgress: "isOrderInProgress",
			hasGeo: "hasGeo",
			isErrorCodeGlobal: "isErrorCodeGlobal",
		}),
		disableCta: function () {
			return this.isPreQuote || this.isBlocked || this.isOrderInProgress;
		},
		isBlocked: function () {
			return this.prices ? this.prices.p_errorCode != null : false;
		},
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		geoText: function () {
			return this.hasGeo ? "in " + this.geo : "";
		},
	},
	methods: {
		bookDJ() {
			this.$store.commit("SET_CURRENT_DJ_CLASS", this.sf_name == "AClass" ? "A" : this.sf_name);
			this.$store.dispatch("postBooking", {
				action: "setDjClass",
				data: {
					CurrentDJClass__c: this.sf_name == "AClass" ? "A" : this.sf_name,
				},
				setBooking: true,
			});
			this.$router.push({name: "DirectBookingCart"});
		},
		requestQuote() {
			this.$store.commit("SET_CURRENT_DJ_CLASS", this.sf_name == "AClass" ? "A" : this.sf_name);
			this.$router.push({name: "QuoteCart", params: {djClass: this.sf_name == "AClass" ? "A" : this.sf_name}});
		},
	},
};
</script>

<style lang="scss" scoped></style>
