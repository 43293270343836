<template>
	<div class="flex flex-col">
		<slot name="subNavEditing"></slot>
		<div>
			<div class="flex flex-nowrap flex-shrink-0 h-full overflow-x-clip px-1 overscroll-y-auto">
				<div
					id="subnav"
					:class="{'translate-x-[-120%]': !mobileSubNavOpen}"
					class="w-full lg:w-[300px] xl:w-[350px] block flex-shrink-0 lg:flex-none translate-x-0 transition duration-300 h-full lg:relative lg:h-auto block lg:bg-transparnt text-sm lg:text-base dark:text-white lg:translate-x-0 lg:!h-[calc(100%-116px)] lg:!fixed lg:overflow-y-auto lg:top-[116px] lg:z-0"
				>
					<div
						class="w-full text-center block bg-slate-100 dark:bg-slate-900 lg:bg-transparent lg:translate-x-0 lg:h-auto lg:text-left lg:h-auto lg:relative lg:pr-3"
					>
						<ul class="w-full divide-y divide-slate-200 dark:divide-slate-600 w-full block lg:pt-0 -mt-2 md:-mt-5">
							<li class="hidden lg:h-[50px] lg:block"></li>
							<li v-for="(item, index) in subNavItems" :key="index" class="w-full block group cursor-pointer">
								<div
									@click="goToHashedUrl(item.route.hash)"
									class="transition-colors hover:duration-300 duration-[1500ms] lg:duration-300 tcursor-pointer text-tdc-blue-500 dark:text-slate-400 py-5 px-2 lg:py-4 block link hover:bg-white hover:dark:bg-sky-900 hover:dark:text-white flex items-start justify-start"
									:class="{
										'bg-slate-200 !text-sky-800 dark:bg-sky-950 dark:[&_span]:text-white !no-underline':
											item.route.hash === molSubnavHash,
									}"
								>
									<font-awesome-icon
										v-if="item.icon"
										:icon="['far', item.icon]"
										class="!text-slate-400 dark:!text-slate-400 h-4 mr-2 mt-1"
										fixed-width
									/>
									<div class="flex justify-start flex-col text-left">
										<span v-html="item.label" />
										<span v-if="item.type == 'space'">
											<span v-if="item.mainSpace">({{ $t("mol.spaceForm.label.Main space") }})</span>
										</span>
										<div v-if="item.type == 'reviewAverage'" class="text-xs text-sky-700 group-hover:dark:text-white">
											<div>
												<span class="transition-all duration-300">
													<span class="font-bold tracking-tighter mr-1">
														<span v-text="item.score ? item.score : ' - '" />
													</span>
													<StarRating v-if="item.score" :rating="item.score" />
													<StarRating v-else class="text-slate-400" :rating="0" />
													<span class="ml-1">overal</span>
												</span>
											</div>
											<div>
												<span class="transition-all duration-300">
													<span class="font-bold tracking-tighter mr-1">
														<span v-text="item.count" />
													</span>
													reviews
												</span>
											</div>
											<!-- <div v-if="item.quote" class="my-1 flex">
												<font-awesome-icon :icon="['far', 'comment']" class="mt-[2px] mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300 italic">"{{ item.quote }}"</span>
											</div>
											<div v-if="item.date" class="my-1">
												<font-awesome-icon :icon="['far', 'party-horn']" class="mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300">{{ item.date }}</span>
											</div> -->
										</div>
										<div v-if="item.type == 'review'" class="text-xs text-sky-700 group-hover:dark:text-white">
											<div>
												<span class="transition-all duration-300">
													<span class="font-bold tracking-tighter mr-1">
														<span v-text="item.score ? item.score : ' - '" />
													</span>
													<StarRating v-if="item.score" :rating="item.score" />
													<StarRating v-else class="text-slate-400" :rating="0" />
												</span>
											</div>
											<div v-if="item.quote" class="my-1 flex">
												<font-awesome-icon :icon="['far', 'comment']" class="mt-[2px] mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300 italic">"{{ item.quote }}"</span>
											</div>
											<div v-if="item.date" class="my-1">
												<font-awesome-icon :icon="['far', 'party-horn']" class="mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300">{{ item.date }}</span>
											</div>
										</div>
										<span v-if="item.type == 'contact'">
											<span v-if="item.mainContact">({{ $t("mol.contactForm.label.Main contact") }})</span>
											<div class="text-xs text-sky-700 dark:group-hover:text-white">
												<div class="my-1">
													<font-awesome-icon :icon="['fas', 'phone']" class="mr-1 text-sky-700" fixed-width />
													<span class="transition-all duration-300" v-text="item.phone ? item.phone : ' - '" />
												</div>
												<div>
													<font-awesome-icon :icon="['far', 'envelope']" class="mr-1 text-sky-700" fixed-width />
													<span class="transition-all duration-300" v-text="item.email ? item.email : ' - '" />
												</div>
											</div>
										</span>
										<span v-if="item.type == 'invoice'">
											<div class="text-xs text-sky-700 dark:group-hover:text-white">
												<div class="my-1">
													<font-awesome-icon
														v-if="item.periodStatus == 'complete'"
														:icon="['far', 'circle-check']"
														class="mr-1 text-tdc-green-500"
														fixed-width
													/>
													<font-awesome-icon v-else :icon="['far', 'circle']" class="mr-1" fixed-width />
													<span
														v-if="item.periodStatus == 'complete'"
														class="text-tdc-green-500 transition-all duration-300"
														>{{ $t("mol.invoicingPage.label.Complete") }}</span
													>
													<span v-else class="transition-all duration-300">{{
														$t("mol.invoicingPage.label.Open")
													}}</span>
												</div>
												<div class="my-1">
													<font-awesome-icon :icon="['far', 'file-invoice']" class="mr-1 text-sky-700" fixed-width />
													<span
														class="transition-all duration-300"
														v-text="item.periodBookingCountLabel ? item.periodBookingCountLabel : ''"
													/>
												</div>
												<div>
													<font-awesome-icon :icon="['fas', 'euro']" class="mr-1 text-sky-700" fixed-width />
													<span
														class="transition-all duration-300"
														v-text="
															item.invoiceAmount ? item.invoiceAmount + ' ' + $t('mol.general.label.excl tax') : ' - '
														"
													/>
												</div>
											</div>
										</span>
										<div v-if="item.type == 'booking'" class="text-xs text-sky-700 group-hover:dark:text-white">
											<span class="text-tdc-blue-500 dark:text-slate-400 mb-2 block" v-html="item.subTitle" />
											<div
												class="my-1"
												:class="{
													'border-tdc-green-400 bg-tdc-green-100 rounded py-[1px] px-[7px] border inline-block':
														item.internal,
												}"
											>
												<div class="flex items-center">
													<!-- <font-awesome-icon
														v-if="item.internal"
														:icon="['fas', 'star']"
														class="mr-1 text-tdc-green-600 h-2 w-2"
														fixed-width
													/> -->
													<font-awesome-icon v-if="!item.internal" :icon="['far', 'user']" class="mr-1" fixed-width />
													<span :class="{'!text-tdc-green-600': item.internal}" class="transition-all duration-300">{{
														item.customer
													}}</span>
												</div>
											</div>
											<div v-if="item.date" class="my-1">
												<font-awesome-icon :icon="['far', 'calendar-day']" class="mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300">{{ item.date }}</span>
											</div>
											<div v-if="item.times">
												<font-awesome-icon :icon="['far', 'clock']" class="mr-1 text-sky-700" fixed-width />
												<span class="transition-all duration-300">{{ item.times }}</span>
											</div>
										</div>
									</div>
									<font-awesome-icon
										v-if="item.type == 'create'"
										:icon="['far', 'plus']"
										class="self-center text-slate-400 h-4 mr-1 ml-auto lg:hidden"
										fixed-width
									/>
									<font-awesome-icon
										v-else
										:icon="['far', 'arrow-right']"
										class="self-center text-slate-400 h-4 mr-1 ml-auto lg:hidden"
										fixed-width
									/>
								</div>
							</li>
							<li class="hidden lg:h-[70px] lg:block"></li>
						</ul>
					</div>
				</div>
				<div
					:class="{'translate-x-full ': mobileSubNavOpen}"
					class="w-full flex-shrink-0 transition duration-300 -translate-x-full lg:translate-x-[none] lg:relative lg:flex-none lg:ml-[320px] lg:w-[calc(100%-320px)] xl:ml-[400px] xl:w-[calc(100%-400px)]"
				>
					<div id="content">
						<div class="flex flex-row justify-between lg:justify-end">
							<div
								@click="goToHashedUrl('')"
								class="py-4 px-2 text-tdc-blue-500 hover:bg-white md:hover:underline lg:hover:bg-transparent hover:dark:bg-sky-950 hover:dark:text-white flex items-center justify-start text-sm cursor-pointer md:flex-none lg:hidden w-1/2"
							>
								<font-awesome-icon :icon="['far', 'arrow-left']" class="text-slate-400 h-4 mr-2 block" />
								{{ mobileBackLabel != "" ? mobileBackLabel : $t("mol.general.button.Back") }}
							</div>
						</div>
						<slot name="subNavTabContent"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import StarRating from "@/components/location/StarRating";

export default {
	name: "SubNav",
	components: {
		StarRating,
	},
	data() {
		return {
			mobileSubNavOpen: true,
			activeItem: null,
			navOpenScrolledAmount: 0,
			mdScreenWidth: 1024,
		};
	},
	props: {
		mobileBackLabel: {
			type: String,
			default: "",
		},
		subNavItems: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
		}),
	},
	methods: {
		goToHashedUrl(hash) {
			var fullUrl =
				window.location.origin + window.location.pathname + window.location.search + "#" + this.$route.path + hash;
			var contentElement = document.getElementById("content");
			var subNavElement = document.getElementById("subnav");
			var contentElementHeight = contentElement.offsetHeight;

			history.pushState({}, "", fullUrl);
			this.$store.commit("SET_MOL_SUBNAV_HASH", hash);
			if (hash) {
				this.navOpenScrolledAmount = window.scrollY;
				this.mobileSubNavOpen = false;
				this.scrollToTop();
				subNavElement.style.height = contentElementHeight + "px";
			} else {
				subNavElement.style.height = "auto";
				this.mobileSubNavOpen = true;
				this.scrollToNavPosition();
			}
		},
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		scrollToNavPosition() {
			if (window.innerWidth < this.mdScreenWidth) {
				window.scrollTo({top: this.navOpenScrolledAmount, left: 0, behavior: "smooth"});
			}
		},
	},
	mounted() {
		if (!this.$route.hash) this.$store.commit("SET_MOL_SUBNAV_HASH", "");
		if (this.$route.hash) this.mobileSubNavOpen = false;
		if (this.$route.hash) this.activeItem = this.$route.hash;
	},
	watch: {
		molSubnavHash() {
			if (!this.molSubnavHash) {
				this.goToHashedUrl("");
			}
		},
	},
};
</script>
