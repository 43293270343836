<template>
	<div>
		<ul class="steps">
			<BookingStep :title="partyDateStepTitle" :status="partyDateStepStatus" destination="Prices">
				<div v-if="hasPartyDate">
					<div v-b-toggle="'partydate-accordion'" class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="text-success mr-2" :icon="['far', 'calendar-day']" />
						<div>
							{{ formatPartyDate("dddd, D MMMM YYYY") }}

							<span class="d-inline-block ml-1 text-dark">
								<div class="point-up">
									<font-awesome-icon :icon="['far', 'chevron-up']" />
								</div>
								<div class="point-down">
									<font-awesome-icon :icon="['far', 'chevron-down']" />
								</div>
							</span>
						</div>
					</div>
					<b-collapse id="partydate-accordion" hide>
						<div class="d-flex justify-content-start align-items-center">
							<font-awesome-icon :icon="['far', 'info-circle']" class="mr-2 text-success" />
							<div>
								<span
									v-if="booking.number_of_guests__c != '301'"
									v-text="'tot ' + booking.number_of_guests__c + ' gasten'"
								/>
								<span v-if="booking.number_of_guests__c == '301'" v-text="'300+ gasten '" />
								<span v-text="' van ' + booking.begin_time__c + ' tot ' + booking.end_time__c" />
							</div>
						</div>
						<div class="d-flex justify-content-start align-items-center">
							<font-awesome-icon :icon="['far', 'map-marker-alt']" class="mr-2 text-success" />
							<div v-if="hasLocation">
								<span v-if="locationIsPrivate" v-text="'Privé feestlocatie'" />
								<span v-else v-text="location.Name" />
								<span v-if="hasGeo" v-text="' in ' + geo" />
							</div>
							<div v-else>Feestlocatie nog niet bij ons bekend</div>
						</div>
					</b-collapse>
				</div>
				<div v-else class="text-warning d-flex justify-content-start align-items-center">
					<font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']" /> Wanneer is het feest? Laat het ons
					weten.
				</div>
			</BookingStep>

			<BookingStep :title="pricesStepTitle" :status="pricesStepStatus" destination="Prices">
				<ul v-if="!isOrderInProgress && !hasQuotes" class="list-unstyled">
					<li class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'check']" /> Niet goed, geld terug
					</li>
					<li class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'check']" /> Nu met 150,- korting
					</li>
				</ul>
			</BookingStep>

			<BookingStep
				:title="quoteStepTitle"
				:status="quoteStepStatus"
				:destination="quoteStepDestination"
				:quoteId="singleQuoteId"
			>
				<ul v-if="!isOrderInProgress && hasQuotes" class="list-unstyled">
					<li>
						<div
							v-if="quoteCount > 1"
							v-b-toggle="'quote-accordion'"
							class="d-flex justify-content-start align-items-center text-warning"
						>
							<font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']" />
							<div>
								{{ quoteCountMessage }}

								<span class="d-inline-block ml-1 text-dark">
									<div class="point-up">
										<font-awesome-icon :icon="['far', 'chevron-up']" />
									</div>
									<div class="point-down">
										<font-awesome-icon :icon="['far', 'chevron-down']" />
									</div>
								</span>
							</div>
						</div>
						<b-collapse id="quote-accordion" :hide="quoteCount > 1" :visible="quoteCount == 1">
							<ul class="list-unstyled">
								<li
									v-for="(quote, index) in quotes.slice(0, 3)"
									:key="index"
									class="flex-column d-flex rounded w-100 justify-content-start my-2 position-relative"
								>
									<QuoteSummaryItem :quote="quote" styleVariant="overviewSteps" />
								</li>
								<li v-if="quoteCount > 3">
									<router-link :to="{name: 'Quote'}"
										>Bekijk alle {{ quoteCount }} offertes
										<font-awesome-icon :icon="['far', 'chevron-right']" class="ml-1" style="height: 12px"
									/></router-link>
								</li>
							</ul>
						</b-collapse>
					</li>
					<li class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'check']" /> Gratis annuleren tot 14 dagen voor
						het feest
					</li>
				</ul>
			</BookingStep>

			<BookingStep v-if="isOrderInProgress" :title="payStepTitle" :status="payStepStatus" destination="Booking">
				<div v-if="!isPaid">
					<div class="text-warning d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']" />
						<div><span v-if="isWedding">Jullie </span><span v-else>Jouw </span>DJ boeking is nog niet definitief</div>
					</div>
					<div v-if="isDutch" class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2" :icon="['fab', 'ideal']" style="color: #cc0066" />
						<div>Betaal veilig en online met iDeal</div>
					</div>
					<div v-if="isBelgian" class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'euro-sign']" />
						<div>Betaal veilig en online met Bancontact</div>
					</div>
					<div class="d-flex justify-content-start align-items-center">
						<font-awesome-icon class="mr-2 text-success" :icon="['fas', 'check']" /> Gratis annuleren tot 14 dagen voor
						het feest
					</div>
				</div>
			</BookingStep>

			<BookingStep :title="bookingStepTitle" :status="bookingStepStatus" destination="Booking"> </BookingStep>

			<BookingStep
				v-if="showLocationStep"
				:title="locationStepTitle"
				:status="locationStepStatus"
				destination="PartyLocation"
			>
				<div v-if="!hasLocation" class="text-warning d-flex justify-content-start align-items-center">
					<font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']" />
					De feestlocatie is nog niet bekend
				</div>
				<div v-if="hasLocation"></div>
			</BookingStep>

			<BookingStep :title="wishesStepTitle" :status="wishesStepStatus" destination="PartyWishes">
				<div v-if="wishesProgress < 100" class="d-flex justify-content-start align-items-center">
					<font-awesome-icon class="mr-2 text-primary" :icon="['fas', 'music']" />
					Muziekwensen, microfoon gebruik, etc.
				</div>
				<div v-if="wishesProgress < 100" class="d-flex justify-content-start align-items-center">
					<font-awesome-icon class="mr-2 text-primary" :icon="['fas', 'music']" />
					<div>
						{{ Math.round(wishesProgress) }}% van <span v-if="isWedding">jullie </span
						><span v-else>je </span>feestwensen zijn al ingevuld
					</div>
				</div>
			</BookingStep>

			<BookingStep :title="goalStepTitle" :status="goalStepStatus" destination="Booking"> </BookingStep>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import BookingStep from "@/components/customer/BookingStep";
import QuoteSummaryItem from "@/components/customer/QuoteSummaryItem";

export default {
	name: "BookingSteps",
	components: {FontAwesomeIcon, BookingStep, QuoteSummaryItem},
	computed: {
		...mapGetters({
			booking: "getBooking",
			geo: "getGeo",
			hasGeo: "hasGeo",
			hasLocation: "hasLocation",
			hasPartyDate: "hasPartyDate",
			isBelgian: "isBelgian",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
			isDutch: "isDutch",
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isWedding: "isWedding",
			location: "getLocation",
			partyType: "getPartyType",
			quotes: "getQuotes",
			reviewTotals: "getReviewTotals",
			wishesProgress: "getWishesProgress",
		}),
		hasQuotes() {
			return this.quoteCount > 0 ? true : false;
		},
		quoteCount() {
			return this.quotes.length;
		},
		singleQuoteId() {
			if (this.quoteCount == 1) return this.quotes[0].Id;
			return "";
		},
		expiredQuoteCount() {
			var expiredQuoteCount = 0;

			this.quotes.forEach((quote) => {
				if (Date.parse(quote.ExpirationDate__c) - Date.now() <= 0) {
					expiredQuoteCount++;
				}
			});
			return expiredQuoteCount;
		},
		quoteCountMessage() {
			var message = "";
			if (this.quoteCount == this.expiredQuoteCount) {
				message = "Alle offertes zijn helaas verlopen";
			} else {
				message = "Er zijn " + this.quoteCount + " offertes";
				if (this.expiredQuoteCount == 0) message = message + " die binnenkort verlopen";
				else if (this.expiredQuoteCount == 1) message = message + ", waarvan al eentje is verlopen";
				else if (this.expiredQuoteCount > 1)
					message = message + ", waarvan al " + this.expiredQuoteCount + " zijn verlopen";
			}

			return message;
		},
		partyDateStepStatus() {
			return this.hasPartyDate ? "completed" : "priority";
		},
		partyDateStepTitle() {
			return this.hasPartyDate ? "Feestdatum" : "Kies een feestdatum";
		},
		pricesStepStatus() {
			if (this.hasPartyDate) {
				if (this.isQuote) {
					if (this.hasQuotes) {
						return "completed";
					}
					return "priority";
				}
				if (this.isOrderInProgress) return "completed";
			}
			return "active";
		},
		pricesStepTitle() {
			if (this.isOrderInProgress || this.hasQuotes) {
				if (this.quoteCount == 1) {
					return "Prijs berekend";
				}
				return "Prijzen berekend";
			} else {
				if (this.isWedding) return "Bereken direct jullie prijzen";
				return "Bereken direct je prijzen";
			}
		},
		quoteStepStatus() {
			if (this.isQuote) {
				if (this.hasQuotes) {
					return "priority";
				}
				return "disabled";
			}
			if (this.isOrderInProgress) return "completed";
			return "disabled";
		},
		quoteStepTitle() {
			if (this.isOrderInProgress) {
				return "Offerte bevestigd";
			} else {
				if (this.quoteCount > 1) {
					return "Bevestig een offerte";
				} else {
					if (this.isWedding) return "Bevestig jullie offerte";
					return "Bevestig jouw offerte";
				}
			}
		},
		quoteStepDestination() {
			return this.quoteCount == 1 ? "Quote" : "Quotes";
		},
		payStepStatus() {
			return this.isPaid ? "completed" : "priority";
		},
		payStepTitle() {
			if (this.isOrderInProgress) {
				if (this.isPaid) {
					return "Waarborgsom betaald";
				} else {
					if (this.isWedding) return "Betaal jullie waarborgsom";
					return "Betaal de waarborgsom";
				}
			}
			if (this.isWedding) return "Betaal jullie waarborgsom";
			return "Betaal de waarborgsom";
		},
		bookingStepStatus() {
			return this.isPaid ? "completed" : "disabled";
		},
		bookingStepTitle() {
			if (this.isWedding) return "Jullie bruilofstfeest DJ is geboekt!";
			return "Jouw " + this.partyType + " DJ is geboekt!";
		},
		wishesStepStatus() {
			if (this.wishesProgress >= 100) {
				return "completed";
			} else {
				return this.isOrderInProgress && this.isPaid ? "priority" : "active";
			}
		},
		wishesStepTitle() {
			if (this.wishesProgress >= 100) return "Feestwensen doorgegeven";
			if (this.isOrderInProgress && this.isPaid) {
				if (this.isWedding) return "Geef jullie feestwensen door";
				return "Geef jouw feestwensen door";
			}
			if (this.isWedding) return "Geef alvast jullie feestwensen door";
			return "Geef alvast jouw feestwensen door";
		},
		showLocationStep() {
			return this.isOrderInProgress && this.isPaid;
		},
		locationStepTitle() {
			if (this.hasLocation) {
				if (this.isWedding) return "Jullie feestlocatie is bekend";
				return "Jouw feestlocatie is bekend";
			}
			if (this.isWedding) return "Geef jullie feestlocatie door";
			return "Geef de feestlocatie door";
		},
		locationStepStatus() {
			return this.hasLocation ? "completed" : "priority";
		},
		locationIsPrivate() {
			return this.location.Location_private__c;
		},
		locationHasImage() {
			return this.hasLocation && "location_profile_thumb_url__c" in this.location;
		},
		locationBackgroundImage() {
			if (this.hasLocation) {
				if (this.locationIsPrivate) return require("@/assets/images/dj-image.jpg");
				else if (this.locationHasImage) return this.location.location_profile_thumb_url__c;
				return require("@/assets/images/dj-image.jpg");
			}
			return require("@/assets/images/grey-gradient.jpg");
		},
		locationRating() {
			return "LocationReviewsForLocScore__c" in this.location
				? parseInt(this.location.LocationReviewsForLocScore__c.toFixed(1))
				: 9.2;
		},
		discountTitle() {
			return "Photobooth";
		},
		discountSlogan() {
			return "Huur een photobooth en ontvang gratis fotoprops t.w.v. 25,-";
		},
		discountBackgroundImage() {
			return require("@/assets/images/bg-a1H2p000009J3KHEA0.jpg");
		},
		discountIcon() {
			return "camera-retro";
		},
		goalStepStatus() {
			if (this.hasPartyDatePassed) return "completed";
			if (this.isPaid) {
				return this.wishesProgress >= 100 ? "priority" : "active";
			}
			return "disabled";
		},
		goalStepTitle() {
			var partOfLifeDate = "";
			if (this.hasPartyDate) {
				partOfLifeDate = " op " + this.formatPartyDate("D MMMM, YYYY");
			}
			if (this.isWedding) return "Het bruilofstfeest van jullie leven" + partOfLifeDate;
			return "Het " + this.partyType + " van je leven" + partOfLifeDate;
		},
		contactDetailsIcon() {
			return this.isWedding ? "user-group" : "user";
		},
	},
	data() {
		return {
			overviewTabViewCount: 0,
		};
	},
	methods: {
		formatPartyDate(format) {
			return this.$date(this.booking.Party_Date__c).locale("nl").format(format);
		},
	},
	mounted() {
		if (window.sessionStorage) {
			sessionStorage.setItem("overviewTabViewCount", parseInt(sessionStorage.getItem("overviewTabViewCount")) + 1);
			this.overviewTabViewCount = parseInt(sessionStorage.getItem("overviewTabViewCount"));
		}
	},
	filters: {
		capitalize: function (value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
	},
};
</script>

<style lang="scss" scoped>
.steps {
	@extend .list-unstyled;
	@extend .mb-0;
}

.collapsed .point-up,
.not-collapsed .point-down {
	display: none;
}
</style>
