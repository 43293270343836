<template>
	<div>
		<PageTitle
			:title="$t('mol.bookingsPage.title.DJ bookings')"
			:subTitle="subTitle"
			:breadcrumbs="breadcrumbs"
			:adaptSubnav="true"
		/>
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavEditing">
				<SlideOutPanel v-if="bookingOnDisplay" :panelOpen="molEditingState">
					<template slot="outerContent">
						<EditButton v-if="bookingOnDisplayConfirmed" disabled>
							{{ $t("mol.general.button.Edit") }}
						</EditButton>
						<EditButton v-else @onClick="openEditingPanel()">
							{{ $t("mol.general.button.Edit") }}
						</EditButton>
					</template>
					<template slot="innerContent">
						<div class="h-full flex-col flex align-middle">
							<div class="absolute right-0">
								<button
									@click="closeEditingPanel()"
									class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
								>
									<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
								</button>
							</div>
							<PageTitle
								:title="$t('mol.bookingsPage.title.DJ bookings')"
								:subTitle="subTitle"
								:breadcrumbs="breadcrumbsEdit"
							/>
							<InputItem
								v-model="editBooking.Location_Contact__c"
								inputName="Location_Contact__c"
								inputType="select"
								:inputLabel="$t('mol.bookingForm.label.Venue contact')"
								:choices="contactChoices"
							/>
							<InputItem
								v-model="editBooking.Location_Space__c"
								inputName="Location_Space__c"
								inputType="select"
								:inputLabel="$t('mol.bookingForm.label.Venue space')"
								:choices="spaceChoices"
							/>
							<InputItem
								v-model="editBooking.location_details__c"
								inputName="location_details__c"
								inputType="textarea"
								:inputLabel="$t('mol.bookingForm.label.Event notes')"
							/>
							<FormGridWrapper>
								<template slot="right">
									<Alert>
										{{ $t("mol.bookingForm.string.Only about the event") }}
									</Alert>
								</template>
							</FormGridWrapper>
							<FormGridWrapper>
								<template slot="right">
									<div class="flex flex-row-reverse w-full">
										<Button
											@click.native="saveBooking(editBooking)"
											class="ml-2 flex-grow"
											type="green"
											icon="check"
											:label="$t('mol.general.button.Save')"
										/>
										<Button @click.native="closeEditingPanel()" type="cancel" />
									</div>
								</template>
							</FormGridWrapper>
						</div>
					</template>
				</SlideOutPanel>
			</template>

			<template slot="subNavTabContent">
				<div v-if="viewingCreateBookings">
					<BookADJ />
				</div>
				<div v-else-if="viewingPastBookings">
					<div id="pastBookings">
						<p v-if="countPastBookings == 0">
							{{ $t("mol.bookingsPage.string.No past DJ bookings") }}
						</p>
						<div v-else>
							<div v-for="(pastBooking, index) in pastBookings" :key="index">
								<h2 class="mb-0" :id="'heading' + pastBooking.Id">
									<button
										@click="toggleAccordion(pastBooking.Id)"
										class="group relative flex w-full items-center border-0 py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-accordion-collapsed])]:text-slate-700 [&:not([data-accordion-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-accordion-collapsed])]:text-slate-300 dark:[&:not([data-accordion-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
										type="button"
										:data-accordion-target="'#collapse' + pastBooking.Id"
										:data-accordion-collapsed="isOpen == pastBooking.Id"
										:aria-expanded="isOpen"
										:aria-controls="'collapse' + pastBooking.Id"
									>
										<div>
											{{ partyDate(pastBooking.Party_Date__c) }}&nbsp;<b
												>{{ capitalize(pastBooking.booking_type__c) }}:&nbsp;
											</b>
											<span v-if="bookingByVenue(pastBooking)">
												<span
													class="mx-1 border-tdc-green-400 bg-tdc-green-100 rounded py-[1px] px-[7px] border inline-block text-xs text-tdc-green-600"
												>
													{{ $t("mol.bookingsPage.label.Booked by venue") }}
												</span>

												<span
													v-if="getInvoice(pastBooking) && getInvoice(pastBooking).Payment_Status__c !== 'Paid'"
													class="mx-1 text-xs border-amber-400 bg-amber-100 rounded py-[1px] px-[7px] border inline-block text-amber-600"
												>
													{{ $t("mol.bookingsPage.label.Invoice due") }}
												</span>
											</span>
											<span v-else>{{ pastBooking.website_customer_name__c }}</span>
										</div>
										<span
											class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-accordion-collapsed]]:rotate-0 motion-reduce:transition-none dark:group-[[data-accordion-collapsed]]:text-white"
										>
											<font-awesome-icon class="h-5 w-5" :icon="['far', 'chevron-up']" fa-fw />
										</span>
									</button>
								</h2>
								<div
									:class="{'max-h-[1000px]': isOpen === pastBooking.Id}"
									:id="'collapse' + pastBooking.Id"
									class="max-h-0 overflow-hidden transition-all duration-500 ease"
									:aria-labelledby="'heading' + pastBooking.Id"
									data-accordion-parent="#pastBookings"
								>
									<div class="p-3 sm:p-5 bg-white/70 dark:bg-slate-800/80">
										<DataItem :dataLabel="$t('mol.bookingForm.label.Booking')" :dataValue="pastBooking.Name" />
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.Client')"
											:dataValue="pastBooking.website_customer_name__c"
										/>
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.Event type')"
											:dataValue="capitalize(pastBooking.booking_type__c)"
										/>
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.Event date')"
											:dataValue="partyDate(pastBooking.Party_Date__c)"
										/>
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.Event times')"
											:dataValue="pastBooking.begin_time__c + '-' + pastBooking.end_time__c"
										/>
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.Guest count')"
											:dataValue="pastBooking.number_of_guests__c"
										/>
										<DataItem
											:dataLabel="$t('mol.bookingForm.label.DJ')"
											:dataValue="djName(pastBooking.dj_first_name__c)"
										/>
										<div v-if="getInvoice(pastBooking)">
											<DataItem
												v-if="totalInvoiceAmountExclTax(getInvoice(pastBooking))"
												:dataLabel="$t('mol.bookingForm.label.Total amount')"
												:dataSubLabel="$t('mol.general.label.excl tax')"
												:dataValue="'€ ' + totalInvoiceAmountExclTax(getInvoice(pastBooking))"
												class="mb-3"
											/>
											<DataItem :dataLabel="$t('mol.bookingForm.label.Invoice')">
												<a
													v-if="getInvoice(pastBooking).InvoicePdfUrl__c"
													class="text-sky-500 hover:text-sky-600 hover:underline dark:text-sky-400 cursor-pointer"
													:href="getInvoice(pastBooking).InvoicePdfUrl__c"
													target="_blank"
													>{{ $t("mol.bookingForm.string.Download the invoice") }}
													<font-awesome-icon :icon="['far', 'file-pdf']" class="text-tdc-blue-500 h-6 w-6" />
												</a>
											</DataItem>
											<DataItem
												v-if="getPayment(getInvoice(pastBooking))"
												:dataLabel="$t('mol.bookingForm.label.Payment')"
											>
												<a
													v-if="!invoicePaid(getInvoice(pastBooking))"
													:href="getPayment(getInvoice(pastBooking)).PaymentUrl__c"
													target="_blank"
													><Button type="green" :label="$t('mol.bookingForm.button.Settle invoice')"
												/></a>

												<div v-if="!invoicePaid(getInvoice(pastBooking))" class="text-xs text-slate-500">
													{{ $t("mol.bookingForm.label.Secure connection") }}
													<font-awesome-icon :icon="['fas', 'lock-alt']" class="text-tdc-green-500 w-3 h-3" />
												</div>
												<div v-else class="text-tdc-green-500">
													{{
														$t("mol.bookingForm.label.Payment received on", {
															date: paidDate(getInvoice(pastBooking)),
														})
													}}
												</div>
											</DataItem>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="!bookingOnDisplay">
					<p v-if="countFutureBookings == 0">
						{{ $t("mol.bookingsPage.string.No DJ bookings currently") }}
					</p>
					<p v-else-if="countFutureBookings == 1">
						{{ $t("mol.bookingsPage.string.You have 1 DJ booking") }}
					</p>
					<p v-else>
						{{ $t("mol.bookingsPage.string.You have num DJ bookings", {count: countFutureBookings}) }}
						{{ $t("mol.bookingsPage.string.Choose a DJ booking to view more details") }}
					</p>
				</div>
				<div v-else-if="bookingOnDisplay">
					<Alert v-if="bookingOnDisplayConfirmed" type="warning">
						<p>
							<span class="font-bold">{{ $t("mol.bookingsPage.message.Event can no longer be edited") }}</span
							><br />{{ $t("mol.bookingsPage.string.Event is within 2 weeks") }}
						</p>
					</Alert>
					<DataItem :dataLabel="$t('mol.bookingForm.label.Booking')" :dataValue="bookingOnDisplay.Name" />
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Client')"
						:dataValue="bookingOnDisplay.website_customer_name__c"
					/>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Event type')"
						:dataValue="capitalize(bookingOnDisplay.booking_type__c)"
					/>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Event date')"
						:dataValue="partyDate(bookingOnDisplay.Party_Date__c)"
					/>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Event times')"
						:dataValue="bookingOnDisplay.begin_time__c + '-' + bookingOnDisplay.end_time__c"
					/>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Guest count')"
						:dataValue="bookingOnDisplay.number_of_guests__c"
					/>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.DJ')"
						:dataValue="djName(bookingOnDisplay.dj_first_name__c)"
						class="mb-3"
					/>
					<div v-if="bookingByVenue(bookingOnDisplay)">
						<ContentTitle>
							{{ $t("mol.bookingForm.label.Musical requirements") }}
						</ContentTitle>
						<DataItem
							musicChoices
							:top="3"
							:dataLabel="$t('mol.bookingForm.label.Top 3')"
							:dataValue="bookingOnDisplay.Top_10_songs__c"
							class="mb-3"
						/>
						<DataItem
							:dataLabel="$t('mol.bookingForm.label.Music request comments')"
							:dataValue="bookingOnDisplay.music_request_comments__c"
							class="mb-3"
						/>
						<DataItem :dataLabel="$t('mol.bookingForm.label.Online requirements form')" class="mb-3">
							<div
								ref="portalUrl"
								:class="{'!ring-tdc-green-500': urlCopied}"
								class="transition-all duration-300 flex items-center justify-between !w-full mb-2 text-sm md:text-base p-2 w-full ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 bg-white dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600"
							>
								<Ahref
									><span :class="{'bg-tdc-blue-500/20': urlCopied}"
										><a :href="bookingOnDisplay.PartyWishesShortLink__c" target="_blank">{{
											bookingOnDisplay.PartyWishesShortLink__c
										}}</a></span
									></Ahref
								>
								<font-awesome-icon
									:icon="['far', 'check-circle']"
									:class="{'!opacity-100': urlCopied}"
									class="text-tdc-green-500 h-5 w-5 opacity-0 transition-opacity duration-300"
								/>
							</div>
							<Button @click.native="copyUrl(bookingOnDisplay.PartyWishesShortLink__c)" icon="copy">{{
								$t("mol.general.button.Copy to clipboard")
							}}</Button>
							<span
								:class="{'!opacity-100': urlCopied}"
								class="opacity-0 transition-opacity duration-300 inline-block ml-2 text-xs text-tdc-green-500"
							>
								{{ $t("mol.general.label.Copied") }}
							</span>
						</DataItem>
						<Alert>
							{{ $t("mol.bookingForm.string.Send the form url to your customer") }}
						</Alert>
						<div v-if="bookingByVenue(bookingOnDisplay)">
							<ContentTitle>
								{{ $t("mol.bookingForm.label.Invoice") }}
							</ContentTitle>
							<div v-if="invoiceOnDisplay">
								<div class="mb-5">
									<DataItem
										v-if="totalInvoiceAmountExclTax(invoiceOnDisplay)"
										:dataLabel="$t('mol.bookingForm.label.Total amount')"
										:dataSubLabel="$t('mol.general.label.excl tax')"
										:dataValue="'€ ' + totalInvoiceAmountExclTax(invoiceOnDisplay)"
										class="mb-3"
									/>
									<a
										v-if="invoiceOnDisplay.InvoicePdfUrl__c"
										class="text-sky-500 hover:text-sky-600 hover:underline dark:text-sky-400 cursor-pointer"
										:href="invoiceOnDisplay.InvoicePdfUrl__c"
										target="_blank"
										>{{ $t("mol.bookingForm.string.Download the invoice") }}
										<font-awesome-icon :icon="['far', 'file-pdf']" class="text-tdc-blue-500 h-6 w-6" />
									</a>
								</div>
								<ContentTitle>
									{{ $t("mol.bookingForm.label.Payment") }}
								</ContentTitle>
								<div v-if="payment" class="mb-5">
									<a v-if="!invoicePaid(invoiceOnDisplay)" :href="payment.PaymentUrl__c" target="_blank"
										><Button type="green" :label="$t('mol.bookingForm.button.Settle invoice')"
									/></a>
									<Button
										v-else
										type="green"
										:label="$t('mol.bookingForm.button.Invoice settled')"
										disabled
										icon="check"
									/>
									<div v-if="!invoicePaid(invoiceOnDisplay)" class="text-xs text-slate-500">
										{{ $t("mol.bookingForm.label.Secure connection") }}
										<font-awesome-icon :icon="['fas', 'lock-alt']" class="text-tdc-green-500 w-3 h-3" />
									</div>
									<div v-else class="text-xs text-tdc-green-500">
										{{ $t("mol.bookingForm.label.Payment received on", {date: paidDate(invoiceOnDisplay)}) }}
										<font-awesome-icon :icon="['far', 'check']" class="text-tdc-green-500 w-3 h-3" />
									</div>
								</div>
							</div>
							<div v-else class="mb-5">
								{{ $t("mol.bookingForm.string.Invoice available after event") }}
								<font-awesome-icon :icon="['far', 'file-pdf']" class="text-slate-400 h-6 w-6" />
							</div>
						</div>
					</div>
					<ContentTitle>
						{{ $t("mol.bookingForm.label.Venue contact details") }}
					</ContentTitle>
					<DataItem
						v-if="bookingOnDisplayContact"
						:dataLabel="$t('mol.bookingForm.label.Venue contact')"
						:dataValue="bookingOnDisplayContact.FirstName + ' ' + bookingOnDisplayContact.LastName"
						class="mb-3"
					/>
					<DataItem
						v-else
						:dataLabel="$t('mol.bookingForm.label.Venue contact')"
						:dataValue="$t('mol.bookingForm.label.No venue contact found or chosen')"
						class="mb-3"
					/>
					<ContentTitle>
						{{ $t("mol.bookingForm.label.Venue space details") }}
					</ContentTitle>
					<SpaceData v-if="bookingOnDisplaySpace" :space="bookingOnDisplaySpace" class="mb-3 block" />
					<DataItem
						v-else
						:dataLabel="$t('mol.bookingForm.label.Venue space')"
						:dataValue="$t('mol.bookingForm.label.No venue space found or chosen')"
						class="mb-3"
					/>
					<ContentTitle>
						{{ $t("mol.bookingForm.label.Event notes") }}
					</ContentTitle>
					<DataItem
						:dataLabel="$t('mol.bookingForm.label.Event notes')"
						:dataValue="
							bookingOnDisplay.location_details__c
								? bookingOnDisplay.location_details__c
								: $t('mol.bookingForm.label.No event notes')
						"
						extraSpaceBottom
					/>
					<Alert>
						{{ $t("mol.bookingForm.string.Only about the event") }}
					</Alert>
				</div>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import BookADJ from "@/components/location/BookADJ";
import PageTitle from "@/components/location/PageTitle";
import SubNav from "@/components/location/SubNav";
import DataItem from "@/components/location/DataItem";
import InputItem from "@/components/location/InputItem";
import SpaceData from "@/components/location/SpaceData";
import ContentTitle from "@/components/location/ContentTitle";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import EditButton from "@/components/location/EditButton";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Button from "@/components/location/Button";
import Alert from "@/components/location/Alert";
import Ahref from "@/components/location/Ahref";
import {capitalize, formatDate, formatDateTime, parseIsoDatetime} from "@/utilities";

export default {
	name: "LocationDjBookings",
	components: {
		BookADJ,
		PageTitle,
		SubNav,
		DataItem,
		InputItem,
		ContentTitle,
		EditButton,
		SpaceData,
		SlideOutPanel,
		Button,
		Alert,
		FormGridWrapper,
		Ahref,
	},
	data() {
		return {
			isOpen: false,
			locationFutureBookings: [],
			createBookingHash: "book",
			pastBookingsHash: "past",
			urlCopied: false,
			bookingOnDisplay: null,
			bookingOnDisplaySpace: null,
			invoiceOnDisplay: null,
			invoiceFromBookingId: new Map(),
		};
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
			molEditingState: "getMolEditingState",
			futureBookings: "getFutureBookings",
			pastBookings: "getPastBookings",
			spaces: "getLocationSpaces",
			contacts: "getLocationContacts",
			invoices: "getInvoices",
		}),
		subNavItems() {
			let navItems = [];
			// let createBookingNavItem = {};

			// createBookingNavItem["type"] = "createBooking";
			// createBookingNavItem["label"] = this.$t("mol.bookingsPage.subNav.Book a DJ");
			// createBookingNavItem["route"] = {name: "Bookings", hash: "#" + this.createBookingHash};
			// createBookingNavItem["title"] = "";
			// createBookingNavItem["subTitle"] = this.$t("mol.bookingsPage.subNav.Book a DJ");
			// createBookingNavItem["breadcrumbLabel"] = this.$t("mol.bookingsPage.subNav.Book a DJ");
			// createBookingNavItem["icon"] = "calendar-plus";

			// navItems.push(createBookingNavItem);
			navItems.push({
				type: "pastBookings",
				label: this.$t("mol.bookingsPage.subNav.Past bookings"),
				route: {name: "Bookings", hash: "#" + this.pastBookingsHash},
				title: "",
				subTitle: "",
				breadcrumbLabel: this.$t("mol.bookingsPage.subNav.Past bookings"),
				icon: "clock-rotate-left",
			});
			if (this.futureBookings.length > 0) {
				this.futureBookings.forEach((booking) => {
					let itemBookedBy = "";
					let itemBookingInternal = false;
					if (this.bookingByVenue(booking)) {
						itemBookedBy = this.$t("mol.bookingsPage.subNav.Booked by venue");
						itemBookingInternal = true;
					} else {
						itemBookedBy = booking.website_customer_name__c;
					}
					navItems.push({
						type: "booking",
						label: capitalize(booking.Name),
						route: {name: "DJBookings", hash: "#" + booking.Id},
						title: booking.Id,
						breadcrumbLabel: booking.Name,
						subTitle: "<b>" + capitalize(booking.booking_type__c) + ":</b> " + booking.website_customer_name__c,
						date: this.partyDate(booking.Party_Date__c),
						times: booking.begin_time__c + " - " + booking.end_time__c,
						name: booking.Name,
						customer: itemBookedBy,
						internal: itemBookingInternal,
						icon: booking.Account__c !== booking.Location_lookup__c ? "calendar" : "calendar-star",
					});
				});
			} else {
				navItems.push({
					type: "booking",
					label: this.$t("mol.bookingsPage.string.No DJ bookings currently"),
					route: {name: "DJBookings"},
					title: this.$t("mol.bookingsPage.string.No DJ bookings currently"),
					breadcrumbLabel: "",
					subTitle: this.$t("mol.bookingsPage.string.No DJ bookings currently"),
					icon: "calendar-xmark",
				});
			}
			return navItems;
		},
		countFutureBookings() {
			return this.futureBookings.length;
		},
		countPastBookings() {
			return this.pastBookings.length;
		},
		bookingOnDisplayConfirmed() {
			return this.bookingOnDisplay.dj_confirmation_printed__c;
		},
		editBooking() {
			const bkOnDisplay = this.bookingOnDisplay;
			return {
				Id: bkOnDisplay.Id,
				Location_Space__c: bkOnDisplay.Location_Space__c,
				Location_Contact__c: bkOnDisplay.Location_Contact__c,
				location_details__c: bkOnDisplay.location_details__c,
			};
		},
		contactChoices() {
			return this.contacts.map((contact) => ({value: contact.Id, label: contact.FirstName + " " + contact.LastName}));
		},
		bookingOnDisplayContact() {
			let contactId = this.bookingOnDisplay.Location_Contact__c;
			let contactToDisplay = null;
			if (this.contacts.length > 0) {
				contactToDisplay = this.contacts.find((contact) => {
					return contact.Id === contactId;
				});
			}
			return contactToDisplay;
		},
		spaceChoices() {
			return this.spaces.map((space) => ({value: space.Id, label: space.Space_Name__c}));
		},
		viewingPastBookings() {
			return this.molSubnavHash.substring(1) == this.pastBookingsHash;
		},
		viewingCreateBookings() {
			return this.molSubnavHash.substring(1) == this.createBookingHash;
		},
		title() {
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).title;
			}
			return "";
		},
		subTitle() {
			if (this.viewingPastBookings) return this.$t("mol.bookingsPage.subNav.Past bookings");
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).subTitle;
			}
			return this.$t("mol.bookingsPage.title.Upcoming bookings");
		},
		breadcrumbs() {
			let items = [this.$t("mol.bookingsPage.title.DJ bookings")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(
					this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).breadcrumbLabel
				);
			}
			return items;
		},
		breadcrumbsEdit() {
			let editItem = this.$t("mol.general.label.Edit");
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			breadcrumbs.push(editItem);
			return breadcrumbs;
		},
		payment() {
			if (
				this.invoiceOnDisplay &&
				this.invoiceOnDisplay.MolliePayments__r &&
				this.invoiceOnDisplay.MolliePayments__r.records &&
				this.invoiceOnDisplay.MolliePayments__r.records.length > 0
			) {
				return this.invoiceOnDisplay.MolliePayments__r.records[0];
			}
			return null;
		},
	},
	methods: {
		getInvoice(booking) {
			return this.invoiceFromBookingId.get(booking.Id);
		},
		getPayment(invoice) {
			if (invoice?.MolliePayments__r?.records?.length > 0) {
				return invoice.MolliePayments__r.records[0];
			}
			return null;
		},
		formatPrice(price) {
			return this.$currency(price, 2).format().replace(",00", ",-");
		},
		copyUrl(url) {
			navigator.clipboard
				.writeText(url)
				.then(() => {
					this.urlCopied = true;
					return new Promise((resolve) => {
						setTimeout(resolve, 4000);
					});
				})
				.then(() => {
					this.urlCopied = false;
				})
				.catch((err) => {
					console.error("Could not copy text: ", err);
				});
		},
		bookingByVenue(booking) {
			return booking.Account__c === booking.Location_lookup__c;
		},
		capitalize(string) {
			return capitalize(string);
		},
		djName(name) {
			return name ? "DJ " + capitalize(name) : "-";
		},
		paidDate(invoice) {
			if (!invoice || !invoice.PaidDate__c) {
				return "-";
			}
			if (invoice?.attributes?.type === "Invoice2__c") {
				return formatDateTime(parseIsoDatetime(invoice.PaidDate__c));
			} else {
				return formatDate(invoice.PaidDate__c);
			}
		},
		totalInvoiceAmount(invoice) {
			if (invoice) {
				if (invoice?.attributes?.type === "Invoice2__c") {
					return parseFloat(invoice.TotalPriceIncVat__c).toFixed(2);
				}
				return parseFloat(invoice.Total_Invoice_Incl_VAT__c).toFixed(2);
			}
			return "";
		},
		totalInvoiceAmountExclTax(invoice) {
			if (invoice) {
				if (invoice?.attributes?.type === "Invoice2__c") {
					return parseFloat(invoice.TotalPriceExcVat__c).toFixed(2);
				}
				return parseFloat(invoice.Total_Invoice_Excl_VAT__c).toFixed(2);
			}
			return "";
		},
		partyDate(date) {
			return date ? formatDate(date) : "-";
		},
		toggleAccordion(id) {
			this.isOpen == id ? (this.isOpen = false) : (this.isOpen = id);
		},
		saveBooking(booking) {
			this.$store
				.dispatch("updateBooking", {
					action: "updateBooking",
					data: booking,
					successMessage: this.$t("mol.bookingForm.message.Booking saved"),
					errorMessage: this.$t("mol.bookingForm.message.Booking not saved"),
				})
				.catch((err) => {
					console.error(err);
				});
		},
		closeEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
		},
		openEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", true);
		},
		resetDisplay() {
			this.bookingOnDisplay = null;
			this.invoiceOnDisplay = null;
			this.bookingOnDisplaySpace = null;
			let spaceId = null;
			if (this.molSubnavHash == "#past") {
				if (this.pastBookings.length === 0) {
					this.$store.dispatch("getPastBookings");
				}
			} else {
				const bookingToDisplayID = this.molSubnavHash.substring(1);
				if (this.futureBookings.length > 0) {
					this.bookingOnDisplay = this.futureBookings.find((book) => book.Id === bookingToDisplayID);
				}
				if (this.bookingOnDisplay) {
					spaceId = this.bookingOnDisplay.Location_Space__c;
				}
				if (this.spaces.length > 0) {
					this.bookingOnDisplaySpace = this.spaces.find((space) => {
						return space.Id === spaceId;
					});
				}
				if (this.invoices.length > 0) {
					this.invoiceOnDisplay = this.invoices.find((inv) => inv.Booking__c === bookingToDisplayID);
					//console.log("invoiceOnDisplay", JSON.stringify(this.invoiceOnDisplay, null, "\t"));
				}
			}
		},
		invoicePaid(invoice) {
			if (invoice) {
				if (invoice?.attributes?.type === "Invoice2__c") {
					return invoice.PaymentStatus__c === "Paid";
				}
				return invoice.Payment_Status__c === "Paid";
			}
			return false;
		},
	},
	mounted() {
		Promise.all([
			this.$store.dispatch("getFutureBookings"),
			this.$store.dispatch("getInvoices"),
			this.$store.dispatch("getPastBookings"),
		]).then(() => {
			this.resetDisplay();
			if (this.invoices.length > 0) {
				this.invoices.forEach((inv) => {
					if (!this.invoiceFromBookingId.has(inv.Booking__c)) {
						this.invoiceFromBookingId.set(inv.Booking__c, inv);
					}
				});
			}
		});
	},
	watch: {
		molSubnavHash() {
			this.resetDisplay();
		},
	},
};
</script>
