<template>
	<div class="relative right-0">
		<slot name="outerContent"></slot>
		<div class="relative z-50 right-0 lg:opacity-100">
			<div
				:class="[
					{'!mr-0': openPanel},
					{'w-[95%] sm:w-[calc(100%-100px)] lg:w-[calc(100%-220px)] xl:w-[calc(100%-350px)] ': level == 1},
					{'w-[90%] sm:w-[calc(100%-200px)] lg:w-[calc(100%-320px)] xl:w-[calc(100%-450px)] ': level >= 2},
				]"
				class="z-50 transition-all duration-300 mr-[-1300px] xl:mr-[-2300px] shadow-2xl rounded-l-xl p-4 md:p-10 lg:p-16 pb-28 md:pb-28 lg:pb-28 fixed top-[10px] right-0 h-[calc(100%-20px)] z-[100] bg-slate-100 dark:bg-slate-800 overflow-y-scroll overflow-x-hidden"
			>
				<div class="max-w-[900px] relative">
					<slot name="innerContent"> </slot>
				</div>
			</div>
			<div
				:class="{hidden: !openPanel}"
				@click="hazeClick"
				class="transition-all duration-300 fixed w-screen h-screen bg-slate-100/70 dark:bg-slate-900/50 z-40 top-0 left-0 backdrop-blur-sm"
			></div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "SlideOutPanel",
	props: {
		panelOpen: {
			type: Boolean,
			default: false,
		},
		showCloseX: {
			type: Boolean,
			default: false,
		},
		closeViaHaze: {
			type: Boolean,
			default: false,
		},
		zIndexClass: {
			type: String,
			default: "z-50",
		},
	},
	data() {
		return {
			level: 0,
			openPanel: this.panelOpen,
		};
	},
	computed: {
		...mapGetters({
			currentLevel: "getMolSidePanelLevel",
			molEditingState: "getMolEditingState",
		}),
	},
	methods: {
		increaseLevel() {
			var currentLevel = JSON.parse(JSON.stringify(this.currentLevel));
			this.level = currentLevel + 1;
			this.$store.commit("SET_MOL_SIDE_PANEL_LEVEL", this.level);
		},
		decreaseLevel() {
			this.$store.commit("SET_MOL_SIDE_PANEL_LEVEL", this.level - 1);
		},
		hazeClick() {
			if (this.closeViaHaze) {
				this.openPanel = false;
			}
		},
	},
	watch: {
		panelOpen: function () {
			this.openPanel = this.panelOpen;
		},
		openPanel: function () {
			if (this.openPanel) {
				this.increaseLevel();
				this.$emit("panelOpening");
			}
			if (!this.openPanel) {
				this.decreaseLevel();
				this.$emit("panelClosing");
			}
		},
		molEditingState: function () {
			if (this.molEditingState) {
				document.querySelector("body").classList.add("overflow-y-hidden");
			} else {
				document.querySelector("body").classList.remove("overflow-y-hidden");
			}
		},
	},
};
</script>
