<template>
	<div class="desktop-content-left-right-wrapper">
		<div class="d-flex flex-row justify-content-between">
			<div>
				<span class="stars d-block display-5">
					<font-awesome-icon v-for="star in $parent.djClassStars" :key="star" :icon="['fas', 'star']" />
				</span>
				<strong class="display-5" v-text="$parent.djClassName"></strong>
				<div
					:class="[$parent.djBestChoice ? 'text-success' : 'text-primary']"
					class="d-flex justify-content-start font-weight-bold"
				>
					<small
						><font-awesome-icon class="mr-1" :icon="['fas', $parent.djClassIcon]" /><span v-text="$parent.djClassType"
					/></small>
				</div>
			</div>
			<div>
				<div class="text-right reduce-line-height">
					<span class="d-flex flex-nowrap align-items-center justify-content-end">
						<small v-if="$parent.isPreQuote" v-text="'vanaf'" />
						<strong>
							<span
								v-if="$parent.booking.Business_Booking__c"
								class="mb-0 display-4 yellow-highlight"
								v-text="$parent.formatPrice($parent.priceExcVat)"
							/>
							<span v-else class="mb-0 display-4 yellow-highlight" v-text="$parent.formatPrice($parent.priceIncVat)" />
						</strong>
					</span>
					<small class="text-muted text-right"
						><s class="d-block"><span v-text="$parent.formatPrice($parent.priceFrom)" /></s
					></small>
					<small class="text-muted" v-if="$parent.booking.Business_Booking__c">incl. alle kosten en excl. btw</small>
					<small class="text-muted" v-else>(incl. alle kosten en btw)</small>
					<small class="text-primary font-weight-bold d-block mb-md-3">Bespaar {{ $parent.save }}%</small>
				</div>
			</div>
		</div>
		<div class="desktop-content-left mt-md-n4 mt-lg-n5">
			<hr class="d-md-none" />
			<ul class="list-unstyled usp-list mb-2 mt-auto">
				<li v-if="$parent.bestPremiumDjFromCountryUsp" class="mb-1">
					<font-awesome-icon class="text-success" :icon="['fal', 'check']" />
					<span class="font-weight-bold">{{ $parent.bestPremiumDjFromCountryUsp }}</span>
				</li>
				<li v-for="(usp, index) in $parent.usps[$parent.sf_name].slice(0, uspSliceNumber)" :key="index" class="mb-1">
					<font-awesome-icon class="text-success" :icon="['fad', 'check']" />
					<span :class="{'font-weight-bold': index == 0 && $parent.bestPremiumDjFromCountryUsp == ''}">{{ usp }}</span>
				</li>
				<li class="mb-4 mb-md-1 ahref" @click="$router.push({name: 'dj', query: {dj: $parent.sf_name}})">
					<font-awesome-icon class="text-primary" :icon="['fal', 'plus']" />
					<span>Lees meer...</span>
				</li>
			</ul>
		</div>
		<div class="desktop-content-right position-relative">
			<div class="d-flex flex-column align-items-center align-items-md-end">
				<div v-if="$parent.disableCta" class="px-5 btn-lg btn btn-success disabled">
					Boek direct <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
				</div>
				<div v-else class="px-5 btn-lg btn btn-success" @click="$parent.bookDJ()">
					Boek direct <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" />
				</div>
				<div class="my-2 mt-3">
					<small v-if="$parent.isPreQuote" class="position-relative">
						Dit is een vanaf prijs.
						<span v-if="!$parent.partyDate" class="ahref">Kies jouw feestdatum</span>
						<div class="date-selector">
							<Datepicker
								v-if="!$parent.partyDate"
								wrapper-class="w-100 h-100 position-absolute"
								calendar-class="forgotten-date-input"
								input-class="w-100 h-100 position-absolute date-selector-input"
								@selected="$root.$emit('setDate', $event)"
								:language="nl"
								:disabled-dates="disabledDates"
							/>
						</div>
					</small>
					<small v-else-if="!$parent.isOrderInProgress && !$parent.isBlocked">
						Optie plaatsen? <span class="ahref" @click="$parent.requestQuote()">Vraag een offerte aan</span>
					</small>

					<Alert alertType="warning" v-else-if="$parent.isOrderInProgress && !isPaid">
						Je kunt geen offerte aanvragen of DJ boeken. Ga naar jouw DJ boeking en
						<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
					</Alert>
					<Alert alertType="warning" v-else-if="$parent.isOrderInProgress && isPaid">
						Je kunt geen offerte aanvragen of DJ boeken i.v.m. jouw DJ boeking op {{ $parent.partyDate }}
						{{ $parent.geoText }}. Bekijk hier
						<router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>.</Alert
					>
					<!-- <Alert v-if="$parent.isBlocked" alertType="warning">
						{{ $parent.prices.p_message }}
					</Alert> -->
					<Alert v-if="!isErrorCodeGlobal && $parent.isBlocked" alertType="warning">
						{{
							$t("common.pricing.message." + $parent.prices.p_message, {
								phone: customPhoneNumber,
								whatsapp: whatsappnumber,
							})
						}}
					</Alert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import Datepicker from "vuejs-datepicker";
import {nl} from "vuejs-datepicker/dist/locale";

export default {
	name: "DjBlockSummary",
	components: {Alert, Datepicker},
	data() {
		return {
			showDate: false,
			nl: nl,
			disabledDates: {
				to: new Date(),
			},
		};
	},
	computed: {
		...mapGetters({
			isErrorCodeGlobal: "isErrorCodeGlobal",
		}),
		uspSliceNumber() {
			return this.$parent.bestPremiumDjFromCountryUsp ? 2 : 3;
		},
	},
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(md) {
	.display-4 {
		font-size: 2rem !important;
	}
	.display-5 {
		font-size: 1.3rem !important;
	}
}
</style>
