<template>
	<div>
		<PageTitle
			:title="$t('mol.howWeWorkPage.title.How we work')"
			:subTitle="$t('mol.howWeWorkPage.title.Learn about how we go about providing the best DJ for your event')"
			:breadcrumbs="[$t('mol.howWeWorkPage.title.How we work')]"
		/>

		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">1</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.howWeWorkPage.title.step 1 arranged online") }}
				</h2>
				<ul>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" />
						<span>{{ $t("mol.howWeWorkPage.usp.Give us your requirements online") }}</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" />
						<span>{{ $t("mol.howWeWorkPage.usp.Receive a quote online") }}</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" />
						<span>{{ $t("mol.howWeWorkPage.usp.Book online") }}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">2</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.howWeWorkPage.title.step 2 personal contact about your requirements") }}
				</h2>
				<p>{{ $t("mol.howWeWorkPage.string.We discuss your requirements") }}</p>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">3</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.howWeWorkPage.title.step 3 we arrange everything") }}
				</h2>
				<ul>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>{{
							$t("mol.howWeWorkPage.usp.Arrange and fine tune with the venue")
						}}</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>{{
							$t("mol.howWeWorkPage.usp.We arrange and provide the equipment")
						}}</span>
					</li>
					<li class="flex align-top items-start space-x-4">
						<font-awesome-icon :icon="['fas', 'circle']" class="text-tdc-blue-500 mt-2 h-2" /><span>{{
							$t("mol.howWeWorkPage.usp.We prepare the music")
						}}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">4</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.howWeWorkPage.title.step 4 party without worries") }}
				</h2>
				<p>{{ $t("mol.howWeWorkPage.string.We ensure an unforgettable evening") }}</p>
			</div>
		</div>
		<div class="flex mb-4 md:mb-8 max-w-[1000px]">
			<div class="text-2xl md:text-3xl w-6 md:w-13 shrink-0">5</div>
			<div>
				<h2 class="underline decoration-4 mb-3 decoration-tdc-blue-500 font-bold text-lg tracking-tight">
					{{ $t("mol.howWeWorkPage.title.step 5 review us") }}
				</h2>
				<p>{{ $t("mol.howWeWorkPage.string.We want you to be satisfied") }}</p>
			</div>
		</div>
		<div class="aspect-video max-w-[1000px] mt-10">
			<iframe
				src="//player.vimeo.com/video/246086300?title=0,badge=0"
				class="w-full h-full"
				frameborder="0"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
			></iframe>
		</div>
	</div>
</template>

<script>
import PageTitle from "@/components/location/PageTitle";

export default {
	name: "LocationHowWeWork",
	components: {
		PageTitle,
	},
};
</script>
