import {userTypeIsCustomerEvaluation, userTypeIsCustomer, userTypeIsLocation, getTld} from "@/mo";

const state = {
	error: "",
	errorMessage: {
		error: false,
		message: "",
	},
	successMessage: {
		success: false,
		message: "",
	},
	loading: true,
};

const getters = {
	getTld() {
		return getTld();
	},
	userIsCustomer() {
		return userTypeIsCustomer();
	},
	userIsCustomerEvaluation() {
		return userTypeIsCustomerEvaluation();
	},
	userIsLocation() {
		return userTypeIsLocation();
	},
	getError(state) {
		return state.error;
	},
	getErrorMessage(state) {
		return state.errorMessage;
	},
	getSuccessMessage(state) {
		return state.successMessage;
	},
	getLoadingState(state) {
		return state.loading;
	},
};

const mutations = {
	SET_ERROR(state, error) {
		state.error = error;
	},
	SET_ERROR_MESSAGE(state, error) {
		state.errorMessage = error;
	},
	SET_SUCCESS_MESSAGE(state, success) {
		state.successMessage = success;
	},
	RESET_ERROR_MESSAGE(state) {
		state.errorMessage = "";
	},
	SET_LOADING_STATE(state, status) {
		state.loading = status;
	},
};

const actions = {
	handleError({commit}, payload) {
		//var errorMessage = payload.message;
		commit("SET_ERROR", payload);
		//setTimeout(() => commit("SET_ERROR", {error:false, message:errorMessage}), 3000);
	},
	displayErrorMessage({commit}, payload) {
		var errorMessage = payload.message;
		commit("SET_ERROR_MESSAGE", payload);
		setTimeout(() => commit("SET_ERROR_MESSAGE", {error: false, message: errorMessage}), 3000);
	},
	displaySuccessMessage({commit}, payload) {
		var successMessage = payload.message;
		commit("SET_SUCCESS_MESSAGE", payload);
		setTimeout(() => commit("SET_SUCCESS_MESSAGE", {success: false, message: successMessage}), 3000);
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
