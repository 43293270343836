<template>
	<div>
		<PageTitle
			:title="$t('mol.reviewsPage.title.Reviews')"
			:subTitle="subTitle"
			:breadcrumbs="breadcrumbs"
			:adaptSubnav="true"
		/>
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavTabContent">
				<div v-if="!reviewOnDisplay || molSubnavHash == 'average'">
					<p v-if="reviewTotalCount == 0">{{ $t("mol.reviewsPage.string.No reviews currently") }}</p>
					<p v-else-if="reviewTotalCount == 1">{{ $t("mol.reviewsPage.string.You have 1 review") }}</p>
					<div v-else>
						<p class="mb-3">
							{{
								$t("mol.reviewsPage.string.The DJ Company scores an average", {
									score: averageReviewScore,
									reviewCount: reviewTotalCount,
									location: location.Name,
								})
							}}
						</p>
						<div class="flex align-middle mb-4">
							<StarRating :rating="averageReviewScore" class="text-2xl flex align-middle items-center" />
							<div class="text-3xl font-bold ml-2">
								{{ averageReviewScore }}
								<span class="text-base text-slate-500"> /10</span>
							</div>
						</div>
						<DataItem
							starRating
							:dataLabel="
								$t('mol.reviewsPage.label.DJs ability to read the room') +
								' (' +
								$t('mol.reviewsPage.label.average') +
								')'
							"
							:dataValue="averageCategoryScore('dj_played_according_to_atmosphere_rating__c')"
						/>
						<DataItem
							starRating
							:dataLabel="
								$t('mol.reviewsPage.label.DJs musical ability') + ' (' + $t('mol.reviewsPage.label.average') + ')'
							"
							:dataValue="averageCategoryScore('dj_played_music_i_requested_rating__c')"
						/>
						<DataItem
							starRating
							:dataLabel="
								$t('mol.reviewsPage.label.DJs friendliness and service') +
								' (' +
								$t('mol.reviewsPage.label.average') +
								')'
							"
							:dataValue="averageCategoryScore('dj_service_level_rating__c')"
						/>
						<div v-for="(n, i) in 5" :key="i">
							<div class="flex flex-row items-stretch my-4">
								<div class="w-[130px] text-sm flex mr-2">
									<StarRating :rating="(i + 1) * 2" class="text-2xl flex align-middle items-center text-xs" />
								</div>
								<div class="w-full flex align-middle">
									<div class="my-auto w-full bg-slate-200 rounded-full h-1 dark:bg-slate-700">
										<div
											class="bg-tdc-blue-500 h-1 rounded-full bg-tdc-blue-500"
											:style="{width: starRatingPercentage(i + 1) + '%'}"
										></div>
									</div>
								</div>
								<div class="ml-2 w-[100px] text-xs">
									{{ starRatingPercentage(i + 1) }}% ({{ starRatingCount(i + 1) }})
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="reviewOnDisplay">
					<div class="flex align-middle">
						<StarRating
							:rating="
								averageScore([
									reviewOnDisplay.dj_played_according_to_atmosphere_rating__c,
									reviewOnDisplay.dj_played_music_i_requested_rating__c,
									reviewOnDisplay.dj_service_level_rating__c,
								])
							"
							class="text-2xl flex align-middle items-center"
						/>
						<div class="text-3xl font-bold ml-2">
							{{
								averageScore([
									reviewOnDisplay.dj_played_according_to_atmosphere_rating__c,
									reviewOnDisplay.dj_played_music_i_requested_rating__c,
									reviewOnDisplay.dj_service_level_rating__c,
								])
							}}
							<span class="text-base text-slate-500"> /10</span>
						</div>
					</div>
					<div class="text-xl italic mb-4">
						"{{ notEmpty(reviewOnDisplay.evaluation_quote__c, reviewOnDisplay.evaluation_quote_edit__c) }}"
					</div>

					<ContentTitle>
						{{ $t("mol.reviewsPage.title.Client rating of The DJ Company") }}
					</ContentTitle>
					<DataItem
						:dataLabel="$t('mol.reviewsPage.label.Reviewer')"
						:dataValue="reviewOnDisplay.Name_of_evaluator__c"
					/>
					<DataItem
						v-if="reviewOnDisplay.Role_of_evaluator__c != ''"
						:dataLabel="$t('mol.reviewsPage.label.Role')"
						:dataValue="reviewOnDisplay.Role_of_evaluator__c"
					/>
					<DataItem
						starRating
						:dataLabel="$t('mol.reviewsPage.label.DJs ability to read the room')"
						:dataValue="reviewOnDisplay.dj_played_according_to_atmosphere_rating__c"
					/>
					<DataItem
						starRating
						:dataLabel="$t('mol.reviewsPage.label.DJs musical ability')"
						:dataValue="reviewOnDisplay.dj_played_music_i_requested_rating__c"
					/>
					<DataItem
						starRating
						:dataLabel="$t('mol.reviewsPage.label.DJs friendliness and service')"
						:dataValue="reviewOnDisplay.dj_service_level_rating__c"
					/>
					<DataItem
						:dataLabel="$t('mol.reviewsPage.label.Customer feedback')"
						:dataValue="
							notEmpty(
								reviewOnDisplay.general_comments_regarding_booking__c,
								reviewOnDisplay.general_comments_regarding_booking_edit__c
							)
						"
					/>
					<ContentTitle>
						{{ $t("mol.reviewsPage.title.Event details") }}
					</ContentTitle>
					<DataItem
						:dataLabel="$t('mol.reviewsPage.label.Event date')"
						:dataValue="partyDate(reviewOnDisplay.party_dayte__c)"
					/>
					<DataItem
						:dataLabel="$t('mol.reviewsPage.label.Event times')"
						:dataValue="reviewOnDisplay.Booking__r.begin_time__c + '-' + reviewOnDisplay.Booking__r.end_time__c"
					/>
					<DataItem
						:dataLabel="$t('mol.reviewsPage.label.Guest count')"
						:dataValue="reviewOnDisplay.Booking__r.number_of_guests__c"
					/>
				</div>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import ContentTitle from "@/components/location/ContentTitle";
import SubNav from "@/components/location/SubNav";
import DataItem from "@/components/location/DataItem";
import StarRating from "@/components/location/StarRating";
import {capitalize, formatDate, average} from "@/utilities";

export default {
	name: "LocationReviews",
	components: {
		PageTitle,
		ContentTitle,
		SubNav,
		DataItem,
		StarRating,
	},
	data() {
		return {
			Stars1ReviewCount: 0,
			Stars2ReviewCount: 0,
			Stars3ReviewCount: 0,
			Stars4ReviewCount: 0,
			Stars5ReviewCount: 0,
		};
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
			molLangRegion: "getMolLangRegion",
			reviews: "getLocationReviews",
			location: "getLocationInformation",
		}),
		subNavItems() {
			let navItems = [];
			if (this.reviews.length > 0) {
				if (this.reviews.length > 1) {
					var reviewOverviewItem = {};
					reviewOverviewItem["type"] = "reviewAverage";
					reviewOverviewItem["label"] = this.$t("mol.reviewsPage.subNav.Reviews overview");
					reviewOverviewItem["route"] = {name: "Reviews", hash: "#average"};
					reviewOverviewItem["title"] = "";
					reviewOverviewItem["breadcrumbLabel"] = this.$t("mol.reviewsPage.title.Reviews overview");
					reviewOverviewItem["subTitle"] = this.$t("mol.reviewsPage.title.Reviews overview");
					reviewOverviewItem["icon"] = "comments";
					reviewOverviewItem["score"] = this.averageReviewScore;
					reviewOverviewItem["count"] = this.reviewTotalCount;

					navItems.push(reviewOverviewItem);
				}
				this.reviews.forEach((review) => {
					var reviewItem = {};
					reviewItem["type"] = "review";
					reviewItem["label"] = this.notEmpty(review.evaluation_title__c, review.evaluation_title_edit__c);
					reviewItem["route"] = {name: "Reviews", hash: "#" + review.Id};
					reviewItem["title"] = this.notEmpty(review.evaluation_title__c, review.evaluation_title_edit__c);
					reviewItem["breadcrumbLabel"] = this.notEmpty(review.evaluation_title__c, review.evaluation_title_edit__c);
					reviewItem["subTitle"] = this.notEmpty(review.evaluation_title__c, review.evaluation_title_edit__c);
					reviewItem["icon"] = "comment";
					reviewItem["date"] = this.partyDate(review.party_dayte__c);
					reviewItem["score"] = this.averageScore([
						review.dj_played_according_to_atmosphere_rating__c,
						review.dj_played_music_i_requested_rating__c,
						review.dj_service_level_rating__c,
					]);
					reviewItem["quote"] = this.notEmpty(review.evaluation_quote__c, review.evaluation_quote_edit__c);

					navItems.push(reviewItem);
				});
			} else {
				var reviewItem = {};
				reviewItem["type"] = "review";
				reviewItem["label"] = this.$t("mol.reviewsPage.string.No reviews currently");
				reviewItem["route"] = {name: "Reviews"};
				reviewItem["title"] = this.$t("mol.reviewsPage.string.No reviews");
				reviewItem["breadcrumbLabel"] = "";
				reviewItem["subTitle"] = this.$t("mol.reviewsPage.string.No reviews");
				reviewItem["icon"] = "comment-xmark";
				reviewItem["score"] = 0;

				navItems.push(reviewItem);
			}
			return navItems;
		},
		reviewTotalCount() {
			return this.reviews.length;
		},
		reviewCount1Star() {
			var count = [];
			if (this.reviews.length > 0) {
				this.reviews.forEach((r) => {
					var avg = this.averageScore([
						r.dj_played_music_i_requested_rating__c,
						r.dj_service_level_rating__c,
						r.dj_played_according_to_atmosphere_rating__c,
					]);
					if (avg <= 3) count++;
				});
			}
			return count;
		},
		reviewOnDisplay() {
			let reviewToDisplayID = this.molSubnavHash.substring(1);
			let reviewToDisplay = null;
			if (this.reviews.length > 0) {
				reviewToDisplay = this.reviews.find((review) => {
					return review.Id === reviewToDisplayID;
				});
			}
			return reviewToDisplay;
		},
		subTitle() {
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).subTitle;
			}
			return "";
		},
		breadcrumbs() {
			let items = [this.$t("mol.reviewsPage.title.Reviews")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(
					this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).breadcrumbLabel
				);
			}
			return items;
		},
		averageReviewScore() {
			var music = this.averageCategoryScore("dj_played_music_i_requested_rating__c");
			var service = this.averageCategoryScore("dj_service_level_rating__c");
			var atmosphere = this.averageCategoryScore("dj_played_according_to_atmosphere_rating__c");
			return this.averageScore([music, service, atmosphere]);
		},
	},
	methods: {
		djName(name) {
			return name ? "DJ " + capitalize(name) : "-";
		},
		partyDate(date) {
			return date ? formatDate(date) : "-";
		},
		notEmpty(original, edit) {
			if (edit != "" && edit != null && edit != undefined) return edit;
			if (original != "" && original != null && original != undefined) return original;
			return "-";
		},
		averageScore(scores) {
			return average(scores);
		},
		averageCategoryScore(catName) {
			var scores = [];
			if (this.reviews.length > 0) {
				this.reviews.forEach((r) => {
					scores.push(r[catName]);
				});
				return this.averageScore(scores);
			}
			return 0;
		},
		starRatingCount(stars) {
			var minScore = 0;
			var maxScore = 0;
			var count = 0;
			if (stars == 1) {
				maxScore = 3.5;
			} else if (stars == 2) {
				minScore = 3.5;
				maxScore = 5.5;
			} else if (stars == 3) {
				minScore = 5.5;
				maxScore = 7.5;
			} else if (stars == 4) {
				minScore = 7.5;
				maxScore = 9.9;
			} else if (stars == 5) {
				minScore = 9.9;
				maxScore = 10;
			}
			if (this.reviews.length > 0) {
				this.reviews.forEach((r) => {
					var avg = this.averageScore([
						r.dj_played_music_i_requested_rating__c,
						r.dj_service_level_rating__c,
						r.dj_played_according_to_atmosphere_rating__c,
					]);
					if (avg > minScore && avg <= maxScore) count++;
				});
			}
			return count;
		},
		starRatingPercentage(stars) {
			var count = this.starRatingCount(stars);
			if (count > 0) return Math.round((count / this.reviewTotalCount) * 100);

			return 0;
		},
	},
	mounted() {
		this.$store.dispatch("getLocationReviews");
	},
};
</script>
