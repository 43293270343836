<template>
	<div>
		<b-overlay :show="isLoading">
			<div class="row row-cols-1 row-cols-md-2">
				<div v-for="supplier in suppliers" :key="supplier.Id" class="col d-flex align-items-stretch">
					<b-modal
						size="lg"
						:id="'modal-' + supplier.Id"
						:title="supplier.Name"
						:no-close-on-backdrop="false"
						:no-close-on-esc="false"
						:centered="true"
						:scrollable="true"
						:hide-footer="true"
						content-class="shadow bg-light"
						footer-bg-variant="light"
						footer-text-variant="dark"
						footer-class="justify-content-end"
						header-class="bg-light"
						ok-title="Begrepen"
						@hidden="supplier.agreed = false"
					>
						<template slot="modal-title">
							<font-awesome-icon class="text-primary mr-2" :icon="['far', supplierIcon(supplier.Id)]" />
							<strong>{{ supplier.Name }}</strong>
						</template>
						<div class="d-flex flex-column flex-lg-row">
							<div class="mb-3">
								<div
									:style="{
										backgroundImage: 'url(' + supplierBackgroundImage(supplier.BackgroundURL__c, supplier.Id) + ')',
									}"
									class="supplier-image"
								></div>
								<div
									v-if="showStars(supplier.supplier_stars__c, supplier.supplier_review_count__c)"
									class="d-flex flex-row align-items-center mb-3"
								>
									<span class="mr-1 lead"
										><strong>{{ supplier.supplier_stars__c }}</strong></span
									>
									<span class="stars mr-1"
										><StarRating :rating="parseInt(supplier.supplier_stars__c)" class="d-inline"
									/></span>
									<small>
										(<strong>{{ supplier.supplier_review_count__c }}</strong> ervaringen)</small
									>
								</div>

								<div>
									<ul v-if="showUsps(supplier.supplier_usps__c)" class="list-unstyled mb-2">
										<li
											v-for="(supplierUsp, index) in supplierUsps(supplier.supplier_usps__c)"
											:key="index"
											class="d-flex justify-content-start align-items-start"
										>
											<small><font-awesome-icon :icon="['far', 'check']" class="text-success mr-1" /></small
											><small>{{ supplierUsp }}</small>
										</li>
									</ul>
								</div>
								<div class="d-flex flex-row align-items-center mb-0">
									<small
										>Actief in <strong>{{ activeArea }}</strong></small
									>
								</div>
							</div>
							<div>
								<p class="lead" v-if="supplier.slogan__c != '' && supplier.slogan__c != undefined">
									<strong v-text="supplier.slogan__c" />
								</p>
								<p v-if="supplier.Description__c" v-html="supplier.Description__c" />
								<p v-if="supplier.DiscountAction__c" v-html="supplier.DiscountAction__c" />
								<div v-if="supplier.OfferteKnopText__c && !isRequested(supplier.Id)" class="card bg-light">
									<div class="card-body">
										<div class="mb-2 d-flex flex-row align-items-start">
											<div class="form-check">
												<label class="form-check-label">
													<input
														v-model="supplier.agreed"
														type="checkbox"
														class="form-check-input"
														style="margin-top: 6px"
													/>
													{{ supplier.OfferteCheckboxText__c }}
												</label>
											</div>
										</div>
										<button
											:disabled="!supplier.agreed"
											class="btn btn-success mb-2 px-4 px-md-5"
											v-text="supplier.OfferteKnopText__c"
											@click="createQuote(supplier.Id)"
										></button>

										<small
											class="text-muted d-block"
											v-if="supplier.OfferteUitleg__c"
											v-html="supplier.OfferteUitleg__c"
										></small>
									</div>
								</div>
								<div v-else>
									<b-button class="btn btn-success mb-2 px-4 px-md-5" @click="$bvModal.hide('modal-' + supplier.Id)"
										>Begrepen</b-button
									>
								</div>

								<Alert v-if="isRequested(supplier.Id)" alertType="info">
									<span v-html="supplier.OfferteAangevraagdText__c"></span>
								</Alert>
							</div>
						</div>
					</b-modal>
					<div
						@click="$bvModal.show('modal-' + supplier.Id)"
						class="py-2 py-md-3 bg-white rounded mb-3 d-flex flex-column justify-content-start w-100"
					>
						<div class="d-flex flex-row">
							<font-awesome-icon
								class="pl-2 pl-md-3 text-primary mr-2 mt-1 mb-3"
								:icon="['far', supplierIcon(supplier.Id)]"
							/>
							<div class="d-flex flex-column">
								<div class="d-flex justify-content-between">
									<strong class="flex-grow-1">{{ supplier.Name }}</strong>
								</div>
							</div>
						</div>
						<div class="h-100" style="min-height: 170px">
							<div
								:style="{
									backgroundImage: 'url(' + supplierBackgroundImage(supplier.BackgroundURL__c, supplier.Id) + ')',
								}"
								class="d-flex align-items-center h-100 background overview-discount"
							>
								<div class="haze" style="z-index: 1"></div>
								<div class="px-3 px-md-3" style="z-index: 2">
									<p
										class="lead slogan text-white mb-0 font-weight-bolder mr-lg-5 pr-lg-5"
										v-text="supplierSlogan(supplier.slogan__c, supplier.Name)"
									/>
									<div
										v-if="showStars(supplier.supplier_stars__c, supplier.supplier_review_count__c)"
										class="d-flex flex-row align-items-center"
									>
										<span class="text-white mr-1 lead"
											><strong>{{ supplier.supplier_stars__c }}</strong></span
										>
										<span class="stars mr-1"
											><StarRating :rating="parseInt(supplier.supplier_stars__c)" class="d-inline"
										/></span>
										<small class="text-white">
											(<strong>{{ supplier.supplier_review_count__c }}</strong> ervaringen)</small
										>
									</div>
									<div class="d-flex flex-row align-items-center">
										<small class="text-white"
											>Actief in <strong>{{ activeArea }}</strong></small
										>
									</div>
								</div>
							</div>
						</div>
						<div class="pl-2 pl-md-3 mt-2 mt-md-3 d-flex justify-content-end">
							<p class="mb-0 mr-2 mr-md-3 mt-auto btn btn-primary">
								<font-awesome-icon class="mr-2 mt-1" :icon="['far', 'chevron-right']" />
								Meer info
							</p>
						</div>
					</div>
				</div>
			</div>
		</b-overlay>
	</div>
</template>
.replace(/ /g,"_")
<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import StarRating from "@/components/customer/StarRating";

export default {
	name: "Discounts",
	components: {Alert, StarRating},
	computed: {
		...mapGetters({
			suppliers: "getSuppliers",
			booking: "getBooking",
			isLoading: "getLoadingState",
			hasGeo: "hasGeo",
			geo: "getGeo",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
		}),
		activeArea() {
			if (this.hasGeo) return this.geo;
			if (this.isBelgian) return "héél Vlaanderen";
			return "héél Nederland";
		},
	},
	data() {
		return {
			agreed: false,
		};
	},
	methods: {
		createQuote(supplierId) {
			this.$store.dispatch("postBooking", {
				action: "createLeadForExtSupplier",
				data: {supplierContractId: supplierId},
				setBooking: true,
			});
		},
		showStars(stars, count) {
			return stars != 0 && stars != "" && stars != undefined && count != "" && count != undefined;
		},
		showUsps(usps) {
			return usps != "" && usps != undefined;
		},
		supplierUsps(usps) {
			return usps !== null ? usps.split("\r\n") : "";
		},
		isRequested(supplierId) {
			return (
				"LeadsForExternalSupplier__r" in this.booking &&
				this.booking.LeadsForExternalSupplier__r.records.filter(
					(supplier) => supplier.SupplierContract__c == supplierId
				).length == 1
			);
		},
		supplierSlogan(supplierSlogan, supplierName) {
			return supplierSlogan != "" && supplierSlogan != undefined ? supplierSlogan : supplierName;
		},
		supplierIcon(supplierId) {
			if (supplierId == "a1H2p000009J3KHEA0") return "camera-retro";
			else if (supplierId == "a1H7S0000009P41UAE") return "car";
			else if (supplierId == "a1H7S0000009H21UAE") return "music";
			else if (supplierId == "a1H7S000000zI2vUAE") return "music";
			else if (supplierId == "a1H7S0000009H2BUAU") return "headphones-alt";
			else if (supplierId == "a1H57000009I5jnEAC") return "envelope";
			else if (supplierId == "a1H57000009I6vEEAS") return "envelope";
			else if (supplierId == "a1H57000009I6vEEAS") return "envelope";
			else if (supplierId == "a1H57000009I2XuEAK") return "rings-wedding";
			else if (supplierId == "a1H2p000009oa9cEAA") return "camera-alt";
			else if (supplierId == "a1H7S000000I8IiUAK") return "saxophone";
			else if (supplierId == "a1HQB0000001tbx2AA") return "camera-retro";
			else if (supplierId == "a1HQB0000003zYP2AY") return "camera-retro";
			else if (supplierId == "a1HQB0000003xy22AA") return "camera-retro";
			else if (supplierId == "a1HQB0000003yfZ2AQ") return "camera-retro";
			else if (supplierId == "a1HQB0000003ykP2AQ") return "camera-retro";
			else if (supplierId == "a1HQB000000sEnZ2AU") return "utensils";
			else if (supplierId == "a1HQB000000vrIv2AI") return "camera-retro";

			return "circle";
		},
		supplierBackgroundImage(imageUrl, supplierId) {
			if (imageUrl != "" && imageUrl != undefined) return imageUrl;
			else if (
				supplierId == "a1H2p000009J3KHEA0" ||
				supplierId == "a1H7S0000009H2BUAU" ||
				supplierId == "a1H7S0000009P41UAE" ||
				supplierId == "a1H7S0000009H21UAE" ||
				supplierId == "a1H57000009I5jnEAC" ||
				supplierId == "a1H57000009I2XuEAK" ||
				supplierId == "a1H57000009I6vEEAS" ||
				supplierId == "a1H2p000009oa9cEAA" ||
				supplierId == "a1H7S000000I8IiUAK" ||
				supplierId == "a1H7S000001bJdvUAE" ||
				supplierId == "a1H7S000000zI2vUAE" ||
				supplierId == "a1HQB0000001tbx2AA" ||
				supplierId == "a1HQB0000003zYP2AY" ||
				supplierId == "a1HQB0000003xy22AA" ||
				supplierId == "a1HQB0000003yfZ2AQ" ||
				supplierId == "a1HQB0000003ykP2AQ" ||
				supplierId == "a1HQB000000sEnZ2AU" ||
				supplierId == "a1HQB000000vrIv2AI" ||
				supplierId == "a1HQB000001gld32AA" ||
				supplierId == "a1HQB000001giYj2AI"
			) {
				return require("@/assets/images/bg-" + supplierId + ".jpg");
			}
			return require("@/assets/images/blue-gradient.jpg");
		},
	},
	mounted() {
		if (this.isBelgian) this.$router.push({path: "/"});
	},
};
</script>

<style lang="scss">
.slogan {
	@include media-breakpoint-up(lg) {
		font-size: 1.45rem !important;
	}
}
.modal-footer {
	@extend .justify-content-start;
	p:last-child {
		margin-bottom: 0px;
	}
}
.supplier-image {
	@extend .rounded;
	@extend .mb-3;
	height: 150px;
	background-size: cover;
	background-position: center center;
	width: 100%;

	@include media-breakpoint-up(lg) {
		margin-top: 5px;
		width: 255px;
		margin-right: 25px;
	}
}
.haze {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: rgba(#565756, 0.5);
}
.background {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	position: relative;
	background-color: #ccc;
}
</style>
