<template>
	<div class="mb-4 mt-4">
		<h3 class="pt-4 text-center"><strong class="text-primary">Veelgestelde vragen</strong></h3>
		<div class="faq-accordion" role="tablist">
			<div v-for="(faq, index) in questionSet" :key="index" class="faq-question">
				<div header-tag="header" class="py-2 border-bottom" role="tab">
					<div block v-b-toggle="'answer-' + index" variant="link" class="font-weight-bold text-primary">
						<span class="when-opened">
							<font-awesome-icon class="mr-2" :icon="['fas', 'minus']" fa-fw />
						</span>
						<span class="when-closed">
							<font-awesome-icon class="mr-2" :icon="['fas', 'plus']" fa-fw />
						</span>
						{{ questions[faq - 1].question }}
					</div>
				</div>
				<b-collapse :id="'answer-' + index" visible accordion="faq-accordion" role="tabpanel">
					<div class="py-3" style="max-width: 750px">
						<span v-html="questions[faq - 1].answer" />
					</div>
				</b-collapse>
			</div>
			<div class="faq-question">
				<div header-tag="header" class="py-2 border-bottom" role="tab">
					<div block class="font-weight-bold text-primary">
						<span>
							<font-awesome-icon class="mr-2" :icon="['far', 'calendar-clock']" fa-fw />
						</span>
						Hulp nodig?
						<a href="https://calendly.com/thedjcompany/kennismaking" target="_blank">Plan online een belafspraak in</a>.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
	components: {
		FontAwesomeIcon,
	},
	props: {
		questionSet: {
			type: Array,
			default: () => [2, 3, 4, 5, 6],
		},
	},
	data() {
		return {
			questions: [
				{
					question_number: 1,
					question: "Wat zijn de voorwaarden om te annuleren? ",
					answer:
						"<p>Tot uiterlijk 14 dagen voor de feestdatum mag je altijd kosteloos annuleren. Dat betekent dat je de gehele waarborgsom binnen 1 dag op je rekening krijgt, zónder boekingskosten. Dat maakt het online huren van jouw DJ wel zo veilig.</p><p>Annuleer je binnen de 13 dagen tot jouw feest, dan wordt 100% van het offertebedrag in rekening gebracht. We hebben dan namelijk al kosten gemaakt. Denk daarbij aan alle (muzikale) voorbereidingen, contact met de feestlocatie, het maken van de planning, het werk van onze klantenservice, etc. Daarnaast kunnen we dan niet meer een vervangende DJ boeking voor onszelf regelen. Muziek is niet alleen onze passie, maar ook ons brood. Vandaar deze voorwaarde.</p>",
				},
				{
					question_number: 2,
					question: "Wat zit er bij de prijs in?",
					answer:
						"<p>Wij werken met heldere all-in prijzen. Zo zijn al onze prijzen inclusief: sfeervolle lichtshow, goede geluidsapparatuur, reserve DJ, reiskosten, afstemming met jouw feestlocatie, op-en afbouw, alle voorbereidingen, btw en natuurlijk 100% dansgarantie. Je weet daarom precies wat je betaalt en wat je krijgt. Dus géén verassingen achteraf en je hoeft niks meer zelf te regelen.</p>",
				},
				{
					question_number: 3,
					question: "Wat is het verschil tussen Junior, A- en Premium-klasse?",
					answer:
						"<p>The DJ Company draait op klantervaringen. We evalueren ieder feest heel uitgebreid met de klant. Daarom hebben wij zo'n hoge klanttevredenheid. 98% van alle klanten zou ons aanbevelen, en daar zijn we trots op!</p><p>We weten uit ervaring dat vooral de kwaliteit van een DJ het succes van een feest bepaalt. Al onze DJ's voldoen natuurlijk aan onze minimum eisen en zijn door een strenge selectie gekomen. Maar, we bieden jou wel keuze.</p><p>Vind je het prima om een beginnende DJ op je feest te hebben die iets minder ervaring heeft? Ga dan voor een Junior DJ. Ga je voor zekerheid en ervaring, kies dan een A-klasse DJ met een klantbeoordeling van 8,0 of hoger. Wil je echt de allerbeste DJ's met de meeste ervaring, ga dan voor premium. Dit zijn onze best beoordeelde DJ's met een 9,0 of hoger. Kortom, het neusje van de zalm!</p>",
				},
				{
					question_number: 4,
					question: "Wie wordt mijn DJ?",
					answer:
						"<p>Op basis van 9032 reviews en 10 jaar ervaring zorgen we dat je altijd de juiste DJ krijgt op jouw feest. We evalueren al onze DJ's met onze klanten en weten dus precies wie het beste bij jouw wensen past. Je hoeft niet zelf een DJ te kiezen en je hebt dus geen zorgen. Daarom werken wij ook met een niet-goed, geld-terug-garantie.</p><p>Uiteraard heb je altijd nog een gesprek vóór het feest met jouw DJ. Jullie kunnen dan samen alles rustig doornemen over je muziekwensen en het feest. Heb je tussendoor andere vragen? Dan staat jouw persoonlijke party-planner altijd voor je klaar. Daarnaast staat onze klantenservice 7 dagen in de week paraat. Op die manier heb je een feest zonder zorgen.</p>",
				},
				{
					question_number: 5,
					question: "Welke muziek draaien we?",
					answer:
						"<p>Wij draaien de beste muziek van nu en toen. Van de 80's, 90's en 00’s tot de nieuwste top 40 hits. Van dance classics tot de beste dance van nu: wij hebben alle genres bij ons en zorgen voor een perfecte mix. We zorgen dat de muziek perfect aansluit op jouw wensen en die van de gasten zodat de dansvloer vol staat.</p><p>Verzoeknummers zijn ook meer dan welkom natuurlijk. Via een persoonlijke wensenformulier kun je makkelijk online vooraf al je (muziek)wensen doorgeven. In de week voor het feest bespreken we jouw wensen nog uitgebreid zodat de muziek goed is voorbereid. Op het feest zelf kunnen jij en je gasten ook de leukste verzoeknummers doorgeven. Verder hoef je alleen maar te genieten van een zorgeloos en onvergetelijk feest.</p>",
				},
				{
					question_number: 6,
					question: "Welke apparatuur en show nemen we mee?",
					answer:
						"<p>We zorgen altijd voor een perfecte lichtshow en een goede geluidsset. De lichtshow zorgt voor extra sfeer waardoor jouw gasten sneller de dansvloer op gaan. De goede geluidsapparatuur zorgt ervoor dat de muziek altijd perfect klinkt en goed dansbaar is. Je hoeft dus zelf geen show uit te kiezen want wij zorgen dat alle benodigde apparatuur goed geregeld is. Wel zo makkelijk.</p><p>Vooraf stemmen we alles ook nog af met jouw feestlocatie. Zo verloopt jouw feest vlekkeloos.</p>",
				},
			],
		};
	},
};
</script>
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
	display: none;
}
</style>
