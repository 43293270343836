<template>
	<div>
		<div v-if="isPaid">
			<CustomerInvoiceAddress></CustomerInvoiceAddress>
			<QuoteProgress :step="3" :steps="4" :steps_type="'booking'" style="display: none !important" />
			<PaymentNotification />
			<div class="row row-cols-1 row-cols-lg-2">
				<div class="col">
					<div class="bg-white d-flex h-100 rounded">
						<div class="mb-3 mb-md-0">
							<div class="d-flex h-100 align-items-center justify-content-center justify-content-lg-start">
								<div class="p-2 pl-3 p-md-4">
									<div class="mb-3 d-flex justify-content-start w-100 unique-titles">
										<font-awesome-icon class="ml-n1 mr-2 mt-1 text-primary" :icon="['far', titleIcon]" />
										<div class="w-100">
											<p>
												<strong>
													<span v-if="isWedding"
														>{{ booking.bridal_couple_del__c }}'s {{ partyType }} op {{ partyDate }}</span
													>
													<span v-else>{{ booking.Account__r.FirstName }}'s {{ partyType }} op {{ partyDate }}</span>
												</strong>
											</p>
										</div>
									</div>
									<BookingSteps />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col">
					<BookingDetails v-if="!isLoading" />
					<PriceCalculator class="pt-md-3 mb-3 mr-md-5 pt-5 d-block" />
					<MyPartyList class="d-block" />
				</div>
			</div>
		</div>
		<div v-else>
			<DisableTrengoChat />
			<QuoteProgress v-if="isOrderInProgress" :step="2" :steps="4" :steps_type="'booking'" />
			<Alert v-if="!isOrderInProgress" alertType="notification">
				<span v-if="hasQuotes">
					Je hebt nog geen DJ boeking gemaakt. Bevestig eerst één van
					<router-link :to="{name: 'Quotes'}">jouw offertes</router-link>.
				</span>
				<span v-else>
					Je hebt nog geen DJ boeking gemaakt. Bereken eerst de
					<router-link :to="{name: 'Prices'}">prijs voor jouw feest</router-link>.
				</span>
			</Alert>
			<div v-if="isOrderInProgress">
				<QuoteProgress :step="getStep" :steps="4" :steps_type="'booking'" style="display: none !important" />
				<PaymentNotification />
				<div v-if="!isLoading">
					<div class="desktop-content-left-right-wrapper mb-4">
						<DjBlock
							v-if="!isLoading"
							template="DjBlockComplete"
							templateVariant="DirectBookingConfirmed"
							:sf_name="djClass"
							:priceIncVat="priceInc"
							:priceExcVat="priceExc"
							:downPayment="downPayment"
							:showDefaultCta="false"
							:showCustomCta="true"
							:hideAllLists="true"
						>
							<template slot="customCta">
								<div class="d-flex flex-column align-items-end">
									<PaymentButton />
								</div>
							</template>
						</DjBlock>
					</div>
				</div>
			</div>
		</div>
		<BookingIncludes v-if="isOrderInProgress" />
		<div
			v-if="!isPaid && isOrderInProgress"
			class="d-flex flex-column align-items-center border-top border-bottom py-4"
			style="border-color: #cc0066 !important"
		>
			<div class="mx-auto" style="max-width: 450px">
				<PaymentButton />
			</div>
		</div>
		<Faqs :questionSet="[1, 2, 4, 5, 6]" v-if="isOrderInProgress" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PaymentNotification from "@/components/customer/PaymentNotification";
import BookingIncludes from "@/components/customer/BookingIncludes";
import PaymentButton from "@/components/customer/PaymentButton";
import QuoteProgress from "@/components/customer/QuoteProgress";
import Faqs from "@/components/customer/Faqs";
import Alert from "@/components/customer/Alert";
import DjBlock from "@/components/customer/DjBlock";
import BookingDetails from "@/components/customer/BookingDetails";
import PriceCalculator from "@/components/customer/PriceCalculator";
import MyPartyList from "@/components/customer/MyPartyList";
import BookingSteps from "@/components/customer/BookingSteps";
import CustomerInvoiceAddress from "@/components/customer/CustomerInvoiceAddress";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "Booking",
	components: {
		BookingSteps,
		PaymentNotification,
		BookingIncludes,
		PaymentButton,
		QuoteProgress,
		Faqs,
		Alert,
		DjBlock,
		BookingDetails,
		PriceCalculator,
		MyPartyList,
		CustomerInvoiceAddress,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			djClass: "getCurrentDjClass",
			downPayment: "getDownPayment",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
			isLoading: "getLoadingState",
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
			isWedding: "isWedding",
			partyType: "getPartyType",
			priceInc: "getBookingPriceIncVat",
			priceExc: "getBookingPriceExcVat",
			quotes: "getQuotes",
		}),
		getStep: function () {
			return this.isPaid ? 3 : 2;
		},
		hasQuotes: function () {
			return this.quotes.length > 0 ? true : false;
		},
		partyDate: function () {
			return this.$date(this.booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY");
		},
		titleIcon() {
			if (this.isBirthday) return "cake-candles";
			if (this.isWedding) return "champagne-glasses";
			if (this.isBusinessParty) return "face-party";
			return "balloons";
		},
	},
	data() {
		return {
			accept: false,
			submitted: false,
			hideBookingDetails: true,
		};
	},
	methods: {
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
};
</script>
