<template>
	<div class="pt-3 border-top">
		<div v-if="quoteCount > 1">
			<ul class="list-unstyled">
				<li
					v-for="(quote, index) in quotes"
					:key="index"
					:class="{'dashed-border': quoteCount > 1}"
					class="flex-column d-flex rounded w-100 justify-content-start my-2 position-relative"
				>
					<QuoteSummaryItem
						:quote="quote"
						styleVariant="overviewList"
						:class="[quote.Id == currentQuote ? 'alert alert-warning p-1 m-0' : '']"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import QuoteSummaryItem from "@/components/customer/QuoteSummaryItem";

export default {
	name: "MyQuotes",
	props: {
		currentQuote: {
			type: String,
			default: "",
		},
	},
	components: {
		QuoteSummaryItem,
	},
	computed: {
		...mapGetters({
			quotes: "getQuotes",
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
		}),
		quoteCount() {
			return this.quotes.length;
		},
	},
	methods: {
		scrollToTop() {
			window.scrollTo({top: 0, behavior: "smooth"});
		},
	},
	mounted() {
		this.quotes.sort(function (a, b) {
			var keyA = new Date(a.CreatedDate),
				keyB = new Date(b.CreatedDate);
			// Compare the 2 dates
			if (keyA > keyB) return -1;
			if (keyA < keyB) return 1;
			return 0;
		});
	},
};
</script>

<style lang="scss" scoped>
.alert-warning {
	background-color: #ffffea;
	border-color: #f9efbc;
}
.disabled {
	text-decoration: none !important;
}
.link-block-icon {
	margin-left: 10px;
	width: 45px;
	height: 45px;
	@include media-breakpoint-up(md) {
		width: 65px;
		height: 65px;
		margin-left: 0px;
	}
}
</style>
