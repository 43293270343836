<template>
	<div id="party_wishes" class="position-relative">
		<BackTo class="py-2 d-md-none" v-if="!showOverviewTabContent" to="PartyWishes" label="Terug naar wensenoverzicht" />
		<Alert v-if="!isConfirmed" alertType="info">
			<strong>Let op!</strong> Onderstaande gegevens gebruiken wij ter voorbereiding op het feest. Ze worden dus meestal
			niet direct doorgelezen. <span v-if="isWedding">Hebben jullie</span><span v-else>Heb je</span> nu een vraag? Stel
			deze dan via <a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a> of via één van de andere
			contactopties onderaan deze pagina.
		</Alert>
		<Alert v-if="isConfirmed" alertType="notification">
			<strong>Let op! </strong>Je kunt hier nu niks meer aanpassen. We zijn namelijk al begonnen met voorbereiding op
			basis van wat is ingevuld. Toch nog iets doorgeven? Neem contact op via
			<a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a> of via één van de andere contactopties (zie
			onderaan pagina).
		</Alert>
		<b-form :aria-hidden="isLoading ? 'true' : null" ref="content">
			<transition name="fade">
				<div v-if="showOverviewTabContent">
					<h3>
						<strong><span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> wensenoverzicht </strong>
					</h3>
					<div class="row row-cols-1 row-cols-md-2">
						<div class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-primary text-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-white mr-2 mt-1" :icon="['far', 'timer']" />
								<div class="w-100">
									<strong
										>Op tijd <span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> wensen
										invullen</strong
									>
									<p>
										Vul <span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> wensen in en laat het
										ons weten hoe <span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> perfecte
										feest eruit ziet!
									</p>
									<p class="mb-0">
										<font-awesome-icon class="text-white mr-2 mt-1" :icon="['far', 'chevron-right']" />Ga
										<router-link class="text-white" :to="{name: 'PartyWishes', hash: '#music'}"
											>direct aan de slag!</router-link
										>
									</p>
									<ProgressBarWishes :tabViewCount="wishesTabViewCount" />
								</div>
							</div>
						</div>
						<div class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'music']" />
								<div class="w-100">
									<div class="d-flex justify-content-between">
										<strong class="flex-grow-1">Muziek</strong>
										<b-form-text class="m-0 flex-shrink-1 d-flex justify-content-end align-items-center">
											powered by <img src="~@/assets/images/logo-spotify.png" class="ml-1" style="max-width: 70px"
										/></b-form-text>
									</div>

									<p>
										<span v-if="isWedding" v-text="'Hebben jullie'" /><span v-else v-text="'Heb je'" /> specifieke
										muziekwensen? Geef ze aan ons door!
									</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#music'}"
											><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />Muziekwensen
											aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div v-if="isWedding" class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'rings-wedding']" />
								<div class="w-100">
									<strong>Openingsdans</strong>
									<p>Maak jullie speciale dag nog persoonlijker</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#firstdance'}"
											><font-awesome-icon
												class="text-primary mr-2 mt-1"
												:icon="['far', 'chevron-right']"
											/>Bruiloftsfeest aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div v-if="isBirthday" class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'party-horn']" />
								<div class="w-100">
									<strong>Verjaardagsfeestdetails</strong>
									<p>Gefeliciteerd! Geef je nog door hoe wij het feest nog persoonlijker kunnen maken?</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#partydetails'}"
											><font-awesome-icon
												class="text-primary mr-2 mt-1"
												:icon="['far', 'chevron-right']"
											/>Verjaardagfeest wensen aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div v-else class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'party-horn']" />
								<div class="w-100">
									<strong>Feestdetails</strong>
									<p>Geef je nog door hoe wij het feest nog persoonlijker kunnen maken?</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#partydetails'}"
											><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />Feestwensen
											aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'headphones-alt']" />
								<div class="w-100">
									<strong><span v-if="isWedding" v-text="'Jullie DJ'" /><span v-else v-text="'Jouw DJ'" /></strong>
									<p>
										Microfoon gebruik, specifieke kledingstijl, laat ons weten wat de wensen zijn voor
										<span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> DJ.
									</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#dj'}"
											><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />DJ wensen
											aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'speakers']" />
								<div class="w-100">
									<strong>Opbouw & apparatuur</strong>
									<p>
										Microfoon gebruik, specifieke kleding stijl, laat ons weten wat de wensen zijn voor
										<span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'je'" /> DJ.
									</p>
									<p class="mb-0">
										<router-link :to="{name: 'PartyWishes', hash: '#equipment'}"
											><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />Opbouw
											wensen aanvullen</router-link
										>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div v-if="showMusicTabContent">
					<h3><strong>Muziek</strong></h3>
					<b-form-group label="Welke genres" label-for="music_custom_genres__c" class="border-bottom pb-3">
						<template slot="label">
							Welke genres <span v-if="isWedding" v-text="'willen jullie'" /><span v-else v-text="'wil je'" /> graag
							horen tijdens het feest?
						</template>
						<div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 mx-n1 music-genre-options">
							<b-form-checkbox
								:value="true"
								v-model="wishes.music_genre_80s__c"
								@change.native="updateWishes('music_genre_80s__c')"
								button
								button-variant="outline-tdc"
								name="music_genre_80s"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('music_genre_80s__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel checkbox primary="80's" secondary="Hits uit de jaren 80" />
								<div class="music-genre-bg genre-bg-80s"></div>
							</b-form-checkbox>
							<b-form-checkbox
								:value="true"
								v-model="wishes.music_genre_90s__c"
								@change.native="updateWishes('music_genre_90s__c')"
								button
								button-variant="outline-tdc"
								name="music_genre_90s"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('music_genre_90s__c')"
								:disabled="isConfirmed"
							>
								<div class="music-genre-bg genre-bg-90s"></div>
								<OptionLabel checkbox primary="90's" secondary="Hits uit de jaren 90" />
							</b-form-checkbox>
							<b-form-checkbox
								:value="true"
								v-model="wishes.music_genre_00s__c"
								@change.native="updateWishes('music_genre_00s__c')"
								button
								button-variant="outline-tdc"
								name="music_genre_00s"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('music_genre_00s__c')"
								:disabled="isConfirmed"
							>
								<div class="music-genre-bg genre-bg-00s"></div>
								<OptionLabel checkbox primary="00's" secondary="Hits uit de jaren 00" />
							</b-form-checkbox>
							<b-form-checkbox
								:value="true"
								v-model="wishes.music_genre_10s__c"
								@change.native="updateWishes('music_genre_10s')"
								button
								button-variant="outline-tdc"
								name="music_genre_10s"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('music_genre_10s')"
								:disabled="isConfirmed"
							>
								<div class="music-genre-bg genre-bg-10s"></div>
								<OptionLabel checkbox primary="10's" secondary="Hits uit de jaren 10" />
							</b-form-checkbox>
							<b-form-checkbox
								:value="true"
								v-model="wishes.music_genre_top40__c"
								@change.native="updateWishes('music_genre_top40__c')"
								button
								button-variant="outline-tdc"
								name="music_genre_top40"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('music_genre_top40__c')"
								:disabled="isConfirmed"
							>
								<div class="music-genre-bg genre-bg-top40"></div>
								<OptionLabel checkbox primary="Top 40" secondary="Hits uit de Top 40" />
							</b-form-checkbox>
							<b-form-checkbox
								v-model="customGenres"
								@change.native="askCustomGenres(customGenres)"
								class="d-block mb-2 mdc-radio-check col px-1"
								button
								button-variant="outline-tdc"
								name="andere_genres"
								:value="true"
								:disabled="isConfirmed"
							>
								<div class="music-genre-bg genre-bg-other"></div>

								<OptionLabel checkbox primary="Anders" secondary="Andere genres" />
								<img
									v-if="customGenres"
									class="position-absolute"
									alt=""
									src="~@/assets/images/arrow-bottom-left.png"
									style="bottom: -40px; right: 20px"
								/>
							</b-form-checkbox>
							<div class="btn btn-block mb-2 mdc-radio-check col px-1 btn-group-toggle d-inline-block"></div>
						</div>
						<b-form-group
							v-if="customGenres"
							label="Gewenste muziek (stijlen)"
							label-for="music_custom_genres__c"
							class=""
							:class="getInputClass('music_custom_genres__c')"
						>
							<template slot="label">
								Gewenste muziek genres (stijlen)
								<b-form-text class="text-left">
									<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Geef globaal aan wat de
									gewenste muziekstijlen en genres zijn</b-form-text
								>
							</template>
							<div class="position-relative">
								<b-form-textarea
									v-model="wishes.music_custom_genres__c"
									@input.native="delayUpdateWishes('music_custom_genres__c')"
									rows="5"
									maxlength="500"
									id="music_custom_genres__c"
									placeholder=" "
									:disabled="isConfirmed"
								></b-form-textarea>
								<InputFeedback />
							</div>
						</b-form-group>
					</b-form-group>
					<h3>
						<strong><span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Mijn'" /> top 10</strong>
					</h3>
					<b-form-group
						label="Zoek nummers of artiesten en voeg max. 10 opties toe die je graag wilt horen"
						label-for="spotify-search"
						class="border-bottom pb-3 position-relative"
						:class="highlightClass"
						id="spotify-search"
					>
						<div v-if="disableSpotifySearch || isConfirmed" class="disable-spotify"></div>
						<template slot="label">
							<div style="z-index: 9; position: relative">
								Zoek nummers of artiesten en voeg max. 10 opties toe die
								<span v-if="isWedding" v-text="'jullie graag willen'" /><span v-else v-text="'je graag wilt'" /> horen
								<b-form-text class="text-left">
									<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> We gebruiken deze
									nummers om een goed beeld te krijgen van <span v-if="isWedding" v-text="'jullie'" /><span
										v-else
										v-text="'jouw'"
									/>
									muziekwensen. Soms kunnen we niet alle nummers draaien want het hangt echt af van de sfeer op dat
									moment</b-form-text
								>
							</div>
						</template>
						<VueMultiselect
							@search-change="querySpotify"
							@close="loadingSpotifyOptions = false"
							:options="spotifyOptions"
							:internal-search="false"
							:reset-after="true"
							label="name"
							:taggable="false"
							:show-no-results="true"
							:multiple="false"
							:searchable="true"
							:close-on-select="false"
							:clear-on-select="false"
							:group-select="true"
							:show-labels="false"
							:preselect-first="true"
							:loading="loadingSpotifyOptions"
							:placeholder="searchSpotifyPlaceholder"
							select-label="Klik om te kiezen"
							ref="spotifyInput"
						>
							<template slot="option" slot-scope="props">
								<div
									@click="selectSpotifyOption(props.option)"
									class="d-flex justify-content-start align-items-center w-100 d-block"
									style="overflow: hidden: ;"
								>
									<div
										v-if="props.option.type == 'artist'"
										class="d-flex flex-row align-items-center spotify-suggestion-item spotify-suggestion-item-artist"
									>
										<div
											class="spotify-suggestion-item-image"
											:style="{backgroundImage: 'url(' + suggestionImage(props.option.images) + ')'}"
										></div>
										<div class="text-wrap">
											<span class="ahref">{{ props.option.name }}</span
											><br /><small class="text-muted">Artiest</small>
										</div>
									</div>
									<div
										v-if="props.option.type == 'track'"
										class="d-flex flex-row align-items-center spotify-suggestion-item spotify-suggestion-item-track"
									>
										<div
											class="spotify-suggestion-item-image"
											:style="{backgroundImage: 'url(' + suggestionImage(props.option.album.images) + ')'}"
										></div>
										<div class="text-wrap">
											<span class="ahref">{{ props.option.name }}</span>
											<br /><small class="text-muted"
												>{{ props.option.artists[0].name }} | {{ props.option.album.name }} |
												{{ props.option.album.release_date.substring(0, 4) }}</small
											>
										</div>
									</div>
									<font-awesome-icon class="text-primary ml-auto" size="lg" :icon="['fas', 'plus']" />
								</div>
							</template>

							<template slot="noResult">
								<div
									v-if="!loadingSpotifyOptions"
									@click="selectSpotifyOption(emptySongObject(queryText))"
									class="d-flex justify-content-start align-items-center w-100 d-block"
								>
									<div
										class="d-flex flex-row align-items-center spotify-suggestion-item spotify-suggestion-item-artist py-2"
									>
										<div
											class="spotify-suggestion-item-image d-flex justify-content-center align-items-center"
											style="background-color: #ececec"
										>
											<font-awesome-icon class="text-primary" size="lg" :icon="['fas', 'music']" />
										</div>
										<div class="text-wrap">
											<span class="ahref">{{ queryText }}</span
											><br /><small class="text-muted">Klik om "{{ queryText }}" toe te voegen</small>
										</div>
									</div>
									<font-awesome-icon class="text-primary ml-auto" size="lg" :icon="['fas', 'plus']" />
								</div>
								<div v-else class="d-flex justify-content-center align-items-center w-100 d-block">
									<div class="d-flex flex-row align-items-center justify-content-center spotify-suggestion-item py-2">
										<font-awesome-icon class="text-primary" size="lg" :icon="['fad', 'spinner-third']" spin />
									</div>
								</div>
							</template>

							<template slot="noOptions">
								<b-form-text class="d-flex justify-content-center align-items-center">
									powered by <img src="~@/assets/images/logo-spotify.png" class="ml-1" style="max-width: 90px"
								/></b-form-text>
							</template>
						</VueMultiselect>
						<b-form-text class="d-flex justify-content-end align-items-center">
							powered by <img src="~@/assets/images/logo-spotify.png" class="ml-1" style="max-width: 90px"
						/></b-form-text>

						<transition name="chosen">
							<div
								v-if="showAddedBox"
								class="position-absolute d-flex justify-content-center align-items-left w-100 d-block card"
							>
								<div class="card-body p-2 p-md-2 top-10-song" :class="top10SongClass(justSelectedSong.type)">
									<div class="d-flex justify-content-start align-items-center">
										<div>
											<div
												v-if="justSelectedSong.image && justSelectedSong.image != ''"
												class="top-10-song-image"
												:style="{backgroundImage: 'url(' + justSelectedSong.image + ')'}"
											></div>
											<div v-else class="top-10-song-image d-flex justify-content-center align-items-center">
												<font-awesome-icon class="text-primary" size="lg" :icon="['far', 'music']" />
											</div>
										</div>
										<div class="mr-2">
											<div class="mb-0 top-10-song-title">
												<strong class="text-primary">{{ justSelectedSong.name }}</strong>
											</div>
											<span class="text-primary top-10-song-subtitle">
												<span v-if="justSelectedSong.type == 'artist'">Artiest</span>
												<span v-if="justSelectedSong.type == 'track'"
													>{{ justSelectedSong.artist }}
													<span v-if="justSelectedSong.album && justSelectedSong.album != ''">
														| {{ justSelectedSong.album }}</span
													>
													<span v-if="justSelectedSong.album_year && justSelectedSong.album_year != ''">
														| {{ justSelectedSong.album_year }}</span
													>
												</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</transition>
					</b-form-group>

					<b-form-group label="Muziek en artiest keuzes" label-for="songs" class="border-bottom pb-3">
						<template slot="label">
							<span v-if="isWedding">Jullie muziek en artiesten keuzes</span>
							<span v-else>Jouw muziek en artiesten keuzes</span>
							<b-form-text v-if="isWedding" class="text-left mb-3">
								<font-awesome-icon class="text-primary mr-1" :icon="['far', 'music']" /> Inspiratie nodig? Bekijk dan
								deze pagina over de
								<a
									:href="'https://thedjcompany.' + siteDomainExtention + '/bruiloft-dj/bruiloft-muziek/'"
									target="_blank"
									class="ahref"
									>beste bruiloftmuziek</a
								>
							</b-form-text>
						</template>

						<draggable
							v-model="wishes.Top_10_songs"
							group="songs"
							v-bind="dragOptions"
							@start="drag = true"
							@end="drag = false"
							animation="true"
							class="top-10-songs"
						>
							<transition-group type="transition" name="flip-list">
								<div v-for="(song, key) in wishes.Top_10_songs" :key="key + 1" class="card mb-2 border-primary">
									<div class="card-body p-2 p-md-2 top-10-song" :class="top10SongClass(song.type)">
										<div class="d-flex justify-content-start align-items-center">
											<div>
												<div
													v-if="song.image && song.image != ''"
													class="top-10-song-image"
													:style="{backgroundImage: 'url(' + song.image + ')'}"
												></div>
												<div v-else class="top-10-song-image d-flex justify-content-center align-items-center">
													<font-awesome-icon class="text-primary" size="lg" :icon="['far', 'music']" />
												</div>
											</div>
											<div class="mr-2">
												<div class="mb-0 top-10-song-title">
													<strong class="text-primary">{{ key + 1 }}. {{ song.name }}</strong>
												</div>
												<span class="text-primary top-10-song-subtitle">
													<span v-if="song.type == 'artist'">Artiest</span>
													<span v-if="song.type == 'track'"
														>{{ song.artist }}
														<span v-if="song.album && song.album != ''"> | {{ song.album }}</span>
														<span v-if="song.album_year && song.album_year != ''"> | {{ song.album_year }}</span>
													</span>
												</span>
											</div>
											<div v-if="!isConfirmed" class="ml-auto">
												<span class="delete-link ahref d-md-block d-none" @click="removeSpotifyOption(key)"
													>verwijderen</span
												>
												<span class="delete-link ahref d-block d-md-none" @click="removeSpotifyOption(key)"
													><font-awesome-icon class="text-primary" size="lg" :icon="['far', 'times']"
												/></span>
											</div>
											<div v-if="!isConfirmed" class="drag-area d-block">
												<font-awesome-icon class="text-primary ml-3 ahref" size="lg" :icon="['far', 'bars']" />
											</div>
										</div>
									</div>
								</div>
							</transition-group>
						</draggable>
						<div v-if="!isConfirmed">
							<div v-for="index in emptySongSlots" :key="index">
								<div v-if="index < 4" class="card mb-2 top-10-song top-10-song-empty" @click="scrollToSpotify">
									<div class="card-body p-2 p-md-2">
										<div class="d-flex justify-content-start align-items-center">
											<div>
												<div class="top-10-song-image d-flex justify-content-center align-items-center">
													<font-awesome-icon size="lg" :icon="['far', 'music']" />
												</div>
											</div>
											<div class="mr-2">
												<div class="mb-0 top-10-song-title">
													<strong>{{ wishes.Top_10_songs.length + index }}. Zoek een nummer of artiest</strong>
												</div>
											</div>
											<div class="ml-auto"></div>
											<font-awesome-icon class="drag-area ml-3" size="lg" :icon="['fas', 'plus']" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-form-group>

					<b-form-group
						label="Wat wil je absoluut niet horen?"
						label-for="music_styles_or_artists_not_wanted__c"
						class="border-bottom pb-3"
						:class="getInputClass('music_styles_or_artists_not_wanted__c')"
					>
						<template slot="label">
							<span v-if="isWedding">Wat willen jullie absoluut niet horen?</span>
							<span v-else>Wat wil je absoluut niet horen?</span>
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Denk ook aan emotioneel
								beladen nummers</b-form-text
							>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.music_styles_or_artists_not_wanted__c"
								@input.native="delayUpdateWishes('music_styles_or_artists_not_wanted__c')"
								rows="5"
								maxlength="500"
								id="music_styles_or_artists_not_wanted__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
					<b-form-group
						label="Beschrijf de muziekale wensen"
						label-for="music_request_comments__c"
						class="border-bottom pb-3"
						:class="getInputClass('music_request_comments__c')"
					>
						<template slot="label">
							<span v-if="isWedding">Beschrijf jullie muzikale wensen voor het feest</span>
							<span v-else>Beschrijf jouw muzikale wensen voor het feest</span>
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Bijvoorbeeld:
								<span v-if="isWedding" v-text="'wij willen'" /><span v-else v-text="'ik wil'" /> dat het feest rustig
								begint en met een knaller eindigt.</b-form-text
							>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.music_request_comments__c"
								@input.native="delayUpdateWishes('music_request_comments__c')"
								rows="5"
								maxlength="500"
								id="music_request_comments__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
					<b-form-group v-if="isWedding" label="Gaan jullie op huwelijksreis?" label-for="huwelijkreis">
						<template slot="label">
							Gaan jullie op huwelijksreis?
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Wij gebruiken dit
								eventueel voor een muzikale knipoog naar jullie vakantieland.
							</b-form-text>
						</template>
						<div class="row row-cols-2 mx-n1">
							<b-form-radio
								button
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								name="huwelijkreis"
								v-model="huwelijkreis"
								:value="false"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Nee" icon="plane-up-slash" />
							</b-form-radio>
							<b-form-radio
								button
								class="d-block mb-2 mdc-radio-check col px-1"
								button-variant="outline-tdc"
								name="huwelijkreis"
								:value="true"
								v-model="huwelijkreis"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Ja" icon="plane-up" />
								<img
									v-if="huwelijkreis"
									class="position-absolute"
									alt=""
									src="~@/assets/images/arrow-bottom-left.png"
									style="bottom: -40px; right: 20px; z-index: 9"
								/>
							</b-form-radio>
						</div>
					</b-form-group>
					<b-form-group
						v-if="isWedding && huwelijkreis"
						label="Bestemming"
						label-for="honeymoon_destination__c"
						class="pb-3"
						:class="getInputClass('honeymoon_destination__c')"
					>
						<div class="position-relative">
							<b-form-input
								v-model="wishes.honeymoon_destination__c"
								@input.native="delayUpdateWishes('honeymoon_destination__c')"
								type="text"
								id="honeymoon_destination__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-input>
							<InputFeedback />
						</div>
					</b-form-group>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="showPartyTypeTabContent">
					<span v-if="!isWedding">
						<h3>
							<strong>{{ partyTypeTitle | capitalize }}</strong>
						</h3>
						<b-form-group
							v-if="!isBirthday"
							label="Aanleiding van het feest"
							label-for="reason_party__c"
							class="border-bottom pb-3"
							:class="getInputClass('reason_party__c')"
						>
							<template slot="label">
								Wat is de reden dat dit feest wordt gegeven?
								<b-form-text class="text-left">
									<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Wie is er jarig? Van
									wie is het jubileum? Vertel iets over de aanleiding
								</b-form-text>
							</template>
							<div class="position-relative">
								<b-form-textarea
									v-model="wishes.reason_party__c"
									@input.native="delayUpdateWishes('reason_party__c')"
									rows="5"
									maxlength="500"
									id="reason_party__c"
									placeholder=" "
									:disabled="isConfirmed"
								></b-form-textarea>
								<InputFeedback />
							</div>
						</b-form-group>
						<b-form-group
							v-if="isBirthday"
							label="Aanleiding van het feest"
							label-for="birthday_age__c"
							class="border-bottom pb-3"
						>
							<template slot="label"> Hoe oud wordt de jarige? </template>
							<div class="row row-cols-3 row-cols-md-6 mx-n1">
								<b-form-radio
									v-for="(age_option, key) in birthday_age_options"
									:key="key"
									:value="age_option"
									v-model="wishes.birthday_age__c"
									@change.native="updateWishes('birthday_age__c'), askCustomAge(false)"
									button
									button-variant="outline-tdc"
									name="birthday_age__c"
									class="d-block mb-2 mdc-radio-check col px-1"
									:class="getInputClass('birthday_age__c')"
									:disabled="isConfirmed"
								>
									<OptionLabel radio :primary="age_option" secondary="jaar" />
								</b-form-radio>
								<b-form-radio
									v-model="customAge"
									@change.native="askCustomAge(true)"
									class="d-block mb-2 mdc-radio-check col px-1"
									button
									button-variant="outline-tdc"
									name="birthday_age__c"
									:value="true"
									:disabled="isConfirmed"
								>
									<OptionLabel radio primary="" secondary="Andere leeftijd" />
									<img
										v-if="customAge"
										class="position-absolute"
										alt=""
										src="~@/assets/images/arrow-bottom-left.png"
										style="bottom: -40px; right: 20px; z-index: 9"
									/>
								</b-form-radio>
							</div>

							<div v-if="customAge" class="position-relative" :class="getInputClass('birthday_age__c')">
								<b-form-input
									@input.native="delayUpdateWishes('birthday_age__c')"
									v-model="wishes.birthday_age__c"
									class="mt-2"
									type="number"
									max="120"
									placeholder="Vul leeftijd in"
									:disabled="isConfirmed"
								/>
								<InputFeedback />
							</div>
						</b-form-group>
						<b-form-group
							v-if="isBirthday"
							label="Naam van de jarige"
							label-for="birthday_name__c"
							class="border-bottom pb-3"
							:class="getInputClass('birthday_name__c')"
						>
							<template slot="label"> Wat is de naam van de jarige? </template>
							<div class="position-relative">
								<b-form-input
									v-model="wishes.birthday_name__c"
									@input.native="delayUpdateWishes('birthday_name__c')"
									type="text"
									maxlength="255"
									id="birthday_name__c"
									placeholder=" "
									:disabled="isConfirmed"
								/>
								<InputFeedback />
							</div>
						</b-form-group>
					</span>

					<h3><strong>Feestdetails</strong></h3>
					<b-form-group
						label="Het perfecte feest "
						label-for="perfect_night__c"
						class="border-bottom pb-3"
						:class="getInputClass('perfect_night__c')"
					>
						<template slot="label">
							Het perfecte {{ partyTypeName }}
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> De juiste sfeer? Een
								volle dansvloer? Ook ruimte om te kletsen? Andere activiteiten? etc.
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.perfect_night__c"
								@input.native="delayUpdateWishes('perfect_night__c')"
								rows="5"
								maxlength="500"
								id="perfect_night__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>

					<b-form-group
						label="Indeling van de avond"
						label-for="party_time_schedule__c"
						class="border-bottom pb-3"
						:class="getInputClass('party_time_schedule__c')"
					>
						<template slot="label">
							Indeling van het {{ partyTypeName }}
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Is er een etentje? Komen
								er stukjes? Andere zaken die van belang zijn voor ons?
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.party_time_schedule__c"
								@input.native="delayUpdateWishes('party_time_schedule__c')"
								rows="5"
								maxlength="500"
								id="party_time_schedule__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
					<b-form-group
						label="Afsluiting van de avond"
						label-for="party_time_schedule_closing__c"
						class="border-bottom pb-3"
						:class="getInputClass('party_time_schedule_closing__c')"
					>
						<template slot="label">
							Afsluiting van het {{ partyTypeName }}
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Is er een specifiek
								laatste nummer? Mag dit naar eigen inzicht van de DJ? Andere manier?
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.party_time_schedule_closing__c"
								@input.native="delayUpdateWishes('party_time_schedule_closing__c')"
								rows="5"
								maxlength="500"
								id="party_time_schedule_closing__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
					<b-form-group
						label="Omschrijving van de gasten"
						label-for="average_age_of_the_guests__c"
						class="border-bottom pb-3"
						:class="getInputClass('average_age_of_the_guests__c')"
					>
						<template slot="label">
							Omschrijving van de gasten
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Bijvoorbeeld leeftijd,
								muzieksmaken, etc
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.average_age_of_the_guests__c"
								@input.native="delayUpdateWishes('average_age_of_the_guests__c')"
								rows="5"
								maxlength="500"
								id="average_age_of_the_guests__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="showYourDjTabContent">
					<h3>
						<strong><span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> DJ </strong>
					</h3>
					<b-form-group
						label="Is er een maaltijd beschikbaar voor de DJ?"
						label-for="meal_available_for_DJ__c"
						class="border-bottom pb-3"
					>
						<template slot="label">
							Is er een maaltijd beschikbaar voor de DJ?
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> We rekenen niet op een
								maaltijd, maar het komt regelmatig voor dat er er een hapje meegegeten kan worden of een
								artiestenmaaltijd wordt geserveerd. We waarderen dit enorm en houden er dan graag rekening mee! Indien
								dit het geval is, stemt de DJ nog even definitief af of hij er gebruik van wenst te maken in het
								telefoongesprek vóór het feest.</b-form-text
							>
						</template>
						<div class="row row-cols-1 row-cols-md-2 mx-n1">
							<b-form-radio
								button
								v-model="wishes.meal_available_for_DJ__c"
								@change.native="updateWishes('meal_available_for_DJ__c')"
								:value="true"
								name="meal_available_for_DJ__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('meal_available_for_DJ__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Ja" secondary="De DJ stemt dit nog definitief af" icon="utensils" />
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.meal_available_for_DJ__c"
								@change.native="updateWishes('meal_available_for_DJ__c')"
								:value="false"
								name="meal_available_for_DJ__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('meal_available_for_DJ__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Nee" secondary="Dit is helaas niet mogelijk" icon="utensils-slash" />
							</b-form-radio>
						</div>
					</b-form-group>

					<b-form-group
						label="Hoe zie je het microfoongebruik van de DJ voor je?"
						label-for="dj_microphone_use__c"
						class="border-bottom pb-3"
					>
						<template slot="label">
							<span v-if="isWedding">Hoe zien jullie het microfoongebruik van de DJ voor je?</span>
							<span v-else>Hoe zie je het microfoongebruik van de DJ voor je?</span>
						</template>

						<div class="row row-cols-1 row-cols-md-2 mx-n1">
							<b-form-radio
								button
								v-model="wishes.dj_microphone_use__c"
								@change.native="updateWishes('dj_microphone_use__c')"
								value="Geen voorkeur"
								name="dj_microphone_use__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_microphone_use__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Geen voorkeur" secondary="Ik heb geen voorkeur" icon="volume-off" />
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.dj_microphone_use__c"
								@change.native="updateWishes('dj_microphone_use__c')"
								value="ik wil dat de DJ de microfoon niet tot nauwelijks gebruikt"
								name="dj_microphone_use__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_microphone_use__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="Niet of nauwelijks"
									secondary="Ik wil dat de DJ de microfoon niet tot nauwelijks gebruikt"
									icon="volume-xmark"
								/>
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.dj_microphone_use__c"
								@change.native="updateWishes('dj_microphone_use__c')"
								value="ik wil dat de DJ in overleg enkele dingen zegt/aankondigt"
								name="dj_microphone_use__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_microphone_use__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="In overleg"
									secondary="Ik wil dat de DJ in overleg enkele dingen zegt/aankondigt"
									icon="volume-low"
								/>
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.dj_microphone_use__c"
								@change.native="updateWishes('dj_microphone_use__c')"
								value="ik wil dat de DJ de microfoon veel gebruikt om het feest extra aan te jagen"
								name="dj_microphone_use__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_microphone_use__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="Veel"
									secondary="Ik wil dat de DJ de microfoon veel gebruikt om het feest extra aan te jagen"
									icon="volume-high"
								/>
							</b-form-radio>
						</div>
					</b-form-group>
					<b-form-group label="Voorkeur DJ kleding" label-for="dj_clothes__c" class="border-bottom pb-3">
						<div class="row row-cols-1 row-cols-md-3 mx-n1">
							<b-form-radio
								button
								v-model="wishes.dj_clothes__c"
								@change.native="updateWishes('dj_clothes__c')"
								value="Geen voorkeur"
								name="dj_clothes__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_clothes__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Geen voorkeur" icon="clothes-hanger" />
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.dj_clothes__c"
								@change.native="updateWishes('dj_clothes__c')"
								value="Formeel"
								name="dj_clothes__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_clothes__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Formeel" icon="user-tie" />
							</b-form-radio>
							<b-form-radio
								button
								v-model="wishes.dj_clothes__c"
								@change.native="updateWishes('dj_clothes__c')"
								value="Informeel"
								name="dj_clothes__c"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								:class="getInputClass('dj_clothes__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel radio primary="Informeel" icon="user-vneck" />
							</b-form-radio>
						</div>
					</b-form-group>
					<b-form-group
						label="Uw bereikbaarheid"
						label-for="when_should_dj_call__c"
						:class="getInputClass('when_should_dj_call__c')"
					>
						<template slot="label">
							<span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Je'" /> bereikbaarheid
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Wanneer
								<span v-if="isWedding" v-text="'zijn jullie'" /><span v-else v-text="'ben je'" /> (globaal) goed
								bereikbaar in de week voor het optreden? Degene van ons die komt draaien belt in de week voor het
								optreden.
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.when_should_dj_call__c"
								@input.native="delayUpdateWishes('when_should_dj_call__c')"
								rows="5"
								maxlength="500"
								id="when_should_dj_call__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="showEquipmentTabContent">
					<h3><strong>Opbouwtijd & apparatuur</strong></h3>
					<b-form-group>
						<template slot="label">
							<b-form-text class="text-left mb-3">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Het klaarzetten van de
								apparatuur duurt ongeveer 45 minuten tot 1,5 uur. Dit kan in alle stilte zodat
								<span v-if="isWedding" v-text="'jullie en jullie gasten'" /><span
									v-else
									v-text="'jij en jouw gasten'"
								/>
								er nauwelijks last van hebt. Soms moet de opbouw eerder plaatsvinden. Geef dat hier aan. Er zitten
								meestal kosten aan verbonden.
							</b-form-text>
						</template>
					</b-form-group>
					<b-form-group
						label="Kunnen we direct voorafgaand aan het feest opbouwen?"
						label-for="opbouw"
						class="border-bottom pb-3"
					>
						<div class="row row-cols-1 row-cols-md-2 mx-n1">
							<b-form-radio
								v-model="wishes.deviating_equipment_setup_time_required__c"
								button
								class="d-block mb-2 mdc-radio-check col px-1"
								button-variant="outline-tdc"
								name="deviating_equipment_setup_time_required__c"
								@change.native="
									updateWishes('deviating_equipment_setup_time_required__c'), (wishes.equipment_set_up_ready_at__c = '')
								"
								:value="false"
								:class="getInputClass('deviating_equipment_setup_time_required__c')"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="Ja"
									:secondary="'De ruimte is WEL direct voor ' + beginTime + ' beschikbaar.'"
									icon="check"
								/>
							</b-form-radio>
							<b-form-radio
								v-model="wishes.deviating_equipment_setup_time_required__c"
								button
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								name="deviating_equipment_setup_time_required__c"
								@change.native="updateWishes('deviating_equipment_setup_time_required__c')"
								:class="getInputClass('deviating_equipment_setup_time_required__c')"
								:value="true"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="Nee"
									:secondary="'De ruimte is NIET direct voor ' + beginTime + ' beschikbaar.'"
									icon="times"
								/>
								<img
									v-if="wishes.deviating_equipment_setup_time_required__c"
									class="position-absolute"
									alt=""
									src="~@/assets/images/arrow-bottom-left.png"
									style="bottom: -40px; right: 20px"
								/>
							</b-form-radio>
						</div>
					</b-form-group>
					<b-form-group
						v-if="wishes.deviating_equipment_setup_time_required__c"
						label="Alles moet eerder klaar staan om"
						label-for="equipment_set_up_ready_at__c"
						class="border-bottom pb-3"
						:class="getInputClass('equipment_set_up_ready_at__c')"
					>
						<div class="position-relative d-inline-block">
							<b-form-select
								v-if="wishes.deviating_equipment_setup_time_required__c"
								v-model="wishes.equipment_set_up_ready_at__c"
								@change.native="updateWishes('equipment_set_up_ready_at__c')"
								name="equipment_set_up_ready_at__c"
								class="w-100"
								style="min-width: 200px"
								:disabled="isConfirmed"
							>
								<b-form-select-option v-for="time in times" :key="time" :value="time">{{ time }}</b-form-select-option>
							</b-form-select>
							<InputFeedback />
						</div>

						<b-alert variant="info" show class="mt-3"
							><small>
								<span v-if="isWedding" v-text="'Weten jullie'" /><span v-else v-text="'Weet je'" /> zeker dat we eerder
								klaar moeten staan? Dit betekent meestal ook extra kosten en daarover
								<span v-if="isWedding" v-text="'krijgen jullie'" /><span v-else v-text="'krijg je'" /> nog bericht.
								<span v-if="isWedding" v-text="'Jullie huren'" /><span v-else v-text="'Je huurt'" /> de apparatuur
								namelijk langer en er worden meer uren gemaakt door de DJ.
							</small>
						</b-alert>
					</b-form-group>
					<b-form-group
						label="Wil je je eigen muziek/geluid aansluiten?"
						label-for="Connect_client_music_source__c"
						class="border-bottom pb-3"
					>
						<template slot="label">
							<span v-if="isWedding">Willen jullie je eigen muziek/geluid aansluiten?</span>
							<span v-else>Wil je je eigen muziek/geluid aansluiten?</span>
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Bijvoorbeeld voor een
								presentatie of een stukje. <span v-if="isWedding" v-text="'Willen jullie'" /><span
									v-else
									v-text="'Wil je'"
								/>
								bepaalde nummer(s) afspelen, geef deze dan door bij de favorieten top 10. Het is namelijk niet raadzaam
								om gedurende de avond veel af te spelen vanaf een eigen apparaat.
							</b-form-text>
						</template>
						<div class="row row-cols-1 row-cols-md-2 mx-n1">
							<b-form-radio
								button
								@change.native="updateWishes('Connect_client_music_source__c')"
								button-variant="outline-tdc"
								class="mb-2 mdc-radio-check col px-1"
								name="Connect_client_music_source__c"
								v-model="wishes.Connect_client_music_source__c"
								:class="getInputClass('Connect_client_music_source__c')"
								:value="true"
								:disabled="isConfirmed"
							>
								<OptionLabel
									radio
									primary="Ja"
									secondary="De DJ stemt de mogelijkheden nog even af"
									icon="mp3-player"
								/>
							</b-form-radio>
							<b-form-radio
								button
								@change.native="updateWishes('Connect_client_music_source__c')"
								button-variant="outline-tdc"
								class="d-block mb-2 mdc-radio-check col px-1"
								name="Connect_client_music_source__c"
								v-model="wishes.Connect_client_music_source__c"
								:class="getInputClass('Connect_client_music_source__c')"
								:value="false"
								:disabled="isConfirmed"
							>
								<font-awesome-layers class="option-icon-primary" fixed-width>
									<font-awesome-icon :icon="['far', 'slash']" style="opacity: 0.9" />
									<font-awesome-icon :icon="['far', 'mp3-player']" />
								</font-awesome-layers>
								<OptionLabel radio primary="Nee" secondary="Eigen muziek/geluid aansluiten is niet nodig" />
							</b-form-radio>
						</div>
					</b-form-group>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="showWeddingTabContent">
					<h3><strong>Openingsdans</strong></h3>

					<b-form-group
						label="Openingsdans"
						label-for="first_dance_song__c"
						class="border-bottom pb-3"
						:class="getInputClass('first_dance_song__c')"
					>
						<template slot="label">
							Vertel ons over jullie openingsdans
							<b-form-text class="text-left">
								<font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Alles wat jullie moeten
								weten over de openingsdans:
								<a target="_blank" :href="'https://thedjcompany.' + siteDomainExtention + '/bruiloft-dj/openingsdans/'"
									>Lees de blog door onze experts!</a
								>. Laat ons precies weten welk nummer en welke versie. Mogelijk is het verstandig om een muziekbestand
								bij ons aan te leveren (zeker als jullie een speciaal nummer of mix hebben). Email naar
								<a :href="'mailto:' + infoEmailAddress">{{ infoEmailAddress }}</a
								>. Groot bestand? Gebruik WeTransfer (www.wetransfer.com)
							</b-form-text>
						</template>
						<div class="position-relative">
							<b-form-textarea
								v-model="wishes.first_dance_song__c"
								@input.native="delayUpdateWishes('first_dance_song__c')"
								rows="5"
								maxlength="500"
								id="first_dance_song__c"
								placeholder=" "
								:disabled="isConfirmed"
							></b-form-textarea>
							<InputFeedback />
						</div>
					</b-form-group>
				</div>
			</transition>
			<div class="linear-subnav d-flex flex-row justify-content-between position-relative">
				<router-link
					v-if="showOverviewTabContent"
					:to="{name: 'PartyWishes', hash: '#music'}"
					class="btn btn-primary d-flex justify-content-center mx-auto ml-sm-auto mr-sm-0"
				>
					Geef wensen door <font-awesome-icon class="ml-2 mt-1" :icon="['far', 'arrow-right']" />
				</router-link>
				<div v-if="backToButton" id="popover-left">
					<router-link
						:is="unsavedWishData ? 'span' : 'router-link'"
						:to="{name: 'PartyWishes', hash: backToButtonHash}"
						class="btn btn-outline-primary btn-outline-primary-white-bg border-primary d-flex justify-content-center mr-1"
						:class="{disabled: unsavedWishData}"
					>
						<font-awesome-icon class="mr-2 mt-1" :icon="['far', 'arrow-left']" /> {{ backToButtonHashLabel }}
					</router-link>
					<b-popover
						target="popover-left"
						triggers="click blur"
						placement="bottomright"
						:disabled.sync="disableLinearNavPopover"
						variant="primary"
					>
						<small v-if="unsavedWishData">
							<font-awesome-icon :icon="['fad', 'spinner-third']" spin class="text-primary" />
							<strong class="font-weight-bold text-primary"> Een moment svp.</strong>
							<span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> feestwensen worden opgeslagen.
						</small>
						<small v-else>
							<strong class="font-weight-bold text-success"> Gelukt!</strong>
							<span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> feestwensen zijn opgeslagen.
							<span v-if="isWedding" v-text="'Jullie kunnen'" /><span v-else v-text="'Je kunt'" /> door klikken.
						</small>
					</b-popover>
				</div>
				<div v-if="goToButton" id="popover-right">
					<router-link
						:is="unsavedWishData ? 'span' : 'router-link'"
						:to="{name: 'PartyWishes', hash: goToButtonHash}"
						class="btn btn-outline-primary btn-outline-primary-white-bg border-primary d-flex justify-content-center ml-1"
						:class="[{'ml-auto': !backToButton}, {disabled: unsavedWishData}]"
					>
						{{ goToButtonHashLabel }} <font-awesome-icon class="ml-2 mt-1" :icon="['far', 'arrow-right']" />
					</router-link>
					<b-popover
						target="popover-right"
						triggers="click blur"
						placement="bottomleft"
						:disabled.sync="disableLinearNavPopover"
						variant="primary"
					>
						<small v-if="unsavedWishData">
							<font-awesome-icon :icon="['fad', 'spinner-third']" spin class="text-primary" />
							<strong class="font-weight-bold text-primary"> Een moment svp.</strong>
							<span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> feestwensen worden opgeslagen.
						</small>
						<small v-else>
							<strong class="font-weight-bold text-success"> Gelukt!</strong>
							<span v-if="isWedding" v-text="'Jullie'" /><span v-else v-text="'Jouw'" /> feestwensen zijn opgeslagen.
							<span v-if="isWedding" v-text="'Jullie kunnen'" /><span v-else v-text="'Je kunt'" /> door klikken.
						</small>
					</b-popover>
				</div>
			</div>
		</b-form>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Alert from "@/components/customer/Alert";
import OptionLabel from "@/components/customer/OptionLabel";
import InputFeedback from "@/components/customer/InputFeedback";
import BackTo from "@/components/customer/BackTo";
import ProgressBarWishes from "@/components/customer/ProgressBarWishes";
import VueMultiselect from "vue-multiselect";
import draggable from "vuedraggable";
import countUp from "vue-countup-v2";

export default {
	name: "PartyWishes",
	components: {
		FontAwesomeIcon,
		Alert,
		OptionLabel,
		InputFeedback,
		VueMultiselect,
		draggable,
		countUp,
		BackTo,
		ProgressBarWishes,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isConfirmed: "isConfirmed",
			isLoading: "getLoadingState",
			partyType: "getPartyType",
			loadingInput: "getLoadingInput",
			spotifyData: "getSearchData",
			hasPartyDatePassed: "hasPartyDatePassed",
			isBelgian: "isBelgian",
			isWedding: "isWedding",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
			infoEmailAddress: "getInfoEmailAddress",
		}),
		partyTypeName() {
			if (this.isWedding) return "bruiloftsfeest";
			else if (this.isBirthday) return "verjaardagsfeest";
			else if (this.isBusinessParty) return "bedrijfsfeest";
			return "feest";
		},
		dragOptions() {
			return {
				animation: 0,
				disabled: false,
				ghostClass: "ghost",
				handle: ".drag-area",
			};
		},
		canEditWishes: function () {
			return this.editingWishes == 0 && !this.isConfirmed && !this.isLoading;
		},
		beginTime: function () {
			return this.booking.begin_time__c === "n.t.b." ? "de begintijd" : this.booking.begin_time__c;
		},
		partyTypeTitle: function () {
			if (
				this.booking.birthday_name__c &&
				this.booking.birthday_name__c != "undefined" &&
				this.booking.birthday_name__c != "" &&
				this.partyType != ""
			)
				return this.partyType + " van " + this.booking.birthday_name__c;
			if ((this.booking.birthday_name__c == "undefined" || !this.booking.birthday_name__c) && this.partyType != "")
				return this.partyType;
			return "Feest";
		},
		times: function () {
			var times = [];
			for (var i = 0; i <= 48; i++) {
				var n = i % 2 == 0 ? i / 2 + ".00" : (i + 1) / 2 - 1 + ".30";
				if (n < 10) n = "0" + n;
				times.push(n.replace(".", ":"));
			}
			return times;
		},
		songs: function () {
			return Object.keys(this.wishes).filter((key) => key.includes("Top_10_song_") && this.wishes[key] != "");
		},
		emptySongSlots: function () {
			if (this.wishes.Top_10_songs.length >= 10) return 0;
			else return 10 - this.wishes.Top_10_songs.length;
		},
		showOverviewTabContent: function () {
			return this.$route.hash == "";
		},
		showMusicTabContent: function () {
			var musicHash = this.$route.hash === "#music";
			if (musicHash) this.$store.dispatch("initClient");
			return musicHash;
		},
		showPartyTypeTabContent: function () {
			return this.$route.hash === "#partydetails";
		},
		showYourDjTabContent: function () {
			return this.$route.hash === "#dj";
		},
		showWeddingTabContent: function () {
			return this.$route.hash === "#firstdance";
		},
		showEquipmentTabContent: function () {
			return this.$route.hash === "#equipment";
		},
		hasSongsList() {
			return this.wishes.Top_10_songs;
		},
		disableSpotifySearch() {
			return this.emptySongSlots == 0;
		},
		searchSpotifyPlaceholder() {
			if (this.emptySongSlots == 0) return "Er zijn al 10 keuzes gemaakt";
			return "Zoek een nummer of artiest";
		},
		goToButton() {
			return this.showEquipmentTabContent || this.showOverviewTabContent ? false : true;
		},
		goToButtonHash() {
			if (this.showOverviewTabContent) {
				return "#music";
			} else if (this.showMusicTabContent) {
				return "#partydetails";
			} else if (this.showPartyTypeTabContent) {
				if (this.isWedding) {
					return "#firstdance";
				} else {
					return "#dj";
				}
			} else if (this.showWeddingTabContent) {
				return "#dj";
			} else if (this.showYourDjTabContent) {
				return "#equipment";
			}
			return "";
		},
		goToButtonHashLabel() {
			if (this.showOverviewTabContent) {
				return "Muziek";
			} else if (this.showMusicTabContent) {
				return "Feestdetails";
			} else if (this.showPartyTypeTabContent) {
				if (this.isWedding) {
					return "Openingsdans";
				} else {
					return "Jouw DJ";
				}
			} else if (this.showWeddingTabContent) {
				return "Jullie DJ";
			} else if (this.showYourDjTabContent) {
				return "Opbouw & Apparatuur";
			}
			return "";
		},
		backToButton() {
			return this.showOverviewTabContent ? false : true;
		},
		backToButtonHash() {
			if (this.showMusicTabContent) {
				return "";
			} else if (this.showPartyTypeTabContent) {
				return "#music";
			} else if (this.showWeddingTabContent) {
				return "#partydetails";
			} else if (this.showYourDjTabContent) {
				if (this.isWedding) {
					return "#firstdance";
				} else {
					return "#partydetails";
				}
			} else if (this.showEquipmentTabContent) {
				return "#dj";
			}
			return "";
		},
		backToButtonHashLabel() {
			if (this.showMusicTabContent) {
				return "Overzicht";
			} else if (this.showPartyTypeTabContent) {
				return "Muziek";
			} else if (this.showWeddingTabContent) {
				return "Feestdetails";
			} else if (this.showYourDjTabContent) {
				if (this.isWedding) {
					return "Openingsdans";
				} else {
					return "Feestdetails";
				}
			} else if (this.showEquipmentTabContent) {
				if (this.isWedding) {
					return "Jullie DJ";
				} else {
					return "Jouw DJ";
				}
			}
			return "";
		},
		siteDomainExtention: function () {
			return this.isBelgian ? "be" : "nl";
		},
	},
	data() {
		return {
			wishesTabViewCount: 0,
			highlightClass: "",
			showAddedBox: false,
			loadingSpotifyOptions: false,
			spotifyOptions: [],
			justSelectedSong: [],
			searchSpotifyText: "",
			queryText: "",
			customAge: "",
			customGenres: false,
			editingWishes: 0,
			delay: 0,
			unsavedWishData: false,
			disableLinearNavPopover: true,
			wishes: {
				birthday_age__c: "",
				birthday_name__c: "",
				reason_party__c: "",
				perfect_night__c: "",
				party_time_schedule__c: "",
				party_time_schedule_closing__c: "",
				average_age_of_the_guests__c: "",
				music_custom_genres__c: "",
				music_request_comments__c: "",
				music_styles_or_artists_not_wanted__c: "",
				Connect_client_music_source__c: false,
				meal_available_for_DJ__c: false,
				dj_microphone_use__c: false,
				first_dance_song__c: "",
				honeymoon_destination__c: "",
				Top_10_songs: [],
				Top_10_songs__c: "",
				Top_10_song_1__c: "",
				Top_10_song_2__c: "",
				Top_10_song_3__c: "",
				Top_10_song_4__c: "",
				Top_10_song_5__c: "",
				Top_10_song_6__c: "",
				Top_10_song_7__c: "",
				Top_10_song_8__c: "",
				Top_10_song_9__c: "",
				Top_10_song_10__c: "",
				dj_clothes__c: "",
				when_should_dj_call__c: "",
				equipment_set_up_ready_at__c: "",
				deviating_equipment_setup_time_required__c: false,
				music_genre_80s__c: true,
				music_genre_90s__c: true,
				music_genre_00s__c: true,
				music_genre_10s__c: true,
				music_genre_top40__c: true,
				music_genres: [],
				music_genres__c: "",
			},
			huwelijkreis: false,
			birthday_age_options: ["18", "30", "40", "50", "60"],
			age: "",
			counterOptions: {
				useEasing: true,
				useGrouping: true,
				separator: ",",
				decimal: ".",
				prefix: "",
				suffix: "",
			},
		};
	},
	methods: {
		selectSpotifyOption(data) {
			this.justSelectedSong = [];
			var newSong = {};
			newSong["type"] = data.type;
			newSong["name"] = data.name;
			if (data.type == "artist" && data.images[0]) {
				newSong["image"] = data.images[0].url;
			} else if (data.type == "track" && data.album.images[0]) {
				newSong["image"] = data.album.images[0].url;
			}
			if (data.type == "track") {
				newSong["artist"] = data.artists[0].name;
				newSong["album"] = data.album.name;
				newSong["album_year"] = data.album.release_date.substring(0, 4);
			}
			this.wishes.Top_10_songs.push(newSong);
			this.justSelectedSong = newSong;
			this.showAddedBox = true;
			document.activeElement.blur();
			clearTimeout(this.delay);
			this.delay = setTimeout(
				function () {
					this.showAddedBox = false;
				}.bind(this),
				1800
			);
		},
		removeSpotifyOption: function (index) {
			this.wishes.Top_10_songs.splice(index, 1);
		},
		clearSpotifyOptions() {
			this.spotifyOptions = [];
		},
		querySpotify(val) {
			if (val.length >= 1 && val.length <= 30) {
				this.loadingSpotifyOptions = true;
				this.queryText = val;
				clearTimeout(this.delay);
				this.delay = setTimeout(
					function () {
						this.$store.dispatch("searchData", val).then(() => {
							this.updateSpotifyOptions();
							this.loadingSpotifyOptions = false;
						});
					}.bind(this),
					1500
				);
			}
		},
		scrollToSpotify() {
			var spotifyElement = document.getElementById("spotify-search");
			this.highlightClass = "highlight";
			window.scrollTo({
				top: spotifyElement.offsetTop,
				left: 0,
				behavior: "smooth",
			});
			this.delay = setTimeout(
				function () {
					this.$refs.spotifyInput.$el.focus();
				}.bind(this),
				1000
			);
			this.delay = setTimeout(
				function () {
					this.highlightClass = "";
				}.bind(this),
				2000
			);
		},
		updateSpotifyOptions() {
			this.clearSpotifyOptions();
			this.spotifyOptions = this.spotifyData.artists.items.concat(this.spotifyData.tracks.items);
			this.spotifyOptions.sort((a, b) => b.popularity - a.popularity);
			this.loadingSpotifyOptions = false;
		},
		updateWishes(input) {
			this.$store.dispatch("postWish", {
				action: "updateWishes",
				data: this.wishes,
				setBooking: true,
				input: input,
			});
		},
		delayUpdateWishes(input) {
			this.setUnsavedWishData(true);
			clearTimeout(this.delay);
			this.delay = setTimeout(
				function () {
					this.updateWishes(input);
				}.bind(this),
				2000
			);
		},
		getInputClass(input) {
			//var value = this.wishes[input]
			if (input == this.loadingInput.name) {
				if (this.loadingInput.status == "saving") return "input-saving";
				else if (this.loadingInput.status == "saved") {
					this.setUnsavedWishData(false);
					this.delay = setTimeout(
						function () {
							this.$root.$emit("bv::hide::popover");
						}.bind(this),
						4000
					);
					return "input-saved";
				}
			}
			return "";
		},
		setUnsavedWishData(boelean) {
			this.unsavedWishData = boelean;
			this.disableLinearNavPopover = !boelean;
		},
		addSong() {
			const count = this.songs.length + 1;
			this.wishes["Top_10_song_" + count + "__c"] = "Nummer + artiest";
		},
		askCustomAge: function (ask) {
			if (ask == true) this.customAge = true;
			if (ask == false) this.customAge = false;
			if (this.customAge == true) this.wishes.birthday_age__c = "";
		},
		askCustomGenres: function (ask) {
			if (ask == true) this.customGenres = true;
			if (ask == false) {
				this.customGenres = false;
				this.wishes.music_custom_genres__c = "";
				this.updateWishes("music_custom_genres__c");
			}
		},
		suggestionImage: function (images) {
			if (images.length > 0) return images[0].url;
			return "";
		},
		top10SongClass(type) {
			if (type == "artist") return "top-10-song-artist";
			else if (type == "track") return "top-10-song-track";
			return "top-10-song-unknown";
		},
		emptySongObject(name) {
			var songObject = {};
			songObject["name"] = name;
			return songObject;
		},
	},
	mounted() {
		if (
			!this.showOverviewTabContent &&
			!this.showMusicTabContent &&
			!this.showWeddingTabContent &&
			!this.showEquipmentTabContent &&
			!this.showYourDjTabContent &&
			!this.showPartyTypeTabContent
		) {
			this.$router.push({name: "PartyWishes"});
		}
		if (window.sessionStorage) {
			sessionStorage.setItem("wishesTabViewCount", parseInt(sessionStorage.getItem("wishesTabViewCount")) + 1);
			this.wishesTabViewCount = parseInt(sessionStorage.getItem("wishesTabViewCount"));
		}
		Object.keys(this.wishes).forEach((key) => {
			if (key in this.booking) this.wishes[key] = this.booking[key];
		});
		if ("honeymoon_destination__c" in this.booking) this.huwelijkreis = true;
		if (this.wishes.birthday_age__c == "") {
			this.customAge = false;
		} else if (this.birthday_age_options.indexOf(this.wishes.birthday_age__c) === -1) {
			this.customAge = true;
		}
		this.initUser;
		if (this.wishes.Top_10_songs__c == "") {
			this.songs.forEach((song) => {
				var existingSong = {};
				existingSong["name"] = this.wishes[song];
				this.wishes.Top_10_songs.push(existingSong);
			});
			this.wishes.Top_10_songs__c = JSON.stringify(this.wishes.Top_10_songs);
			this.updateWishes("Top_10_songs__c");
		} else {
			this.wishes.Top_10_songs = JSON.parse(this.wishes.Top_10_songs__c);
		}
		if (this.wishes.music_custom_genres__c != "") {
			this.customGenres = true;
		}
	},
	filters: {
		capitalize: function (value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
	},
	watch: {
		hasSongsList: function () {
			this.wishes.Top_10_songs__c = JSON.stringify(this.wishes.Top_10_songs);
			this.updateWishes("Top_10_songs__c");
		},
	},
};
</script>

<style lang="scss">
.moc {
	.linear-subnav {
		@extend .p-sm-2;
		@extend .rounded;
		@include media-breakpoint-up(sm) {
			background-color: #ececec;
		}
	}
	.chosen {
		opacity: 0;
		z-index: -1;
	}
	.chosen-enter-active {
		animation: slide-in-and-out 2s;
		z-index: 9;
	}
	.chosen-leave-active {
		transform: translateY(-100px);
		opacity: 0;
	}
	@keyframes slide-in-and-out {
		0% {
			opacity: 0;
			transform: translateY(-100px);
		}
		48% {
			opacity: 0.9;
			transform: translateY(-75px);
		}
		62% {
			opacity: 0.9;
			transform: translateY(-75px);
		}
		100% {
			opacity: 0;
			transform: translateY(150px);
		}
	}
	.delete-link {
		padding: 2px 8px;
		border-radius: 5px;
		&:hover {
			background-color: #d91010;
			color: #fff !important;
			svg {
				color: #fff !important;
			}
		}
	}
	.top-10-songs {
		min-heigh: 30px;
	}
	.flip-list-move {
		transition: transform 0.5s;
	}
	.no-move {
		transition: transform 0s;
	}
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
		border-style: dashed !important;
		background-color: #eff0bd !important;
	}
	.top-10-song {
		line-height: 18px;
		@include media-breakpoint-up(md) {
			line-height: 24px;
		}
		@include media-breakpoint-up(lg) {
			line-height: 30px;
		}
		.top-10-song-title {
			font-size: 18px;
			@include media-breakpoint-up(md) {
				font-size: 22px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 28px;
			}
		}
		.top-10-song-subtitle {
			font-size: 12px;
			@include media-breakpoint-up(md) {
				font-size: 16px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 18px;
			}
		}
		.top-10-song-image {
			height: 50px;
			width: 50px;
			margin-right: 10px;
			background-size: cover;
		}
	}
	.music-genre-options {
		.option-icon-secondary {
			z-index: 4;
		}
		.option-label {
			margin-left: 90px;
			@include media-breakpoint-up(md) {
				margin-left: 110px;
			}
			@include media-breakpoint-up(lg) {
				margin-left: 120px;
			}
			@include media-breakpoint-up(xl) {
			}
		}
	}
	.music-genre-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		z-index: 1;
		background-size: contain;
		background-repeat: no-repeat;
		opacity: 0.3;
		filter: grayscale(100%);
		background-position: left -35px top 0px;
		@include media-breakpoint-up(md) {
			background-position: left -15px top 0px;
		}
		&.genre-bg-80s {
			background-image: url("~@/assets/images/genre-bg-80s.png");
		}
		&.genre-bg-90s {
			background-image: url("~@/assets/images/genre-bg-90s.png");
		}
		&.genre-bg-00s {
			background-image: url("~@/assets/images/genre-bg-00s.png");
		}
		&.genre-bg-10s {
			background-image: url("~@/assets/images/genre-bg-10s.png");
		}
		&.genre-bg-top40 {
			background-image: url("~@/assets/images/genre-bg-top40.png");
		}
		&.genre-bg-other {
			background-image: url("~@/assets/images/genre-bg-other.png");
		}
	}
	.active {
		.music-genre-bg {
			filter: grayscale(0%);
			opacity: 0.8;
		}
	}
	.top-10-song-artist {
		.top-10-song-image {
			border-radius: 50%;
		}
	}
	.top-10-song-track {
		.top-10-song-image {
			border-radius: 4px;
		}
	}
	.top-10-song-empty {
		cursor: pointer;
		border: 1px dashed #ccc !important;
		.card-body {
			background-color: #ececec;
		}
		.top-10-song-title,
		svg {
			transition: color 0.4s ease-out;
			color: #ccc !important;
		}
		&:hover {
			border-color: $tdc-blue !important;
			.card-body {
				background-color: #fff !important;
				.top-10-song-title {
					color: $tdc-blue !important;
				}
				svg {
					color: $tdc-blue !important;
				}
			}
		}
	}
	.multiselect__tags {
		font-size: 16px;
		padding-top: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 1px;
		height: 44px;
	}
	.multiselect__placeholder {
		margin: 0;
		padding: 0;
		padding-left: 12px;
		height: 40px;
		font-size: 16px;
		margin-bottom: 1px;
		@extend .d-flex;
		@extend .align-items-center;
	}
	.multiselect__input {
		margin: 0;
		padding: 0;
		height: 40px;
		padding-left: 12px;
	}
	.multiselect__option--highlight {
		background-color: #ececec;
	}
	.multiselect__option--highlight:after {
		background-color: #ececec;
		color: $tdc-blue;
		height: 100%;
		display: none !important;
	}
	.spotify-suggestion-item {
		.spotify-suggestion-item-image {
			height: 50px;
			width: 50px;
			margin-right: 10px;
			background-size: cover;
		}
		&.spotify-suggestion-item-track {
			.spotify-suggestion-item-image {
				border-radius: 4px;
			}
		}
		&.spotify-suggestion-item-artist {
			.spotify-suggestion-item-image {
				border-radius: 50%;
			}
		}
	}
	.disable-spotify {
		background: rgba($tdc-body-color, 0.6);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 8;
	}
	.btn-outline-primary,
	.btn-outline-dark {
		background-color: #fff;
	}
	input[type="text"]:not(:placeholder-shown),
	input[type="number"]:not(:placeholder-shown),
	input[type="email"]:not(:placeholder-shown),
	textarea:not(:placeholder-shown) {
		border-color: $tdc-green;
	}
	.form-group {
		font-size: 1.1rem;
	}
	@include media-breakpoint-up(lg) {
		h3 {
			margin-bottom: 25px;
		}
	}
	.slide-fade-enter-active {
		transition: all 0.8s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateX(20px);
		opacity: 0;
	}
	.bounce-enter-active {
		animation: bounce-in 0.7s;
	}
	.bounce-leave-active {
		animation: bounce-in 0.7s reverse;
	}
	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.05);
		}
		100% {
			transform: scale(1);
		}
	}
	.highlight {
		animation: highlight-fade 1.2s;
	}
	@keyframes highlight-fade {
		0% {
			background-color: #ebd99c;
			transform: scale(1.15);
		}
		100% {
			background: none;
			transform: scale(1);
		}
	}
}
</style>
