<template>
	<div>
		<div
			class="mb-3 bg-blue d-flex d-lg-none flex-row align-items-center rounded justify-content-between"
			style="
				background: rgb(0, 159, 238);
				background: linear-gradient(111deg, rgba(0, 159, 238, 1) 65%, rgba(91, 196, 247, 1) 98%);
			"
		>
			<div class="mr-md-3 p-2 pl-md-4">
				<strong v-if="'Account__r' in booking" class="text-white"
					>Welkom <span v-text="booking.Account__r.FirstName" /> in <span v-if="isWedding" v-text="'jullie'" /><span
						v-else
						v-text="'jouw'"
					/>
					persoonlijke Mijn DJ Company</strong
				><br />
				<small class="text-white">{{ partyType | capitalize }}: {{ booking.Name }}</small>
			</div>
			<img
				src="~@/assets/images/tette-cutout.png"
				data-was-processed="true"
				style="max-height: 100px"
				class="pt-1 pr-2"
			/>
		</div>

		<div class="overview-steps-wrapper d-flex flex-column flex-md-row justify-content-between mb-3">
			<div v-if="!isBookingByVenue" class="overview-steps mb-3 mb-md-0">
				<div class="d-flex h-100 align-items-center justify-content-center justify-content-lg-start">
					<div class="p-2 pl-3 p-md-4">
						<div class="mb-3 d-flex justify-content-start w-100 unique-titles">
							<font-awesome-icon class="ml-n1 mr-2 mt-1 text-primary" :icon="['far', 'calendar']" />
							<div class="w-100">
								<strong>DJ Huren</strong>
							</div>
						</div>
						<BookingSteps v-if="!isBookingByVenue" />
					</div>
				</div>
			</div>
			<div class="overview-wishes" :class="{'w-100': isBookingByVenue}">
				<div class="d-flex w-100; h-100 align-items-center" :class="{'justify-content-center ': isBookingByVenue}">
					<div class="row">
						<div class="col d-flex align-items-stretch">
							<div class="p-2 p-md-3 text-white rounded mb-3 d-flex justify-content-start w-100">
								<font-awesome-icon class="text-white mr-2 mt-1 unique-titles" :icon="['fas', 'music']" />
								<div class="w-100">
									<strong class="unique-titles">Feestwensen</strong>
									<p>
										Vul <span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> wensen in en laat het
										ons weten hoe <span v-if="isWedding" v-text="'jullie'" /><span v-else v-text="'jouw'" /> perfecte
										feest eruit ziet!
									</p>
									<p class="mb-0">
										<font-awesome-icon class="text-white mr-2 mt-1" :icon="['far', 'chevron-right']" />Ga
										<router-link class="text-white" :to="{name: 'PartyWishes', hash: '#music'}"
											>direct aan de slag!</router-link
										>
									</p>
									<ProgressBarWishes :tabViewCount="overviewTabViewCount" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row row-cols-1 row-cols-md-2">
			<div class="col d-flex align-items-stretch">
				<div
					class="overview-music p-2 pb-0 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100 position-relative"
					:class="{'order-1': isBookingByVenue}"
				>
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'music']" />
					<div class="w-100 d-flex flex-column">
						<div class="d-flex justify-content-between">
							<strong class="flex-grow-1">Muziek</strong>
							<b-form-text class="m-0 flex-shrink-1 d-flex justify-content-end align-items-center align-items-end">
								powered by <img src="~@/assets/images/logo-spotify.png" class="ml-1" style="max-width: 70px"
							/></b-form-text>
						</div>
						<div class="d-flex justify-content-between align-items-end">
							<div>
								<p>
									<span v-if="isWedding" v-text="'Hebben jullie'" /><span v-else v-text="'Heb je'" /> specifieke
									muziekwensen? Geef ze aan ons door!<br /><router-link :to="{name: 'PartyWishes', hash: '#music'}"
										><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />Muziekwensen
										aanvullen</router-link
									>
								</p>
							</div>
							<div class="h-100 d-flex align-items-end">
								<img src="~@/assets/images/artists-mobile.png" class="artists-mobile-img d-sm-none mr-n2 mb-n2" />
								<img
									src="~@/assets/images/artists-desktop.png"
									class="artists-mobile-img d-none d-sm-block d-md-none mr-n2 mb-n2"
								/>
							</div>
						</div>
						<img
							src="~@/assets/images/artists-desktop.png"
							class="mt-auto artists-desktop-img align-self-end w-100 mb-n3 mx-auto align-self-center d-none d-md-flex"
							style="max-width: 400px"
						/>
					</div>
				</div>
			</div>
			<div v-if="!isBookingByVenue" class="col d-flex align-items-stretch">
				<div class="py-2 py-md-3 bg-white rounded mb-3 d-flex flex-column justify-content-start w-100">
					<div class="d-flex flex-row">
						<font-awesome-icon class="pl-2 pl-md-3 text-primary mr-2 mt-1 mb-3" :icon="['far', 'map-marker-alt']" />
						<div class="d-flex flex-column">
							<div class="d-flex justify-content-between">
								<strong class="flex-grow-1"
									><span v-if="isWedding" v-text="'Jullie feestlocatie'" /><span v-else v-text="'Feestlocatie'" />
								</strong>
							</div>
						</div>
					</div>
					<div class="h-100" style="min-height: 170px">
						<div
							class="d-flex align-items-center h-100 background"
							:style="{backgroundImage: 'url(' + locationBackgroundImage + ')'}"
						>
							<div class="haze" style="z-index: 1"></div>
							<div v-if="hasLocation" class="px-3 px-md-3" style="z-index: 2">
								<div v-if="locationIsPrivate">
									<p class="lead text-white mb-0 font-weight-bolder">
										Privé feestlocatie<span v-if="hasGeo" v-text="' in ' + geo" />
									</p>
								</div>
								<div v-else>
									<p class="lead text-white mb-0 font-weight-bolder">
										{{ location.Name }}<span v-if="hasGeo" v-text="' in ' + geo" />
									</p>
								</div>
								<p class="text-white mb-0">
									<span v-if="!location.Location_private__c"
										>The DJ Company scoort een <strong v-html="locationRating"></strong> op deze locatie</span
									>
									<span v-if="location.Location_private__c"
										>The DJ Company scoort een <strong v-html="locationRating"></strong> op privé locaties</span
									><br /><span class="stars"><StarRating :rating="locationRating" class="d-inline" /></span> (<strong
										v-html="locationRating"
									></strong
									>/10)
								</p>
							</div>
							<div v-else class="px-3 px-md-3" style="z-index: 2">
								<p class="lead text-warning mb-0 font-weight-bolder">
									<font-awesome-icon class="mr-2" :icon="['fas', 'exclamation-triangle']" /> Feestlocatie nog niet
									bekend
								</p>
								<p class="text-white mb-0">
									De plaats en locatie van het feest is bij ons nog niet bekend. Vergeet niet om dit nog door te geven.
								</p>
							</div>
						</div>
					</div>
					<div class="pl-2 pl-md-3 mt-3">
						<p class="mb-0 mt-auto">
							<router-link :to="{name: 'PartyLocation'}"
								><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />
								<span v-if="hasLocation">
									<span v-if="isWedding" v-text="'Bekijk jullie locatie'" /><span
										v-else
										v-text="'Bekijk jouw locatie'"
									/>
								</span>
								<span v-else>
									<span v-if="isWedding" v-text="'Geef jullie feestlocatie door'" /><span
										v-else
										v-text="'Geef de feestlocatie door'"
									/>
								</span>
							</router-link>
						</p>
					</div>
				</div>
			</div>
			<div class="col d-flex align-items-stretch" :class="[[isBookingByVenue ? '' : 'col-md-12']]">
				<div class="overview-reviews p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'stars']" />
					<div class="w-100">
						<div class="d-flex justify-content-between">
							<div class="d-flex flex-column">
								<strong class="flex-grow-1">Klantervaringen</strong>
								<p>
									Lees meer dan {{ Math.floor(reviewTotals.NumberOfReviews__c / 100) * 100 }} klantervaringen over ons
								</p>
							</div>
							<div class="d-flex align-items-center">
								<img src="~@/assets/images/background-reviews-mobile.png" class="reviews-mobile-img d-sm-none" />
							</div>
						</div>
						<p class="mb-0">
							<router-link :to="{name: 'Reviews'}"
								><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />Bekijk onze
								klantervaringen</router-link
							>
						</p>
					</div>
				</div>
			</div>
			<div
				v-if="isDutch && !isBookingByVenue"
				class="col d-flex align-items-stretch"
				:class="{'order-2': isBookingByVenue}"
			>
				<div class="py-2 py-md-3 bg-white rounded mb-3 d-flex flex-column justify-content-start w-100">
					<div class="d-flex flex-row">
						<font-awesome-icon class="pl-2 pl-md-3 text-primary mr-2 mt-1 mb-3" :icon="['far', discountIcon]" />
						<div class="d-flex flex-column">
							<div class="d-flex justify-content-between">
								<strong class="flex-grow-1">{{ discountTitle }}</strong>
							</div>
						</div>
					</div>
					<div class="h-100" style="min-height: 170px">
						<div class="d-flex align-items-center h-100 background overview-discount">
							<div class="haze" style="z-index: 1"></div>
							<div class="px-3 px-md-3" style="z-index: 2">
								<p class="lead text-white mb-0 font-weight-bolder" v-text="discountSlogan" />
							</div>
						</div>
					</div>
					<div class="pl-2 pl-md-3 mt-3">
						<p class="mb-0 mt-auto">
							<router-link :to="{name: 'Discounts'}"
								><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" />
								Meer leuke kortingen
							</router-link>
						</p>
					</div>
				</div>
			</div>
			<div v-if="hasQuotes && !isBookingByVenue" class="col col-md-12 d-flex align-items-stretch order-md-last">
				<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'calendar-day']" />
					<div class="w-100">
						<div class="d-flex justify-content-between">
							<strong class="flex-grow-1">
								<span v-if="isWedding && quoteCount > 1" v-text="'Jullie offertes'" />
								<span v-else-if="isWedding && quoteCount == 1" v-text="'Jullie offerte'" />
								<span v-else-if="!isWedding && quoteCount > 1" v-text="'Jouw offertes'" />
								<span v-else-if="!isWedding && quoteCount == 1" v-text="'Jouw offerte'" />
							</strong>
						</div>
						<ul class="list-unstyled">
							<li
								v-for="(quote, index) in quotes.slice(0, 3)"
								:key="index"
								:class="{'dashed-border': quoteCount > 1}"
								class="flex-column d-flex rounded w-100 justify-content-start my-2 position-relative"
							>
								<QuoteSummaryItem :quote="quote" styleVariant="overviewList" />
							</li>
						</ul>
						<p v-if="quoteCount > 1" class="mb-0">
							<router-link :to="{name: 'Quotes'}"
								><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" /><span
									v-if="quoteCount > 3"
									>Bekijk alle {{ quoteCount }} offertes</span
								><span v-else>Bekijk alle offertes</span></router-link
							>
						</p>
					</div>
				</div>
			</div>
			<div v-if="!isBookingByVenue" :class="{'col-md-12': isBelgian}" class="col d-flex align-items-stretch">
				<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', contactDetailsIcon]" />
					<div class="d-flex flex-column w-100">
						<div class="d-flex justify-content-between">
							<strong class="flex-grow-1"
								><span v-if="isWedding" v-text="'Jullie gegevens'" /><span v-else v-text="'Jouw gegevens'" />
							</strong>
						</div>
						<div class="w-100 flex-fill">
							<p class="text-dark mb-0 pt-2 dashed-border" v-text="booking.Account__r.Name"></p>
							<p
								v-if="booking.Account__r.Phone"
								class="text-dark mb-0 dashed-border pt-2"
								v-text="booking.Account__r.Phone"
							></p>
							<p
								v-else-if="booking.X2nd_phone_main_contact__c"
								class="text-dark mb-0 dashed-border pt-2"
								v-text="booking.X2nd_phone_main_contact__c"
							></p>
							<p v-else class="mb-0 dashed-border pt-2"><span class="text-muted">Geen telefoonnummer bekend</span></p>
							<p class="text-dark mb-0 dashed-border pt-2" v-text="booking.Account__r.PersonEmail"></p>
						</div>
						<p class="mb-0 mt-auto pt-2">
							<router-link :to="{name: 'ContactDetails'}"
								><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'chevron-right']" /><span
									v-if="isWedding"
									v-text="'Bekijk jullie gegevens'"
								/><span v-else v-text="'Bekijk jouw gegevens'" />
							</router-link>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import ProgressBarWishes from "@/components/customer/ProgressBarWishes";
import QuoteSummaryItem from "@/components/customer/QuoteSummaryItem";
import StarRating from "@/components/customer/StarRating";
import BookingSteps from "@/components/customer/BookingSteps";

export default {
	name: "Overview",
	components: {FontAwesomeIcon, ProgressBarWishes, QuoteSummaryItem, StarRating, BookingSteps},
	computed: {
		...mapGetters({
			booking: "getBooking",
			location: "getLocation",
			quotes: "getQuotes",
			partyType: "getPartyType",
			wishesProgress: "getWishesProgress",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isOrderInProgress: "isOrderInProgress",
			isPaid: "getPaidStatus",
			isWedding: "isWedding",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
			hasPartyDate: "hasPartyDate",
			hasLocation: "hasLocation",
			hasGeo: "hasGeo",
			geo: "getGeo",
			reviewTotals: "getReviewTotals",
			userType: "getUserType",
			isBookingByVenue: "isBookingByVenue",
			locationMedia: "getLocationMedia",
			hasLocationMedia: "hasLocationMedia",
		}),
		hasQuotes() {
			return this.quoteCount > 0 ? true : false;
		},
		quoteCount() {
			return this.quotes.length;
		},
		singleQuoteId() {
			if (this.quoteCount == 1) return this.quotes[0].Id;
			return "";
		},
		locationIsPrivate() {
			return this.location.Location_private__c;
		},
		locationHasImage() {
			return this.hasLocation && "location_profile_thumb_url__c" in this.location;
		},
		locationBackgroundImage() {
			if (this.hasLocationMedia) {
				if (this.locationIsPrivate) return require("@/assets/images/dj-image.jpg");
				else if (this.hasLocationMedia) return this.locationMedia[0].FileLink__c;
				return require("@/assets/images/dj-image.jpg");
			}
			return require("@/assets/images/dj-image.jpg");
		},
		locationRating() {
			return "LocationReviewsForLocScore__c" in this.location
				? parseInt(this.location.LocationReviewsForLocScore__c.toFixed(1))
				: 9.2;
		},
		discountTitle() {
			return "Photobooth";
		},
		discountSlogan() {
			return "Huur een photobooth en ontvang gratis fotoprops t.w.v. 25,-";
		},
		discountBackgroundImage() {
			return require("@/assets/images/bg-a1H2p000009J3KHEA0.jpg");
		},
		discountIcon() {
			return "camera-retro";
		},
		contactDetailsIcon() {
			return this.isWedding ? "user-group" : "user";
		},
	},
	data() {
		return {
			overviewTabViewCount: 0,
		};
	},
	methods: {
		formatPartyDate(format) {
			return this.$date(this.booking.Party_Date__c).locale("nl").format(format);
		},
	},
	mounted() {
		if (this.hasLocation && !this.locationIsPrivate) this.$store.dispatch("getLocationMedia");
		if (window.sessionStorage) {
			sessionStorage.setItem("overviewTabViewCount", parseInt(sessionStorage.getItem("overviewTabViewCount")) + 1);
			this.overviewTabViewCount = parseInt(sessionStorage.getItem("overviewTabViewCount"));
		}
		this.quotes.sort(function (a, b) {
			var keyA = new Date(a.CreatedDate),
				keyB = new Date(b.CreatedDate);
			// Compare the 2 dates
			if (keyA > keyB) return -1;
			if (keyA < keyB) return 1;
			return 0;
		});
	},
	filters: {
		capitalize: function (value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
	},
};
</script>

<style lang="scss" scoped>
.steps {
	@extend .list-unstyled;
	@extend .mb-0;
	.step {
		.sub-step-bullet {
			left: -25px;
			top: 2px;
		}
	}
}

.overview-steps-wrapper {
	@include media-breakpoint-up(md) {
		background-color: #fff;
	}
	.overview-steps {
		background-color: #fff;
		@extend .rounded;
		@include media-breakpoint-up(md) {
			width: 60%;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}
	.overview-wishes {
		@extend .rounded;
		@extend .bg-blue;
		@include media-breakpoint-up(md) {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
			//width: 40%;
			background: rgb(0, 159, 238);
			background: linear-gradient(111deg, rgba(0, 159, 238, 1) 65%, rgba(91, 196, 247, 1) 98%);
		}
	}
}
.overview-music {
	background-image: url("~@/assets/images/background-dancing.jpg");
	background-position: bottom right;
	background-size: cover;
	overflow: hidden;
	background-repeat: no-repeat;
	.artists-mobile-img {
		height: 70px;
		@include media-breakpoint-up(sm) {
			height: 90px;
			padding-right: 15px;
		}
	}
}
.overview-discount {
	background-image: url("~@/assets/images/bg-a1H2p000009J3KHEA0.jpg");
}
.overview-reviews {
	@include media-breakpoint-up(sm) {
		background-image: url("~@/assets/images/background-reviews-desktop.png");
		background-position: center right -20px;
		background-size: 45%;
		background-repeat: no-repeat;
	}
	@include media-breakpoint-up(md) {
		background-position: center right 10px;
		background-size: 45%;
	}
	.reviews-mobile-img {
		top: 10px;
		position: relative;
		height: 60px;
		@media only screen and (min-width: 450px) {
			height: 80px;
		}
		@include media-breakpoint-up(md) {
		}
	}
}
.dashed-border {
	@extend .pb-2;
	border-bottom: 1px dashed #ccc;
}
.background {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	position: relative;
	background-color: #ccc;
}
.haze {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: rgba(#565756, 0.5);
}
.collapsed .point-up,
.not-collapsed .point-down {
	display: none;
}
</style>
