<template>
	<div v-if="isAvailable || isFilled">
		<PageTitle
			:title="$t('mol.reviewUsPage.title.Complete our evaluation')"
			:subTitle="subTitle"
			:breadcrumbs="[$t('mol.reviewUsPage.title.Evaluation')]"
		/>
		<InputItem
			ref="net_promoter_likert_scale__c"
			inputName="review.net_promoter_likert_scale__c"
			v-model="review.net_promoter_likert_scale__c"
			:inputLabel="$t('mol.reviewUsForm.string.Would you recommend us?')"
			:errorMessage="$t('mol.reviewUsForm.string.Please choose a value')"
			:error="checked && !review.net_promoter_likert_scale__c"
			inputType="rating"
			inputLabelAlwaysTop
			extraSpaceBottom
			:disabled="isFilled"
		/>
		<InputItem
			ref="ServiceCommunicationTDC__c"
			inputName="review.ServiceCommunicationTDC__c"
			v-model="review.ServiceCommunicationTDC__c"
			:inputLabel="$t('mol.reviewUsForm.string.Service and communication by The DJ Company')"
			:errorMessage="$t('mol.reviewUsForm.string.Please choose a value')"
			:error="checked && !review.ServiceCommunicationTDC__c"
			inputType="rating"
			inputLabelAlwaysTop
			extraSpaceBottom
			:disabled="isFilled"
		/>
		<InputItem
			ref="CoordinationCooperationTDC__c"
			inputName="review.CoordinationCooperationTDC__c"
			v-model="review.CoordinationCooperationTDC__c"
			:inputLabel="$t('mol.reviewUsForm.string.Coordination and cooperation by The DJ Company')"
			:errorMessage="$t('mol.reviewUsForm.string.Please choose a value')"
			:error="checked && !review.CoordinationCooperationTDC__c"
			inputType="rating"
			inputLabelAlwaysTop
			extraSpaceBottom
			:disabled="isFilled"
		/>
		<InputItem
			ref="dj_played_according_to_atmosphere_rating__c"
			inputName="review.dj_played_according_to_atmosphere_rating__c"
			v-model="review.dj_played_according_to_atmosphere_rating__c"
			:inputLabel="$t('mol.reviewUsForm.string.Music selection and responding to the atmosphere by the DJs')"
			:errorMessage="$t('mol.reviewUsForm.string.Please choose a value')"
			:error="checked && !review.dj_played_according_to_atmosphere_rating__c"
			inputType="rating"
			inputLabelAlwaysTop
			extraSpaceBottom
			:disabled="isFilled"
		/>
		<InputItem
			ref="dj_service_level_rating__c"
			inputName="review.dj_service_level_rating__c"
			v-model="review.dj_service_level_rating__c"
			:inputLabel="$t('mol.reviewUsForm.string.Customer friendliness and cooperation of the DJs')"
			:errorMessage="$t('mol.reviewUsForm.string.Please choose a value')"
			:error="checked && !review.dj_service_level_rating__c"
			inputType="rating"
			inputLabelAlwaysTop
			extraSpaceBottom
			:disabled="isFilled"
		/>
		<InputItem
			v-if="contactCount > 1"
			v-model="review.LocationContact__c"
			inputName="review.LocationContact__c"
			inputType="select"
			:inputLabel="$t('mol.reviewUsForm.label.Venue contact')"
			inputLabelAlwaysTop
			:choices="contactChoices"
			:disabled="isFilled"
		/>
		<DataItem
			v-else
			dataLabelAlwaysTop
			:dataLabel="$t('mol.reviewUsForm.label.Venue contact')"
			:dataValue="contacts[0].FirstName + ' ' + contacts[0].LastName"
		/>
		<Ahref v-if="isAvailable" @click.native="openNewContactPanel()">{{
			$t("mol.reviewUsForm.label.Add another venue contact")
		}}</Ahref>
		<InputItem
			inputName="review.evaluation_quote__c"
			v-model="review.evaluation_quote__c"
			:inputLabel="$t('mol.reviewUsForm.string.Your experience in 5 words')"
			inputLabelAlwaysTop
			extraSpaceBottom
			extraSpaceTop
			:disabled="isFilled"
		/>
		<InputItem
			inputName="review.location_general_comments__c"
			v-model="review.location_general_comments__c"
			:inputLabel="$t('mol.reviewUsForm.string.Your story about The DJ Company')"
			:inputSubLabel="$t('mol.reviewUsForm.string.For example')"
			inputType="textarea"
			inputLabelAlwaysTop
			:disabled="isFilled"
		/>
		<FormGridWrapper labelAlwaysTop v-if="isAvailable">
			<template slot="right">
				<Button icon="check" class="ml-auto w-full" :disabled="isFilled" @click.native="saveLocationEvaluation">
					{{ $t("mol.general.button.Save") }}
				</Button>
			</template>
		</FormGridWrapper>
		<SlideOutPanel v-if="!isFilled" :panelOpen="addVenueContact">
			<template slot="innerContent">
				<div class="absolute right-0">
					<button
						@click="closeNewContactPanel()"
						class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
					>
						<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
					</button>
				</div>
				<PageTitle
					:title="$t('mol.reviewUsForm.title.New contact')"
					:subTitle="$t('mol.reviewUsForm.title.Add a new contact')"
					:breadcrumbs="[$t('mol.reviewUsPage.title.Evaluation'), $t('mol.reviewUsForm.title.New contact')]"
				/>
				<ContactForm create :contact="newContact" :formErrors="contactFormErrors" />
				<FormGridWrapper>
					<template slot="right">
						<div class="w-full">
							<Button @click.native="saveContact(newContact)" icon="user-plus" class="w-full">
								{{ $t("mol.contactForm.button.Save new contact") }}
							</Button>
						</div>
					</template>
				</FormGridWrapper>
			</template>
		</SlideOutPanel>
	</div>
	<div v-else>
		<PageTitle
			:title="$t('mol.reviewUsPage.title.Complete our evaluation')"
			:subTitle="$t('mol.reviewUsPage.message.Evaluation not available')"
			:breadcrumbs="[$t('mol.reviewUsPage.title.Evaluation')]"
		/>
		{{ $t("mol.reviewUsPage.message.Evaluation not available") }}
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle.vue";
import FormGridWrapper from "@/components/location/FormGridWrapper.vue";
import InputItem from "@/components/location/InputItem.vue";
import DataItem from "@/components/location/DataItem.vue";
import Button from "@/components/location/Button.vue";
import Ahref from "@/components/location/Ahref.vue";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import ContactForm from "@/components/location/ContactForm";
import {emailRegex, EVALUATION_ID_PREFIX} from "@/utilities";

export default {
	name: "LocationReviewUs",
	components: {
		PageTitle,
		InputItem,
		DataItem,
		Button,
		Ahref,
		FormGridWrapper,
		SlideOutPanel,
		ContactForm,
	},
	data() {
		return {
			review: {
				net_promoter_likert_scale__c: 0,
				ServiceCommunicationTDC__c: 0,
				CoordinationCooperationTDC__c: 0,
				dj_played_according_to_atmosphere_rating__c: 0,
				dj_service_level_rating__c: 0,
				evaluation_quote__c: "",
				location_general_comments__c: "",
				LocationContact__c: "",
			},
			addVenueContact: false,
			contactFormErrors: this.initialContactFormErrors(),
			newContact: this.initialNewContact(),
			checked: false,
		};
	},
	computed: {
		...mapGetters({
			contactCount: "getLocationContactCount",
			contacts: "getLocationContacts",
			mainContact: "getLocationMainContactDetails",
			info: "getLocationInformation",
		}),
		contactChoices() {
			return this.contacts.map((contact) => ({value: contact.Id, label: contact.FirstName + " " + contact.LastName}));
		},
		isAvailable() {
			return this.info.LocationEvaluation__c === "Available";
		},
		isFilled() {
			return (
				this.info.LocationEvaluation__c &&
				this.info.LocationEvaluation__c.length == 18 &&
				this.info.LocationEvaluation__c.startsWith(EVALUATION_ID_PREFIX)
			);
		},
		subTitle() {
			if (this.isFilled) return this.$t("mol.reviewUsPage.title.Thank you for your evaluation");
			return this.$t("mol.reviewUsPage.title.Win a voucher");
		},
	},
	methods: {
		initialContactFormErrors() {
			return {
				hasErrors: false,
				contactErrorFirstName: false,
				contactErrorLastName: false,
				contactErrorEmail: false,
				contactErrorPhone: false,
			};
		},
		initialNewContact() {
			return {
				Id: null,
				Salutation: "mevr.",
				FirstName: "",
				LastName: "",
				Title: "",
				Email: "",
				Phone: "",
				isMain: null,
			};
		},
		closeNewContactPanel() {
			this.addVenueContact = false;
		},
		openNewContactPanel() {
			if (this.isFilled) {
				return;
			}
			this.resetNewContactFormErrors();
			this.resetNewContact();
			this.addVenueContact = true;
		},
		validateContact(contact) {
			this.resetNewContactFormErrors();

			if (contact.FirstName == "") {
				this.contactFormErrors.contactErrorFirstName = true;
			}
			if (contact.LastName == "") {
				this.contactFormErrors.contactErrorLastName = true;
			}
			if (!emailRegex.test(contact.Email)) {
				this.contactFormErrors.contactErrorEmail = true;
			}

			if (contact.FirstName == "") {
				this.contactFormErrors.hasErrors = true;
				document.getElementById("FirstName").scrollIntoView({behavior: "smooth"});
			} else if (contact.LastName == "") {
				this.contactFormErrors.hasErrors = true;
				document.getElementById("LastName").scrollIntoView({behavior: "smooth"});
			} else if (!emailRegex.test(contact.Email)) {
				this.contactFormErrors.hasErrors = true;
				document.getElementById("Email").scrollIntoView({behavior: "smooth"});
			}
		},
		saveContact(contact) {
			this.validateContact(contact);
			if (!this.contactFormErrors.hasErrors) {
				this.$store
					.dispatch("upsertSFData", {
						action: "upsertContact",
						data: contact,
						successMessage: this.$t("mol.contactForm.message.Contact saved"),
						errorMessage: this.$t("mol.contactForm.message.Contact not saved"),
					})
					.then(this.selectNewContact)
					.catch((err) => {
						console.error(err);
					});
			}
		},
		selectNewContact(data) {
			let newContactId = data.data.Contacts.records[data.data.Contacts.records.length - 1].Id;
			this.review.LocationContact__c = newContactId;
			this.closeNewContactPanel();
		},
		resetNewContact() {
			this.newContact = this.initialNewContact();
		},
		resetNewContactFormErrors() {
			this.formErrors = this.initialContactFormErrors();
		},
		saveLocationEvaluation() {
			// validate
			let firstErr = null;
			for (let field of [
				"net_promoter_likert_scale__c",
				"ServiceCommunicationTDC__c",
				"CoordinationCooperationTDC__c",
				"dj_played_according_to_atmosphere_rating__c",
				"dj_service_level_rating__c",
			]) {
				if (!this.review[field] && firstErr === null) {
					firstErr = this.$refs[field];
				}
			}
			this.checked = true;
			if (firstErr) {
				firstErr.$el.scrollIntoView({behavior: "smooth"});
				return;
			}
			this.$store
				.dispatch("upsertSFData", {
					action: "saveLocationEvaluation",
					data: this.review,
					successMessage: this.$t("mol.reviewUsForm.message.Evaluation saved"),
					errorMessage: this.$t("mol.reviewUsForm.message.Evaluation not saved"),
				})
				.then(() => {
					this.$store.dispatch("getLocationInformation");
				})
				.catch((err) => {
					console.error(err);
				});
		},
	},
	mounted() {
		if (this.isFilled) {
			this.$store
				.dispatch("getLocationEvaluation")
				.then(() => {
					this.review = Object.assign({}, this.$store.getters.getLocationEvaluation);
				})
				.catch((err) => {
					console.error(err);
				});
		}
		this.$store.dispatch("getLocationInformation").then(() => {
			this.review.LocationContact__c = this.mainContact.Id;
		});
	},
};
</script>
