<template>
	<span>
		<ul class="list-unstyled usp-list mb-0 mt-md-n1 mt-lg-1">
			<li class="mb-1">
				<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
				100% match met <span v-if="partyType == 'bruiloftsfeest'">jullie</span><span v-else>jouw</span> DJ
			</li>
			<li class="mb-1">
				<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
				Reis- en parkeerkosten
			</li>
			<component :is="collapseOnMobile ? 'b-collapse' : 'div'" id="partyList" :class="{'d-md-block': collapseOnMobile}">
				<li class="mb-1">
					<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
					Reserve DJ
				</li>
				<li v-if="booking.light_show_needed__c == 'ja' || booking.pa_system_needed__c == 'ja'" class="mb-1">
					<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
					{{ lightSoundLabel }}
				</li>
				<li class="mb-1">
					<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
					Niet goed, geld terug
				</li>
				<li class="mb-1">
					<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
					Gratis annuleren tot 14 dagen voor feest
				</li>
				<li class="mb-1">
					<font-awesome-icon :icon="['fal', 'check']" class="text-success mr-1" />
					50% achteraf betalen
				</li>
			</component>
		</ul>
		<span class="d-flex justify-content-start mb-3">
			<p
				v-if="collapseOnMobile"
				class="d-block d-md-none mb-0 text-primary ahref"
				v-b-toggle.partyList
				@click.prevent="hidePartyList = !hidePartyList"
			>
				<span v-if="!hidePartyList"><font-awesome-icon class="mr-1" :icon="['fal', 'minus']" /> Lees minder</span>
				<span v-if="hidePartyList"><font-awesome-icon class="mr-1" :icon="['fal', 'plus']" /> Lees meer</span>
			</p>
		</span>
	</span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "MyPartyList",
	computed: {
		...mapGetters({
			booking: "getBooking",
			partyType: "getPartyType",
		}),
		lightSoundLabel: function () {
			if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "nee") return "";
			else if (this.booking.light_show_needed__c == "ja" && this.booking.pa_system_needed__c == "nee")
				return "Lichtapparatuur";
			else if (this.booking.light_show_needed__c == "nee" && this.booking.pa_system_needed__c == "ja")
				return "Geluidsapparatuur";
			return "Licht- en geluidsapparatuur";
		},
	},
	props: {
		collapseOnMobile: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			hidePartyList: true,
		};
	},
};
</script>
