<template>
	<div>
		<PageTitle
			:title="$t('mol.invoicingPage.title.Invoicing')"
			:subTitle="subTitle"
			:breadcrumbs="breadcrumbs"
			:adaptSubnav="true"
		/>
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavEditing">
				<SlideOutPanel v-if="invoicePeriodOnDisplay" :panelOpen="molEditingState">
					<template slot="outerContent">
						<EditButton v-if="invoicingPeriodComplete" disabled>
							{{ $t("mol.invoicingPage.button.Upload invoice") }}
						</EditButton>
						<EditButton v-else @onClick="openEditingPanel()">
							{{ $t("mol.invoicingPage.button.Upload invoice") }}
						</EditButton>
					</template>
					<template slot="innerContent">
						<div class="h-full flex-col flex align-middle">
							<div class="absolute right-0">
								<button
									@click="closeEditingPanel()"
									class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
								>
									<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
								</button>
							</div>
							<PageTitle
								:title="$t('mol.invoicingPage.title.Invoicing')"
								:subTitle="$t('mol.invoicingPage.title.Upload invoice')"
								:breadcrumbs="breadcrumbsEdit"
							/>
							<div>
								<InputItem
									v-model="editInvoicePeriod.pdf"
									inputType="file"
									:fileInputButtonLabel="$t('mol.general.button.Select files')"
									inputName="Photo"
									:inputLabel="$t('mol.general.label.File')"
									:error="formErrors.invoicingErrorFile"
									:errorMessage="$t('mol.general.message.Please choose a valid file format')"
									@fileschange="filesChange"
								/>
								<FormGridWrapper v-if="filePreviews.length > 0">
									<template slot="left">
										<div>
											<span v-if="filePreviews.length === 1">{{ $t("mol.general.string.1 file chosen") }}</span>
											<span v-else>{{ $t("mol.general.string.num Files chosen", {count: filePreviews.length}) }}</span>
										</div>
									</template>
									<template slot="right">
										<div class="w-full">
											<div
												class="bg-slate-200/70 p-2 mb-2 grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-1 sm:gap-2 md:gap-2 mr-2"
											>
												<div
													v-for="file in photoPreviews"
													:key="file"
													class="rounded w-100 bg-slate-300 aspect-square bg-contain bg-center bg-no-repeat border border-slate-200 relative"
													:style="{backgroundImage: 'url(' + file + ')'}"
												></div>
											</div>
										</div>
									</template>
								</FormGridWrapper>
							</div>
						</div>
						<div class="md:grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
							<div class="md:col-start-2 lg:col-start-2 xl:col-start-2 md:col-span-3 lg:col-span-4 xl:col-span-5">
								<div class="flex flex-row">
									<Button
										@click.native="saveInvoiceData(editInvoicePeriod)"
										type="green"
										icon="check"
										:disabled="filePreviews.length === 0"
										class="mr-2 flex-grow"
										:label="$t('mol.general.button.Save files')"
									/>

									<Button @click.native="closeEditingPanel()" class="mr-2" type="cancel" />
								</div>
							</div>
						</div>
					</template>
				</SlideOutPanel>
			</template>
			<template slot="subNavTabContent">
				<div v-if="!invoicePeriodOnDisplay">
					<p>
						{{ $t("mol.invoicingPage.string.Choose an invoicing period to view more details") }}
					</p>
				</div>
				<div v-else>
					<DataItem dataLabel="Invoice period" :dataValue="invoicePeriodOnDisplay.label" />
					<DataItem dataLabel="Bookings in period" :dataValue="invoicePeriodOnDisplay.periodBookingCountLabel" />
					<DataItem dataLabel="Invoice period status">
						<span v-if="invoicePeriodOnDisplay.periodStatus === 'complete'" class="font-bold text-tdc-green-500">{{
							$t("mol.invoicingPage.label.Invoice completed on date", {date: "25/12/23"})
						}}</span>
						<span v-if="invoicePeriodOnDisplay.periodStatus === 'open'">{{ $t("mol.invoicingPage.label.Open") }}</span>
					</DataItem>
					<DataItem dataLabel="Compensation per booking" dataValue="€ 50" extraSpaceBottom />
					<Alert>
						<p>
							<span class="font-bold">{{ $t("mol.invoicingPage.string.Bookings count") }}</span
							><br />{{ $t("mol.invoicingPage.string.Bookings count is based on customer count") }}
						</p>
					</Alert>

					<div
						class="mb-3 bg-white dark:bg-slate-700 p-4 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700"
					>
						<ContentTitle>
							{{ $t("mol.invoicingPage.label.Amount billable to The DJ Company") }}
						</ContentTitle>
						<DataItem dataValue="€ 250,-" pricingData>
							<template slot="dataLabel">
								<span>{{ $t("mol.invoicingPage.label.Subtotal amount") }} </span>
								<span class="text-slate-400 dark:text-slate-300"
									>(
									<span
										v-text="invoicePeriodOnDisplay.periodBookingCount ? invoicePeriodOnDisplay.periodBookingCount : 0"
									/>
									<font-awesome-icon :icon="['far', 'times']" class="h-2 w-2 mx-1" />
									<span>50,-</span>)
								</span>
							</template>
						</DataItem>
						<DataItem dataValue="€ 52,50" pricingData>
							<template slot="dataLabel">
								<span>{{ $t("mol.invoicingPage.label.Tax") }} </span>
								<span class="text-slate-400 dark:text-slate-300">( 21% )</span>
							</template>
						</DataItem>
						<DataItem class="border-b border-t border-slate-200 dark:border-slate-600 pt-3 mt-3 mb-3" pricingData>
							<template slot="dataLabel"
								><span class="font-bold text-lg text-right w-full">{{
									$t("mol.invoicingPage.label.Total amount")
								}}</span></template
							>
							<template><span class="font-bold text-lg">€ 450,-</span></template>
						</DataItem>
						<div class="flex flex-col items-end pt-4">
							<Button v-if="invoicingPeriodComplete" disabled class="ml-auto w-full md:w-auto">
								{{ $t("mol.invoicingPage.button.Upload invoice") }}
							</Button>
							<Button v-else class="ml-auto w-full md:w-auto" @click.native="openEditingPanel()">
								{{ $t("mol.invoicingPage.button.Upload invoice") }}
							</Button>
							<div
								v-if="invoicingPeriodComplete"
								class="text-sm font-bold text-tdc-green-500 text-center md:text-right w-full"
							>
								{{ $t("mol.invoicingPage.label.Invoice completed on date", {date: "25/12/23"}) }}
							</div>
							<div v-else class="text-xs text-center md:text-right w-full">
								{{ $t("mol.invoicingPage.string.Send us the invoice") }}
								<Ahref @click.native="openEditingPanel()">{{ $t("mol.invoicingPage.string.via this link") }}</Ahref
								>.
							</div>
						</div>
					</div>
				</div>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import SubNav from "@/components/location/SubNav";
import DataItem from "@/components/location/DataItem";
import InputItem from "@/components/location/InputItem";
import ContentTitle from "@/components/location/ContentTitle";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import EditButton from "@/components/location/EditButton";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Button from "@/components/location/Button";
import Alert from "@/components/location/Alert";
import Ahref from "@/components/location/Ahref";

export default {
	name: "LocationInvoicing",
	components: {
		PageTitle,
		SubNav,
		DataItem,
		InputItem,
		ContentTitle,
		EditButton,
		SlideOutPanel,
		Button,
		Alert,
		FormGridWrapper,
		Ahref,
	},
	data() {
		return {
			files: [],
			filePreviews: [],
			formErrors: this.initialInvoicingFormErrors(),
		};
	},
	computed: {
		...mapGetters({
			molSubnavHash: "getMolSubnavHash",
			molEditingState: "getMolEditingState",
		}),
		invoicePeriods() {
			let periods = [];
			let incompletedInvoicePeriod = {};
			let completedInvoicePeriod = {};
			let completedInvoicePeriod2 = {};
			let completedInvoicePeriod3 = {};

			incompletedInvoicePeriod["Id"] = "Q32023";
			incompletedInvoicePeriod["type"] = "invoice";
			incompletedInvoicePeriod["label"] = this.$t("mol.invoicingPage.subNav.Invoice") + " Q3 2023";
			incompletedInvoicePeriod["route"] = {name: "Bookings", hash: "#Q32023"};
			incompletedInvoicePeriod["title"] = "";
			incompletedInvoicePeriod["subTitle"] = "Period Q3 2023";
			incompletedInvoicePeriod["breadcrumbLabel"] = this.$t("mol.invoicingPage.subNav.Complete invoice");
			incompletedInvoicePeriod["icon"] = "file-invoice";
			incompletedInvoicePeriod["periodStatus"] = "open";
			incompletedInvoicePeriod["periodBookingCountLabel"] = this.bookingsCountLabel(5);
			incompletedInvoicePeriod["periodBookingCount"] = 5;
			incompletedInvoicePeriod["invoiceAmount"] = "250";
			incompletedInvoicePeriod["invoiceCurrency"] = "euro";

			completedInvoicePeriod["Id"] = "Q22023";
			completedInvoicePeriod["type"] = "invoice";
			completedInvoicePeriod["label"] = this.$t("mol.invoicingPage.subNav.Invoice") + " Q2 2023";
			completedInvoicePeriod["route"] = {name: "Bookings", hash: "#Q22023"};
			completedInvoicePeriod["title"] = "";
			completedInvoicePeriod["subTitle"] = "Period Q2 2023";
			completedInvoicePeriod["breadcrumbLabel"] = this.$t("mol.invoicingPage.subNav.Incomplete invoice");
			completedInvoicePeriod["icon"] = "file-invoice";
			completedInvoicePeriod["periodStatus"] = "complete";
			completedInvoicePeriod["periodBookingCountLabel"] = this.bookingsCountLabel(7);
			completedInvoicePeriod["periodBookingCount"] = 7;
			completedInvoicePeriod["invoiceAmount"] = "350";
			completedInvoicePeriod["invoiceCurrency"] = "euro";

			completedInvoicePeriod2["Id"] = "Q12023";
			completedInvoicePeriod2["type"] = "invoice";
			completedInvoicePeriod2["label"] = this.$t("mol.invoicingPage.subNav.Invoice") + " Q1 2023";
			completedInvoicePeriod2["route"] = {name: "Bookings", hash: "#Q12023"};
			completedInvoicePeriod2["title"] = "";
			completedInvoicePeriod2["subTitle"] = "Period Q1 2023";
			completedInvoicePeriod2["breadcrumbLabel"] = this.$t("mol.invoicingPage.subNav.Incomplete invoice");
			completedInvoicePeriod2["icon"] = "file-invoice";
			completedInvoicePeriod2["periodStatus"] = "complete";
			completedInvoicePeriod2["periodBookingCountLabel"] = this.bookingsCountLabel();
			completedInvoicePeriod2["periodBookingCount"] = "";
			completedInvoicePeriod2["invoiceAmount"] = "";
			completedInvoicePeriod2["invoiceCurrency"] = "euro";

			completedInvoicePeriod3["Id"] = "Q42022";
			completedInvoicePeriod3["type"] = "invoice";
			completedInvoicePeriod3["label"] = this.$t("mol.invoicingPage.subNav.Invoice") + " Q4 2022";
			completedInvoicePeriod3["route"] = {name: "Bookings", hash: "#Q42022"};
			completedInvoicePeriod3["title"] = "";
			completedInvoicePeriod3["subTitle"] = "Period Q4 2022";
			completedInvoicePeriod3["breadcrumbLabel"] = this.$t("mol.invoicingPage.subNav.Incomplete invoice");
			completedInvoicePeriod3["icon"] = "file-invoice";
			completedInvoicePeriod3["periodStatus"] = "complete";
			completedInvoicePeriod3["periodBookingCountLabel"] = this.bookingsCountLabel(1);
			completedInvoicePeriod3["periodBookingCount"] = 1;
			completedInvoicePeriod3["invoiceAmount"] = "50";
			completedInvoicePeriod3["invoiceCurrency"] = "euro";

			periods.push(incompletedInvoicePeriod);
			periods.push(completedInvoicePeriod);
			periods.push(completedInvoicePeriod2);
			periods.push(completedInvoicePeriod3);

			return periods;
		},
		subNavItems() {
			let navItems = this.invoicePeriods;
			return navItems;
		},
		invoicePeriodOnDisplay() {
			let invoicePeriodToDisplayID = this.molSubnavHash.substring(1);
			let invoicePeriodToDisplay = null;
			if (this.invoicePeriods.length > 0) {
				invoicePeriodToDisplay = this.invoicePeriods.find((period) => {
					return period.Id === invoicePeriodToDisplayID;
				});
			}
			return invoicePeriodToDisplay;
		},
		editInvoicePeriod() {
			var period = {};
			period["Id"] = this.invoicePeriodOnDisplay.Id;
			period["pdf"] = this.invoicePeriodOnDisplay.pdf;
			return period;
		},
		invoicingPeriodComplete() {
			return this.invoicePeriodOnDisplay.periodStatus === "complete";
		},
		subTitle() {
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).subTitle;
			}
			return "";
		},

		breadcrumbs() {
			let items = [this.$t("mol.invoicingPage.title.Invoicing")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).subTitle);
			}
			return items;
		},
		breadcrumbsEdit() {
			let editItem = this.$t("mol.invoicingPage.label.Upload invoice");
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			breadcrumbs.push(editItem);
			return breadcrumbs;
		},
	},
	methods: {
		resetFormErrors() {
			this.formErrors = this.initialInvoicingFormErrors();
		},
		initialInvoicingFormErrors() {
			return {
				hasErrors: false,
			};
		},
		filesChange(files) {
			var arr = Array.from(files);
			this.filePreviews = [];

			arr.forEach((file) => {
				this.filePreviews.push(URL.createObjectURL(file));
			});
			this.files = files;
		},
		bookingsCountLabel(count) {
			if (count === 1) {
				return this.$t("mol.invoicingPage.label.1 booking");
			} else if (count > 1) {
				return this.$t("mol.invoicingPage.label.count bookings", {count: count});
			}
			return this.$t("mol.invoicingPage.label.no bookings", {count: count});
		},
		subtotalLabel() {},
		closeEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
		},
		openEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", true);
		},
	},
	mounted() {},
	watch: {
		molEditingState: function () {
			if (this.molEditingState) {
				//this.setInvoicingDataToEdit(this.molSubnavHash);
				this.filePreviews = [];
			}
		},
		molSubnavHash: function () {
			this.resetFormErrors();
		},
	},
};
</script>
