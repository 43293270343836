<template>
	<div id="contact_details" class="position-relative">
		<b-form :aria-hidden="isLoading ? 'true' : null">
			<b-form-group label="Contactpersoon" label-for="Name" class="border-bottom pb-3">
				<label class="col-form-label font-weight-bold">{{ booking.Account__r.Name }}</label>
			</b-form-group>

			<b-form-group label="Telefoon" label-for="Phone" class="border-bottom pb-3">
				<label v-if="booking.Account__r.Phone" class="col-form-label font-weight-bold">{{
					booking.Account__r.Phone
				}}</label>
				<label v-if="!booking.Account__r.Phone" class="col-form-label font-weight-bold">-</label>
			</b-form-group>

			<b-form-group label="Email" label-for="PersonEmail" class="border-bottom pb-3">
				<label v-if="booking.Account__r.PersonEmail" class="col-form-label font-weight-bold">{{
					booking.Account__r.PersonEmail
				}}</label>
				<label v-if="!booking.Account__r.PersonEmail" class="col-form-label font-weight-bold">-</label>
			</b-form-group>

			<b-form-group
				label="Alternatief telefoonnummer (10 cijfers)"
				label-for="X2nd_phone_main_contact__c"
				class="border-bottom pb-3"
				:class="getInputClass('X2nd_phone_main_contact__c')"
			>
				<div class="position-relative">
					<TelInput
						v-model="booking.X2nd_phone_main_contact__c"
						@set="
							booking.X2nd_phone_main_contact__c = $event;
							delayUpdateContact('X2nd_phone_main_contact__c', $event);
						"
						id="X2nd_phone_main_contact__c"
						:disabled="isConfirmed || updatingContact"
						moc
					></TelInput>
					<InputFeedback />
				</div>
			</b-form-group>
		</b-form>
		<CustomerInvoiceAddress></CustomerInvoiceAddress>

		<b-form>
			<b-form-group
				label="Factuur maken voor zakelijke boeking?"
				label-for="booking.BusinessInvoice__c"
				class="border-bottom pb-3"
				:class="getInputClass('booking.BusinessInvoice__c')"
			>
				<template slot="label"> Factuur maken voor zakelijke boeking? </template>
				<div class="row row-cols-1 row-cols-md-2 mx-n1">
					<b-form-radio
						button
						v-model="booking.BusinessInvoice__c"
						:value="true"
						name="booking.BusinessInvoice__c"
						button-variant="outline-tdc"
						class="d-block mb-2 mdc-radio-check col px-1"
						:disabled="isConfirmed"
						@input.native="delayUpdateBillingDetails('booking.BusinessInvoice__c')"
					>
						<OptionLabel radio primary="Ja" secondary="Dit is een zakelijke boeking" icon="chair-office" />
					</b-form-radio>
					<b-form-radio
						button
						v-model="booking.BusinessInvoice__c"
						:value="false"
						name="booking.BusinessInvoice__c"
						button-variant="outline-tdc"
						class="d-block mb-2 mdc-radio-check col px-1"
						:disabled="isConfirmed"
						@input.native="delayUpdateBillingDetails('booking.BusinessInvoice__c')"
					>
						<OptionLabel radio primary="Nee" secondary="Dit is géén zakelijke boeking" icon="house-user" />
					</b-form-radio>
				</div>
			</b-form-group>

			<b-form-group
				v-if="booking.BusinessInvoice__c"
				label="E-mailadres voor facturatie"
				label-for="booking.X2nd_Invoice_Email__c"
				class="border-bottom pb-3"
				:class="getInputClass('booking.X2nd_Invoice_Email__c')"
			>
				<div class="position-relative">
					<b-form-input
						type="email"
						v-model="booking.X2nd_Invoice_Email__c"
						id="booking.X2nd_Invoice_Email__c"
						placeholder=""
						:disabled="isConfirmed"
						@input.native="delayUpdateBillingDetails('booking.X2nd_Invoice_Email__c')"
					></b-form-input>
					<InputFeedback />
				</div>
			</b-form-group>
			<b-form-group
				v-if="!!booking.BusinessInvoice__c"
				label="BTW nummer"
				label-for="booking.Account__r.VAT_number__c"
				class="border-bottom pb-3"
				:class="getInputClass('booking.Account__r.VAT_number__c')"
			>
				<div class="position-relative">
					<b-form-input
						type="text"
						v-model="booking.Account__r.VAT_number__c"
						id="booking.Account__r.VAT_number__c"
						placeholder=""
						:disabled="isConfirmed"
						@input.native="delayUpdateBillingDetails('booking.Account__r.VAT_number__c')"
					></b-form-input>
					<InputFeedback />
				</div>
			</b-form-group>
			<b-form-group
				v-if="!!booking.BusinessInvoice__c"
				label="Factuurdetails"
				label-for="booking.InvoiceDetails__c"
				class="border-bottom pb-3"
				:class="getInputClass('booking.InvoiceDetails__c')"
			>
				<div class="position-relative">
					<b-form-textarea
						id="booking.InvoiceDetails__c"
						v-model="booking.InvoiceDetails__c"
						rows="3"
						max-rows="6"
						@input.native="delayUpdateBillingDetails('booking.InvoiceDetails__c')"
						:disabled="isConfirmed"
						placeholder="Extra info voor op je factuur"
					></b-form-textarea>
					<InputFeedback />
				</div>
			</b-form-group>
		</b-form>

		<div v-if="isWedding">
			<h3>
				<strong>Gegevens {{ role(booking.X2nd_contact_role__c) }}</strong>
			</h3>

			<b-form :aria-hidden="isLoading ? 'true' : null">
				<b-form-group
					label="Voornaam"
					label-for="X2nd_contact_first_name__c"
					class="border-bottom pb-3"
					:class="getInputClass('X2nd_contact_first_name__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X2nd_contact_first_name__c"
							@input.native="delayUpdateContact2('X2nd_contact_first_name__c')"
							id="X2nd_contact_first_name__c"
							placeholder=" "
							maxlength="100"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					label="Achternaam"
					label-for="X2nd_contact_last_name__c"
					class="border-bottom pb-3"
					:class="getInputClass('X2nd_contact_last_name__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X2nd_contact_last_name__c"
							@input.native="delayUpdateContact2('X2nd_contact_last_name__c')"
							id="X2nd_contact_last_name__c"
							placeholder=" "
							maxlength="100"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					label="Telefoon (10 cijfers)"
					label-for="X2nd_contact_phone__c"
					class="border-bottom pb-3"
					:class="getInputClass('X2nd_contact_phone__c')"
				>
					<div class="position-relative">
						<TelInput
							v-model="booking.X2nd_contact_phone__c"
							@set="
								booking.X2nd_contact_phone__c = $event;
								delayUpdateContact2('X2nd_contact_phone__c', $event);
							"
							id="X2nd_contact_phone__c"
							:disabled="isConfirmed || updating2Contact"
							moc
						></TelInput>
						<InputFeedback />
					</div>
				</b-form-group>
				<b-form-group
					label="Email"
					label-for="X2nd_contact_email__c"
					class="border-bottom pb-3"
					:class="getInputClass('X2nd_contact_email__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X2nd_contact_email__c"
							type="email"
							@input.native="delayUpdateContact2('X2nd_contact_email__c')"
							id="X2nd_contact_email__c"
							placeholder=" "
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>
			</b-form>
		</div>
		<div v-if="isWedding">
			<h3><strong>Gegevens ceremoniemeester</strong></h3>
			<b-form-group label="Hebben jullie een ceremoniemeester" label-for="ceremoniemeester" class="border-bottom pb-3">
				<template slot="label"> Hebben jullie een ceremoniemeester </template>
				<div class="row row-cols-1 row-cols-md-2 mx-n1">
					<b-form-radio
						button
						v-model="ceremoniemeester"
						:value="true"
						name="ceremoniemeester"
						button-variant="outline-tdc"
						class="d-block mb-2 mdc-radio-check col px-1"
						:disabled="isConfirmed"
					>
						<OptionLabel radio primary="Ja" secondary="Wij hebben een ceremoniemeester" icon="user-tie" />
					</b-form-radio>
					<b-form-radio
						button
						v-model="ceremoniemeester"
						:value="false"
						name="ceremoniemeester"
						button-variant="outline-tdc"
						class="d-block mb-2 mdc-radio-check col px-1"
						:disabled="isConfirmed"
					>
						<OptionLabel radio primary="Nee" secondary="Wij hebben geen ceremoniemeester" icon="user-slash" />
					</b-form-radio>
				</div>
			</b-form-group>

			<b-form v-if="ceremoniemeester" :aria-hidden="isLoading ? 'true' : null">
				<b-form-group
					label="Voornaam"
					label-for="X3rd_contact_first_name__c"
					class="border-bottom pb-3"
					:class="getInputClass('X3rd_contact_first_name__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X3rd_contact_first_name__c"
							@input.native="delayUpdateContact3('X3rd_contact_first_name__c')"
							id="X3rd_contact_first_name__c"
							placeholder=" "
							maxlength="100"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>
				<b-form-group
					label="Achternaam"
					label-for="X3rd_contact_last_name__c"
					class="border-bottom pb-3"
					:class="getInputClass('X3rd_contact_last_name__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X3rd_contact_last_name__c"
							@input.native="delayUpdateContact3('X3rd_contact_last_name__c')"
							id="X3rd_contact_last_name__c"
							placeholder=" "
							maxlength="100"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>
				<b-form-group
					label="Telefoon (10 cijfers)"
					label-for="X3rd_contact_phone__c"
					class="border-bottom pb-3"
					:class="getInputClass('X3rd_contact_phone__c')"
				>
					<div class="position-relative">
						<TelInput
							v-model="booking.X3rd_contact_phone__c"
							@set="
								booking.X3rd_contact_phone__c = $event;
								delayUpdateContact3('X3rd_contact_phone__c', $event);
							"
							id="X3rd_contact_phone__c"
							:disabled="isConfirmed || updating3Contact"
							moc
						></TelInput>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					label="Email"
					label-for="X3rd_contact_email__c"
					class="border-bottom pb-3"
					:class="getInputClass('X3rd_contact_email__c')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="booking.X3rd_contact_email__c"
							type="email"
							@input.native="delayUpdateContact3('X3rd_contact_email__c')"
							id="X3rd_contact_email__c"
							placeholder=" "
							maxlength="100"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>
			</b-form>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import OptionLabel from "@/components/customer/OptionLabel";
import InputFeedback from "@/components/customer/InputFeedback";
import CustomerInvoiceAddress from "@/components/customer/CustomerInvoiceAddress";
import TelInput from "@/components/common/TelInput";

export default {
	name: "ContactDetails",
	components: {
		InputFeedback,
		OptionLabel,
		CustomerInvoiceAddress,
		TelInput,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			isConfirmed: "isConfirmed",
			partyType: "getPartyType",
			loadingInput: "getLoadingInput",
			isBelgian: "isBelgian",
			isWedding: "isWedding",
			isBirthday: "isBirthday",
			isBusinessParty: "isBusinessParty",
		}),
	},
	data() {
		return {
			ceremoniemeester: false,
			updatingContact: false,
			updating2Contact: false,
			updating3Contact: false,
		};
	},
	methods: {
		updateContact(input) {
			const payload = {
				action: "updateContact",
				data: {
					phone: this.booking.Account__r.Phone,
					alt_phone: this.booking.X2nd_phone_main_contact__c,
					street: this.booking.account_street__c,
					postal_code: this.booking.account_postal_code__c,
					city: this.booking.account_city__c,
				},
				setBooking: true,
				input: input,
			};
			console.log("updateContact", input, payload);
			this.updatingContact = true;
			this.$store.dispatch("postBooking", payload).finally(() => {
				this.updatingContact = false;
			});
		},
		updateContact2(input) {
			this.$store
				.dispatch("postBooking", {
					action: "updateExtraContacts",
					data: {
						X2nd_contact_first_name__c: this.booking.X2nd_contact_first_name__c,
						X2nd_contact_last_name__c: this.booking.X2nd_contact_last_name__c,
						X2nd_contact_phone__c: this.booking.X2nd_contact_phone__c,
						X2nd_contact_email__c: this.booking.X2nd_contact_email__c,
					},
					setBooking: true,
					input: input,
				})
				.finally(() => {
					this.updating2Contact = false;
				});
			this.updating2Contact = true;
		},
		updateContact3(input) {
			this.$store
				.dispatch("postBooking", {
					action: "updateExtraContacts",
					data: {
						X3rd_contact_first_name__c: this.booking.X3rd_contact_first_name__c,
						X3rd_contact_last_name__c: this.booking.X3rd_contact_last_name__c,
						X3rd_contact_phone__c: this.booking.X3rd_contact_phone__c,
						X3rd_contact_email__c: this.booking.X3rd_contact_email__c,
					},
					setBooking: true,
					input: input,
				})
				.finally(() => {
					this.updating3Contact = false;
				});
			this.updating3contact = true;
		},
		delayUpdateContact(input, evt) {
			console.log("delayUpdateContact", input, evt);
			clearTimeout(this.contactTimeout);
			this.contactTimeout = setTimeout(() => {
				this.updateContact(input);
			}, 2000);
		},
		delayUpdateContact2(input) {
			console.log("delayUpdateContact2");
			clearTimeout(this.contact2Timeout);
			this.contact2Timeout = setTimeout(() => {
				this.updateContact2(input);
			}, 2000);
		},
		delayUpdateContact3(input) {
			console.log("delayUpdateContact3");
			clearTimeout(this.contact3Timeout);
			this.contact3Timeout = setTimeout(() => {
				this.updateContact3(input);
			}, 2000);
		},
		getInputClass(input) {
			if (input == this.loadingInput.name) {
				if (this.loadingInput.status == "saving") return "input-saving";
				else if (this.loadingInput.status == "saved") {
					this.delay = setTimeout(
						function () {
							this.$root.$emit("bv::hide::popover");
						}.bind(this),
						4000
					);
					return "input-saved";
				}
			}
			return "";
		},
		updateBillingDetails(input) {
			this.$store.dispatch("postBooking", {
				action: "updateBillingInformation",
				data: {
					VAT_number__c: this.booking.Account__r.VAT_number__c,
					InvoiceDetails__c: this.booking.InvoiceDetails__c,
					X2nd_Invoice_Email__c: this.booking.X2nd_Invoice_Email__c,
					BusinessInvoice__c: this.booking.BusinessInvoice__c,
				},
				setBooking: false,
				input: input,
			});
		},
		delayUpdateBillingDetails(input) {
			clearTimeout(this.billingDetailsTimer);
			this.billingDetailsTimer = setTimeout(() => {
				this.updateBillingDetails(input);
			}, 2000);
		},
		role(r) {
			switch (r) {
				case "groom": return "bruidegom";
				case "bride": return "bruid";
				case "master of ceremonies": return "ceremoniemeester";
				default: return "contactpersoon";
			}
		}
	},
	mounted() {
		if ("X3rd_contact_first_name__c" in this.booking) this.ceremoniemeester = true;
	},
};
</script>
