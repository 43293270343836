<template>
	<div v-if="socialRatings" class="row no-gutters">
		<div class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block" id="irb-facebook">
				<img class="mw-75 img-fluid" src="~@/assets/images/brand-facebook.png" />
				<StarRating :rating="4.9" :highestRating="5" class="stars" />
				<div class="font-weight-bold">
					{{ socialRatings.FacebookScore__c }} <small> / {{ socialRatings.FacebookMaxScore__c }}</small>
				</div>
				<small class="social-review-label d-block text-muted">{{ socialRatings.FacebookReviews__c }} ervaringen</small>
			</div>
		</div>
		<div v-if="isDutch" class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block" id="irb-perfectwedding">
				<img class="mw-75 img-fluid" src="~@/assets/images/brand-tpw.png" />
				<StarRating :rating="9.5" class="stars" />
				<div class="font-weight-bold">
					{{ socialRatings.ThePerfectWeddingScore__c }}
					<small> / {{ socialRatings.ThePerfectWeddingMaxScore__c }}</small>
				</div>
				<small class="social-review-label d-block text-muted"
					>{{ socialRatings.ThePerfectWeddingReviews__c }} ervaringen</small
				>
			</div>
		</div>
		<div v-if="isDutch" class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block" id="irb-klantenvertellen">
				<img class="mw-75 img-fluid" src="~@/assets/images/brand-kv.png" />
				<StarRating :rating="9.2" class="stars" />
				<div class="font-weight-bold">
					{{ socialRatings.KlantenVertellenScore__c }} <small> / {{ socialRatings.KlantenVertellenMaxScore__c }}</small>
				</div>
				<small class="social-review-label d-block text-muted"
					>{{ socialRatings.KlantenVertellenReviews__c }} ervaringen</small
				>
			</div>
		</div>
		<div class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block" id="irb-google">
				<img class="mw-75 img-fluid" src="~@/assets/images/brand-google.png" />
				<StarRating :rating="4.8" :highestRating="5" class="stars" />
				<div class="font-weight-bold">
					{{ socialRatings.GoogleScore__c }} <small> / {{ socialRatings.GoogleMaxScore__c }}</small>
				</div>
				<small class="social-review-label d-block text-muted">{{ socialRatings.GoogleReviews__c }} ervaringen</small>
			</div>
		</div>

		<div v-if="isBelgian" class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block d-flex align-items-center h-100 justify-content-center">
				<img
					alt="Webwinkel Keurmerk en klantbeoordelingen"
					class="mw-75 img-fluid"
					src="~@/assets/images/brand-safeshops.png"
				/>
			</div>
		</div>
		<div v-if="isBelgian" class="social-review-block-wrap mb-3 text-center">
			<div class="social-review-block d-flex align-items-center h-100 justify-content-center">
				<img
					alt="Webwinkel Keurmerk en klantbeoordelingen"
					class="mw-75 img-fluid"
					src="~@/assets/images/brand-trustecommerceeu.png"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import StarRating from "@/components/customer/StarRating";

export default {
	name: "social-reviews",
	components: {StarRating},
	computed: {
		...mapGetters({
			socialRatings: "getSocialRatings",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
		}),
	},
};
</script>

<style lang="scss" scoped>
.social-review-block {
	border-right: none !important;

	img {
		max-height: 60px;
	}
	.social-review-label {
		line-height: 12px;
		font-size: 12px;
	}
	.mw-75 {
		max-width: 75%;
	}
}
</style>
