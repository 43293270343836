<template>
	<div>
		<div class="row row-cols-1 row-cols-md-2 row-cols-xl-3">
			<!-- Scenario Order-->
			<b-col v-if="isOrderInProgress" class="mb-4">
				<LinkBlock
					title="Boeking"
					:link="{name: 'Booking'}"
					linkText="Bekijk mijn boeking"
					iconGroup="fad"
					icon="calendar-check"
					linkBlockClasses="overview-highlight"
				>
					<div class="flex-column d-flex h-100">
						<div v-if="!isPaid" class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-light">Gratis annuleren tot 14 dagen voor feest</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon v-if="isPaid" :icon="['fas', 'check']" class="text-success mr-2" />
								<font-awesome-icon v-else :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2" />
							</div>
							<strong v-if="isPaid" class="text-light">Boeking bevestigd</strong>
							<strong v-else class="text-light">DJ Boeking nog niet definitief</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start">
							<div>
								<font-awesome-icon v-if="isPaid" :icon="['fas', 'check']" class="text-success mr-2" />
								<font-awesome-icon v-else :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2" />
							</div>
							<strong v-if="isPaid" class="text-light">Waarborgsom betaald</strong>
							<strong v-else class="text-light">Waarborgsom nog niet betaald</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<!-- Scenarion has Quotes -->
			<template v-if="quotes.length">
				<b-col class="mb-4" col xl="8">
					<MyQuotes />
				</b-col>
			</template>
			<!-- Scenarion Pre Quote -->
			<b-col v-if="isPreQuote" class="mb-4">
				<LinkBlock
					title="Prijzen: vul de details aan"
					:link="{name: 'Prices'}"
					linkText="Bereken direct mijn prijs"
					iconGroup="fad"
					icon="clipboard-list-check"
					linkBlockClasses="overview-highlight"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2 text-primary" />
							</div>
							<strong class="text-light">Vul alle details aan</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start">
							<div>
								<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2 text-primary" />
							</div>
							<strong class="text-light">Prijs is nog onbekend</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<!-- Scenario Quote -->
			<b-col v-if="!isPreQuote" class="mb-4">
				<LinkBlock
					title="Bekijk jouw pijzen"
					:link="{name: 'Prices'}"
					linkText="Bekijk mijn prijzen"
					iconGroup="fad"
					icon="clipboard-list-check"
					linkBlockClasses="overview-highlight"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2 text-primary" />
							</div>
							<strong class="text-light">Niet goed, geld terug</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2 text-primary" />
							</div>
							<strong class="text-light">150,- korting</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>

			<!-- Scenario bekende feestlocatie -->
			<b-col v-if="hasLocation && !location.Location_private__c && getNumberOfShows > 0" class="mb-4">
				<LinkBlock
					:title="location.Name"
					:link="{name: 'PartyLocation'}"
					linkText="Bekijk mijn feestlocatie"
					iconGroup="fad"
					icon="city"
					:image="hasLocationMedia ? locationMedia[0].FileLink__c : ''"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary" v-text="getNumberOfShows + ' ' + numberOfShowsLabel + ' gedraaid'"></strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary" v-text="'Beoordeling: ' + locationRating"></strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<!-- Scenario privélocatie -->
			<b-col v-if="hasLocation && location.Location_private__c" class="mb-4">
				<LinkBlock
					title="Thuis- of privélocatie"
					:link="{name: 'PartyLocation'}"
					linkText="Bekijk mijn feestlocatie"
					iconGroup="fad"
					icon="city"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong
								class="text-primary"
								v-if="'Location_City__r' in location"
								v-text="'Meest geboekt in ' + location.Location_City__r.Name"
							></strong>
							<strong class="text-primary" v-else>Meest geboekt op thuis- en privélocaties</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong
								class="text-primary"
								v-if="'Location_City__r' in location"
								v-text="'Best beoordeeld in ' + location.Location_City__r.Name"
							></strong>
							<strong class="text-primary" v-else>Best beoordeeld op thuis- en privélocaties</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<!-- Scenario onbekende feestlocatie -->
			<b-col v-if="hasLocation && !location.Location_private__c && getNumberOfShows == 0" class="mb-4">
				<LinkBlock
					:title="location.Name"
					:link="{name: 'PartyLocation'}"
					:linkText="'Location_City__r' in location ? 'Bekijk mijn feestlocatie' : 'Vul adres en plaats in'"
					iconGroup="fad"
					icon="city"
				>
					<div v-if="'Location_City__r' in location" class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary" v-text="'Meest geboekt in ' + location.Location_City__r.Name"></strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary" v-text="'Best beoordeeld in ' + location.Location_City__r.Name"></strong>
						</div>
					</div>
					<div v-else class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2" />
							</div>
							<strong class="text-primary">Adres en plaats onbekend</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<!-- Scenario geen feestlocatie -->
			<b-col v-if="!hasLocation" class="mb-4">
				<LinkBlock
					title="Feestlocatie"
					:link="{name: 'PartyLocation'}"
					linkText="Vul feestlocatie in"
					iconGroup="fad"
					icon="city"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2" />
							</div>
							<strong class="text-primary">Feestlocatie onbekend</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="text-warning mr-2" />
							</div>
							<strong class="text-primary">Adres en plaats onbekend</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<b-col class="mb-4">
				<LinkBlock
					title="Klantervaringen"
					:link="{name: 'Reviews'}"
					linkText="Bekijk onze klantervaringen"
					iconGroup="fas"
					iconClass="stars"
					icon="stars"
				>
					<div class="d-flex align-items-top w-100">
						<div>
							<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
						</div>
						<strong class="text-primary"
							>Lees meer dan {{ Math.floor(reviewTotals.NumberOfReviews__c / 100) * 100 }} klantervaringen</strong
						>
					</div>
				</LinkBlock>
			</b-col>
			<b-col class="mb-4">
				<LinkBlock
					title="Feestwensen"
					:link="{name: 'PartyWishes'}"
					linkText="Bekijk mijn feestwensen"
					iconGroup="fad"
					icon="music"
				>
					<div class="d-flex align-items-center w-100">
						<b-progress
							variant="success"
							style="height: 5px"
							class="mx-2 flex-grow-1"
							:value="wishesProgress"
							max="100"
						></b-progress>
						<small
							class="text-right text-primary font-weight-bold"
							style="width: 30px"
							v-text="wishesProgress.toFixed(0) + ' %'"
						></small>
					</div>
				</LinkBlock>
			</b-col>
			<b-col class="mb-4">
				<LinkBlock
					title="Leveranciers met kortingen"
					:link="{name: 'Discounts'}"
					linkText="Bekijk andere kortingen"
					iconGroup="fad"
					icon="badge-percent"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-start mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary">Leuke kortingen en acties</strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-start">
							<div>
								<font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />
							</div>
							<strong class="text-primary">Handige leveranciers</strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
			<b-col class="mb-4">
				<LinkBlock
					title="Mijn gegevens"
					:link="{name: 'ContactDetails'}"
					linkText="Bekijk mijn gegevens"
					iconGroup="fad"
					icon="address-card"
				>
					<div class="flex-column d-flex h-100">
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'user']" class="text-success mr-2" />
							</div>
							<strong class="text-dark" style="line-height: 25px" v-text="booking.Account__r.Name"></strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center mb-2">
							<div>
								<font-awesome-icon :icon="['fas', 'phone']" class="text-success mr-2" />
							</div>
							<strong
								v-if="booking.Account__r.Phone"
								class="text-dark"
								style="line-height: 25px"
								v-text="booking.Account__r.Phone"
							></strong>
							<strong
								v-else
								class="text-dark"
								style="line-height: 25px"
								v-text="booking.X2nd_phone_main_contact__c"
							></strong>
						</div>
						<div class="d-flex flex-row justify-content-start align-items-center">
							<div>
								<font-awesome-icon :icon="['fas', 'at']" class="text-success mr-2" />
							</div>
							<strong class="text-dark" style="line-height: 25px" v-text="booking.Account__r.PersonEmail"></strong>
						</div>
					</div>
				</LinkBlock>
			</b-col>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LinkBlock from "@/components/customer/LinkBlock";
import MyQuotes from "@/components/customer/MyQuotes";

export default {
	name: "Home",
	components: {
		FontAwesomeIcon,
		LinkBlock,
		MyQuotes,
	},
	computed: {
		...mapGetters({
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isOrderInProgress: "isOrderInProgress",
			booking: "getBooking",
			location: "getLocation",
			hasLocation: "hasLocation",
			wishesProgress: "getWishesProgress",
			getNumberOfShows: "getNumberOfShows",
			reviewTotals: "getReviewTotals",
			quotes: "getQuotes",
			locationMedia: "getLocationMedia",
			hasLocationMedia: "hasLocationMedia",
		}),
		hasLocationImage: function () {
			return this.hasLocation && "location_profile_thumb_url__c" in this.location;
		},
		locationRating: function () {
			return "LocationReviewsForLocScore__c" in this.location
				? this.location.LocationReviewsForLocScore__c.toFixed(1)
				: 9.2;
		},
		isPaid: function () {
			return "Down_Payment_Paid_Date__c" in this.booking || this.booking.Down_Payment_Amount2__c == 0;
		},
		numberOfShowsLabel: function () {
			return this.getNumberOfShows == 1 ? "feest" : "feesten";
		},
	},
};
</script>
