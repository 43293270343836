import {library} from "@fortawesome/fontawesome-svg-core";

import {faAddressCard as fadAddressCard} from "@fortawesome/pro-duotone-svg-icons";
import {faArrowAltCircleLeft as farArrowAltCircleLeft} from "@fortawesome/pro-regular-svg-icons";
import {faArrowUpRightFromSquare as farArrowUpRightFromSquare} from "@fortawesome/pro-regular-svg-icons";
import {faArrowLeft as farArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import {faArrowLeft as fasArrowLeft} from "@fortawesome/pro-solid-svg-icons";
import {faArrowRight as farArrowRight} from "@fortawesome/pro-regular-svg-icons";
import {faArrowRight as fasArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {faAt as fadAt} from "@fortawesome/pro-duotone-svg-icons";
import {faAt as fasAt} from "@fortawesome/pro-solid-svg-icons";
import {faBadgePercent as fadBadgePercent} from "@fortawesome/pro-duotone-svg-icons";
import {faBalloons as falBalloons} from "@fortawesome/pro-light-svg-icons";
import {faBalloons as farBalloons} from "@fortawesome/pro-regular-svg-icons";
import {faBan as falBan} from "@fortawesome/pro-light-svg-icons";
import {faBan as farBan} from "@fortawesome/pro-regular-svg-icons";
import {faBanParking as farBanParking} from "@fortawesome/pro-regular-svg-icons";
import {faBars as farBars} from "@fortawesome/pro-regular-svg-icons";
import {faBell as fasBell} from "@fortawesome/pro-solid-svg-icons";
import {faBellExclamation as fasBellExclamation} from "@fortawesome/pro-solid-svg-icons";
import {faBellOn as fasBellOn} from "@fortawesome/pro-solid-svg-icons";
import {faBuilding as falBuilding} from "@fortawesome/pro-light-svg-icons";
import {faBuilding as farBuilding} from "@fortawesome/pro-regular-svg-icons";
import {faCakeCandles as farCakeCandles} from "@fortawesome/pro-regular-svg-icons";
import {faCalculator as falCalculator} from "@fortawesome/pro-light-svg-icons";
import {faCalendar as farCalendar} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarAlt as fadCalendarAlt} from "@fortawesome/pro-duotone-svg-icons";
import {faCalendarAlt as falCalendarAlt} from "@fortawesome/pro-light-svg-icons";
import {faCalendarAlt as farCalendarAlt} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarCheck as fadCalendarCheck} from "@fortawesome/pro-duotone-svg-icons";
import {faCalendarCheck as falCalendarCheck} from "@fortawesome/pro-light-svg-icons";
import {faCalendarCheck as farCalendarCheck} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarCheck as fasCalendarCheck} from "@fortawesome/pro-solid-svg-icons";
import {faCalendarClock as falCalendarClock} from "@fortawesome/pro-light-svg-icons";
import {faCalendarClock as farCalendarClock} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarStar as farCalendarStar} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarXmark as farCalendarXmark} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarDay as falCalendarDay} from "@fortawesome/pro-light-svg-icons";
import {faCalendarDay as farCalendarDay} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarPlus as farCalendarPlus} from "@fortawesome/pro-regular-svg-icons";
import {faCalendarTimes as falCalendarTimes} from "@fortawesome/pro-light-svg-icons";
import {faCameraAlt as falCameraAlt} from "@fortawesome/pro-light-svg-icons";
import {faCameraAlt as farCameraAlt} from "@fortawesome/pro-regular-svg-icons";
import {faCameraRetro as farCameraRetro} from "@fortawesome/pro-regular-svg-icons";
import {faCar as farCar} from "@fortawesome/pro-regular-svg-icons";
import {faChairOffice as farChairOffice} from "@fortawesome/pro-regular-svg-icons";
import {faChampagneGlass as farChampagneGlass} from "@fortawesome/pro-regular-svg-icons";
import {faChampagneGlasses as farChampagneGlasses} from "@fortawesome/pro-regular-svg-icons";
import {faChartLine as farChartLine} from "@fortawesome/pro-regular-svg-icons";
import {faChartNetwork as farChartNetwork} from "@fortawesome/pro-regular-svg-icons";
import {faCheck as fadCheck} from "@fortawesome/pro-duotone-svg-icons";
import {faCheck as falCheck} from "@fortawesome/pro-light-svg-icons";
import {faCheck as farCheck} from "@fortawesome/pro-regular-svg-icons";
import {faCheck as fasCheck} from "@fortawesome/pro-solid-svg-icons";
import {faCheckCircle as fadCheckCircle} from "@fortawesome/pro-duotone-svg-icons";
import {faCheckCircle as falCheckCircle} from "@fortawesome/pro-light-svg-icons";
import {faCheckCircle as farCheckCircle} from "@fortawesome/pro-regular-svg-icons";
import {faShareNodes as farShareNodes} from "@fortawesome/pro-regular-svg-icons";
import {faCheckCircle as fasCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import {faChevronDown as falChevronDown} from "@fortawesome/pro-light-svg-icons";
import {faChevronDown as farChevronDown} from "@fortawesome/pro-regular-svg-icons";
import {faChevronDown as fasChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {faChevronLeft as falChevronLeft} from "@fortawesome/pro-light-svg-icons";
import {faChevronLeft as farChevronLeft} from "@fortawesome/pro-regular-svg-icons";
import {faChevronLeft as fasChevronLeft} from "@fortawesome/pro-solid-svg-icons";
import {faChevronRight as falChevronRight} from "@fortawesome/pro-light-svg-icons";
import {faChevronRight as farChevronRight} from "@fortawesome/pro-regular-svg-icons";
import {faChevronRight as fasChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {faChevronUp as falChevronUp} from "@fortawesome/pro-light-svg-icons";
import {faChevronUp as farChevronUp} from "@fortawesome/pro-regular-svg-icons";
import {faChevronUp as fasChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {faCircle as falCircle} from "@fortawesome/pro-light-svg-icons";
import {faCircle as farCircle} from "@fortawesome/pro-regular-svg-icons";
import {faCircle as fasCircle} from "@fortawesome/pro-solid-svg-icons";
import {faCircleDashed as farCircleDashed} from "@fortawesome/pro-regular-svg-icons";
import {faCircleDot as farCircleDot} from "@fortawesome/pro-regular-svg-icons";
import {faCity as fadCity} from "@fortawesome/pro-duotone-svg-icons";
import {faClipboard as farClipboard} from "@fortawesome/pro-regular-svg-icons";
import {faClipboardListCheck as fadClipboardListCheck} from "@fortawesome/pro-duotone-svg-icons";
import {faClipboardListCheck as falClipboardListCheck} from "@fortawesome/pro-light-svg-icons";
import {faClipboardListCheck as farClipboardListCheck} from "@fortawesome/pro-regular-svg-icons";
import {faClock as falClock} from "@fortawesome/pro-light-svg-icons";
import {faClock as farClock} from "@fortawesome/pro-regular-svg-icons";
import {faClock as fasClock} from "@fortawesome/pro-solid-svg-icons";

import {faClockOne as farClockOne} from "@fortawesome/pro-regular-svg-icons";
import {faClockTwo as farClockTwo} from "@fortawesome/pro-regular-svg-icons";
import {faClockThree as farClockThree} from "@fortawesome/pro-regular-svg-icons";
import {faClockFour as farClockFour} from "@fortawesome/pro-regular-svg-icons";
import {faClockFive as farClockFive} from "@fortawesome/pro-regular-svg-icons";
import {faClockSix as farClockSix} from "@fortawesome/pro-regular-svg-icons";
import {faClockSeven as farClockSeven} from "@fortawesome/pro-regular-svg-icons";
import {faClockEight as farClockEight} from "@fortawesome/pro-regular-svg-icons";
import {faClockNine as farClockNine} from "@fortawesome/pro-regular-svg-icons";
import {faClockTen as farClockTen} from "@fortawesome/pro-regular-svg-icons";
import {faClockEleven as farClockEleven} from "@fortawesome/pro-regular-svg-icons";
import {faClockTwelve as farClockTwelve} from "@fortawesome/pro-regular-svg-icons";

import {faClockOneThirty as farClockOneThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockTwoThirty as farClockTwoThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockThreeThirty as farClockThreeThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockFourThirty as farClockFourThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockFiveThirty as farClockFiveThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockSixThirty as farClockSixThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockSevenThirty as farClockSevenThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockEightThirty as farClockEightThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockNineThirty as farClockNineThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockTenThirty as farClockTenThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockElevenThirty as farClockElevenThirty} from "@fortawesome/pro-regular-svg-icons";
import {faClockTwelveThirty as farClockTwelveThirty} from "@fortawesome/pro-regular-svg-icons";

import {faBadgeCheck as farBadgeCheck} from "@fortawesome/pro-regular-svg-icons";
import {faClockRotateLeft as farClockRotateLeft} from "@fortawesome/pro-regular-svg-icons";
import {faClothesHanger as farClothesHanger} from "@fortawesome/pro-regular-svg-icons";
import {faComment as farComment} from "@fortawesome/pro-regular-svg-icons";
import {faCommentDots as farCommentDots} from "@fortawesome/pro-regular-svg-icons";
import {faCommentMusic as fasCommentMusic} from "@fortawesome/pro-solid-svg-icons";
import {faCommentXmark as farCommentXmark} from "@fortawesome/pro-regular-svg-icons";
import {faComments as fadComments} from "@fortawesome/pro-duotone-svg-icons";
import {faComments as falComments} from "@fortawesome/pro-light-svg-icons";
import {faComments as farComments} from "@fortawesome/pro-regular-svg-icons";
import {faComments as fasComments} from "@fortawesome/pro-solid-svg-icons";
import {faCompress as farCompress} from "@fortawesome/pro-regular-svg-icons";
import {faComputerMouse as farComputerMouse} from "@fortawesome/pro-regular-svg-icons";
import {faCopy as farCopy} from "@fortawesome/pro-regular-svg-icons";
import {faFileArrowDown as falFileArrowDown} from "@fortawesome/pro-light-svg-icons";
import {faEdit as farEdit} from "@fortawesome/pro-regular-svg-icons";
import {faEdit as fasEdit} from "@fortawesome/pro-solid-svg-icons";
import {faElevator as farElevator} from "@fortawesome/pro-regular-svg-icons";
import {faEllipsisStroke as falEllipsisStroke} from "@fortawesome/pro-light-svg-icons";
import {faEnvelope as falEnvelope} from "@fortawesome/pro-light-svg-icons";
import {faEnvelope as farEnvelope} from "@fortawesome/pro-regular-svg-icons";
import {faEuroSign as fasEuroSign} from "@fortawesome/pro-solid-svg-icons";
import {faExclamationTriangle as falExclamationTriangle} from "@fortawesome/pro-light-svg-icons";
import {faExclamationTriangle as farExclamationTriangle} from "@fortawesome/pro-regular-svg-icons";
import {faExclamationTriangle as fasExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";
import {faEye as fadEye} from "@fortawesome/pro-duotone-svg-icons";
import {faFacebookF as fabFacebookF} from "@fortawesome/free-brands-svg-icons";
import {faFaceParty as farFaceParty} from "@fortawesome/pro-regular-svg-icons";
import {faFilePdf as farFilePdf} from "@fortawesome/pro-regular-svg-icons";
import {faFiles as farFiles} from "@fortawesome/pro-regular-svg-icons";
import {faHeadphonesAlt as falHeadphonesAlt} from "@fortawesome/pro-light-svg-icons";
import {faHeadphonesAlt as farHeadphonesAlt} from "@fortawesome/pro-regular-svg-icons";
import {faHeadSideHeadphones as farHeadSideHeadphones} from "@fortawesome/pro-regular-svg-icons";
import {faHeart as farHeart} from "@fortawesome/pro-regular-svg-icons";
import {faHeart as fasHeart} from "@fortawesome/pro-solid-svg-icons";
import {faHeartCircle as fadHeartCircle} from "@fortawesome/pro-duotone-svg-icons";
import {faHome as falHome} from "@fortawesome/pro-light-svg-icons";
import {faHome as farHome} from "@fortawesome/pro-regular-svg-icons";
import {faHome as fatHome} from "@fortawesome/pro-thin-svg-icons";
import {faHouseUser as farHouseUser} from "@fortawesome/pro-regular-svg-icons";
import {faIdeal as fabIdeal} from "@fortawesome/free-brands-svg-icons";
import {faImages as farImages} from "@fortawesome/pro-regular-svg-icons";
import {faImages as fasImages} from "@fortawesome/pro-solid-svg-icons";
import {faInfo as farInfo} from "@fortawesome/pro-regular-svg-icons";
import {faInfoCircle as falInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {faInfoCircle as farInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import {faInstagram as fabInstagram} from "@fortawesome/free-brands-svg-icons";
import {faFileInvoice as farFileInvoice} from "@fortawesome/pro-regular-svg-icons";
import {faLandmark as fadLandmark} from "@fortawesome/pro-duotone-svg-icons";
import {faLinkedinIn as fabLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faLightbulbOn as farLightbulbOn} from "@fortawesome/pro-regular-svg-icons";
import {faLink as farLink} from "@fortawesome/pro-regular-svg-icons";
import {faLinkSlash as farLinkSlash} from "@fortawesome/pro-regular-svg-icons";
import {faList as fadList} from "@fortawesome/pro-duotone-svg-icons";
import {faList as falList} from "@fortawesome/pro-light-svg-icons";
import {faLock as fasLock} from "@fortawesome/pro-solid-svg-icons";
import {faLockAlt as fasLockAlt} from "@fortawesome/pro-solid-svg-icons";
import {faMapMarkedAlt as fadMapMarkedAlt} from "@fortawesome/pro-duotone-svg-icons";
import {faMapMarkerAlt as falMapMarkerAlt} from "@fortawesome/pro-light-svg-icons";
import {faMapMarkerAlt as farMapMarkerAlt} from "@fortawesome/pro-regular-svg-icons";
import {faMapMarkerCheck as fadMapMarkerCheck} from "@fortawesome/pro-duotone-svg-icons";
import {faMapSigns as falMapSigns} from "@fortawesome/pro-light-svg-icons";
import {faMartiniGlass as farMartiniGlass} from "@fortawesome/pro-regular-svg-icons";
import {faMicrophoneStand as falMicrophoneStand} from "@fortawesome/pro-light-svg-icons";
import {faMicrophoneStand as farMicrophoneStand} from "@fortawesome/pro-regular-svg-icons";
import {faMinus as falMinus} from "@fortawesome/pro-light-svg-icons";
import {faMinus as fasMinus} from "@fortawesome/pro-solid-svg-icons";
import {faMobileAlt as fasMobileAlt} from "@fortawesome/pro-solid-svg-icons";
import {faMousePointer as fadMousePointer} from "@fortawesome/pro-duotone-svg-icons";
import {faMp3Player as farMp3Player} from "@fortawesome/pro-regular-svg-icons";
import {faMusic as fadMusic} from "@fortawesome/pro-duotone-svg-icons";
import {faMusic as falMusic} from "@fortawesome/pro-light-svg-icons";
import {faMusic as farMusic} from "@fortawesome/pro-regular-svg-icons";
import {faMusic as fasMusic} from "@fortawesome/pro-solid-svg-icons";
import {faMusicSlash as farMusicSlash} from "@fortawesome/pro-regular-svg-icons";
import {faPartyHorn as falPartyHorn} from "@fortawesome/pro-light-svg-icons";
import {faPartyHorn as farPartyHorn} from "@fortawesome/pro-regular-svg-icons";
import {faPeopleLine as farPeopleLine} from "@fortawesome/pro-regular-svg-icons";
import {faPercent as falPercent} from "@fortawesome/pro-light-svg-icons";
import {faPercent as fasPercent} from "@fortawesome/pro-solid-svg-icons";
import {faPhone as falPhone} from "@fortawesome/pro-light-svg-icons";
import {faPhone as farPhone} from "@fortawesome/pro-regular-svg-icons";
import {faPhone as fasPhone} from "@fortawesome/pro-solid-svg-icons";
import {faPhoneRotary as farPhoneRotary} from "@fortawesome/pro-regular-svg-icons";
import {faPhoneSquare as fadPhoneSquare} from "@fortawesome/pro-duotone-svg-icons";
import {faPlaneUp as falPlaneUp} from "@fortawesome/pro-regular-svg-icons";
import {faPlaneUpSlash as falPlaneUpSlash} from "@fortawesome/pro-regular-svg-icons";
import {faPlay as falPlay} from "@fortawesome/pro-light-svg-icons";
import {faPlayCircle as fadPlayCircle} from "@fortawesome/pro-duotone-svg-icons";
import {faPlayCircle as falPlayCircle} from "@fortawesome/pro-light-svg-icons";
import {faPlug as farPlug} from "@fortawesome/pro-regular-svg-icons";
import {faPlus as falPlus} from "@fortawesome/pro-light-svg-icons";
import {faPlus as farPlus} from "@fortawesome/pro-regular-svg-icons";
import {faPlus as fasPlus} from "@fortawesome/pro-solid-svg-icons";
import {faQuestion as farQuestion} from "@fortawesome/pro-regular-svg-icons";
import {faQuestionCircle as falQuestionCircle} from "@fortawesome/pro-light-svg-icons";
import {faRingsWedding as falRingsWedding} from "@fortawesome/pro-light-svg-icons";
import {faRingsWedding as farRingsWedding} from "@fortawesome/pro-regular-svg-icons";
import {faRoad as farRoad} from "@fortawesome/pro-regular-svg-icons";
import {faRuler as farRuler} from "@fortawesome/pro-regular-svg-icons";
import {faSaxophone as farSaxophone} from "@fortawesome/pro-regular-svg-icons";
import {faShareNodes as falShareNodes} from "@fortawesome/pro-light-svg-icons";
import {faShield as fasShield} from "@fortawesome/pro-solid-svg-icons";
import {faSlash as farSlash} from "@fortawesome/pro-regular-svg-icons";
import {faSlashForward as farSlashForward} from "@fortawesome/pro-regular-svg-icons";
import {faSlidersH as farSlidersH} from "@fortawesome/pro-regular-svg-icons";
import {faSmile as fadSmile} from "@fortawesome/pro-duotone-svg-icons";
import {faSmile as farSmile} from "@fortawesome/pro-regular-svg-icons";
import {faSortAlt as farSortAlt} from "@fortawesome/pro-regular-svg-icons";
import {faSpeakers as falSpeakers} from "@fortawesome/pro-light-svg-icons";
import {faSpeakers as farSpeakers} from "@fortawesome/pro-regular-svg-icons";
import {faSpinnerThird as fadSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import {faSquare as farSquare} from "@fortawesome/pro-regular-svg-icons";
import {faSquare as fasSquare} from "@fortawesome/pro-solid-svg-icons";
import {faSquareCheck as fadSquareCheck} from "@fortawesome/pro-duotone-svg-icons";
import {faSquareCheck as farSquareCheck} from "@fortawesome/pro-regular-svg-icons";
import {faSquareCheck as fasSquareCheck} from "@fortawesome/pro-solid-svg-icons";
import {faSquareHeart as farSquareHeart} from "@fortawesome/pro-regular-svg-icons";
import {faSquareX as fadSquareX} from "@fortawesome/pro-duotone-svg-icons";
import {faSquareX as farSquareX} from "@fortawesome/pro-regular-svg-icons";
import {faSquareX as fasSquareX} from "@fortawesome/pro-solid-svg-icons";
import {faStar as fadStar} from "@fortawesome/pro-duotone-svg-icons";
import {faStar as falStar} from "@fortawesome/pro-light-svg-icons";
import {faStar as farStar} from "@fortawesome/pro-regular-svg-icons";
import {faHundredPoints as farHundredPoints} from "@fortawesome/pro-regular-svg-icons";
import {faStar as fasStar} from "@fortawesome/pro-solid-svg-icons";
import {faStarHalfAlt as fasStarHalfAlt} from "@fortawesome/pro-solid-svg-icons";
import {faStars as farStars} from "@fortawesome/pro-regular-svg-icons";
import {faStars as fasStars} from "@fortawesome/pro-solid-svg-icons";
import {faStopwatch as farStopwatch} from "@fortawesome/pro-regular-svg-icons";
import {faStopwatch as fasStopwatch} from "@fortawesome/pro-solid-svg-icons";
import {faTableCellsLarge as farTableCellsLarge} from "@fortawesome/pro-regular-svg-icons";
import {faTag as falTag} from "@fortawesome/pro-light-svg-icons";
import {faThumbsUp as fadThumbsUp} from "@fortawesome/pro-duotone-svg-icons";
import {faTimer as falTimer} from "@fortawesome/pro-light-svg-icons";
import {faTimer as farTimer} from "@fortawesome/pro-regular-svg-icons";
import {faTimes as falTimes} from "@fortawesome/pro-light-svg-icons";
import {faTimes as farTimes} from "@fortawesome/pro-regular-svg-icons";
import {faTimes as fasTimes} from "@fortawesome/pro-solid-svg-icons";
import {faTimesCircle as fasTimesCircle} from "@fortawesome/pro-solid-svg-icons";
import {faTrash as farTrash} from "@fortawesome/pro-regular-svg-icons";
import {faTrashCanSlash as farTrashCanSlash} from "@fortawesome/pro-regular-svg-icons";
import {faTrophy as fasTrophy} from "@fortawesome/pro-solid-svg-icons";
import {faTruckRampBox as farTruckRampBox} from "@fortawesome/pro-regular-svg-icons";
import {faUser as fadUser} from "@fortawesome/pro-duotone-svg-icons";
import {faUser as falUser} from "@fortawesome/pro-light-svg-icons";
import {faUser as farUser} from "@fortawesome/pro-regular-svg-icons";
import {faUser as fasUser} from "@fortawesome/pro-solid-svg-icons";
import {faUserCog as farUserCog} from "@fortawesome/pro-regular-svg-icons";
import {faUserCog as fasUserCog} from "@fortawesome/pro-solid-svg-icons";
import {faUserGroup as farUserGroup} from "@fortawesome/pro-regular-svg-icons";
import {faUserPlus as farUserPlus} from "@fortawesome/pro-regular-svg-icons";
import {faUserPlus as fasUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {faUsers as falUsers} from "@fortawesome/pro-light-svg-icons";
import {faUsers as farUsers} from "@fortawesome/pro-regular-svg-icons";
import {faUsersCog as farUsersCog} from "@fortawesome/pro-regular-svg-icons";
import {faUserSlash as farUserSlash} from "@fortawesome/pro-regular-svg-icons";
import {faUserTie as farUserTie} from "@fortawesome/pro-regular-svg-icons";
import {faUserVneck as farUserVneck} from "@fortawesome/pro-regular-svg-icons";
import {faUtensils as farUtensils} from "@fortawesome/pro-regular-svg-icons";
import {faUtensilsSlash as farUtensilsSlash} from "@fortawesome/pro-regular-svg-icons";
import {faVideo as farVideo} from "@fortawesome/pro-regular-svg-icons";
import {faVolumeHigh as farVolumeHigh} from "@fortawesome/pro-regular-svg-icons";
import {faVolumeLow as farVolumeLow} from "@fortawesome/pro-regular-svg-icons";
import {faVolumeOff as farVolumeOff} from "@fortawesome/pro-regular-svg-icons";
import {faVolumeSlash as farVolumeSlash} from "@fortawesome/pro-regular-svg-icons";
import {faVolumeXmark as farVolumeXmark} from "@fortawesome/pro-regular-svg-icons";
import {faWhatsapp as fabWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faWindowAlt as fadWindowAlt} from "@fortawesome/pro-duotone-svg-icons";
import {faYoutube as fabYoutube} from "@fortawesome/free-brands-svg-icons";

library.add(
	fabFacebookF,
	fabIdeal,
	fabInstagram,
	fabLinkedinIn,
	fabWhatsapp,
	fabYoutube,
	fadAddressCard,
	fadAt,
	farClipboard,
	fadBadgePercent,
	fadCalendarAlt,
	fadCalendarCheck,
	fadCheck,
	fadCheckCircle,
	fadCity,
	fadClipboardListCheck,
	fadComments,
	fadEye,
	fadHeartCircle,
	fadLandmark,
	fadList,
	fadMapMarkedAlt,
	fadMapMarkerCheck,
	fadMapMarkerCheck,
	fadMousePointer,
	fadMusic,
	fadPhoneSquare,
	fadPlayCircle,
	fadSmile,
	fadSpinnerThird,
	fadSquareCheck,
	fadSquareX,
	fadStar,
	fadThumbsUp,
	fadUser,
	fadWindowAlt,
	falBalloons,
	falBan,
	falBuilding,
	farHundredPoints,
	falCalculator,
	falCalendarAlt,
	falCalendarCheck,
	falCalendarClock,
	falCalendarDay,
	falCalendarTimes,
	falCameraAlt,
	falCheck,
	falCheckCircle,
	falChevronDown,
	falChevronLeft,
	falChevronRight,
	falChevronUp,
	falCircle,
	falClipboardListCheck,
	falClock,
	falComments,
	falFileArrowDown,
	falEllipsisStroke,
	falEnvelope,
	falExclamationTriangle,
	falHeadphonesAlt,
	falHome,
	falInfoCircle,
	falList,
	falMapMarkerAlt,
	falMapSigns,
	falMicrophoneStand,
	falMinus,
	falMusic,
	falPartyHorn,
	falPercent,
	falPhone,
	falPlaneUp,
	falPlaneUpSlash,
	falPlay,
	falPlayCircle,
	falPlus,
	falQuestionCircle,
	falRingsWedding,
	falShareNodes,
	falSpeakers,
	falStar,
	falTag,
	falTimer,
	falTimes,
	falUser,
	falUsers,
	farArrowAltCircleLeft,
	farArrowUpRightFromSquare,
	farArrowLeft,
	farArrowRight,
	farBalloons,
	farBan,
	farBanParking,
	farBars,
	farBuilding,
	farCakeCandles,
	farCalendar,
	farCalendarAlt,
	farCalendarCheck,
	farCalendarClock,
	farCalendarStar,
	farCalendarXmark,
	farCalendarDay,
	farCalendarPlus,
	farCameraAlt,
	farCameraRetro,
	farCar,
	farChairOffice,
	farChampagneGlass,
	farChampagneGlasses,
	farChartLine,
	farChartNetwork,
	farCheck,
	farCheckCircle,
	farChevronDown,
	farChevronLeft,
	farChevronRight,
	farChevronUp,
	farCircle,
	farCircleDashed,
	farCircleDot,
	farClipboardListCheck,
	farClock,
	farClockOne,
	farClockTwo,
	farClockThree,
	farClockFour,
	farClockFive,
	farClockSix,
	farClockSeven,
	farClockEight,
	farClockNine,
	farClockTen,
	farClockEleven,
	farClockTwelve,
	farClockOneThirty,
	farClockTwoThirty,
	farClockThreeThirty,
	farClockFourThirty,
	farClockFiveThirty,
	farClockSixThirty,
	farClockSevenThirty,
	farClockEightThirty,
	farClockNineThirty,
	farClockTenThirty,
	farClockElevenThirty,
	farClockTwelveThirty,
	farClockRotateLeft,
	farClothesHanger,
	farComment,
	farCommentDots,
	farComments,
	farCommentXmark,
	farCompress,
	farComputerMouse,
	farCopy,
	farEdit,
	farElevator,
	farEnvelope,
	farExclamationTriangle,
	farFaceParty,
	farFilePdf,
	farFiles,
	farHeadphonesAlt,
	farHeadSideHeadphones,
	farHeart,
	farHome,
	farHouseUser,
	farImages,
	farInfo,
	farInfoCircle,
	farFileInvoice,
	farLightbulbOn,
	farLink,
	farLinkSlash,
	farMapMarkerAlt,
	farMartiniGlass,
	farMicrophoneStand,
	farMp3Player,
	farMusic,
	farMusicSlash,
	farPartyHorn,
	farPeopleLine,
	farPhone,
	farPhoneRotary,
	farPlug,
	farPlus,
	farQuestion,
	farRingsWedding,
	farRoad,
	farRuler,
	farSaxophone,
	farSlash,
	farSlashForward,
	farSlidersH,
	farSmile,
	farSortAlt,
	farShareNodes,
	farSpeakers,
	farSquare,
	farSquareCheck,
	farSquareHeart,
	farSquareX,
	farStar,
	farStars,
	farStopwatch,
	farTableCellsLarge,
	farTimer,
	farTimes,
	farTruckRampBox,
	farUser,
	farUserCog,
	farUserGroup,
	farUserPlus,
	farUsers,
	farUsersCog,
	farUserSlash,
	farUserTie,
	farUserVneck,
	farUtensils,
	farUtensilsSlash,
	farVideo,
	farVolumeHigh,
	farVolumeLow,
	farVolumeOff,
	farVolumeSlash,
	farVolumeXmark,
	fasArrowLeft,
	fasArrowRight,
	fasAt,
	fasBell,
	fasBellExclamation,
	fasBellOn,
	fasCalendarCheck,
	fasCheck,
	fasCheckCircle,
	fasChevronDown,
	fasChevronLeft,
	fasChevronRight,
	fasChevronUp,
	fasCircle,
	fasClock,
	fasCommentMusic,
	fasComments,
	fasEdit,
	fasEuroSign,
	fasExclamationTriangle,
	fasHeart,
	fasImages,
	fasLock,
	fasLockAlt,
	fasMinus,
	fasMobileAlt,
	fasMusic,
	fasPercent,
	fasPhone,
	fasPlus,
	fasShield,
	fasSquare,
	fasSquareCheck,
	fasSquareX,
	fasStar,
	fasStarHalfAlt,
	fasStars,
	fasStopwatch,
	fasTimes,
	fasTimesCircle,
	farTrash,
	farTrashCanSlash,
	fasTrophy,
	fasUser,
	fasUserCog,
	fasUserPlus,
	fatHome,
	farBadgeCheck
);
