<template>
	<div class="progress progress-tdc">
		<div
			class="progress-bar progress-bar-tdc"
			:class="{'progress-bar-tdc-animate': tabViewCount == 1}"
			role="progressbar"
			:style="{width: progressBarWidth}"
		>
			<div class="progress-meter-tdc">
				<strong>
					<countUp
						:delay="1000"
						:endVal="Math.round(wishesProgress)"
						:options="counterOptions"
						v-if="tabViewCount == 1"
					/>
					<span v-else>{{ Math.round(wishesProgress) }}</span> </strong
				><small>%</small>
			</div>
		</div>
		<img class="progress-arrow" alt="" src="~@/assets/images/arrow-klaar.png" />
		<font-awesome-icon class="text-primary" :icon="['fas', 'euro-sign']" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import countUp from "vue-countup-v2";

export default {
	name: "ProgressBarWishes",
	components: {FontAwesomeIcon, countUp},
	computed: {
		...mapGetters({
			wishesProgress: "getWishesProgress",
		}),
	},
	props: {
		tabViewCount: {
			type: Number,
			default: 1,
		},
	},

	data() {
		return {
			progressBarWidth: 0,
			counterOptions: {
				useEasing: true,
				useGrouping: true,
				separator: ",",
				decimal: ".",
				prefix: "",
				suffix: "",
			},
		};
	},
	methods: {
		setProgressBarWidth() {
			var delaySeconds = 0;
			if (this.tabViewCount == 1) delaySeconds = 1000;
			this.delay = setTimeout(
				function () {
					this.progressBarWidth = Math.round(this.wishesProgress) + "% !important";
				}.bind(this),
				delaySeconds
			);
		},
	},
	mounted() {
		this.setProgressBarWidth();
	},
};
</script>

<style lang="scss">
.progress-tdc {
	margin-top: 30px;
	margin-bottom: 40px;
	overflow: visible !important;
	background-color: #0094d7 !important;
	border-radius: 5px;
	height: 7px !important;
	position: relative;

	.progress-bar-tdc {
		width: 0% !important;
		overflow: visible !important;
		background-color: #49dcff !important;
		position: relative;
		border-radius: 5px;
		transition: none;
		.progress-meter-tdc {
			z-index: 8;
			height: 46px;
			width: 46px;
			border-radius: 50%;
			border: 3px solid #49dcff;
			background-color: #fff !important;
			color: #000;
			font-weight: 900;
			position: absolute;
			right: -3px;
			font-size: 18px;
			@extend .d-flex;
			@extend .justify-content-center;
			@extend .align-items-center;
		}
	}
	.progress-bar-tdc-animate {
		transition: width 1.5s ease;
	}
	.progress-arrow {
		height: 40px;
		position: absolute;
		right: 10px;
		margin-top: 10px;
	}
}
</style>
