<template>
	<div class="position-relative">
		<div class="mt-3 mt-md-3 row row-cols-1">
			<div class="col d-flex align-items-stretch">
				<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'map-marker-alt']" />
					<div class="d-flex flex-column w-100">
						<div class="d-flex justify-content-between">
							<strong class="flex-grow-1">Mijn factuuradres</strong>
						</div>
						<div class="w-100 flex-fill">
							<p v-if="BillingStreet" class="mb-0">
								<span v-if="BillingStreet">{{ BillingStreet }}<br /></span>
								<span v-if="BillingPostalCode">{{ BillingPostalCode }}<br /></span>
								{{ BillingCity }}<br />
								{{ countries[BillingCountryCode] }}
							</p>
							<div v-else>
								<p>Adres onbekend</p>
							</div>
							<span class="ahref" @click="$bvModal.show(modalId)"
								>Zoek mijn factuuradres <font-awesome-icon :icon="['fas', 'chevron-right']" class="mr-2"
							/></span>
						</div>
					</div>
				</div>
			</div>
			<div class="col"></div>
			<div class="col">
				<Alert alertType="warning"> Het factuuradres is belangrijk voor het verwerken van de betaling </Alert>
			</div>
		</div>

		<b-modal
			:id="modalId"
			ok-only
			header-bg-variant="light"
			header-text-variant="dark"
			footer-bg-variant="light"
			footer-text-variant="dark"
		>
			<template #modal-header="{close}">
				<div class="d-flex w-100 flex-row justify-content-between">
					<h5 class="m-0 p-0 w-100 flex-grow-1">Factuuradres</h5>
					<b-button
						size="sm"
						variant="outline-primary"
						@click="close()"
						class="d-flex align-items-center flex-shrink-1"
						style="width: auto"
					>
						<font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
					</b-button>
				</div>
			</template>
			<template #modal-footer="{ok}">
				<div class="d-flex w-100 flex-row justify-content-start">
					<b-button
						size="sm"
						variant="outline-primary"
						@click="ok()"
						class="d-flex align-items-center flex-shrink-1"
						style="width: auto"
					>
						Sluiten
					</b-button>
				</div>
			</template>
			<b-form :aria-hidden="isLoading ? 'true' : null">
				<b-form-group
					v-if="manualAddressInput"
					label="Straatnaam + huisnummer"
					label-for="BillingStreet"
					class="border-bottom pb-3"
					:class="getInputClass('BillingStreet')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="BillingStreet"
							id="BillingStreet"
							placeholder=" "
							@input.native="delayUpdateBillingAddress('BillingStreet')"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					v-if="manualAddressInput"
					label="Postcode"
					label-for="BillingPostalCode"
					class="border-bottom pb-3"
					:class="getInputClass('BillingPostalCode')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="BillingPostalCode"
							id="BillingPostalCode"
							placeholder=" "
							@input.native="delayUpdateBillingAddress('BillingPostalCode')"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					v-if="manualAddressInput"
					label="Plaatsnaam"
					label-for="BillingCity"
					class="border-bottom pb-3"
					:class="getInputClass('BillingCity')"
				>
					<div class="position-relative">
						<b-form-input
							v-model="BillingCity"
							id="BillingCity"
							placeholder=" "
							@input.native="delayUpdateBillingAddress('BillingCity')"
							:disabled="isConfirmed"
						></b-form-input>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					v-if="manualAddressInput"
					label="Land"
					label-for="BillingCountryCode"
					class="border-bottom pb-3"
					:class="getInputClass('BillingCountryCode')"
				>
					<div class="position-relative">
						<b-form-select
							v-model="BillingCountryCode"
							id="BillingCountryCode"
							:disabled="isConfirmed"
							@input.native="delayUpdateBillingAddress('BillingCountryCode')"
						>
							<option v-for="(label, code) of countries" :key="code" :value="code">{{ label }}</option>
						</b-form-select>
						<InputFeedback />
					</div>
				</b-form-group>

				<b-form-group
					v-else
					label="Factuuradres"
					label-for="BillingStreet"
					class="border-bottom pb-3"
					:class="getInputClass('BillingAddress')"
				>
					<div class="position-relative">
						<google-autocomplete
							id="address"
							classname="form-control"
							placeholder="Zoek mijn adres"
							v-on:placechanged="getAddressData"
						>
						</google-autocomplete>
						<InputFeedback />
						<div class="mt-2">
							<div>{{ BillingStreet }}</div>
							<div>{{ BillingPostalCode }}</div>
							<div>{{ BillingCity }}</div>
							<div>{{ countries[BillingCountryCode] }}</div>
						</div>
					</div>
				</b-form-group>

				<b-form-group>
					<div class="row row-cols-2 row-cols-md-2 mx-n1">
						<b-form-checkbox v-model="manualAddressInput"> Adres handmatig invullen </b-form-checkbox>
					</div>
				</b-form-group>
			</b-form>
		</b-modal>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import InputFeedback from "@/components/customer/InputFeedback";
import countries from "@/store/modules/shared/countries";
import Alert from "@/components/customer/Alert";

import GoogleAutocomplete from "@/components/customer/GoogleAutoComplete";

export default {
	name: "CustomerInvoiceAddress",
	components: {
		InputFeedback,
		GoogleAutocomplete,
		Alert,
	},
	props: {
		placement: {
			type: String,
			default: "top",
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isLoading: "getLoadingState",
			isConfirmed: "isConfirmed",
			loadingInput: "getLoadingInput",
		}),
		modalId: function () {
			return "av-modal-" + this.placement;
		},
	},
	data() {
		return {
			BillingStreet: null,
			BillingPostalCode: null,
			BillingCity: null,
			BillingCountryCode: null,
			manualAddressInput: false,
			countries: countries,
		};
	},
	methods: {
		updateAccountBillingAddress(input) {
			this.$store.dispatch("postBooking", {
				action: "updateBillingInformation",
				data: {
					BillingStreet: this.BillingStreet,
					BillingPostalCode: this.BillingPostalCode,
					BillingCity: this.BillingCity,
					BillingCountryCode: this.BillingCountryCode,
				},
				setBooking: false,
				input: input,
			});
		},
		delayUpdateBillingAddress(input) {
			clearTimeout(this.billingAddressTimer);
			this.billingAddressTimer = setTimeout(() => {
				this.updateAccountBillingAddress(input);
			}, 2000);
		},
		getInputClass(input) {
			if (input == this.loadingInput.name) {
				if (this.loadingInput.status == "saving") return "input-saving";
				else if (this.loadingInput.status == "saved") {
					this.delay = setTimeout(
						function () {
							this.$root.$emit("bv::hide::popover");
						}.bind(this),
						4000
					);
					return "input-saved";
				}
			}
			return "";
		},
		getAddressData(data) {
			this.BillingStreet = data.route || "";
			if (data.street_number !== null && data.street_number !== undefined) {
				this.BillingStreet += (this.BillingStreet.length > 0 ? " " : "") + data.street_number;
			}
			this.BillingPostalCode = data.postal_code;
			this.BillingCity = data.locality;
			this.BillingCountryCode = data.country;
			this.updateAccountBillingAddress("BillingAddress");
		},
	},
	mounted() {
		this.BillingStreet = this.booking.Account__r.BillingStreet;
		this.BillingPostalCode = this.booking.Account__r.BillingPostalCode;
		this.BillingCity = this.booking.Account__r.BillingCity;
		this.BillingCountryCode = this.booking.Account__r.BillingCountryCode;
	},
};
</script>

<style>
.pac-container {
	z-index: 5000;
}
</style>
