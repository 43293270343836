<template>
	<div>
		<div v-if="!booking.FollowUpOptOut__c">
			<h2>Sorry dat we je tot last waren, waarom wil je de emails niet ontvangen?</h2>
			<div>
				<textarea v-model="reason" rows="5" maxlength="255"></textarea>
			</div>
			<button class="btn btn-warning" @click="doOptOut">Uitschrijven</button>
		</div>
		<div v-if="booking.FollowUpOptOut__c">
			<h2>Je bent uitgeschreven voor toekomstige emails</h2>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "OptOut",
	data() {
		return {
			reason: "",
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
		}),
	},
	methods: {
		doOptOut() {
			this.$store.dispatch("followUpOptOut", {
				action: "followUpOptOut",
				data: {reason: this.reason},
				setBooking: true,
			});
		},
	},
};
</script>

<style lang="scss">
textarea {
	width: 100%;
}
</style>
