<template>
	<div class="mt-5">
		<div class="row row-cols-2 row-cols-sm-4">
			<div class="col text-center mb-2 d-flex flex-column px-0">
				<img
					src="~@/assets/images/illustration-allinprijs-2.png"
					class="img-responsive w-50 mx-auto"
					style="max-width: 175px"
				/>
				<strong class="text-primary">All-in prijs: <span class="text-success">geen verassingen achteraf</span></strong>
			</div>
			<div class="col text-center mb-2 d-flex flex-column px-0">
				<img
					src="~@/assets/images/illustration-5050-2.png"
					class="img-responsive w-50 mx-auto"
					style="max-width: 175px"
				/>
				<strong class="text-primary">50% pas <span class="text-success">achteraf betalen</span></strong>
			</div>
			<div class="col text-center mb-2 d-flex flex-column px-0">
				<img
					src="~@/assets/images/illustration-100gratis-2.png"
					class="img-responsive w-50 mx-auto"
					style="max-width: 175px"
				/>
				<strong class="text-primary"
					><span class="text-success">Gratis annuleren</span> tot 14 dagen voor het feest</strong
				>
			</div>
			<div class="col text-center mb-2 d-flex flex-column px-0">
				<img
					src="~@/assets/images/illustration-moneyback-2.png"
					class="img-responsive w-50 mx-auto"
					style="max-width: 175px"
				/>
				<strong class="text-primary">Niet goed, <span class="text-success">geld terug</span></strong>
			</div>
		</div>
		<div v-if="longList" class="pt-4 mt-4 what-you-get">
			<!-- <p class="h3 mb-4 text-center"><strong class="text-primary">We regelen alles voor één vaste prijs</strong></p> -->
			<StrikeThroughTitle alwaysVisible>We regelen alles voor één vaste prijs</StrikeThroughTitle>
			<div class="row row-cols-2 row-cols-sm-3 row-cols-lg-5">
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img class="mx-auto d-block" alt="Huur licht en geluid" src="~@/assets/images/illustration-speaker.png" />
						<p>Huur licht en geluid</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img class="mx-auto img-responsive" alt="Reserve DJ" src="~@/assets/images/illustration-backup-dj.png" />
						<p>Reserve DJ</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img
							class="mx-auto img-responsive"
							src="~@/assets/images/illustration-speech.png"
							alt="Afstemming met feestlocatie"
						/>
						<p>Afstemming met feestlocatie</p>
					</div>
				</div>
				<div class="text-center col align-top mb-1">
					<div class="wyg-block">
						<img
							class="mx-auto img-responsive"
							src="~@/assets/images/illustration-parking.png"
							alt="Reis- en parkeerkosten"
						/>
						<p>Reis- en parkeerkosten</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img
							class="mx-auto img-responsive"
							src="~@/assets/images/illustration-checklist.png"
							alt="Persoonlijk wensenformulier"
						/>
						<p>Persoonlijk wensenformulier</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img
							class="mx-auto img-responsive"
							src="~@/assets/images/illustration-music-notes.png"
							alt="Muzikale voorbereiding"
						/>
						<p>Muzikale voorbereiding</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img
							class="mx-auto img-responsive"
							src="~@/assets/images/illustration-djbooth.png"
							alt="Op- en afbouw apparatuur"
						/>
						<p>Op- en afbouw apparatuur</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img class="mx-auto img-responsive" src="~@/assets/images/illustration-shield.png" alt="Verzekeringen" />
						<p>Veilig boeken</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img class="mx-auto img-responsive" src="~@/assets/images/illustration-21percent.png" alt="Btw" />
						<p>Btw</p>
					</div>
				</div>
				<div class="text-center col align-top">
					<div class="wyg-block">
						<img class="mx-auto img-responsive" src="~@/assets/images/illustration-clock.png" alt="Non-stop draaien" />
						<p>Non-stop draaien</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StrikeThroughTitle from "@/components/customer/StrikeThroughTitle";

export default {
	name: "BookingIncludes",
	components: {
		StrikeThroughTitle,
	},
	props: {
		longList: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.what-you-get {
	color: $tdc-blue;
	.col {
		@extend .mb-4;
		.wyg-block {
			@extend .py-3;
			@extend .py-md-2;
			@extend .py-lg-2;
			@extend .px-1;
			@extend .d-flex;
			@extend .align-items-center;
			@extend .justify-content-center;
			@extend .flex-column;
			background-color: #eeeeee;
			border-radius: 4px;
			height: 100%;
			img {
				width: 50%;
			}
		}
	}
}
</style>
