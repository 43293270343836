<template>
	<div v-if="!isLoading">
		<DisableTrengoChat />
		<QuoteProgress :step="1" :steps="4" :steps_type="'booking'" />
		<div class="desktop-content-left-right-wrapper mb-4">
			<DjBlock
				template="DjBlockComplete"
				templateVariant="DirectBookingCart"
				:sf_name="djClass"
				:priceIncVat="priceInc"
				:priceExcVat="priceExc"
				:showDefaultCta="false"
				:showCustomCta="true"
				:activeCalculator="false"
			>
				<template slot="customCta">
					<div class="d-flex flex-column align-items-center align-items-md-end">
						<ConfirmBookingButton bookingType="booking" :djClass="djClass" />
					</div>
				</template>
			</DjBlock>
		</div>
		<BookingIncludes />
		<div class="d-flex flex-column align-items-center border-top border-success border-bottom py-4">
			<div class="mx-auto" style="max-width: 450px">
				<ConfirmBookingButton bookingType="booking" :djClass="djClass" placement="bottom" />
			</div>
		</div>
		<Faqs :questionSet="[1, 2, 4, 5, 6]" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import QuoteProgress from "@/components/customer/QuoteProgress";
import BookingIncludes from "@/components/customer/BookingIncludes";
import ConfirmBookingButton from "@/components/customer/ConfirmBookingButton";
import Faqs from "@/components/customer/Faqs";
import DjBlock from "@/components/customer/DjBlock";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "DirectBookingCart",
	components: {
		QuoteProgress,
		BookingIncludes,
		ConfirmBookingButton,
		Faqs,
		DjBlock,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			djClass: "getCurrentDjClass",
			isLoading: "getLoadingState",
			prices: "getPrices",
		}),
		djPriceClass: function () {
			if (this.djClass === "Junior") {
				return "p_junior";
			} else if (this.djClass === "Premium") {
				return "p_premium";
			}
			return "p_a_class";
		},
		priceInc: function () {
			return this.prices[this.djPriceClass]?.p_totalAmount;
		},
		priceExc: function () {
			return this.prices[this.djPriceClass]?.p_exVatAmount;
		},
	},
	methods: {
		formatPrice(price, precision) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
};
</script>
