<template>
	<span>
		<span class="d-flex flex-nowrap align-items-center justify-content-center">
			<strong>
				<span class="mb-0 display-4 yellow-highlight d-block position-relative">
					<div v-if="business">{{ priceExc }}</div>
					<div v-else>{{ priceInc }}</div>
					<div v-if="isQuoteConfirmedTemplateVariant" class="price-locked">
						<font-awesome-icon class="" :icon="['fas', 'lock-alt']" />
					</div>
				</span>
			</strong>
		</span>
		<span class="text-muted" v-if="!business">(incl. alle kosten en btw)</span>
		<span class="text-muted" v-else>incl. alle kosten en excl. btw</span>
	</span>
</template>

<script>
export default {
	name: "BookingPricing",
	props: {
		priceInc: {
			type: String,
			required: true,
		},
		priceExc: {
			type: String,
			required: true,
		},
		business: {
			type: Boolean,
			default: true,
		},
		templateVariant: {
			type: String,
			default: "",
		},
	},
	computed: {
		isQuoteConfirmedTemplateVariant: function () {
			return this.templateVariant == "QuoteConfirmed" ? true : false;
		},
	},
};
</script>
