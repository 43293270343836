<template>
	<div>
		<PageTitle
			:title="$t('mol.spacesPage.title.Venue spaces')"
			:subTitle="subTitle"
			:breadcrumbs="breadcrumbs"
			:adaptSubnav="true"
		/>
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavEditing">
				<SlideOutPanel v-if="spaceOnDisplay" :panelOpen="molEditingState">
					<template slot="outerContent">
						<EditButton @onClick="openEditingPanel()">
							{{ $t("mol.general.button.Edit") }}
						</EditButton>
					</template>
					<template slot="innerContent">
						<div v-if="spaceOnDisplay">
							<div class="absolute right-0">
								<button
									@click="closeEditingPanel()"
									class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
								>
									<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
								</button>
							</div>
							<PageTitle
								:title="$t('mol.spacesPage.title.Venue spaces')"
								:subTitle="subTitle"
								:breadcrumbs="breadcrumbsEdit"
							/>
							<SpaceForm edit :mainSpaceId="mainSpace.Id" :space="editSpace" :formErrors="formErrors" />
							<FormGridWrapper>
								<template slot="right">
									<div class="flex flex-row-reverse w-full">
										<Button
											@click.native="saveSpace(editSpace)"
											type="green"
											icon="check"
											class="flex-grow"
											:label="$t('mol.general.button.Save')"
										/>
										<Button @click.native="closeEditingPanel()" class="mx-2" type="cancel" />
										<Button
											v-if="editSpace.Id != mainSpace.Id"
											@click.native="openConfirmRemovePanel()"
											type="delete"
											icon="trash"
											:label="$t('mol.general.button.Remove')"
										/>
										<Button
											v-else
											type="red"
											icon="trash-can-slash"
											disabled
											:label="$t('mol.general.button.Remove')"
										/>
									</div>
								</template>
							</FormGridWrapper>
						</div>
					</template>
				</SlideOutPanel>
				<SlideOutPanel :panelOpen="panelConfirmRemove" zIndexClass="z-50">
					<template slot="innerContent">
						<div class="h-full flex-col flex align-middle">
							<div class="mb-3 font-bold">
								{{
									$t("mol.spacesPage.string.Are you sure you want to remove space", {space: editSpace.Space_Name__c})
								}}
							</div>

							<div class="flex flex-row">
								<Button @click.native="closeConfirmRemovePanel()" class="mr-2" type="cancel" />
								<Button
									@click.native="deleteSpace(editSpace)"
									type="red"
									icon="trash"
									:label="$t('mol.general.button.Remove')"
								/>
							</div>
						</div>
					</template>
				</SlideOutPanel>
			</template>
			<template slot="subNavTabContent">
				<div v-if="creatingSpace">
					<SpaceForm create :space="newSpace" :formErrors="formErrors" />
					<FormGridWrapper>
						<template slot="right">
							<div class="flex flex-row-reverse w-full">
								<Button
									@click.native="saveSpace(newSpace)"
									icon="user-plus"
									class="flex-grow"
									:label="$t('mol.spaceForm.button.Save new space')"
								/>
							</div>
						</template>
					</FormGridWrapper>
				</div>
				<div v-else-if="spaceCount == 0">
					{{ $t("mol.spacesPage.string.No venue spaces found") }}
				</div>
				<div v-else-if="!spaceOnDisplay">
					{{ $t("mol.spacesPage.string.Choose a venue space to view more details or") }}
					<Ahref
						@click.native="$store.commit('SET_MOL_SUBNAV_HASH', '#create')"
						:label="$t('mol.spacesPage.link.create a new venue space')"
					/>
				</div>
				<div v-else>
					<SpaceData :space="spaceOnDisplay" />
				</div>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import SubNav from "@/components/location/SubNav";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import Button from "@/components/location/Button";
import EditButton from "@/components/location/EditButton";
import Ahref from "@/components/location/Ahref";
import SpaceForm from "@/components/location/SpaceForm";
import SpaceData from "@/components/location/SpaceData";
import FormGridWrapper from "@/components/location/FormGridWrapper";

export default {
	name: "LocationSpaces",
	components: {
		PageTitle,
		SubNav,
		SlideOutPanel,
		Button,
		EditButton,
		Ahref,
		SpaceForm,
		SpaceData,
		FormGridWrapper,
	},
	data() {
		return {
			editSpace: [],
			panelConfirmRemove: false,
			formErrors: this.initialSpaceFormErrors(),
			newSpace: this.initialNewSpace(),
		};
	},
	computed: {
		...mapGetters({
			error: "getError",
			molSubnavHash: "getMolSubnavHash",
			molEditingState: "getMolEditingState",
			location: "getLocationInformation",
			mainSpace: "getLocationMainSpaceDetails",
			spaces: "getLocationSpaces",
		}),
		subNavItems() {
			let navItems = [];
			let createSpaceNavItem = {};

			createSpaceNavItem["type"] = "create";
			createSpaceNavItem["label"] = this.$t("mol.spacesPage.subNav.Create new space");
			createSpaceNavItem["route"] = {name: "Spaces", hash: "#create"};
			createSpaceNavItem["title"] = "";
			createSpaceNavItem["subTitle"] = "";
			createSpaceNavItem["icon"] = "plus";

			navItems.push(createSpaceNavItem);
			this.spaces.forEach((space) => {
				let spaceItem = {};
				spaceItem["type"] = "space";
				spaceItem["label"] = space.Space_Name__c;
				spaceItem["route"] = {name: "Spaces", hash: "#" + space.Id};
				spaceItem["title"] = space.Space_Name__c;
				spaceItem["subTitle"] = "";
				spaceItem["icon"] = "champagne-glass";
				spaceItem["mainSpace"] = space.Id == this.mainSpace.Id;
				navItems.push(spaceItem);
			});
			return navItems;
		},
		spaceCount() {
			return this.spaces.length;
		},
		spaceOnDisplay() {
			let spaceOnDisplayID = this.molSubnavHash.substring(1);
			let spaceOnDisplay = null;
			if (this.spaces.length > 0) {
				spaceOnDisplay = this.spaces.find((space) => space.Id === spaceOnDisplayID);
			}
			return spaceOnDisplay;
		},
		creatingSpace() {
			return this.molSubnavHash.substring(1) == "create";
		},
		isMainSpaceLabel() {
			return this.spaceOnDisplay.Id == this.mainSpace.Id
				? this.$t("mol.general.string.Yes")
				: this.$t("mol.general.string.No");
		},
		hasVolumeRestrictionsLabel() {
			return this.spaceOnDisplay.space_attr_soundlimit__c
				? this.$t("mol.spaceForm.label.Venue space has volume restrictions")
				: this.$t("mol.spaceForm.label.Venue space has no volume restrictions");
		},
		subTitle() {
			if (this.spaceOnDisplay) return this.spaceOnDisplay.Space_Name__c;
			if (this.creatingSpace) return this.$t("mol.spacesPage.title.Create new venue space");
			return this.$t("mol.spacesPage.title.All venue spaces");
		},
		breadcrumbs() {
			let items = [this.$t("mol.spacesPage.title.Venue spaces")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).label);
			}
			return items;
		},
		breadcrumbsEdit() {
			let editItem = this.$t("mol.general.label.Edit");
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			breadcrumbs.push(editItem);
			return breadcrumbs;
		},
	},
	methods: {
		initialSpaceFormErrors() {
			return {
				hasErrors: false,
				spaceErrorSpace_Name__c: false,
				spaceErrorspace_attr_size__c: false,
				spaceErrorspace_attr_maxdb__c: false,
			};
		},
		initialNewSpace() {
			return {
				Id: null,
				Name: "",
				isMain: false,
				Details__c: "",
				space_description__c: "",
				Space_Name__c: "",
				Space_profile_img_url__c: "",
				space_attr_guestsmax__c: "",
				space_attr_guestsmin__c: "",
				no_free_electric_network__c: false,
				no_free_parking__c: false,
				not_ground_floor__c: false,
				not_ground_floor_no_elevator__c: false,
				not_paved_road_to_unload__c: false,
				not_paved_road_unload_to_gig__c: false,
				small_doors__c: false,
				space_attr_aircon__c: false,
				space_attr_dancefloor__c: false,
				space_attr_decor_authentic__c: false,
				space_attr_decor_browncafe__c: false,
				space_attr_decor_classic__c: false,
				space_attr_decor_elegant__c: false,
				space_attr_decor_historic__c: false,
				space_attr_decor_homey__c: false,
				space_attr_decor_industrial__c: false,
				space_attr_decor_modern__c: false,
				space_attr_decor_professional__c: false,
				space_attr_decor_romantic__c: false,
				space_attr_decor_rural__c: false,
				space_attr_decor_rustic__c: false,
				space_attr_decor_trendy__c: false,
				space_attr_disabwc__c: false,
				space_attr_livemusic__c: false,
				space_attr_maxdb__c: "",
				space_attr_outside__c: false,
				space_attr_presentation__c: false,
				space_attr_size__c: "",
				space_attr_smoking__c: false,
				space_attr_soundlimit__c: false,
				space_attr_wheelchair__c: false,
				space_attr_wifi__c: false,
				unload_25_meter__c: false,
			};
		},
		setSpaceToEdit() {
			this.editSpace = JSON.parse(JSON.stringify(this.spaceOnDisplay));
			if (this.editSpace.Id == this.mainSpace.Id) {
				this.$set(this.editSpace, "isMain", true);
			} else {
				this.$set(this.editSpace, "isMain", false);
			}
		},
		closeEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
			this.resetFormErrors();
		},
		openEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", true);
		},
		openConfirmRemovePanel() {
			return (this.panelConfirmRemove = true);
		},
		closeConfirmRemovePanel() {
			return (this.panelConfirmRemove = false);
		},
		validateValue(value) {
			return value === undefined || value === null || value == "";
		},
		validateSpace(space) {
			this.resetFormErrors();

			if (this.validateValue(space.Space_Name__c)) {
				this.formErrors.spaceErrorSpace_Name__c = true;
			}
			if (!this.validateValue(space.space_attr_size__c) && !/^\d+$/.test(space.space_attr_size__c)) {
				this.formErrors.spaceErrorspace_attr_size__c = true;
			}
			if (!this.validateValue(space.space_attr_maxdb__c) && !/^\d+$/.test(space.space_attr_maxdb__c)) {
				this.formErrors.spaceErrorspace_attr_maxdb__c = true;
			}

			if (space.Space_Name__c == "") {
				this.formErrors.hasErrors = true;
				document.getElementById("Space_Name__c").scrollIntoView({behavior: "smooth"});
			} else if (!this.validateValue(space.space_attr_size__c) && !/^\d+$/.test(space.space_attr_size__c)) {
				this.formErrors.hasErrors = true;
				document.getElementById("space_attr_size__c").scrollIntoView({behavior: "smooth"});
			} else if (!this.validateValue(space.space_attr_maxdb__c) && !/^\d+$/.test(space.space_attr_maxdb__c)) {
				this.formErrors.hasErrors = true;
				document.getElementById("space_attr_maxdb__c").scrollIntoView({behavior: "smooth"});
			}
		},
		saveSpace(space) {
			this.validateSpace(space);
			if (!this.formErrors.hasErrors) {
				this.$store
					.dispatch("upsertSFData", {
						action: "upsertLocationSpace",
						data: space,
						successMessage: this.$t("mol.spaceForm.message.Venue space saved"),
						errorMessage: this.$t("mol.spaceForm.message.Venue space not saved"),
					})
					.then(space.Id ? this.updateSpace : this.insertSpace)
					.catch((err) => {
						console.error(err);
					});
			}
		},
		updateSpace() {},
		insertSpace(data) {
			this.resetSpace();
			let newSpaceHash = "#" + data.data.Location_Spaces__r.records[data.data.Location_Spaces__r.records.length - 1].Id;
			this.$router.push({name: "Spaces", hash: newSpaceHash});
		},
		deleteSpace(space) {
			this.$store.dispatch("upsertSFData", {
				action: "deleteSpace",
				data: space,
				successMessage: this.$t("mol.spaceForm.message.Space removed"),
				errorMessage: this.$t("mol.spaceForm.message.Space not removed"),
			});
		},
		resetSpace() {
			this.newSpace = this.initialNewSpace();
		},
		resetFormErrors() {
			this.formErrors = this.initialSpaceFormErrors();
		},
	},
	mounted() {
		this.newSpace.AccountId = this.location.Id;
	},
	watch: {
		molEditingState: function () {
			if (this.molEditingState) {
				this.setSpaceToEdit();
			} else {
				this.panelConfirmRemove = false;
			}
		},
		molSubnavHash: function () {
			this.resetFormErrors();
		},
	},
};
</script>
