function getApiKey() {
	let key = process.env.VUE_APP_GMAPS_APIKEY;
	return key;
}

let google = null;
let loadingPromise = null;
let promiseResolver = null;

function initGMaps() {
	google = window.google;
	promiseResolver(google);
}

function loadGMaps() {
	if (!document.head.querySelector("#gmaps")) {
		let mapsScript = document.createElement("script");
		let mapsurl = "https://maps.googleapis.com/maps/api/js?key=" + getApiKey() + "&libraries=places&callback=initGMaps";
		mapsScript.setAttribute("src", mapsurl);
		mapsScript.setAttribute("id", "gmaps");
		mapsScript.setAttribute("async", true);
		window.initGMaps = initGMaps;
		document.head.appendChild(mapsScript);
	}
}

export async function getGMaps() {
	if (google) {
		return google;
	}
	if (!loadingPromise) {
		loadingPromise = new Promise((resolve) => {
			promiseResolver = resolve;
			loadGMaps();
		});
	}
	return loadingPromise;
}
