<template>
	<div>
		<PageTitle
			title="DJ reservations"
			subTitle="Reserve a DJ for an upcoming event"
			:breadcrumbs="['DJ reservations']"
		/>
	</div>
</template>

<script>
import PageTitle from "@/components/location/PageTitle";

export default {
	name: "LocationDJReservations",
	components: {
		PageTitle,
	},
};
</script>
