import Vue from "vue";
import VueI18n from "vue-i18n";
import {getSalesForce} from "@/services/TranslationService";

Vue.use(VueI18n);

const loadedLanguages = ["null"]; // our default language that is preloaded

let currentHost = window.location.host;
let currentLocale = "en-US";
if (currentHost.indexOf(".nl") !== -1 || currentHost.indexOf(":3030") !== -1) {
	currentLocale = "nl-NL";
} else if (currentHost.indexOf(".be") !== -1 || currentHost.indexOf(":3032") !== -1) {
	currentLocale = "nl-BE";
} else if (currentHost.indexOf(".uk") !== -1 || currentHost.indexOf(":3033") !== -1) {
	currentLocale = "en-US";
}
export const i18n = new VueI18n({
	locale: "null", // set locale
	fallbackLocale: "null",
	messages: {}, // set locale messages
});

function setI18nLanguage(lang) {
	i18n.locale = lang;
	document.querySelector("html").setAttribute("lang", lang);
	return lang;
}

export function loadLanguageAsync(lang) {
	if (!lang) {
		return;
	}
	// If the same language
	if (i18n.locale === lang) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	// If the language was already loaded
	if (loadedLanguages.includes(lang)) {
		return Promise.resolve(setI18nLanguage(lang));
	}

	// If the language hasn't been loaded yet
	return getSalesForce(lang).then((responses) => {
		console.log("Translation", responses);
		let data = {};
		responses.forEach((resp) => {
			if (resp) {
				Object.assign(data, resp.data);
			}
		});
		i18n.setLocaleMessage(lang, data);
		loadedLanguages.push(lang);
		return setI18nLanguage(lang);
	});
}

loadLanguageAsync(currentLocale);
