<template>
	<div>
		<Alert v-if="!isPaid" alertType="warning" dismissible>
			<span v-if="partyType == 'bruiloftsfeest'">Jullie</span><span v-else>Jouw</span> datum en DJ zijn gereserveerd ná
			betaling van de waarborgsom. <span v-if="partyType == 'bruiloftsfeest'">Jullie mogen</span
			><span v-else>Je mag</span> tot 14 dagen voor het feest altijd gratis annuleren.
			<span v-if="partyType == 'bruiloftsfeest'">Jullie ontvangen</span><span v-else>Je ontvangt</span> binnen 1 dag de
			gehele waarborgsom kosteloos op je rekening.
		</Alert>
		<Alert v-if="isPaid" alertType="info" dismissible>
			Bedankt voor de betaling van de waarborgsom van {{ $currency(down_payment, 2).format().replace(",00", ",-") }}.
			Jouw {{ partyType }} op {{ $date(booking.Party_Date__c).locale("nl").format("dddd, D MMMM YYYY") }} is bevestigd.
		</Alert>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";

export default {
	name: "PaymentNotification",
	components: {
		Alert,
	},
	computed: {
		...mapGetters({
			partyType: "getPartyType",
			isPaid: "getPaidStatus",
			down_payment: "getDownPayment",
			booking: "getBooking",
		}),
	},
};
</script>

<style lang="scss" scoped>
.alert {
	@include media-breakpoint-down(sm) {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
		margin-bottom: 0.5rem;
	}
}
</style>
