<template>
	<div class="mb-2 mt-md-n4 mb-md-3">
		<router-link v-if="to != ''" :to="{name: to}" class="d-inline-block w-auto">
			<font-awesome-icon class="text-primary mr-1" :icon="['fal', 'chevron-left']" />{{ label }}
		</router-link>
	</div>
</template>

<script>
export default {
	name: "back-to",
	props: {
		to: {
			type: String,
			default: "Home",
		},
		label: {
			type: String,
			default: "terug naar overzicht",
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-link svg {
	@include media-breakpoint-up(md) {
		height: 30px;
	}
}
</style>
