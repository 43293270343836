<template>
	<div class="text-center">
		<strong v-if="isBookingConfirmedTemplateVariant" class="display-5 my-md-1 d-block text-primary">Geboekt!</strong>
		<SelectedDj />
		<BookingPricing
			:priceFrom="formatPrice((booking.Business_Booking__c ? priceExc : priceInc) + 150, 2)"
			:priceExc="formatPrice(priceExc, 2)"
			:priceInc="formatPrice(priceInc, 2)"
			:business="booking.Business_Booking__c"
			:save="((150 / (price + 150)) * 100).toFixed(0) + '%'"
		/>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

import BookingPricing from "@/components/customer/BookingPricing";
import SelectedDj from "@/components/customer/SelectedDj";
export default {
	name: "BookingPaid",
	components: {
		SelectedDj,
		BookingPricing,
	},
	props: {
		paymentLink: {
			type: Boolean,
			default: false,
		},
		templateVariant: {
			type: String,
			default: "",
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			priceInc: "getBookingPriceIncVat",
			priceExc: "getBookingPriceExcVat",
		}),
		isBookingConfirmedTemplateVariant: function () {
			return this.templateVariant == "DirectBookingConfirmed" ? true : false;
		},
	},
	methods: {
		formatPrice(price, precision = 2) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
};
</script>
