import router from "@/router";
import {getSalesForce, postSalesForce} from "@/services/SalesforceService";

const state = {
	booking: {
		booking: {},
		locationContacts: [],
		locationEvaluations: [],
		messageList: [],
		spaces: [],
		customerScores: [],
	},
	locationMedia: [],
	prices: {},
	company_reviews: {},
	CurrentDJClass: "",
	suppliers: {},
	loadingInput: {
		name: "",
		status: "idle",
	},
	loadingLocations: false,
	loadingCities: false,
	loadingSpotifyOptions: false,
	queryLocations: [],
	queryCities: [],
};

const getters = {
	getBooking(state) {
		return state.booking.booking;
	},
	getPreQuotePricing(state) {
		return "pricing" in state.booking ? state.booking.pricing : "";
	},
	getSuppliers(state) {
		return state.suppliers;
	},
	getMessages(state) {
		return state.booking && state.booking.messageList ? state.booking.messageList : [];
	},
	isPreQuote(state) {
		return (
			state.booking.booking.status_booking__c.indexOf("Pre Quote") != -1 ||
			(state.booking.booking.status_booking__c.includes("Quote rejected") &&
				!("Class_A_Excl_VAT__c" in state.booking.booking))
		);
	},
	isQuote(state) {
		return (
			state.booking.booking.status_booking__c == "Quote" ||
			state.booking.booking.status_booking__c.includes("Quote - fixed prices") ||
			(state.booking.booking.status_booking__c.includes("Quote rejected") &&
				"Class_A_Excl_VAT__c" in state.booking.booking)
		);
	},
	isOrderInProgress(state) {
		return state.booking?.booking?.status_booking__c === "Order in progress";
	},
	isFixed(state) {
		return state.booking.booking.status_booking__c.includes("Quote - fixed prices");
	},
	getLoadingInput(state) {
		return state.loadingInput;
	},
	getLocationLoadingState(state) {
		return state.loadingLocations;
	},
	getCityLoadingState(state) {
		return state.loadingCities;
	},
	getSpotifyOptionsLoadingState(state) {
		return state.loadingSpotifyOptions;
	},
	hasLocation(state) {
		return "Location_lookup__r" in state.booking.booking;
	},
	getLocation(state) {
		return "Location_lookup__r" in state.booking.booking ? state.booking.booking.Location_lookup__r : "";
	},
	getLocationContact(state) {
		return "Location_Contact__r" in state.booking.booking ? state.booking.booking.Location_Contact__r : "";
	},
	getLocationcontacts(state) {
		return state.booking.locationContacts;
	},
	hasGeo(state) {
		return (
			"Location_lookup__r" in state.booking.booking && "Location_City__c" in state.booking.booking.Location_lookup__r
		);
	},
	getGeo(state) {
		return state.booking.booking.Location_lookup__r.BillingCity;
	},
	getReviews(state) {
		return state.booking.locationEvaluations;
	},
	getLocationMedia(state) {
		return state.locationMedia;
	},
	getSpaces(state) {
		return state.booking.spaces;
	},
	getQueryLocations(state) {
		return state.queryLocations;
	},
	getQueryCities(state) {
		return state.queryCities;
	},
	getPartyType(state) {
		return "booking_type__c" in state.booking.booking ? state.booking.booking.booking_type__c : "feest";
	},
	getCustomPhoneNumber(state) {
		return "customized_phone_number__c" in state.booking.booking
			? state.booking.booking.customized_phone_number__c
			: "085 - 40 19 438";
	},
	isConfirmed(state) {
		return "dj_confirmation_printed__c" in state.booking.booking
			? state.booking.booking.dj_confirmation_printed__c
			: false;
	},
	hasPartyDate(state) {
		return "Party_Date__c" in state.booking.booking ? true : false;
	},
	hasPartyDatePassed(state) {
		let partyDate = new Date(state.booking.booking.Party_Date__c).getTime();
		let today = new Date().getTime();
		let yesterday = today - 86400000;
		if (partyDate == "") return false;
		else if (partyDate < yesterday) return true;
		return false;
	},
	getCleanPartyType(state) {
		let partyType = state.booking.booking.booking_type__c;
		if (partyType.includes("verjaardag")) return "voor verjaardagsfeesten";
		else if (partyType.includes("bruiloft")) return "voor bruiloftsfeesten";
		else if (partyType.includes("huwelijksfeest")) return "voor huwelijksfeesten";
		else if (partyType.includes("afstudeerfeest")) return "voor afstudeerfeesten";
		else if (partyType.includes("galafeest")) return "voor galafeesten";
		else if (partyType.includes("bedrijfsfeest")) return "voor bedrijfsfeesten";
		else if (partyType.includes("jubileumfeest")) return "voor jubileumfeesten";
		else if (partyType.includes("promotiefeest")) return "voor promotiefeesten";
		else return null;
	},
	hasEvaluation(state) {
		return state.booking.locationEvaluations.length > 0;
	},
	hasBookingEvaluations(state) {
		return state.booking.evaluations.length > 0;
	},
	hasLocationMedia(state) {
		return state.locationMedia.length > 0;
	},
	getNumberOfShows(state) {
		return "Location_lookup__r" in state.booking.booking
			? state.booking.booking.Location_lookup__r.Location_Leads_confirmed_total__c
			: 0;
	},
	getWishesProgress(state) {
		return "WishesFilledPercentage__c" in state.booking.booking && state.booking.booking.WishesFilledPercentage__c >= 5
			? state.booking.booking.WishesFilledPercentage__c
			: 5;
	},
	getReviewTotals(state) {
		return state.booking.evaluationTotals;
	},
	getCompanyReviews(state) {
		return state.company_reviews;
	},
	getSocialRatings(state) {
		return state.booking.customerScores;
	},
	getQuotes(state) {
		return "Quotes__r" in state.booking.booking ? state.booking.booking.Quotes__r.records : [];
	},
	getCurrentDjClass(state) {
		if (state.CurrentDJClass) {
			return state.CurrentDJClass;
		}
		return state.booking.booking.CurrentDJClass__c;
	},
	getDownPayment(state) {
		return state.booking.booking.Down_Payment_Amount2__c;
	},
	getPrices(state) {
		return state.prices;
	},
	getBookingPriceIncVat(state) {
		return state.booking.booking.Quote_price_Incl_VAT__c;
	},
	getBookingPriceExcVat(state) {
		return state.booking.booking.Quote_price__c;
	},
	getBookingPrice() {
		throw new Error("DEPRECATED");
	},
	getStars(state) {
		if (state.booking.booking.CurrentDJClass__c === "Junior") return 1;
		else if (state.booking.booking.CurrentDJClass__c === "A") return 3;
		return 5;
	},
	getCurrentDjClassUsp(state) {
		if (state.booking.booking.CurrentDJClass__c === "Junior") return "Voordeligste keuze";
		else if (state.booking.booking.CurrentDJClass__c === "A") return "Meest gekozen";
		return "Veiligste keuze";
	},
	getCurrentDjClassUspIconName(state) {
		if (state.booking.booking.CurrentDJClass__c === "Junior") return "percent";
		else if (state.booking.booking.CurrentDJClass__c === "A") return "trophy";
		return "shield";
	},
	getPaidStatus(state) {
		return (
			!state.booking.booking.status_booking__c.includes("Quote rejected") &&
			("Down_Payment_Paid_Date__c" in state.booking.booking || state.booking.booking.Down_Payment_Amount2__c == 0)
		);
	},
	isTestGroup(state, getters) {
		if (getters.isOrderInProgress || getters.getQuotes.length > 0) return false;
		if (state.booking.booking.Test_group__c === "A") return true;
		return false;
	},
	isBelgian(state) {
		return "language_region__c" in state.booking.booking && state.booking.booking.language_region__c == "nl-BE"
			? true
			: false;
	},
	isDutch(state) {
		return "language_region__c" in state.booking.booking && state.booking.booking.language_region__c == "nl-NL"
			? true
			: false;
	},
	isWedding(state) {
		return "booking_type__c" in state.booking.booking && state.booking.booking.booking_type__c == "bruiloftsfeest"
			? true
			: false;
	},
	isBirthday(state) {
		return "booking_type__c" in state.booking.booking && state.booking.booking.booking_type__c == "verjaardagsfeest"
			? true
			: false;
	},
	isBusinessParty(state) {
		return "booking_type__c" in state.booking.booking && state.booking.booking.booking_type__c == "bedrijfsfeest"
			? true
			: false;
	},
	getInfoEmailAddress(state) {
		return "language_region__c" in state.booking.booking && state.booking.booking.language_region__c == "nl-BE"
			? "info@thedjcompany.be"
			: "info@thedjcompany.nl";
	},
	isBookingByVenue(state) {
		return state.booking.booking.Account__c === state.booking.booking.Location_lookup__c;
	},
	isErrorCodeGlobal(state) {
		if (state.prices.p_junior || state.prices.p_a_class || state.prices.p_premium) {
			return (
				state.prices.p_junior.p_errorCode === state.prices.p_a_class.p_errorCode &&
				state.prices.p_a_class.p_errorCode === state.prices.p_premium.p_errorCode
			);
		}
		return false;
	},
	areTermsAndConditionsAccepted(state) {
		return state.booking?.booking?.TermsAndConditionsAccepted__c || false;
	},
};

const mutations = {
	SET_BOOKING(state, booking) {
		state.booking = booking;
	},
	SET_PRICES(state, prices) {
		state.prices = prices;
	},
	SET_SUPPLIERS(state, suppliers) {
		state.suppliers = suppliers;
	},
	SET_LOADING_INPUT(state, input) {
		state.loadingInput = input;
	},
	SET_LOCATION_LOADING_STATE(state, status) {
		state.loadingLocations = status;
	},
	SET_CITY_LOADING_STATE(state, status) {
		state.loadingCities = status;
	},
	SET_SPOTIFY_OPTIONS_LOADING_STATE(state, status) {
		state.loadingSpotifyOptions = status;
	},
	SET_LOCATIONS(state, locations) {
		state.queryLocations = locations;
	},
	SET_LOCATION_MEDIA(state, media) {
		state.locationMedia = media;
	},
	SET_CITIES(state, cities) {
		state.queryCities = cities;
	},
	SET_COMPANY_REVIEWS(state, reviews) {
		state.company_reviews = reviews;
	},
	CLEAR_QUERY_LOCATIONS(state) {
		state.queryLocations = [];
	},
	CLEAR_QUERY_CITIES(state) {
		state.queryCities = [];
	},
	SET_CURRENT_DJ_CLASS(state, dj_class) {
		state.booking.booking.CurrentDJClass__c = dj_class;
		state.CurrentDJClass = dj_class;
	},
};

const actions = {
	postBooking({commit}, payload) {
		if (payload.input) {
			commit("SET_LOADING_INPUT", {name: payload.input, status: "saving"});
		} else {
			commit("SET_LOADING_STATE", true);
		}
		return postSalesForce(payload)
			.then((response) => {
				if (payload.setBooking && response) commit("SET_BOOKING", response.data);
				if (payload.input) {
					commit("SET_LOADING_INPUT", {name: payload.input, status: "saved"});
					setTimeout(() => commit("SET_LOADING_INPUT", {name: payload.input, status: "idel"}), 1500);
				} else {
					commit("SET_LOADING_STATE", false);
				}
				if (payload.action == "bookDirect") router.push({name: "DirectBookingConfirmed"});
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},

	postWish({commit}, payload) {
		commit("SET_LOADING_INPUT", {name: payload.input, status: "saving"});
		return postSalesForce(payload)
			.then((response) => {
				if (payload.setBooking && response) commit("SET_BOOKING", response.data);
				commit("SET_LOADING_INPUT", {name: payload.input, status: "saved"});
				setTimeout(() => commit("SET_LOADING_INPUT", {name: payload.input, status: "idel"}), 1500);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	postPrices({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_PRICES", response.data);
				commit("SET_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	requestQuote({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_BOOKING", response.data);
				commit("SET_LOADING_STATE", false);
				const lastIndex = response.data.booking.Quotes__r.totalSize - 1;
				router.push({
					name: "QuoteConfirmed",
					params: {
						id: response.data.booking.Quotes__r.records[lastIndex].Id,
					},
				});
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	bookQuote({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_BOOKING", response.data);
				commit("SET_LOADING_STATE", false);
				router.push({name: "DirectBookingConfirmed"});
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	getBooking({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getBookingInformation")
			.then((response) => {
				if (response) commit("SET_BOOKING", response.data);
				commit("SET_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	getCompanyReviews({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getEvaluations", false, payload.data)
			.then((response) => {
				commit("SET_COMPANY_REVIEWS", response.data);
				commit("SET_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	getLocationMedia({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getLocationMedia")
			.then((response) => {
				if (response) commit("SET_LOCATION_MEDIA", response.data);
				commit("SET_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	getSuppliers({commit}) {
		return getSalesForce("getSuppliers")
			.then((response) => {
				if (response) commit("SET_SUPPLIERS", response.data);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	queryLocations({commit}, payload) {
		commit("SET_LOCATION_LOADING_STATE", true);
		return getSalesForce("queryLocations", payload)
			.then((response) => {
				if (response) {
					const locations = [];
					response.data.map(function (location, index) {
						locations[index] = {};
						locations[index]["Name"] = location.Name;
						locations[index]["City"] = location.BillingCity;
						locations[index]["Id"] = location.Id;
						locations[index]["Image"] =
							"location_profile_thumb_url__c" in location ? location.location_profile_thumb_url__c : false;
					});
					commit("SET_LOCATIONS", locations);
				}
				commit("SET_LOCATION_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	queryCities({commit}, payload) {
		commit("SET_CITY_LOADING_STATE", true);
		return getSalesForce("queryCities", payload)
			.then((response) => {
				if (response) {
					const cities = [];
					response.data.map(function (city, index) {
						cities[index] = {};
						cities[index]["Name"] = city.Name;
						cities[index]["Id"] = city.Id;
					});
					commit("SET_CITIES", cities);
				}
				commit("SET_CITY_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
	clearQueryLocations({commit}) {
		commit("CLEAR_QUERY_LOCATIONS");
	},

	clearQueryCities({commit}) {
		commit("CLEAR_QUERY_CITIES");
	},
	followUpOptOut({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				if (payload.setBooking && response) {
					commit("SET_BOOKING", response.data);
				}
				commit("SET_LOADING_STATE", false);
			})
			.catch(() => {
				commit("SET_LOADING_STATE", false);
			});
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
