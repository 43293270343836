<template>
	<div :class="[{'mb-2 md:mb-3': previousStepCompleted}]" class="relative transition-all duration-700 delay-300">
		<div
			:class="[
				{'py-5 p-4 !max-h-[2000px] !opacity-100': previousStepCompleted},
				{'shadow-transparent !py-2 !border-tdc-green-500': stepCompleted},
			]"
			class="max-h-0 opacity-0 py-0 transition-all duration-700 delay-300 overflow-hidden bg-white dark:bg-slate-700 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700"
		>
			<ContentTitle
				class="transition-all duration-500 off-delay-150"
				:class="{'border-none !p-0 !m-0 ': stepCompleted}"
			>
				<div class="flex justify-between w-full">
					<div
						:class="[{'text-sm': stepCompleted}]"
						class="text-base transition-all duration-300 off-delay-150 flex items-center"
					>
						<div class="inline-block mr-2">{{ stepTitle }}</div>
						<span
							:class="{'!max-w-[0px]': stepCompleted}"
							class="text-slate-500 font-medium transition-all duration-500 off-delay-150 overflow-hidden max-w-[500px] whitespace-nowrap inline-block"
							>({{ $t("mol.bookADjForm.label.Step of", {step: stepNumber, num: of}) }})</span
						>
						<Ahref
							v-if="!lastStep"
							@click.native="reopen()"
							:class="{'!opacity-0 !max-w-[0px]': !stepCompleted}"
							class="text-xs text-tdc-blue-500 font-normal transition-all duration-500 delay-150 overflow-hidden max-w-[500px] whitespace-nowrap inline-block"
							>[<font-awesome-icon :icon="['far', 'edit']" class="ml-auto h-3 w-3 mb-[1px] mr-1 relative" />{{
								$t("mol.general.label.Edit")
							}}]</Ahref
						>
					</div>
					<font-awesome-icon
						:icon="['far', 'check-circle']"
						:class="{'!opacity-100': stepCompleted}"
						class="ml-auto text-tdc-green-500 h-4 w-4 transition-all duration-500 off-delay-150 opacity-0 mt-[2px]"
					/>
				</div>
			</ContentTitle>
			<div
				:class="{'!max-h-0 opacity-0 py-0 !mb-0 overflow-hidden': stepCompleted}"
				class="max-h-[2000px] transition-all duration-700 delay-150"
			>
				<slot></slot>
			</div>
		</div>
		<div
			v-if="!lastStep"
			:class="{'!opacity-100 relative z-50': stepCompleted}"
			class="flex justify-center opacity-0 transition-opacity off-delay-500 duration-500 relative"
		>
			<div class="relative ml-[-55px]">
				<div
					class="w-0 h-0 border-r-[20px] border-l-[20px] border-l-transparent border-t-[20px] border-t-tdc-green-500 border-r-transparent absolute mt-0"
				></div>
				<div
					class="w-0 h-0 border-r-[20px] border-l-[20px] border-l-transparent border-t-[20px] border-t-white dark:border-t-slate-700 border-r-transparent absolute mt-[-1px]"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentTitle from "@/components/location/ContentTitle";
import Ahref from "@/components/location/Ahref";
export default {
	name: "CollapsableStepBlock",
	components: {
		ContentTitle,
		Ahref,
	},
	props: {
		stepTitle: {
			type: String,
			default: "",
		},
		previousStepCompleted: {
			type: Boolean,
			default: false,
		},
		stepCompleted: {
			type: Boolean,
			default: false,
		},
		lastStep: {
			type: Boolean,
			default: false,
		},
		stepNumber: {
			type: Number,
		},
		of: {
			type: Number,
		},
	},
	computed: {
		thisStepCompleted() {
			return this.stepCompleted;
		},
	},
	methods: {
		reopen() {
			this.$emit("reopen");
		},
	},
};
</script>
