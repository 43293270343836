<template>
	<span>
		<div class="d-flex flex-column flex-md-row justify-content-between">
			<div class="text-center text-md-left">
				<strong v-if="isBookingConfirmedTemplateVariant" class="display-5 my-md-1 d-block text-primary"
					>Geboekt!</strong
				>
				<span class="stars d-block display-5">
					<font-awesome-icon v-for="star in $parent.djClassStars" :key="star" :icon="['fas', 'star']" />
				</span>
				<strong class="display-5 my-md-1 d-block" v-text="$parent.djClassName"></strong>
				<div
					v-if="!isBookingConfirmedTemplateVariant"
					:class="[$parent.djBestChoice ? 'text-success' : 'text-primary']"
					class="d-flex justify-content-center justify-content-md-start font-weight-bold align-items-center mb-3 mb-md-1"
				>
					<font-awesome-icon class="mr-1" :icon="['fas', $parent.djClassIcon]" /><span v-text="$parent.djClassType" />
				</div>
			</div>
			<div v-if="showExtendedPriceList" class="mt-4 mt-md-0 mb-4 mb-md-0">
				<div class="border-top pb-3 d-md-none"></div>
				<ul class="border-bottom pb-3 ml-0 pl-0">
					<li class="d-flex justify-content-end text-right">
						<span class="mr-2 font-weight-bold">Normale prijs</span>
						<s class="d-block"><span class="" v-text="$parent.formatPrice($parent.priceFrom)"></span></s>
					</li>
					<li class="d-flex justify-content-end font-weight-bold text-success text-right">
						<span class="mr-2">Korting</span>
						<span v-text="$parent.formatPrice(-150, 2)"></span>
					</li>
					<li class="d-flex justify-content-end font-weight-bold text-right">
						<span class="mr-2">
							Jouw prijs <small v-if="$parent.booking.Business_Booking__c" class="text">(ex. btw)</small>
						</span>
						<span
							v-if="$parent.booking.Business_Booking__c"
							class="yellow-highlight"
							v-text="$parent.formatPrice($parent.priceExcVat, 2)"
						></span>
						<span v-else class="yellow-highlight" v-text="$parent.formatPrice($parent.priceIncVat, 2)"></span>
					</li>
					<li v-if="$parent.booking.Business_Booking__c" class="d-flex justify-content-end text-right font-weight-bold">
						<span class="mr-2"><small>(incl. btw)</small></span>
						<span class="" v-text="$parent.formatPrice($parent.priceIncVat)"></span>
					</li>
					<li class="d-flex justify-content-end text-right font-weight-bold">
						<span class="mr-2">50% pas achteraf betalen</span>
						<span v-text="'-' + $parent.formatPrice($parent.downPayment, 2)"></span>
					</li>
				</ul>
				<div class="mb-4">
					<div class="d-flex flex-row justify-content-end font-weight-bold align-items-center">
						<div class="mr-2 text-right h5 mb-0">Totale waarborgsom</div>
						<div class="text-right h3 mb-0" v-text="$parent.formatPrice($parent.downPayment, 2)"></div>
					</div>
				</div>
			</div>
			<div v-else class="mb-4 mb-md-0">
				<div class="text-center text-md-right">
					<span class="d-flex flex-nowrap align-items-center justify-content-center justify-content-md-end">
						<small v-if="$parent.isPreQuote" v-text="'vanaf'" />
						<strong>
							<span class="mb-0 display-4 yellow-highlight d-block position-relative">
								<div v-if="$parent.booking.Business_Booking__c" v-text="$parent.formatPrice($parent.priceExcVat)" />
								<div v-else v-text="$parent.formatPrice($parent.priceIncVat)" />
								<div v-if="isQuoteConfirmedTemplateVariant" class="price-locked">
									<font-awesome-icon class="" :icon="['fas', 'lock-alt']" />
								</div>
							</span>
						</strong>
					</span>
					<span class="text-muted"
						><s class="d-block"><span v-text="$parent.formatPrice($parent.priceFrom)" /></s
					></span>
					<span class="text-muted" v-if="!$parent.booking.Business_Booking__c">(incl. alle kosten en btw)</span>
					<span class="text-muted" v-else>incl. alle kosten en excl. btw</span>
					<div v-if="isQuoteConfirmedTemplateVariant" class="text-success font-weight-bold d-block">
						50% achteraf betalen
					</div>
					<div :class="{'mb-md-4': !showLimitedTimeMsg}" class="text-primary font-weight-bold d-block">
						Bespaar {{ $parent.save }}%
					</div>
					<div v-if="showLimitedTimeMsg" class="mb-md-4">
						<font-awesome-icon class="text-danger mr-1" :icon="['fal', 'timer']" /> prijs is beperkt geldig
					</div>
				</div>
			</div>
		</div>
		<div v-if="showDefaultCta" class="desktop-content-right position-relative">
			<div class="d-flex flex-column align-items-center align-items-md-end mb-3">
				<BookButton
					class="d-flex flex-column align-items-center align-items-md-end mb-3"
					:sf_name="$parent.sf_name"
					:prices="$parent.prices"
				/>
			</div>
		</div>
		<div v-if="$parent.showCustomCta" class="desktop-content-right position-relative">
			<slot name="customCta"></slot>
		</div>
		<div class="desktop-content-left mt-md-n2 mt-lg-n3">
			<PaymentLink v-if="isBookingConfirmedTemplateVariant" />
			<div>
				<PriceCalculator
					class="pt-2 pt-md-3 mb-3 mr-md-5 d-block mb-3"
					:displayQuoteDetails="$parent.displayQuoteDetails"
					:quote="$parent.quote"
					:active="$parent.activeCalculator"
					:displayQuoteName="isQuoteConfirmedTemplateVariant"
				/>
			</div>
			<ul v-if="showDjList" class="list-unstyled usp-list mb-2 mt-auto">
				<li v-if="$parent.bestPremiumDjFromCountryUsp" class="mb-1">
					<font-awesome-icon class="text-success" :icon="['fal', 'check']" />
					<span class="font-weight-bold">{{ $parent.bestPremiumDjFromCountryUsp }}</span>
				</li>
				<li v-for="(usp, index) in $parent.usps[$parent.sf_name]" :key="index" class="mb-1">
					<font-awesome-icon class="text-success" :icon="['fal', 'check']" />
					<span :class="{'font-weight-bold': index == 0 && $parent.bestPremiumDjFromCountryUsp == ''}">{{ usp }}</span>
				</li>
			</ul>
			<div class="mt-2">
				<MyPartyList v-if="showMyPartyList" />
			</div>
		</div>
	</span>
</template>

<script>
import MyPartyList from "@/components/customer/MyPartyList";
import PriceCalculator from "@/components/customer/PriceCalculator";
import PaymentLink from "@/components/customer/PaymentLink";
import BookButton from "@/components/customer/BookButton";

export default {
	name: "DJ-Block-Complete",
	components: {
		MyPartyList,
		PriceCalculator,
		PaymentLink,
		BookButton,
	},
	props: {
		templateVariant: {
			type: String,
		},
	},
	data() {
		return {
			showDate: false,
		};
	},
	computed: {
		isDjDetailsTemplateVariant: function () {
			return this.templateVariant == "DjDetails" ? true : false;
		},
		isQuoteCartTemplateVariant: function () {
			return this.templateVariant == "QuoteCart" ? true : false;
		},
		isQuoteConfirmedTemplateVariant: function () {
			return this.templateVariant == "QuoteConfirmed" ? true : false;
		},
		isQuoteDetailTemplateVariant: function () {
			return this.templateVariant == "QuoteDetail" ? true : false;
		},
		isBookingTemplateVariant: function () {
			return this.templateVariant == "Booking" ? true : false;
		},
		isBookingConfirmedTemplateVariant: function () {
			return this.templateVariant == "DirectBookingConfirmed" ? true : false;
		},
		isBookingCartTemplateVariant: function () {
			return this.templateVariant == "DirectBookingCart" ? true : false;
		},
		showDjList: function () {
			if (this.isDjDetailsTemplateVariant) return true;
			return false;
		},
		showMyPartyList: function () {
			if (this.isDjDetailsTemplateVariant) return false;
			return true;
		},
		showDefaultCta: function () {
			if (this.isDjDetailsTemplateVariant) return true;
			return false;
		},
		showExtendedPriceList: function () {
			if (this.isBookingTemplateVariant) return true;
			else if (this.isBookingConfirmedTemplateVariant) return true;
			return false;
		},
		showLimitedTimeMsg: function () {
			return this.isQuoteCartTemplateVariant || this.isBookingCartTemplateVariant ? true : false;
		},
	},
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(md) {
	.display-5 {
		font-size: 2rem !important;
	}
}
.price-locked {
	font-size: 20px;
	position: absolute;
	top: -5px;
	right: 0;
}
</style>
