<template>
	<div>
		<b-alert
			show
			:variant="alertColor"
			:dismissible="dismissible"
			class="d-flex flex-row justify-content-start align-items-center mb-3 p-2 p-md-3"
			:class="[{'py-1': reduced}, {'pr-5 pr-md-5': dismissible}]"
		>
			<font-awesome-icon v-if="alertIcon" :icon="['fas', alertIcon]" class="mr-2" :class="iconColor" />
			<div class="h-100" :class="[borderClass, {'border-left pl-2': borderClass}]">
				<small>
					<slot v-if="!isScarcity" />
					<span v-else>
						<strong>Jouw datum is beschikbaar</strong>
						De prijzen zijn net gestegen. Je ziet nu de laagste prijzen voor
						{{ $date(booking.Party_Date__c).locale("nl").format("D MMMM, YYYY") }}. Boek direct of plaats een optie met
						een offerte om jouw prijs te verzekeren.
					</span>
				</small>
			</div>
		</b-alert>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "Alert",
	props: {
		alertType: {
			type: String,
			required: true,
			default: "info",
		},
		isScarcity: {
			type: Boolean,
			default: false,
		},
		dismissible: {
			type: Boolean,
			required: false,
			default: false,
		},
		reduced: {
			type: Boolean,
			required: false,
			default: false,
		},
		scarcity: {
			type: Object,
			default: function () {
				return {
					prices: 80,
					availability: 75,
				};
			},
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
		}),
		alertColor: function () {
			if (this.alertType == "info") return "success";
			else if (this.alertType == "notification") return "warning";
			else if (this.alertType == "warning") return "warning";
			else if (this.alertType == "danger") return "danger";
			else if (this.alertType == "light") return "light";
			return "success";
		},
		alertIcon: function () {
			if (this.isScarcity) return "stopwatch";
			else if (this.alertType == "info") return "lock-alt";
			else if (this.alertType == "notification") return "bell";
			else if (this.alertType == "warning") return "exclamation-triangle";
			else if (this.alertType == "danger") return "times-circle";
			else if (this.alertType == "light") return "bell";
			return "";
		},
		iconColor: function () {
			if (this.isScarcity) return "text-warning";
			else if (this.alertType == "info") return "text-success";
			else if (this.alertType == "notification") return "text-warning";
			else if (this.alertType == "warning") return "text-warning";
			else if (this.alertType == "danger") return "text-danger";
			else if (this.alertType == "light") return "text-primary";
			return "";
		},
		borderClass: function () {
			if (this.alertType == "info") return "border-success";
			else if (this.alertType == "notification") return "border-warning";
			else if (this.alertType == "warning") return "border-warning";
			else if (this.alertType == "danger") return "border-danger";
			else if (this.alertType == "light") return "border-dark";
			return "";
		},
	},
};
</script>
