<template>
	<div v-if="booking" class="w-100">
		<div v-if="!areTermsAndConditionsAccepted" class="form-group">
			<label
				class="d-flex flex-row justify-content-center justify-content-md-end"
				:class="{'alert alert-warning mb-1 p-1': !accepted && submitted}"
			>
				<div class="">
					<input class="mr-2 mt-1" type="checkbox" v-model="accepted" name="agree" value="1" />
				</div>
				<div class="terms-check-lbl">
					Ja, ik ga akkoord met de <span class="ahref" @click="$bvModal.show('tc')">algemene voorwaarden</span>.
				</div>
			</label>
			<small class="d-block font-weight-bold mt-2 text-warning text-center text-md-right" v-if="!accepted && submitted"
				>Ga eerst akkoord met onze algemene voorwaarden</small
			>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
	name: "TermsAndConditionsCheck",
	computed: {
		...mapGetters({
			booking: "getBooking",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
			areTermsAndConditionsAccepted: "areTermsAndConditionsAccepted",
		}),
	},
	data() {
		return {
			accepted: false,
			submitted: false,
		};
	},
	methods: {},
	watch: {
		accepted: function watchAccepted(val) {
			this.$root.$emit("setTermsAccepted", val);
		},
	},
	mounted() {
		this.$root.$emit("setSubmitted", false);
		this.$root.$on("setTermsAccepted", (value) => (this.accepted = value));
		this.$root.$on("setSubmitted", (value) => (this.submitted = value));
	},
};
</script>
<style lang="scss" scoped>
.terms-check-lbl {
	@include media-breakpoint-up(md) {
		max-width: 225px;
	}

	@include media-breakpoint-only(lg) {
		max-width: 225px;
	}
}
</style>
