import store from "@/store";
const axios = require("axios");

export function getSpotify(action, url, getters) {
	return axios
		.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + getters.getTokens["access_token"],
			},
		})
		.then((response) => {
			if (response.status === 200) {
				return response;
			} else {
				console.error(action + "Error");
				return null;
			}
		})
		.catch(function (err) {
			store.dispatch("handleError", err);
		});
}

export function postSpotify(action, url, params, basicHeader = false) {
	var authHeader = "";
	var contentTypeHeader = "";

	if (basicHeader) {
		authHeader =
			"Basic " +
			new Buffer(process.env.VUE_APP_SPOTIFY_CLIENT_ID + ":" + process.env.VUE_APP_SPOTIFY_CLIENT_SECRET).toString(
				"base64"
			);
		contentTypeHeader = "application/x-www-form-urlencoded";
	}
	axios.defaults.headers = {
		"Content-Type": contentTypeHeader,
		Authorization: authHeader,
	};
	return axios
		.post(url, params)
		.then(function (response) {
			return response;
		})
		.catch(function (err) {
			store.dispatch(action + "handleError", err);
		});
}
