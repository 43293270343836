<template>
	<div :id="inputName" :class="[{'mt-5': extraSpaceTop}, {'mb-5': extraSpaceBottom}]" class="scroll-mt-36 relative">
		<FormGridWrapper :labelAlwaysTop="inputLabelAlwaysTop" :labelHidden="noInputLabel">
			<template slot="left">
				<div v-if="!inputLabelAlwaysTop" :class="{'md:absolute top-[0.55rem] block': inputLabelStretch}">
					<span
						class="break-words hyphens-auto"
						:class="{'text-amber-600 underline decoration-2 decoration-amber-500': error}"
					>
						{{ inputLabel }}</span
					>
					<div v-if="inputSubLabel" v-text="inputSubLabel" class="text-xs md:text-sm text-slate-400 block" />
				</div>
			</template>
			<template slot="right">
				<div v-if="inputLabelAlwaysTop" class="mb-2" :class="{'md:absolute top-[0.55rem] block': inputLabelStretch}">
					<span
						class="break-words hyphens-auto text-slate-500 dark:text-slate-400 text-sm md:text-base"
						:class="{'text-amber-600 underline decoration-2 decoration-amber-500': error}"
					>
						{{ inputLabel }}</span
					>
					<div v-if="inputSubLabel" v-text="inputSubLabel" class="text-xs md:text-sm text-slate-400 block" />
				</div>

				<div v-if="isPhoneInput" class="relative w-full">
					<TelInput
						ref="phoneInput"
						:id="inputName"
						v-model="inputValue"
						:disabled="disabled"
						@change="onChange"
						@keyup="onKeyUp"
						@keypress="onKeyPress"
						errorclass="mt-2 text-xs text-amber-600"
						:class="{'border border-amber-500': error}"
						mol
					></TelInput>
				</div>
				<div v-if="isDateInput" class="relative w-full">
					<div class="absolute inset-y-0 left-0 flex items-center pl-3">
						<font-awesome-icon :icon="['far', 'calendar']" class="text-slate-400 h-4 w-4" />
					</div>
					<input
						:value="chosenDate"
						type="text"
						@focus="onFocus()"
						@blur="onBlur()"
						@change="onChange"
						@keypress="onKeyPress"
						@keyup="onKeyUp"
						:disabled="disabled"
						:placeholder="placeholder"
						class="pl-9 text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600"
						:class="{'border border-amber-500': error}"
					/>
					<input
						v-model="inputValue"
						type="date"
						@focus="onFocus()"
						@blur="onBlur()"
						@change="onChange"
						@keypress="onKeyPress"
						@keyup="onKeyUp"
						lang="nl-NL"
						:min="$date().format('YYYY-MM-DD')"
						:disabled="disabled"
						:placeholder="placeholder"
						class="text-sm md:text-base p-2 w-full left-0 right-0 md:w-100 opacity-0 absolute top-0"
						:class="{'border border-amber-500': error}"
					/>
				</div>

				<input
					v-if="isTextInput"
					v-model="inputValue"
					:value="value"
					:type="inputTextType"
					@focus="onFocus()"
					@blur="onBlur()"
					@change="onChange"
					@keypress="onKeyPress"
					@keyup="onKeyUp"
					:disabled="disabled"
					:placeholder="placeholder"
					class="text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600"
					:class="[
						{'border border-amber-500': error},
						{'pl-9': inputIcon != ''},
						{'bg-slate-100 text-slate-500 dark:!text-slate-300 dark:!bg-slate-600': disabled},
					]"
				/>

				<textarea
					v-if="isTextarea"
					:placeholder="placeholder"
					:ref="inputName"
					v-model="inputValue"
					rows="6"
					@focus="onFocus()"
					@blur="onBlur()"
					@keyup="onKeyUp"
					:disabled="disabled"
					class="text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600"
					:class="[{'bg-slate-100 text-slate-500 dark:!text-slate-300 dark:!bg-slate-600': disabled}]"
				/>
				<Button v-if="isFileInput" class="relative" :label="fileInputButtonLabelText" icon="files" lg>
					<input
						:name="inputName"
						:id="inputName"
						ref="fileInput"
						type="file"
						:accept="inputFilesAccepted"
						multiple
						@focus="onFocus()"
						@blur="onBlur()"
						@change="onFileChange"
						:disabled="disabled"
						class="opacity-0 absolute w-full h-full top-0 left-0 cursor-pointer"
						:class="{'border border-amber-500': error}"
					/>
				</Button>
				<div v-if="isRatingInput" class="text-xs text-slate-400 flex justify-between w-full mb-2">
					<div v-if="ratingLabels === 'value'" class="justify-self-start flex items-center">
						<font-awesome-icon :icon="['far', 'arrow-left']" class="h-3 mr-1" />
						{{ $t("common.general.label.veryBad") }}
					</div>
					<div v-if="ratingLabels === 'value'" class="justify-self-end flex items-center">
						{{ $t("common.general.label.veryGood") }}
						<font-awesome-icon :icon="['far', 'arrow-right']" class="h-3 ml-1" />
					</div>
					<div v-if="ratingLabels === 'likeliness'" class="justify-self-start flex items-center">
						<font-awesome-icon :icon="['far', 'arrow-left']" class="h-3 mr-1" />
						{{ $t("common.general.label.leastLikely") }}
					</div>
					<div v-if="ratingLabels === 'likeliness'" class="justify-self-end flex items-center">
						{{ $t("common.general.label.mostLikely") }}
						<font-awesome-icon :icon="['far', 'arrow-right']" class="h-3 ml-1" />
					</div>
				</div>
				<div v-if="isRadioInput || isCheckboxInput" :class="[gridColClasses, gridColClassesMd]" class="w-full">
					<div v-for="(choice, index) in inputChoices" :key="index">
						<label :class="{'aspect-square': isRatingInput}" class="flex items-center align-middle justify-between">
							<input
								v-if="isRadioInput"
								v-model="inputValue"
								:id="choice.value"
								type="radio"
								:value="choice.value"
								:name="inputName"
								class="peer hidden"
								:disabled="disabled"
								@change="onChange"
							/>
							<input
								v-if="isCheckboxInput"
								v-model="inputValue"
								:id="choice.value"
								type="checkbox"
								:value="choice.value"
								:name="choice.value"
								class="peer hidden"
								:disabled="disabled"
								@change="onChange"
							/>
							<div
								class="h-full inline-flex items-center justify-between w-full p-2 text-slate-500 dark:text-slate-400 border peer-checked:ring-1 peer-checked:!ring-tdc-blue-500 outline-none border-slate-300 rounded dark:hover:text-slate-300 dark:border-slate-700 dark:peer-checked:!text-tdc-blue-500 peer-checked:border-tdc-blue-500 peer-checked:!bg-sky-100 peer-checked:!text-tdc-blue-500 hover:text-slate-600 dark:text-slate-400 group"
								:class="[
									{'bg-white dark:bg-slate-800 cursor-pointer': !disabled},
									{'h-full !justify-center': isRatingInput},
									{'bg-slate-100 dark:text-slate-400 dark:bg-slate-600': isRatingInput && disabled},
									{'hover:bg-sky-100 dark:hover:bg-slate-400/40': isRatingInput && !disabled},
								]"
							>
								<div :class="{'': isRatingInput}" class="block">
									<div class="w-full text-base">
										{{ choice.label }}
										<div v-if="choice.sublabel" class="text-xs">{{ choice.sublabel }}</div>
									</div>
								</div>
								<font-awesome-icon
									v-if="!isRatingInput"
									:icon="['far', choiceIconSelected]"
									class="h-4 text-tdc-blue-500 dark:text-slate-400 pr-1 hidden peer-checked:group-[]:!inline-block"
								/>
								<font-awesome-icon
									v-if="!isRatingInput"
									:icon="['far', choiceIcon]"
									class="h-4 text-slate-400 dark:text-slate-400 pr-1 peer-checked:group-[]:hidden"
								/>
							</div>
						</label>
					</div>
				</div>
				<div v-if="isSelectInput" class="w-full relative">
					<select
						v-model="inputValue"
						@focus="onFocus()"
						@blur="onBlur()"
						@change="onChange"
						@keypress="onKeyPress"
						@keyup="onKeyUp"
						:disabled="disabled"
						class="appearance-none text-sm md:text-base p-2 w-full md:w-100 ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 bg-white dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600 flex w-full"
						:class="[
							{'border border-amber-500': error},
							{'indent-6': inputIcon != ''},
							{'bg-slate-100 !text-slate-500 dark:!text-slate-300 dark:!bg-slate-600': disabled},
						]"
					>
						<option v-if="placeholder" disabled :selected="inputValue != ''" value="">
							{{ placeholder }}
						</option>
						<option
							v-for="(choice, index) in inputChoices"
							:key="index"
							:value="choice.value"
							:selected="inputValue == choice.value"
							:class="[{'bg-slate-100 !text-slate-500 dark:!text-slate-300 dark:!bg-slate-600': disabled}]"
						>
							{{ choice.label }}
						</option>
					</select>
					<div v-if="inputIcon != ''" class="absolute inset-y-0 left-0 flex items-center pl-3">
						<font-awesome-icon :icon="['far', inputIcon]" class="text-slate-400 h-4 w-4" />
					</div>
				</div>

				<div v-if="error" class="mt-2 text-xs text-amber-600">
					{{ errorMessage }}
				</div>
			</template>
		</FormGridWrapper>
	</div>
</template>

<script>
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Button from "@/components/location/Button";
import TelInput from "@/components/common/TelInput";

export default {
	name: "InputItem",
	props: {
		inputName: {
			type: String,
			default: "",
		},
		inputType: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		inputFilesAccepted: {
			type: String,
			default: "image/png, image/gif, image/jpeg, image/jpg",
		},
		inputLabel: {
			type: String,
			default: "",
		},
		noInputLabel: {
			type: Boolean,
			default: false,
		},
		fileInputButtonLabel: {
			type: String,
			default: "",
		},
		inputIcon: {
			type: String,
			default: "",
		},
		inputLabelAlwaysTop: {
			type: Boolean,
			default: false,
		},
		inputSubLabel: {
			type: String,
			default: "",
		},
		inputLabelStretch: {
			type: Boolean,
			default: false,
		},
		choices: {
			type: Array,
		},
		gridCols: {
			type: Number,
		},
		gridColsMd: {
			type: Number,
		},
		extraSpaceTop: {
			type: Boolean,
			default: false,
		},
		extraSpaceBottom: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: "",
		},
		ratingLabels: {
			type: String,
			default: "likeliness",
		},
	},
	components: {
		FormGridWrapper,
		Button,
		TelInput,
	},
	emits: ["blur", "change", "fileschange", "keypress", "keyup"],
	computed: {
		inputValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		inputChoices() {
			if (this.isRatingInput) {
				return [
					{value: "1", label: "1"},
					{value: "2", label: "2"},
					{value: "3", label: "3"},
					{value: "4", label: "4"},
					{value: "5", label: "5"},
					{value: "6", label: "6"},
					{value: "7", label: "7"},
					{value: "8", label: "8"},
					{value: "9", label: "9"},
					{value: "10", label: "10"},
				];
			}
			return this.choices;
		},
		isTextInput() {
			return (
				!this.inputType ||
				this.inputType == "text" ||
				this.inputType == "number" ||
				this.inputType == "email" ||
				this.inputType == "password"
			);
		},
		isTextarea() {
			return this.inputType == "textarea";
		},
		isRadioInput() {
			return this.inputType == "radio" || this.inputType == "rating";
		},
		isCheckboxInput() {
			return this.inputType == "checkbox";
		},
		isFileInput() {
			return this.inputType == "file";
		},
		fileInputButtonLabelText() {
			return this.fileInputButtonLabel ? this.fileInputButtonLabel : this.$t("common.general.button.selectFiles");
		},
		isSelectInput() {
			return this.inputType == "select";
		},
		isDateInput() {
			return this.inputType == "date";
		},
		isPhoneInput() {
			return this.inputType == "tel";
		},
		isRatingInput() {
			return this.inputType == "rating";
		},
		choiceIcon() {
			if (this.isRadioInput) return "circle";
			if (this.isCheckboxInput) return "square";
			return "question";
		},
		choiceIconSelected() {
			if (this.isRadioInput) return "circle-dot";
			if (this.isCheckboxInput) return "square-check";
			return "question";
		},
		choiceLabelFor() {
			if (this.isRadioInput) return "circle-dot";
			if (this.isCheckboxInput) return "square-check";
			return "question";
		},
		inputTextType() {
			if (!this.inputType || this.inputType == "text") return "text";
			if (this.inputType == "number") return "number";
			if (this.inputType == "email") return "email";
			if (this.inputType == "password") return "password";
			return "";
		},
		gridColClasses() {
			if (this.isRatingInput) return "grid gap-2 grid-cols-5";
			let classes = "";
			if (this.gridCols) classes = "grid gap-2 ";
			if (this.gridCols == 2) classes = classes + "grid-cols-2 ";
			if (this.gridCols == 3) classes = classes + "grid-cols-3 ";
			if (this.gridCols == 4) classes = classes + "grid-cols-4 ";
			return classes;
		},
		gridColClassesMd() {
			if (this.isRatingInput) return "grid gap-2 min-[600px]:grid-cols-10 md:grid-cols-5 min-[900px]:grid-cols-10 ";
			let classes = "";
			if (this.gridColsMd) classes = "grid gap-2 ";
			if (this.gridColsMd == 2) classes = classes + "md:grid-cols-2 ";
			if (this.gridColsMd == 3) classes = classes + "md:grid-cols-3 ";
			if (this.gridColsMd == 4) classes = classes + "md:grid-cols-4 ";
			return classes;
		},
		chosenDate() {
			if (this.isDateInput && !this.inputValue) return "";
			return this.$date(this.inputValue).format("DD/MM/YYYY");
		},
	},
	methods: {
		isValidPhoneNumber() {
			if (!this.$refs.phoneInput) {
				//console.log("telinput not found");
				return true;
			}
			if (!this.$refs.phoneInput.checkValidity) {
				//console.log("checkvalidity not found");
				return true;
			}
			if (!this.$refs.phoneInput.checkValidity()) {
				//console.log("invalid phone number");
				return false;
			}
			if (this.error) {
				//console.log("form validation error");
				return false;
			}
			return true;
		},

		/**
		 * When the input gets focus
		 */
		onFocus() {
			this.$emit("focus");
		},

		/**
		 * When the input loses focus
		 */
		onBlur() {
			this.$emit("blur");
		},

		/**
		 * When the input got changed
		 */
		onChange() {
			this.$emit("change");
		},

		onFileChange(evt) {
			console.log("onFileChange", evt);
			let files = evt.target.files || evt.dataTransfer.files;
			this.$emit("fileschange", files);
		},

		/**
		 * When a key gets pressed
		 * @param  {Event} event A keypress event
		 */
		onKeyPress(event) {
			this.$emit("keypress", event);
		},

		/**
		 * When a keyup occurs
		 * @param  {Event} event A keyup event
		 */
		onKeyUp(event) {
			this.$emit("keyup", event);
		},
	},
};
</script>
