<template>
	<span>
		<DataItem :dataLabel="$t('mol.spaceForm.label.Name')" :dataValue="space.Space_Name__c" />
		<DataItem :dataLabel="$t('mol.spaceForm.label.Main space')" :dataValue="isMainSpaceLabel" />

		<DataItem :dataLabel="$t('mol.spaceForm.label.Space attributes')">
			<DataItem
				v-if="
					!space.not_ground_floor__c &&
					!space.not_ground_floor_no_elevator__c &&
					!space.not_paved_road_to_unload__c &&
					!space.not_paved_road_unload_to_gig__c &&
					!space.no_free_electric_network__c &&
					!space.small_doors__c &&
					!space.unload_25_meter__c &&
					!space.no_free_parking__c
				"
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.No known attributes')"
				:dataValue="true"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Setup area is not on the ground floor')"
				:dataValue="space.not_ground_floor__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Space is not on the ground floor and there is no elevator')"
				:dataValue="space.not_ground_floor_no_elevator__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Path to unloading area is not paved')"
				:dataValue="space.not_paved_road_to_unload__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Unpaved path to the setup area')"
				:dataValue="space.not_paved_road_unload_to_gig__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.No free electrical outlet groups available')"
				:dataValue="space.no_free_electric_network__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Small entrance to setup area')"
				:dataValue="space.small_doors__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.Distance to loading area is more than 25m')"
				:dataValue="space.unload_25_meter__c"
			/>
			<DataItem
				hiddenIfFalse
				labelIsValue
				listItem
				:dataLabel="$t('mol.spaceForm.label.No free parking spaces')"
				:dataValue="space.no_free_parking__c"
			/>
		</DataItem>
		<DataItem extraSpaceBottom :dataLabel="$t('mol.spaceForm.label.Additional info')" :dataValue="space.Details__c" />
		<Alert>
			{{ $t("mol.spaceForm.string.We use the above information") }}
		</Alert>
		<DataItem
			:dataValue="space.space_attr_size__c"
			:dataLabel="$t('mol.spaceForm.label.Space size')"
			dataSubLabel="m2"
			extraSpaceTop
		/>
		<DataItem :dataValue="hasVolumeRestrictionsLabel" :dataLabel="$t('mol.spaceForm.label.Volume restrictions')" />
		<DataItem
			:dataValue="space.space_attr_maxdb__c"
			:dataLabel="$t('mol.spaceForm.label.Max volume')"
			dataSubLabel="dB"
		/>
	</span>
</template>

<script>
import {mapGetters} from "vuex";
import DataItem from "@/components/location/DataItem";
import Alert from "@/components/location/Alert";

export default {
	name: "SpaceData",
	components: {
		DataItem,
		Alert,
	},
	props: {
		space: {},
	},
	computed: {
		...mapGetters({
			mainSpace: "getLocationMainSpaceDetails",
		}),
		isMainSpaceLabel() {
			return this.space.Id == this.mainSpace.Id ? this.$t("mol.general.string.Yes") : this.$t("mol.general.string.No");
		},
		hasVolumeRestrictionsLabel() {
			return this.space.space_attr_soundlimit__c
				? this.$t("mol.spaceForm.label.Venue space has volume restrictions")
				: this.$t("mol.spaceForm.label.Venue space has no volume restrictions");
		},
	},
};
</script>
