<template>
	<div>
		<div v-if="!locationIsKnown || locationIsPrivate" class="mb-3">
			<div
				class="d-flex align-items-center h-100 background rounded"
				:style="{backgroundImage: 'url(' + locationBackgroundImage + ')'}"
			>
				<div class="haze" style="z-index: 1"></div>
				<div class="px-3 px-md-3 py-4" style="z-index: 2">
					<h3 v-if="locationIsPrivate" class="text-white mb-0 font-weight-bolder">
						Privé feestlocatie<span v-if="hasGeo" v-text="' in ' + geo" />
					</h3>
					<h3 v-else-if="!locationIsKnown" class="text-white mb-0 font-weight-bolder">
						{{ location.Name }}<span v-if="hasGeo" v-text="' in ' + geo" />
					</h3>
				</div>
			</div>
		</div>
		<div v-else>
			<h3 class="mb-0 font-weight-bolder">{{ location.Name }}<span v-if="hasGeo" v-text="' in ' + geo" /></h3>
		</div>

		<div
			v-if="hasLocationMedia"
			class="background profile-image mb-3 mt-3"
			:style="{backgroundImage: 'url(' + locationBackgroundImage + ')'}"
		></div>

		<p v-if="locationIsKnown || locationIsPrivate" class="b-0">
			The DJ Company scoort een <strong v-html="locationRating"></strong>
			<span v-if="!location.Location_private__c"> op deze locatie</span>
			<span v-if="location.Location_private__c"> op privé locaties</span><br /><span class="stars"
				><StarRating :rating="locationRating" class="d-inline"
			/></span>
			(<strong v-html="locationRating"></strong>/10)
		</p>

		<div style="z-index: 2">
			<p class="mb-0 mt-auto">
				<LocationSpecs parent="location" v-if="getNumberOfShows > 0" />
			</p>
		</div>

		<div class="mt-3 mt-md-3 row row-cols-1 row-cols-md-2">
			<Contacts v-if="!locationIsPrivate" />
			<div class="col d-flex align-items-stretch">
				<div class="p-2 p-md-3 bg-white rounded mb-3 d-flex justify-content-start w-100">
					<font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'map-marker-alt']" />
					<div class="d-flex flex-column w-100">
						<div class="d-flex justify-content-between">
							<strong class="flex-grow-1">Adres feestlocatie</strong>
						</div>
						<div class="w-100 flex-fill">
							<p class="mb-0">
								<span v-if="location.BillingStreet">{{ location.BillingStreet }}<br /></span>
								<span v-if="location.BillingPostalCode">{{ location.BillingPostalCode }}<br /></span>
								{{ location.BillingCity }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p v-if="locationIsPrivate">
			<small
				><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'info-circle']" /> Feestlocatie adres
				aanpassen? Neem contact op met ons</small
			>
		</p>
		<p v-if="!locationIsPrivate">
			<small
				><font-awesome-icon class="text-primary mr-2 mt-1" :icon="['far', 'info-circle']" /> Feestlocatie aanpassen?
				Neem contact op met ons</small
			>
		</p>
		<h3 v-if="locationIsPrivate"><strong>Type feestlocatie</strong></h3>
		<b-form-group
			v-if="locationIsPrivate"
			vertical
			class="mdc-radio-check-group no-max-width"
			label="Wat voor locatie is het feestlocatie?"
			label-for="Type locatie"
		>
			<div class="row row-cols-1 row-cols-md-3 mx-n1">
				<b-form-checkbox
					id="home"
					@change.native="updateAddress('private_location_type__c')"
					:class="getInputClass('private_location_type__c')"
					:disabled="isConfirmed"
					class="d-block mb-2 mdc-radio-check col px-1"
					v-model="location.private_location_type__c"
					name="type"
					value="Thuislocatie"
					button
					button-variant="outline-tdc"
				>
					<OptionLabel radio primary="Thuis" secondary="Bij mij of iemand anders thuis" icon="home" />
				</b-form-checkbox>
				<b-form-checkbox
					id="office"
					@change.native="updateAddress('private_location_type__c')"
					:class="getInputClass('private_location_type__c')"
					:disabled="isConfirmed"
					class="d-block mb-2 mdc-radio-check col px-1"
					v-model="location.private_location_type__c"
					name="type"
					value="Bedrijfslocatie"
					button
					button-variant="outline-tdc"
				>
					<OptionLabel radio primary="Bedrijfspand" secondary="In een bedrijfslocatie" icon="building" />
				</b-form-checkbox>
				<b-form-checkbox
					id="other"
					@change.native="updateAddress('private_location_type__c')"
					:class="getInputClass('private_location_type__c')"
					:disabled="isConfirmed"
					v-model="location.private_location_type__c"
					name="type"
					value="Anders, namelijk:"
					class="d-block mb-2 mdc-radio-check col px-1"
					button
					button-variant="outline-tdc"
				>
					<OptionLabel radio primary="Anders" secondary="" icon="question" />
				</b-form-checkbox>
			</div>
		</b-form-group>
		<div v-if="location.private_location_type__c == 'Anders, namelijk:'">
			<b-form-group label="Type locatie" label-for="namelijk">
				<b-form-input
					id="namelijk"
					class="d-inline"
					label="Anders, namelijk:"
					v-model="location.private_location_type_custom__c"
					:disabled="location.private_location_type__c != 'Anders, namelijk:' || isConfirmed"
					:required="location.private_location_type__c == 'Anders, namelijk:'"
					maxlength="100"
					placeholder=" "
					@input.native="delayUpdateAddress('private_location_type_custom__c')"
				>
				</b-form-input>
			</b-form-group>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import LocationSpecs from "@/components/customer/LocationSpecs";
import Contacts from "@/components/customer/Contacts";
import StarRating from "@/components/customer/StarRating";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import OptionLabel from "@/components/customer/OptionLabel";

export default {
	name: "Location",
	components: {Contacts, FontAwesomeIcon, LocationSpecs, StarRating, OptionLabel},
	computed: {
		...mapGetters({
			location: "getLocation",
			locationMedia: "getLocationMedia",
			hasLocationMedia: "hasLocationMedia",
			cities: "getQueryCities",
			hasLocation: "hasLocation",
			hasGeo: "hasGeo",
			geo: "getGeo",
			isWedding: "isWedding",
			isLoading: "getLoadingState",
			isConfirmed: "isConfirmed",
			getNumberOfShows: "getNumberOfShows",
			isBelgian: "isBelgian",
			loadingInput: "getLoadingInput",
		}),
		locationIsPrivate() {
			return this.location.Location_private__c;
		},
		locationIsKnown() {
			return "Location_Portal_First_Visit__c" in this.location;
		},
		reviewText: function () {
			return this.location.LocationReviewsForLocCount__c > 1 ? "reviews" : "review";
		},
		canEditAddressDetails: function () {
			return this.locationIsPrivate || !("Location_Portal_First_Visit__c" in this.location);
		},
		locationPrivateType: function () {
			return this.location.private_location_type__c == "Anders, namelijk:"
				? this.location.private_location_type_custom__c
				: this.location.private_location_type__c;
		},
		locationBackgroundImage() {
			if (this.hasLocationMedia) {
				if (this.locationIsPrivate) return require("@/assets/images/dj-image.jpg");
				else if (this.hasLocationMedia) return this.locationMedia[0].FileLink__c;
				return require("@/assets/images/dj-image.jpg");
			}
			return require("@/assets/images/grey-gradient.jpg");
		},
		locationRating() {
			return "LocationReviewsForLocScore__c" in this.location
				? parseInt(this.location.LocationReviewsForLocScore__c.toFixed(1))
				: 9.2;
		},
	},
	data() {
		return {};
	},
	methods: {
		updateAddress(input) {
			this.$store.dispatch("postBooking", {
				action: "updateAddress",
				data: {
					Id: this.location.Id,
					BillingStreet: this.location.BillingStreet,
					BillingPostalCode: this.location.BillingPostalCode,
					BillingCity: this.location.BillingCity,
					private_location_type__c: this.location.private_location_type__c,
					private_location_type_custom__c: this.location.private_location_type_custom__c,
				},
				setBooking: true,
				input: input,
			});
		},
		delayUpdateAddress(input) {
			this.setUnsavedData(true);
			clearTimeout(this.delay);
			this.delay = setTimeout(
				function () {
					this.updateAddress(input);
				}.bind(this),
				2000
			);
		},
		getInputClass(input) {
			if (input == this.loadingInput.name) {
				if (this.loadingInput.status == "saving") return "input-saving";
				else if (this.loadingInput.status == "saved") {
					this.setUnsavedData(false);
					this.delay = setTimeout(
						function () {
							this.$root.$emit("bv::hide::popover");
						}.bind(this),
						4000
					);
					return "input-saved";
				}
			}
			return "";
		},
		setUnsavedData(boelean) {
			this.unsavedData = boelean;
		},
	},
	mounted() {
		if (this.hasLocation && !this.locationIsPrivate) this.$store.dispatch("getLocationMedia");
	},
};
</script>
<style lang="scss">
.profile-image {
	@extend .rounded;
	height: 275px;
	@include media-breakpoint-up(md) {
		height: 550px;
	}
}
.location-content-image {
	height: 250px;
	@include media-breakpoint-up(md) {
		height: 400px;
	}
}
.form-row {
	> div,
	> label {
		padding-bottom: 0px !important;
	}
}
.multiselect__placeholder {
	color: #495057;
}
</style>
