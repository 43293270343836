<template>
	<div>
		<div class="d-flex justify-content-center justify-content-md-end flex-column">
			<TermsAndConditionsCheck v-if="!areTermsAndConditionsAccepted" />
			<div class="d-flex justify-content-center justify-content-sm-end">
				<b-btn
					@click.prevent="goToPayment()"
					size="lg"
					class="btn-ideal d-flex align-items-center justify-content-center mb-3 w-auto px-5"
				>
					Direct betalen <font-awesome-icon class="ml-3" :icon="['far', 'chevron-right']" />
				</b-btn>
			</div>
			<div class="d-flex justify-content-center justify-content-md-end align-items-center">
				<font-awesome-icon style="font-size: 110%" class="text-success mr-1" :icon="['fas', 'lock-alt']" />
				Beveiligde verbinding
			</div>
		</div>

		<div class="mt-3 d-flex justify-content-center my-1 mt-md-5 flex-nowrap">
			<span v-if="isBelgian" class="payment-icon"
				><img alt="Bancontact" class="w-100" src="~@/assets/images/betalen-bancontact.png"
			/></span>
			<span class="payment-icon"><img alt="iDeal" class="w-100" src="~@/assets/images/betalen-ideal.png" /></span>

			<span class="payment-icon"><img alt="PayPal" class="w-100" src="~@/assets/images/betalen-paypal.png" /></span>
			<span class="payment-icon"><img alt="Mastercard" class="w-100" src="~@/assets/images/betalen-mc.png" /></span>
			<span class="payment-icon"><img alt="Maestro" class="w-100" src="~@/assets/images/betalen-maestro.png" /></span>
			<span class="payment-icon"><img alt="Visa" class="w-100" src="~@/assets/images/betalen-visa.png" /></span>
			<span class="payment-icon"
				><img alt="Apple Pay" class="w-100" src="~@/assets/images/betalen-applepay.png"
			/></span>
			<span v-if="isDutch" class="payment-icon"
				><img alt="Google Pay" class="w-100" src="~@/assets/images/betalen-googlepay.png"
			/></span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import TermsAndConditionsCheck from "@/components/customer/TermsAndConditionsCheck";

export default {
	name: "PaymentButton",
	components: {
		TermsAndConditionsCheck,
	},
	data() {
		return {
			accepted: false,
			submitted: false,
		};
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
			areTermsAndConditionsAccepted: "areTermsAndConditionsAccepted",
		}),
	},
	methods: {
		goToPayment() {
			this.$root.$emit("setSubmitted", true);
			if (this.areTermsAndConditionsAccepted) {
				this.$store.commit("SET_LOADING_STATE", true);
				window.location = this.booking.DownPaymentUrl__c;
			} else if (this.accepted) {
				this.$store.commit("SET_LOADING_STATE", true);
				this.$store
					.dispatch("postBooking", {
						action: "acceptTC",
						data: {},
						setBooking: true,
					})
					.then(() => {
						this.$store.commit("SET_LOADING_STATE", true);
						window.location = this.booking.DownPaymentUrl__c;
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
	},
	mounted() {
		this.$root.$on("setTermsAccepted", (value) => (this.accepted = value));
		this.$root.$on("setSubmitted", (value) => (this.submitted = value));
	},
};
</script>

<style lang="scss" scoped>
.payment-icon {
	@extend .text-center;
	@extend .bg-white;
	@extend .p-2;
	@extend .rounded;
	@extend .border;
	@extend .mb-1;
	max-width: 18%;
	margin-left: 1%;
	margin-right: 1%;

	@include media-breakpoint-up(md) {
		max-width: 75px;
	}
}
</style>
