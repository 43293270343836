import {getSpotify} from "@/services/SpotifyService";

const state = {
	searchData: {
		artists: {
			items: [],
		},
		tracks: {
			items: [],
		},
	},
};

const getters = {
	getSearchData(state) {
		return state.searchData;
	},
};

const mutations = {
	setSearchData(state, data) {
		state.searchData = data;
	},
};

const actions = {
	searchData({getters, commit}, val) {
		var searchUrl = "https://api.spotify.com/v1/search?q=" + encodeURI(val) + "&type=track,artist";
		return getSpotify("searchData", searchUrl, getters)
			.then((response) => {
				if (response) {
					commit("setSearchData", response.data);
				} else {
					console.warn("no response");
					commit("setSearchData", {artists: {items: []}, tracks: {items: []}});
				}
			})
			.catch((err) => {
				console.error("catch", err);
				commit("setSearchData", {artists: {items: []}, tracks: {items: []}});
			});
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
