<template>
	<span>
		<div class="input-msg input-saving-msg">
			<font-awesome-icon :icon="['fad', 'spinner-third']" spin />bezig met opslaan
		</div>
		<div class="input-msg input-saved-msg"><font-awesome-icon :icon="['far', 'check-circle']" />opgeslagen</div>
	</span>
</template>

<script>
export default {
	name: "InputFeedback",
};
</script>

<style lang="scss">
.input-msg {
	padding: 3px 8px 3px 8px;
	background: rgba(#fff, 0.95);
	position: absolute;
	z-index: -1;
	top: 1px;
	bottom: 1px;
	right: 1px;
	display: flex;
	@extend .flex-row;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 13px;
	border-radius: 4px;
	opacity: 0;
	max-height: 38px;
	@include transition(all 0.2s ease-in-out);
	svg {
		margin-top: 1px;
		margin-right: 5px;
	}
}
.input-saved-msg {
	color: $tdc-green;
}
.input-saving-msg {
	color: $tdc-blue;
}
.input-saving {
	.input-saving-msg {
		opacity: 1;
		z-index: 9;
	}
}
.input-saved {
	.input-saved-msg {
		opacity: 1;
		z-index: 9;
	}
}
</style>
