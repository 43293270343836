<template>
	<component :is="layout">
		<slot />
	</component>
</template>

<script>
import {mapGetters} from "vuex";
const defaultLayout = "customerLayout";

export default {
	name: "AppLayout",
	data() {
		return {
			ready: false,
		};
	},
	computed: {
		...mapGetters({
			userIsLocation: "userIsLocation",
			userIsCustomer: "userIsCustomer",
			userIsCustomerEvaluation: "userIsCustomerEvaluation",
		}),
		layout() {
			if (this.ready) {
				const layout = this.$route.meta.layout || defaultLayout;
				if (this.userIsCustomer) return () => import(`@/layouts/customer/customerLayout.vue`);
				if (this.userIsLocation) return () => import(`@/layouts/location/locationLayout.vue`);
				if (this.userIsCustomerEvaluation) {
					return () => import(`@/layouts/customerEvaluation/customerEvaluationLayout.vue`);
				}
				return () => import(`@/layouts/${layout}.vue`);
			}
			return () => import(`@/layouts/common/loadingLayout.vue`);
		},
	},
	created() {
		if (this.userIsCustomer) {
			this.$store.dispatch("getBooking").then(() => {
				this.ready = true;
			});
			document.querySelector("body").classList.add("moc");
		}
		if (this.userIsLocation) {
			this.$store.dispatch("getLocationInformation").then(() => {
				this.ready = true;
			});
			document.querySelector("body").classList.add("mol");
		}
		if (this.userIsCustomerEvaluation) {
			this.$store.dispatch("checkEvaluation").then(() => {
				this.ready = true;
			});
			document.querySelector("body").classList.add("moe");
		}
	},
};
</script>
