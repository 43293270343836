<template>
	<div :class="[{'mt-5': extraSpaceTop}, {'mb-5': extraSpaceBottom}]">
		<div
			v-if="!hiddenIfFalse"
			class="flex flex-col md:flex-row w-full"
			:class="[{'!flex-col': dataLabelAlwaysTop}, {'!flex-row align-center justify-end': pricingData}]"
		>
			<div
				:class="[
					{'text-right w-[calc(100%-120px)] flex items-start': pricingData},
					{'md:w-[140px] lg:w-[150px] xl:w-[180px] items-end': !pricingData},
				]"
				class="md:mr-3 text-slate-500 dark:text-slate-400 text-sm md:text-base md:pb-2 flex flex-col"
			>
				<div class="w-full" :class="{'md:text-right': alignLabelRight}" v-text="dataLabel" />

				<div
					v-if="dataSubLabel"
					v-text="dataSubLabel"
					:class="{'md:text-right': alignLabelRight}"
					class="text-xs md:text-sm text-slate-300 block w-full"
				/>
				<div class="w-full"><slot name="dataLabel"></slot></div>
				<div class="text-xs md:text-sm text-slate-300 block w-full"><slot name="dataSubLabel"></slot></div>
			</div>
			<div
				:class="[
					{'w-[80px] text-right': pricingData},
					{'md:w-[calc(100%-130px)] lg:w-[calc(100%-150px)] xl:w-[calc(100%-180px)] flex items-center': !pricingData},
				]"
				class="pb-2 sm:pb-2"
			>
				<StarRating v-if="starRating" :rating="showDataValue" class="mr-2" />
				<span v-if="starRating && showDataValue == 0" class="text-xs pl-1 text-slate-500"> - </span>
				<MusicChoices v-else-if="musicChoices" :choices="showDataValue" :top="top" />
				<div v-else class="">{{ showDataValue }}<slot></slot></div>
				<sub v-if="starRating && showDataValue != 0" class="text-xs pl-1 text-slate-500"> /10</sub>
			</div>
		</div>
		<div v-if="hiddenIfFalse && dataValue" :class="{'flex align-top items-start space-x-4 ': listItem}">
			<font-awesome-icon v-if="listItem" :icon="['fas', 'circle']" class="text-tdc-blue mt-2 h-2" />
			<div>{{ showDataValue }}</div>
		</div>
	</div>
</template>

<script>
import StarRating from "@/components/location/StarRating";
import MusicChoices from "@/components/location/MusicChoices";

export default {
	name: "DataItem",
	components: {
		StarRating,
		MusicChoices,
	},
	props: {
		dataLabel: {
			type: String,
			default: "",
		},
		dataSubLabel: {
			type: String,
			default: "",
		},
		dataValue: {},
		hiddenIfFalse: {
			type: Boolean,
			default: false,
		},
		alignLabelRight: {
			type: Boolean,
			default: false,
		},
		labelIsValue: {
			type: Boolean,
			default: false,
		},
		musicChoices: {
			type: Boolean,
			default: false,
		},
		top: {
			type: Number,
			default: 5,
		},
		starRating: {
			type: Boolean,
			default: false,
		},
		listItem: {
			type: Boolean,
			default: false,
		},
		extraSpaceTop: {
			type: Boolean,
			default: false,
		},
		extraSpaceBottom: {
			type: Boolean,
			default: false,
		},
		dataLabelAlwaysTop: {
			type: Boolean,
			default: false,
		},
		pricingData: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showDataValue() {
			if (this.musicChoices) {
				if (this.dataValue) {
					return this.dataValue;
				} else {
					return "";
				}
			} else if (this.starRating) {
				if (this.dataValue > 0) {
					return this.dataValue;
				} else {
					return 0;
				}
			} else if (this.labelIsValue) return this.dataLabel;
			else if (!this.labelIsValue && this.dataValue) return this.dataValue;
			else if (this.$slots.default) return "";
			return "-";
		},
	},
};
</script>
