<template>
	<div class="pb-10">
		<div v-if="error.status === 404">
			{{ $t("moe.customerPhotoForm.string.somethingWentwrong") }}
		</div>
		<div v-else>
			<div v-if="completed">
				<div class="p-2 md:p-5 bg-slate-200 dark:bg-slate-950 rounded-md max-w-[900px] mx-auto">
					<div class="text-tdc-green-500 font-semibold text-xl">{{ $t("moe.customerPhotoForm.title.success") }}</div>
					<div class="mb-5">{{ $t("moe.customerPhotoForm.string.success") }}</div>
					<Button type="gray" @click.native="startOver" arrowPosition="before" icon="arrow-left">
						{{ $t("moe.customerPhotoForm.button.sendMore") }}</Button
					>
				</div>
			</div>
			<div v-else>
				<div class="p-2 md:p-5 bg-slate-200 dark:bg-slate-950 rounded-md max-w-[900px] mx-auto">
					<InputItem
						v-model="userName"
						inputName="userName"
						inputType="text"
						:inputLabel="$t('moe.customerPhotoForm.label.name')"
						:placeholder="$t('moe.customerPhotoForm.string.yourName')"
						:error="formErrors.name"
						:errorMessage="$t('moe.customerPhotoForm.message.fillInName')"
						@change="formErrors.name = false"
						@keypress="formErrors.name = false"
						inputLabelAlwaysTop
						class="flex justify-center"
					/>
					<InputItem
						v-model="photos"
						inputName="Photos"
						inputType="file"
						:fileInputButtonLabel="$t('moe.customerPhotoForm.button.selectFiles')"
						:inputLabel="$t('moe.customerPhotoForm.label.chooseFiles')"
						:error="formErrors.photos"
						:errorMessage="$t('moe.customerPhotoForm.message.selectValidFile')"
						inputLabelAlwaysTop
						extraSpaceBottom
						@fileschange="filesChange"
						class="flex justify-center"
					/>
					<div v-if="photoPreviews.length > 0" class="max-w-[900px] mx-auto mt-1">
						<div class="mb-2 text-sm text-slate-500 dark:text-slate-400">
							<span v-if="photoPreviews.length === 1">{{ $t("common.general.string.1FileChosen") }}</span>
							<span v-else>{{ $t("common.general.string.numFilesChosen", {count: photoPreviews.length}) }}</span>
						</div>
						<div
							class="bg-slate-100/70 dark:bg-slate-700/70 mb-2 grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-1 sm:gap-2 md:gap-2 mr-2 p-2 rounded"
						>
							<div
								v-for="file in photoPreviews"
								:key="file"
								class="rounded w-100 bg-slate-200 dark:bg-slate-800 aspect-square bg-contain bg-center bg-no-repeat border border-slate-200 dark:border-slate-500 relative"
								:style="{backgroundImage: 'url(' + file + ')'}"
							></div>
						</div>
					</div>
				</div>
				<div class="max-w-[900px] mx-auto mt-3">
					<div class="flex justify-center">
						<Button type="green" :disabled="photoPreviews.length === 0" @click.native="submitPhotos">
							{{ $t("moe.customerPhotoForm.button.submit") }}</Button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import InputItem from "@/components/location/InputItem";
import Button from "@/components/location/Button";
import {postSalesForce} from "@/services/SalesforceService";
import axios from "axios";

export default {
	name: "PhotoSteps",
	components: {
		InputItem,
		Button,
	},
	data() {
		return {
			formErrors: this.initialPhotoFormErrors(),
			userName: "",
			photos: [],
			photoPreviews: [],
			completed: false,
		};
	},
	computed: {
		...mapGetters({
			error: "getError",
			booking: "getBookingInformation",
			isLoading: "getLoadingState",
		}),
		hasFormErrors() {
			return Object.keys(this.formErrors).some((key) => this.formErrors[key]);
		},
	},
	methods: {
		initialPhotoFormErrors() {
			return {
				name: false,
				photos: false,
			};
		},
		resetFormErrors() {
			this.formErrors = this.initialPhotoFormErrors();
		},
		filesChange(files) {
			this.photoPreviews = Array.from(files).map((file) => URL.createObjectURL(file));
			this.photos = files;
		},
		validateForm() {
			this.resetFormErrors();
			if (this.userName === "") {
				this.formErrors.name = true;
			}
			if (this.photoPreviews.length === 0) {
				this.formErrors.photos = true;
			}
			if (this.formErrors.name) {
				document.getElementById("userName").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.photos) {
				document.getElementById("Photos").scrollIntoView({behavior: "smooth"});
			}
		},
		submitPhotos() {
			this.validateForm();
			if (!this.hasFormErrors) {
				//SUBMIT IMAGEKIT
				//CREATE MO MEDIA OBJECTS
				this.uploadPhotos();
			}
		},
		uploadPhotos() {
			if (!this.photos || !this.photos.length) {
				return;
			}
			this.$store.commit("SET_LOADING_STATE", true);
			let proms = [];
			for (let i = 0; i < this.photos.length; ++i) {
				const photo = this.photos[i];
				proms.push(
					postSalesForce({action: "imageAuth"})
						.then((response) => {
							const form = new FormData();
							form.append("file", photo);
							form.append("expire", response.data.expire);
							form.append("token", response.data.token);
							form.append("signature", response.data.signature);
							form.append("publicKey", process.env.VUE_APP_IMAGEKIT_PUBKEY);
							form.append("fileName", photo.name);
							form.append("folder", `/${process.env.NODE_ENV}/${this.booking.Id}/`);
							return axios.post("https://upload.imagekit.io/api/v1/files/upload", form);
						})
						.then((response) => {
							return postSalesForce({
								action: "createMedia",
								data: {
									fileLink: response.data.url,
									thumbnail: response.data.thumbnailUrl,
									fileType: response.data.fileType,
									fileId: response.data.fileId,
									uploader: this.userName?.substring(0, 255),
								},
							});
						})
						.catch((err) => {
							console.error(err);
						})
				);
			}
			Promise.all(proms)
				.then(() => {
					this.$store.commit("SET_LOADING_STATE", false);
					this.completed = true;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		startOver() {
			this.resetFormErrors();
			this.userName = "";
			this.photos = [];
			this.photoPreviews = [];
			this.completed = false;
		},
	},
};
</script>
