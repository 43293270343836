import {getSalesForce, postSalesForce} from "@/services/SalesforceService";
import {getPricing} from "@/services/PricingService";
import {loadLanguageAsync} from "@/i18n";

const state = {
	location: {
		location: {},
	},
	alliance: {},
	molSidePanelLevel: 0,
	molEditingState: false,
	molSubnavHash: "",
	futureBookings: [],
	pastBookings: [],
	reviews: [],
	media: [],
	invoices: [],
	pricing: null,
	initialPanelShown: false,
};

const getters = {
	getLocationExternalId(state) {
		if (!state || !state.location || !state.location.ExternalId__c) {
			throw new Error("ExternalId not defined");
		}
		return state.location.ExternalId__c;
	},
	getLocationEvaluation(state) {
		return state.locationEvaluation;
	},
	getMolEditingState(state) {
		return state.molEditingState ? state.molEditingState : false;
	},
	getMolSubnavHash(state) {
		return state.molSubnavHash ? state.molSubnavHash : "";
	},
	getInitialPanelShown(state) {
		console.log("getInitlaPanelShown", state.initialPanelShown);
		return state.initialPanelShown ? state.initialPanelShown : false;
	},
	getMolSidePanelLevel(state) {
		return state.molSidePanelLevel;
	},
	getLocationInformation(state) {
		return state.location;
	},
	getLocationContacts(state) {
		return "Contacts" in state.location ? state.location.Contacts.records : [];
	},
	getLocationMainContactDetails(state) {
		let mainContactId = null;
		let mainContact = {}; // empty object so getting fields from the object will not result in errors
		let contacts = "Contacts" in state.location ? state.location.Contacts.records : [];
		if (state.location.location_main_contact__c) {
			mainContactId = state.location.location_main_contact__c;
		}
		if (mainContactId) {
			mainContact = contacts.find((mainContact) => mainContact.Id === mainContactId);
		}

		return mainContact;
	},
	getLocationContactCount(state) {
		return state.location.Contacts ? state.location.Contacts.totalSize : 0;
	},
	getLocationSpaces(state) {
		return "Location_Spaces__r" in state.location ? state.location.Location_Spaces__r.records : [];
	},
	getLocationMainSpaceDetails(state) {
		let mainSpaceId = null;
		let mainSpace = {}; // empty object so getting fields from the object will not result in errors
		let spaces = "Location_Spaces__r" in state.location ? state.location.Location_Spaces__r.records : [];
		if (state.location.location_main_space__c) {
			mainSpaceId = state.location.location_main_space__c;
		}
		if (mainSpaceId) {
			mainSpace = spaces.find((mainSpace) => mainSpace.Id === mainSpaceId);
		}

		return mainSpace;
	},
	getLocationSpaceCount(state) {
		return state.location.Location_Spaces__r ? state.location.Location_Spaces__r.totalSize : 0;
	},
	getMolLangRegion(state) {
		return state.location.language_region__c ? state.location.language_region__c : "en-GB";
	},
	getCountryCode(state) {
		let countryCode = "";
		if (state.location.BillingCountryCode) {
			countryCode = state.location.BillingCountryCode;
		}
		if (state.location.location_city__r && state.location.location_city__r.Country__c) {
			countryCode = state.location.location_city__r.Country__c;
		}
		return countryCode;
	},
	getTdcPhoneNumber(state) {
		return state.location.customized_phone_number_location__c;
	},
	getFutureBookings(state) {
		return state.futureBookings;
	},
	getPastBookings(state) {
		return state.pastBookings;
	},
	getLocationReviews(state) {
		return state.reviews;
	},
	getInvoices(state) {
		return state.invoices;
	},
	getAllianceStatus(state) {
		return state.location.alliance_mdc_enable__c;
	},
	getAllianceInformation(state) {
		return state.alliance;
	},
	getAllianceCompanies(state) {
		return "Accounts__r" in state.alliance ? state.alliance.Accounts__r.records : [];
	},
	getAllianceCompanyCount(state) {
		return state.alliance.Accounts__r.totalSize;
	},
	getCouponCount(state) {
		return "Coupons__r" in state.location ? state.location.Coupons__r.totalSize : 0;
	},
	getCouponInformation(state) {
		return "Coupons__r" in state.location ? state.location.Coupons__r.records : [];
	},
	getFirstCouponInformation(state) {
		return "Coupons__r" in state.location ? state.location.Coupons__r.records[0] : [];
	},
	getMedia(state) {
		return state.media;
	},
	getPricing(state) {
		return state.pricing;
	},
};

const mutations = {
	SET_MOL_EDITING_STATE(state, status) {
		state.molEditingState = status;
	},
	SET_MOL_SUBNAV_HASH(state, hash) {
		state.molSubnavHash = hash;
	},
	SET_INITIAL_PANEL_SHOWN(state, boolean) {
		console.log("SET_INITIAL_PANEL_SHOWN", state.initialPanelShown);
		state.initialPanelShown = boolean;
	},
	SET_MOL_SIDE_PANEL_LEVEL(state, level) {
		state.molSidePanelLevel = level;
	},
	SET_LOCATION_INFORMATION(state, location) {
		state.location = location;
	},
	SET_LOADING_STATE(state, status) {
		state.loading = status;
	},
	SET_FUTURE_BOOKINGS(state, payload) {
		state.futureBookings = payload.bookings;
	},
	SET_INVOICES(state, payload) {
		state.invoices = payload.invoices;
	},
	SET_PAST_BOOKINGS(state, payload) {
		state.pastBookings = payload.bookings;
	},
	SET_LOCATION_REVIEWS(state, payload) {
		state.reviews = payload.reviews;
	},
	SET_ALLIANCE_INFORMATION(state, alliance) {
		state.alliance = alliance;
	},
	SET_LOCATION_MEDIA(state, media) {
		state.media = media;
	},
	SET_PRICING(state, pricing) {
		state.pricing = pricing;
	},
	SET_LOCATION_EVALUATION(state, evaluation) {
		state.locationEvaluation = evaluation;
	},
};

const actions = {
	getLocationInformation({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getLocationInformation").then((response) => {
			if (response) {
				loadLanguageAsync(response.data.language_region__c);
				commit("SET_LOCATION_INFORMATION", response.data);
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	getFutureBookings({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getBookings", {when: "future"}).then((response) => {
			if (response) {
				commit({
					type: "SET_FUTURE_BOOKINGS",
					bookings: response.data,
				});
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	getLocationEvaluation({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getLocationEvaluation").then((response) => {
			if (response) {
				commit("SET_LOCATION_EVALUATION", response.data);
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	getInvoices({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getInvoices", {}).then((response) => {
			if (response) {
				commit({
					type: "SET_INVOICES",
					invoices: response.data,
				});
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	getPastBookings({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getBookings", {when: "past"}).then((response) => {
			if (response) {
				commit({
					type: "SET_PAST_BOOKINGS",
					bookings: response.data,
				});
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	getLocationReviews({commit}) {
		commit("SET_LOADING_STATE", true);
		return getSalesForce("getReviews").then((response) => {
			if (response) {
				commit({
					type: "SET_LOCATION_REVIEWS",
					reviews: response.data,
				});
			}
			commit("SET_LOADING_STATE", false);
		});
	},
	upsertSFData({commit, dispatch}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_LOCATION_INFORMATION", response.data);
				if (!payload.handleSuccessManually) {
					commit("SET_MOL_EDITING_STATE", false);
					dispatch("displaySuccessMessage", {success: true, message: payload.successMessage});
					commit("SET_LOADING_STATE", false);
				}
				return response;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				dispatch("displayErrorMessage", {error: true, message: payload.errorMessage});
				throw err;
			});
	},
	updateBooking({commit, dispatch}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				dispatch("getFutureBookings");
				if (!payload.handleSuccessManually) {
					commit("SET_MOL_EDITING_STATE", false);
					dispatch("displaySuccessMessage", {success: true, message: payload.successMessage});
					commit("SET_LOADING_STATE", false);
				}
				return response;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				dispatch("displayErrorMessage", {error: true, message: payload.errorMessage});
				throw err;
			});
	},
	getAllianceInformation({commit}) {
		return getSalesForce("getAlliance").then((response) => {
			if (response) {
				commit("SET_ALLIANCE_INFORMATION", response.data);
			}
		});
	},
	getMedia({commit}) {
		return getSalesForce("getMedia").then((response) => {
			if (response) {
				commit("SET_LOCATION_MEDIA", response.data);
				commit("SET_LOADING_STATE", false);
			}
			return response;
		});
	},
	updateMedia({commit, dispatch}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_LOCATION_MEDIA", response.data);
				if (!payload.handleSuccessManually) {
					commit("SET_MOL_EDITING_STATE", false);
					dispatch("displaySuccessMessage", {success: true, message: payload.successMessage});
					commit("SET_LOADING_STATE", false);
				}
				return response;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				dispatch("displayErrorMessage", {error: true, message: payload.errorMessage});
				throw err;
			});
	},
	getPricing({commit}, query) {
		commit("SET_LOADING_STATE", true);
		return getPricing(query)
			.then((response) => {
				commit("SET_PRICING", response.data);
				commit("SET_LOADING_STATE", false);
				return response.data;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				console.error(err);
				throw err;
			});
	},
	createBooking({commit}, payload) {
		commit("SET_LOADING_STATE", true);
		return postSalesForce(payload)
			.then((response) => {
				commit("SET_LOADING_STATE", false);
				return response.data;
			})
			.catch((err) => {
				commit("SET_LOADING_STATE", false);
				console.error(err);
				throw err;
			});
	},
};

export default {
	state,
	getters,
	mutations,
	actions,
};
