<template>
	<div class="mb-3">
		<h3><strong>Waar is het feestje?</strong></h3>
		<b-overlay :show="isLoading">
			<div class="form-group" v-if="!creatingNewLocation" :aria-hidden="isLoading ? 'true' : null">
				<b-form-group
					vertical
					class="mdc-radio-check-group no-max-width"
					label="Vindt het feest plaats in een commerciële feestlocatie?"
					label-for="Vindt het feest plaats in een commerciële feestlocatie?"
				>
					<div class="row row-cols-1 row-cols-md-2 mx-n1">
						<b-form-radio
							id="LocationNotPrivate"
							class="d-block mb-2 mdc-radio-check col px-1"
							v-model="location_private__c"
							name="location_private__c"
							:value="false"
							button
							button-variant="outline-tdc"
						>
							<OptionLabel radio primary="Ja" secondary="(horeca, feestlocatie, trouwlocatie)" icon="building" />
						</b-form-radio>
						<b-form-radio
							id="LocationPrivate"
							class="d-block mb-2 mdc-radio-check col px-1"
							v-model="location_private__c"
							name="location_private__c"
							:value="true"
							button
							button-variant="outline-tdc"
						>
							<OptionLabel radio primary="Nee" secondary="(woonhuis, tuin, bedrijfslocatie)" icon="home" />
						</b-form-radio>
					</div>
				</b-form-group>
			</div>

			<div v-if="location_private__c === false && !creatingNewLocation">
				<div>
					<b-form-group vertical label="Selecteer de feestlocatie" label-for="Selecteer de feestlocatie">
						<VueMultiselect
							required
							:options="locations"
							v-model="location"
							label="Name"
							track-by="Id"
							:max="1"
							@search-change="asyncFindLocation"
							@open="openLocations()"
							@close="locationMultiSelectOpen = false"
							:loading="loadingLocations"
							:showNoResults="false"
							:preserveSearch="true"
							:optionHeight="40"
							placeholder="Zoek feestlocatie"
						>
							<template slot="noOptions">
								<button v-if="!loadingLocations" class="btn btn-block">Zoek de naam van de feestlocatie</button>
								<button v-if="loadingLocations" class="btn btn-block">Zoeken.....</button>
							</template>
							<template slot="option" slot-scope="props">
								<div class="d-flex justify-content-start align-items-center p-1">
									<div class="option__image">
										<img class="rounded" v-if="props.option.Image" :src="props.option.Image" />
										<img class="rounded" v-if="!props.option.Image" src="~@/assets/images/missing-image.png" />
									</div>
									<div class="option__desc">
										<div class="option__title mb-1 ahref">{{ props.option.Name }}</div>
										<small v-if="props.option.City" class="option__small text-muted">({{ props.option.City }})</small>
									</div>
								</div>
							</template>
							<template slot="clear" slot-scope="props">
								<div class="multiselect__clear" @mousedown.prevent.stop="clearAllLocations(props.search)"></div>
							</template>
						</VueMultiselect>
						<small class="form-text text-muted"
							><font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Kies jouw feestlocatie uit
							de lijst. Staat deze er niet tussen, typ dan de volledige naam van de feestlocatie.</small
						>
					</b-form-group>
					<div class="d-flex justify-content-end mb-4">
						<button v-if="location && !locationMultiSelectOpen" class="btn btn-success btn-lg" @click="setLocation()">
							Kies deze Locatie <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" fa-fw />
						</button>
						<button
							v-if="showCreateButton"
							class="btn btn-success btn-lg"
							@click="creatingNewLocation = !creatingNewLocation"
						>
							Maak locatie aan <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" fa-fw />
						</button>
					</div>
				</div>
			</div>
			<b-form
				@submit="createLocation"
				@reset="resetLocation"
				v-if="location_private__c === true || creatingNewLocation"
			>
				<div>
					<b-form-group v-if="!location_private__c" label="Naam feeestlocatie" label-for="Name">
						<b-form-input
							v-model="newLocation.Name"
							id="Name"
							maxlength="100"
							placeholder="Feestlocatie"
							@input="showNameError = false"
							:class="{'border-warning': showNameError}"
						>
						</b-form-input>
						<small v-if="showNameError" class="text-warning">Vul de locatienaam in </small>
					</b-form-group>
					<b-form-group label="Adres (straatnaam en huisnummer)" label-for="BillingStreet">
						<b-form-input
							v-model="newLocation.BillingStreet"
							id="BillingStreet"
							maxlength="100"
							placeholder="Straatnaam en huisnummer"
						>
						</b-form-input>
					</b-form-group>
					<b-form-group label="Postcode" label-for="BillingPostalCode">
						<b-form-input v-model="newLocation.BillingPostalCode" id="BillingPostalCode" placeholder="Postcode">
						</b-form-input>
					</b-form-group>

					<b-form-group v-if="isDutch || isBelgian" vertical label="Plaatsnaam" label-for="Plaats">
						<VueMultiselect
							required
							:options="cities"
							v-model="city"
							label="Name"
							track-by="Id"
							:max="1"
							@search-change="asyncFindCity"
							@open="openCities()"
							@close="cityMultiSelectClose"
							:loading="loadingCities"
							:showNoResults="true"
							:preselectFirst="true"
							:preserveSearch="true"
							:clear-on-select="false"
							:searchable="true"
							:placeholder="placeholderCityField"
							:class="{'inner-border-warning': showCityError}"
						>
							<template slot="noOptions">
								<span v-if="!loadingCities" class="">Zoek een plaats</span>
								<button v-if="loadingCities" class="btn btn-block">Zoeken.....</button>
							</template>
							<template slot="option" slot-scope="props">
								<span class="ahref">{{ props.option.Name }}</span>
							</template>
							<template slot="clear" slot-scope="props">
								<div class="multiselect__clear" @mousedown.prevent.stop="clearAllCities(props.search)"></div>
							</template>
							<template slot="noResult">
								<div>
									<div v-if="showUnknownCityMessage">Deze plaats is niet bekend bij ons</div>
								</div>
							</template>
						</VueMultiselect>
						<small v-if="showCityError" class="text-warning">Kies een plaats </small>
						<small class="form-text text-muted"
							><font-awesome-icon class="text-success mr-1" :icon="['far', 'info-circle']" /> Kies jouw plaatsnaam uit
							de lijst. Staat deze er niet tussen, typ dan de volledige plaatsnaam</small
						>
					</b-form-group>
					<b-form-group v-else label="Plaatsnaam" label-for="BillingCity">
						<b-form-input
							id="BillingCity"
							maxlength="100"
							required
							v-model="newLocation.BillingCity"
							placeholder=" "
							oninvalid="this.setCustomValidity('Vul de plaats in')"
							oninput="setCustomValidity('')"
						></b-form-input>
					</b-form-group>
					<div class="d-flex justify-content-end mb-4">
						<b-button
							v-if="location_private__c == false"
							class="d-inline-block ml-auto mb-3 mb-sm-0"
							variant="outline-dark"
							@click.prevent="creatingNewLocation = false"
							>Annuleer</b-button
						>
						<b-button type="submit" variant="success" class="ml-sm-1"
							>Maak locatie aan <font-awesome-icon class="ml-1" :icon="['fal', 'chevron-right']" fa-fw
						/></b-button>
					</div>
				</div>
			</b-form>
		</b-overlay>
	</div>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueMultiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import OptionLabel from "@/components/customer/OptionLabel";
export default {
	name: "NoLocation",
	components: {
		FontAwesomeIcon,
		VueMultiselect,
		OptionLabel,
	},
	computed: {
		...mapGetters({
			locations: "getQueryLocations",
			cities: "getQueryCities",
			isLoading: "getLoadingState",
			loadingLocations: "getLocationLoadingState",
			loadingCities: "getCityLoadingState",
			isDutch: "isDutch",
			isBelgian: "isBelgian",
		}),
		showCreateButton: function () {
			return !this.location && !this.loadingLocations && this.queryLocations.length > 2;
		},
		billingCountryCode() {
			if (this.isDutch) return "NL";
			if (this.isBelgian) return "BE";
			return null;
		},
		hasValidCity() {
			return this.newLocation.BillingCity != "" ? true : false;
		},
		hasValidName() {
			return this.newLocation.Name != "" ? true : false;
		},
		validForm() {
			if (this.location_private__c === true) {
				return this.hasValidCity ? true : false;
			}
			return this.hasValidName && this.hasValidCity ? true : false;
		},
	},
	data() {
		return {
			placeholderCityField: "Kies een plaats",
			location_private__c: "",
			location: null,
			city: null,
			queryLocations: "",
			queryCities: "",
			locationMultiSelectOpen: false,
			cityMultiSelectOpen: false,
			creatingNewLocation: false,
			showCityError: false,
			showUnknownCityMessage: false,
			showNameError: false,
			newLocation: {
				Name: "",
				BillingStreet: "",
				BillingPostalCode: "",
				BillingCity: "",
				BillingCountryCode: null,
				private_location_type__c: "Thuislocatie",
				private_location_type_custom__c: "",
				location_private__c: false,
			},
		};
	},
	methods: {
		cityMultiSelectClose() {
			this.cityMultiSelectOpen = false;
		},
		openLocations() {
			this.location = null;
			this.locationMultiSelectOpen = true;
		},
		openCities() {
			this.city = null;
			this.cityMultiSelectOpen = true;
		},
		asyncFindLocation(queryLocations) {
			this.queryLocations = queryLocations;
			if (queryLocations.length > 3) {
				this.newLocation.Name = queryLocations;
				let query = {};
				query.query = queryLocations;
				query.countryCode = this.billingCountryCode;
				this.$store.dispatch("queryLocations", query);
			}
		},
		asyncFindCity(queryCities) {
			this.queryCities = queryCities;
			this.placeholderCityField = "Kies een plaats";
			if (queryCities.length == 0) {
				this.showUnknownCityMessage = false;
			} else if (queryCities.length <= 3) {
				this.showUnknownCityMessage = false;
				this.placeholderCityField = queryCities;
			} else {
				this.showUnknownCityMessage = true;
				this.$store.dispatch("queryCities", queryCities);
				this.placeholderCityField = queryCities;
			}
		},
		clearAllLocations() {
			this.$store.dispatch("clearQueryLocations");
		},

		clearAllCities() {
			this.$store.dispatch("clearQueryCities");
		},
		setCreating() {
			this.creatingNewLocation = true;
		},
		setLocation() {
			this.$store.dispatch("postBooking", {
				action: "setLocation",
				data: {locationId: this.location.Id},
				setBooking: true,
			});
		},
		createLocation(event) {
			event.preventDefault();
			if (this.validForm) {
				this.newLocation.location_private__c = this.location_private__c;
				this.newLocation.BillingCountryCode = this.billingCountryCode;
				this.$store.dispatch("postBooking", {
					action: "createLocation",
					data: this.newLocation,
					setBooking: true,
				});
			} else {
				this.showNameError = !this.hasValidName ? true : false;
				this.showCityError = !this.hasValidCity ? true : false;
			}
		},
		resetLocation(event) {
			event.preventDefault();
			this.newLocation.Name = "";
			this.newLocation.private_location_type__c = "Thuislocatie";
			this.newLocation.private_location_type_custom__c = "";
			this.newLocation.BillingStreet = "";
			this.newLocation.BillingPostalCode = "";
			this.newLocation.BillingCity = "";
			this.newLocation.BillingCountryCode = null;
		},
		resetErrors() {
			this.showCityError = false;
			this.showNameError = false;
		},
	},
	watch: {
		city: function () {
			if (this.city) {
				this.newLocation.BillingCity = this.city.Name;
			} else {
				this.newLocation.BillingCity = "";
			}
		},
		location_private__c: function () {
			this.resetErrors();
			this.newLocation.BillingStreet = "";
			this.newLocation.BillingPostalCode = "";
			this.newLocation.BillingCity = "";
			if (this.newLocation.location_private__c === false) {
				this.newLocation.Name = "";
			}
		},
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.inner-border-warning {
	.multiselect__tags {
		border-color: #dd6d00 !important;
	}
}
.multiselect__content-wrapper,
.multiselect__tags {
	border: 1px solid #ced4da !important;
}
.multiselect__option {
	padding: 8px !important;
	@extend .d-flex;
	@extend .align-items-center;
	> span {
		@extend .d-flex;
		@extend .align-items-stretch;
	}
}
.multiselect__option--highlight {
	//background-color:$tdc-blue !important;
	color: #000;
	small {
		//color:#fff !important;
	}
}
.multiselect__element {
	@extend .border-bottom;
}
.option__image {
	width: 80px;
	background-color: #eee;
	display: inline-block;
	margin-right: 10px;
	text-align: center;
	@extend .rounded;
	img {
		max-width: 100%;
		width: auto;
	}
}
.option__desc {
	display: inline-block;
}
.multiselect__tags,
.multiselect__select {
	height: calc(1.5em + 0.75rem + 2px) !important;
}
.multiselect__single {
	padding-left: 12px;
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
	height: 100%;
	@extend .d-flex;
	@extend .align-items-center;
}
</style>
