<template>
	<div class="w-100">
		<TermsAndConditionsCheck />
		<div class="d-flex justify-content-center justify-content-md-end">
			<b-button variant="success" class="px-4 btn mb-2 mw-50 btn-lg" @click="confirm()">
				<span v-text="buttonText" /><font-awesome-icon class="ml-auto ml-sm-3" :icon="['far', 'chevron-right']" />
			</b-button>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import TermsAndConditionsCheck from "@/components/customer/TermsAndConditionsCheck";

export default {
	name: "ConfirmBookingButton",
	components: {
		TermsAndConditionsCheck,
	},
	props: {
		bookingType: {
			type: String,
			default: "booking",
		},
		djClass: {
			type: String,
			required: true,
		},
		quoteId: {
			type: String,
			required: false,
		},
		placement: {
			type: String,
			default: "top",
		},
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
		}),
		modalId: function () {
			return "av-modal-" + this.placement;
		},
		buttonText: function () {
			if (this.isBelgian) return "Boek en betaal";
			return "Ja, ik boek deze DJ";
		},
	},
	data() {
		return {
			accept: false,
			submitted: false,
		};
	},
	methods: {
		confirm() {
			this.$root.$emit("setSubmitted", true);
			if (this.accepted) {
				if (this.bookingType == "booking") this.bookDJ();
				if (this.bookingType == "quote") this.bookQuote();
			}
		},
		bookDJ() {
			this.$store.dispatch("postBooking", {
				action: "bookDirect",
				data: {
					djClass: this.djClass == "AClass" ? "A" : this.djClass,
				},
				setBooking: true,
			});
			this.$router.push({name: "DirectBookingConfirmed"});
		},
		bookQuote() {
			this.$store.dispatch("bookQuote", {
				action: "bookQuote",
				data: {
					quoteId: this.quoteId,
				},
				setBooking: true,
			});
		},
	},
	mounted() {
		this.$root.$on("setTermsAccepted", (value) => (this.accepted = value));
		this.$root.$emit("setSubmitted", false);
		this.$root.$on("setSubmitted", (value) => (this.submitted = value));
	},
};
</script>
