<template>
	<li class="step" :class="statusCssClass">
		<div class="step-content">
			<div class="step-bullet">
				<font-awesome-icon :icon="['far', statusIcon]" class="step-bullet-icon" />
			</div>
			<div class="step-title"></div>
			<div class="step-title">
				<router-link v-if="hasDestination" :to="{name: destination, params: {id: quoteId}}">
					<strong v-if="status == 'priority'" v-text="title" />
					<span v-else v-text="title" />
					<font-awesome-icon v-if="showChevron" :icon="['far', 'chevron-right']" class="ml-1" />
				</router-link>
				<span v-else v-text="title" />
			</div>
			<div class="step-body">
				<small><slot></slot></small>
			</div>
		</div>
	</li>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
	name: "OverviewStep",
	components: {FontAwesomeIcon},
	props: {
		title: {
			type: String,
		},
		status: {
			type: String,
			default: "disabled",
		},
		destination: {
			type: String,
			default: "",
		},
		quoteId: {
			type: String,
			default: "",
		},
	},
	computed: {
		statusCssClass() {
			return "step-" + this.status;
		},
		statusIcon() {
			if (this.status == "active") return "circle";
			if (this.status == "completed") return "circle-check";
			if (this.status == "priority") return "circle-dot";
			if (this.status == "disabled") return "circle-dashed";
			return "circle-dashed";
		},
		hasDestination() {
			return this.destination != "";
		},
		showChevron() {
			if (this.status == "priority") return true;
			if (this.status == "active") return true;
			return false;
		},
	},
};
</script>

<style lang="scss">
@mixin step-status($color: #ccc, $icon-size: 14px, $title-size: 0.9rem) {
	.step-content {
		border-color: $color;
		.step-title {
			color: $color;
			a,
			strong {
				color: $color;
			}
		}
		.step-bullet-icon {
			color: $color;
			height: $icon-size;
		}
	}
}

.step {
	.step-content {
		border-left: 2px solid #ccc;
		position: relative;
		padding-bottom: 10px;
		.step-title,
		.step-body {
			padding-left: 15px;
			small {
				@include media-breakpoint-up(sm) {
					font-size: 90%;
				}
			}
		}
		.step-title {
			position: relative;
			top: -3px;
			color: #ccc;
		}
		.step-body {
			position: relative;
			top: -3px;
		}
		.step-bullet {
			@extend .d-flex;
			@extend .justify-content-center;
			left: -10px;
			position: absolute;
			width: 19px;
			.step-bullet-icon {
				height: 15px;
				color: #ccc;
				background-color: #fff;
				border-radius: 50%;
			}
		}
	}
	&:last-of-type {
		.step-content {
			border-color: transparent !important;
			padding-bottom: 0px;
		}
	}
	&.step-priority {
		@include step-status($color: $tdc-blue, $icon-size: 21px);
		.step-title {
			font-weight: 900;
		}
	}
	&.step-active {
		@include step-status($color: $tdc-blue);
	}
	&.step-completed {
		@include step-status($color: $tdc-green);
		.step-title {
			font-weight: 900;
			a {
				text-decoration: none;
			}
		}
	}
	&.step-disabled {
		@include step-status($color: #ccc);
		.step-content {
			border-left: 2px dashed #ccc;
		}
		.step-title {
			a {
				text-decoration: none;
			}
		}
	}
}
</style>
