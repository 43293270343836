<template>
	<div>
		<Alert v-if="!isPreQuote" isScarcity alertType="warning" dismissible />
		<DjBlock
			:class="'desktop-content-left-right-wrapper'"
			template="DjBlockComplete"
			templateVariant="DjDetails"
			:sf_name="$route.query.dj"
			:priceIncVat="priceInc"
			:priceExcVat="priceExc"
			:activeCalculator="true"
		/>

		<div class="desktop-content-left-right-wrapper my-3">
			<div class="desktop-content-left">
				<span v-if="isJuniorDjClass">
					<p>
						De Junior-klasse DJ is de voordeligste keuze en heeft minder ervaring dan de A-klasse of de Premium-klasse
						DJ. De Junior-klasse DJ is vooral geschikt voor kleinere feesten of verjaardagen.
					</p>
					<p>
						The DJ Company draait op <router-link to="/klantervaringen">klantervaringen</router-link>. We evalueren
						ieder feest uitgebreid met onze klanten. Daarom hebben wij zo'n hoge klanttevredenheid. 98% van alle klanten
						zou ons aanbevelen, en daar zijn we trots op!
					</p>
					<p>
						We weten uit ervaring dat vooral de kwaliteit van een DJ het succes van een feest bepaalt. Al onze
						Junior-klasse DJ's voldoen natuurlijk aan onze minimum eisen en zijn door een strenge selectie gekomen.
					</p>
				</span>
				<span v-if="isADjClass">
					<p>
						De A-klasse DJ heeft veel ervaring en zorgt gegarandeerd voor een swingend feest. De A-klasse DJ's zijn
						altijd beoordeeld met een 8.0 of hoger. Deze DJ's zijn geschikt voor o.a. bruiloften en hebben meer ervaring
						met bruiloften dan Junior-klasse DJ's.
					</p>
					<p>
						The DJ Company draait op <router-link to="/klantervaringen">klantervaringen</router-link>. We evalueren
						ieder feest uitgebreid met onze klanten. Daarom hebben wij zo'n hoge klanttevredenheid. 98% van alle klanten
						zou ons aanbevelen, en daar zijn we trots op!
					</p>
					<p>
						We weten uit ervaring dat vooral de kwaliteit van een DJ het succes van een feest bepaalt. Al onze A-klasse
						DJ's zijn door een strenge selectie gekomen en hebben goede beoordelingen gekregen.
					</p>
				</span>
				<span v-if="isPremiumDjClass">
					<p>
						De Premium-klasse DJ's zijn onze best beoordeelde DJ's. Ze worden beoordeeld met een 9.0 of hoger. Deze DJ's
						zijn geschikt voor o.a. bruiloften en hebben daar ook een ruime ervaring mee. Het zijn de beste DJ's van
						<span v-if="isBelgian" v-text="'Vlaanderen'" /><span v-else v-text="'Nederland'" /> en hebben meer ervaring
						dan Junior-klasse DJ's en A-klasse DJ's.
					</p>
					<p>
						The DJ Company draait op <router-link to="/klantervaringen">klantervaringen</router-link>. We evalueren
						ieder feest uitgebreid met onze klanten. Daarom hebben wij zo'n hoge klanttevredenheid. 98% van alle klanten
						zou ons aanbevelen, en daar zijn we trots op!
					</p>
					<p>
						We weten uit ervaring dat vooral de kwaliteit van een DJ het succes van een feest bepaalt. Al onze
						Premium-klasse DJ's zijn door een strenge selectie gekomen en hebben de hoogste beoordeling gekregen.
					</p>
				</span>
				<p>
					Wij werken met een <router-link to="/feestwensen">uitgebreid en persoonlijk wensenformulier</router-link>.
					Hierdoor heb je altijd een 100% match met jouw DJ.
				</p>
			</div>
		</div>
		<StrikeThroughTitle alwaysVisible>Bekijk onze video met werkwijze</StrikeThroughTitle>
		<Video videoId="246086300" />
		<BookingIncludes />
		<BookButton
			class="d-flex flex-column align-items-center mb-3 border-top border-bottom border-success py-4"
			:sf_name="$route.query.dj"
			v-if="!isPreQuote"
		/>
		<Faqs />
		<MyQuotes />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import DjBlock from "@/components/customer/DjBlock";
import BookingIncludes from "@/components/customer/BookingIncludes";
import Faqs from "@/components/customer/Faqs";
import MyQuotes from "@/components/customer/MyQuotes";
import Video from "@/components/customer/Video";
import StrikeThroughTitle from "@/components/customer/StrikeThroughTitle";
import Alert from "@/components/customer/Alert";
import BookButton from "@/components/customer/BookButton";

export default {
	name: "DJDetails",
	components: {
		BookingIncludes,
		DjBlock,
		Faqs,
		MyQuotes,
		Alert,
		Video,
		StrikeThroughTitle,
		BookButton,
	},
	computed: {
		...mapGetters({
			booking: "getBooking",
			prices: "getPrices",
			isPreQuote: "isPreQuote",
			isQuote: "isQuote",
			isOrderInProgress: "isOrderInProgress",
			isBelgian: "isBelgian",
		}),
		name: function () {
			if (this.$route.query.dj == "AClass") return "A";
			return this.$route.query.dj;
		},
		djPriceClass: function () {
			if (this.isJuniorDjClass) {
				return "p_junior";
			} else if (this.isPremiumDjClass) {
				return "p_premium";
			}
			return "p_a_class";
		},
		priceInc: function () {
			return this.prices[this.djPriceClass]?.p_totalAmount;
		},
		priceExc: function () {
			return this.prices[this.djPriceClass]?.p_exVatAmount;
		},
		isJuniorDjClass: function () {
			return this.$route.query.dj === "Junior";
		},
		isADjClass: function () {
			return this.$route.query.dj === "AClass";
		},
		isPremiumDjClass: function () {
			return this.$route.query.dj === "Premium";
		},
	},
	mounted() {
		if (!this.$route.query.dj) this.$route.push({name: "Prices"});
	},
};
</script>
