<template>
	<div>
		<div v-if="musicChoiceCount > 0">
			<div v-for="(song, key) in musicChoices.slice(0, top)" :key="'choice' + key">
				<div class="flex items-center my-1 md:mt-0 mb-2">
					{{ key + 1 }}.
					<div
						v-if="song.image && song.image != ''"
						class="w-7 h-7 bg-cover bg-center mx-2"
						:class="[{'rounded-full': isArtist(song.type)}, {rounded: isTrack(song.type)}]"
						:style="{backgroundImage: 'url(' + song.image + ')'}"
					></div>
					<div v-else class="w-7 h-7 bg-slate-200 rounded-full mx-2 flex items-center justify-center">
						<font-awesome-icon class="w-3 h-3 text-slate-500" :icon="['far', 'music']" />
					</div>
					<div>
						{{ song.name }}
						<small class="block text-slate-500">
							<span v-if="isArtist(song.type)">{{ $t("mol.general.label.Artist") }}</span>
							<span v-if="isTrack(song.type)"
								>{{ $t("mol.general.label.Track")
								}}<span v-if="song.album && song.album != ''"> | {{ song.album }}</span
								><span v-if="song.album_year && song.album_year != ''"> | {{ song.album_year }}</span></span
							>
						</small>
					</div>
				</div>
			</div>
			<div v-for="key in emptySongs" :key="'empty' + key" class="flex items-center my-1 md:mt-0 mb-2">
				{{ key + musicChoiceCount }}.
				<div class="w-7 h-7 bg-slate-200 rounded-full mx-2 flex items-center justify-center">
					<font-awesome-icon class="w-3 h-3 text-slate-500" :icon="['far', 'music-slash']" />
				</div>
				<div>
					{{ $t("mol.bookingForm.string.Choice not yet made") }}
				</div>
			</div>
		</div>
		<div v-else>
			<div class="flex items-center my-1 md:mt-0 mb-2">
				<div class="w-7 h-7 bg-slate-200 rounded-full mr-2 flex items-center justify-center">
					<font-awesome-icon class="w-3 h-3 text-slate-500" :icon="['far', 'music-slash']" />
				</div>
				<div>
					{{ $t("mol.bookingForm.string.No choices made") }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "MusicChoices",
	props: {
		choices: {
			type: String,
			default: "",
		},
		top: {
			type: Number,
			default: 5,
		},
	},
	computed: {
		musicChoices() {
			return this.choices ? JSON.parse(this.choices) : "";
		},
		musicChoiceCount() {
			return this.choices ? this.musicChoices.length : 0;
		},
		emptySongs() {
			return this.top - this.musicChoiceCount;
		},
	},
	methods: {
		isTrack(type) {
			return type === "track";
		},
		isArtist(type) {
			return type === "artist";
		},
		isUnknown(type) {
			return type !== "artist" && type !== "track";
		},
	},
};
</script>
