<template>
	<div class="flex items-center text-xs md:text-sm">
		<router-link :to="{path: '/'}" @click.native="$store.commit('SET_MOL_EDITING_STATE', false)">
			<font-awesome-icon
				:icon="['far', 'home']"
				class="h-3 md:h-4 text-tdc-blue-500 hover:underline hover:text-sky-600"
		/></router-link>
		<span v-for="(item, index) in path" :key="index" class="text-slate-400 dark:text-slate-400 flex items-center">
			<font-awesome-icon
				v-if="item != ''"
				:icon="['far', 'slash-forward']"
				class="h-2 text-slate-400 dark:text-slate-400 px-1 md:px-3"
			/>
			<div
				@click="goBackToUnhashed()"
				v-if="showUnhashedUrl(index)"
				v-html="item"
				class="text-tdc-blue-500 hover:underline hover:text-sky-600 cursor-pointer"
			/>
			<div
				@click="goBackToUnedit()"
				v-else-if="showHashedUrl(index)"
				v-html="item"
				class="text-tdc-blue-500 hover:underline hover:text-sky-600 cursor-pointer"
			/>
			<span v-else v-html="item" />
		</span>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "Breadcrumbs",
	props: {
		path: {
			type: Array,
		},
	},
	computed: {
		...mapGetters({
			molEditingState: "getMolEditingState",
		}),

		pathCount() {
			return this.path.length;
		},
		unhashedUrl() {
			return window.location.origin + window.location.pathname + window.location.search + "#" + this.$route.path;
		},
	},
	methods: {
		showHashedUrl(index) {
			return index == 1 && this.molEditingState;
		},
		showUnhashedUrl(index) {
			return index == 0 && this.pathCount != 1;
		},
		goBackToUnedit() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
		},
		goBackToUnhashed() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
			this.$store.commit("SET_MOL_SUBNAV_HASH", "");
		},
	},
};
</script>
