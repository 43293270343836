import store from "@/store";
import {userTypeIsCustomer, userTypeIsLocation, userTypeIsCustomerEvaluation} from "@/mo";
const axios = require("axios");

const sf_domain = "https://thedjcompany.my.salesforce-sites.com/services/apexrest/translationapi";

let category = "";

if (userTypeIsCustomer()) {
	category = "moc";
}
if (userTypeIsLocation()) {
	category = "mol";
}
if (userTypeIsCustomerEvaluation()) {
	category = "moe";
}
const url = `${sf_domain}?category=${encodeURIComponent(category)}`;

export function getSalesForce(locale) {
	const get_url = `${url}&locale=${encodeURIComponent(locale)}`;
	return Promise.all([
		axios.get(get_url).catch(function (err) {
			store.dispatch("handleError", err.response);
		}),
		axios.get(`${sf_domain}?category=common&locale=${encodeURIComponent(locale)}`).catch(function (err) {
			store.dispatch("handleError", err.response);
		}),
	]);
}
