<template>
	<div
		:class="{'!max-h-0 opacity-0 ': notChosen}"
		class="max-h-[1350px] relative transition-all duration-500 delay-300"
	>
		<div
			:class="[{'!shadow-transparent !py-3 !border-tdc-green-500': chosen}]"
			class="mb-2 md:mb-3 overflow-hidden w-auto transition-all duration-1000 delay-150 flex flex-col items-stretch bg-white dark:bg-slate-700 p-4 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 min-h-full"
		>
			<div class="flex flex-row justify-between">
				<div class="mr-2">
					<font-awesome-icon
						v-for="star in djClass.stars"
						:key="star"
						:icon="['fas', 'star']"
						:class="[{'h-5 w-5 sm:h-6 sm:w-6': chosen}, {'h-5 w-5': !chosen}]"
						class="text-amber-500 transition-all duration-500 delay-300"
					/>
					<div
						:class="[{'text-base sm:text-2xl': chosen}, {'text-xl': !chosen}]"
						class="transition-all duration-500 delay-300 font-bold leading-6"
					>
						{{ djClass.name }}
					</div>
					<div class="text-tdc-blue-500 text-sm">{{ djClass.slogan }}</div>
				</div>
				<div>
					<div class="text-right">
						<div
							:class="[{'text-xl md:text-3xl': chosen}, {'text-3xl': !chosen}]"
							class="transition-all duration-500 delay-300 font-bold text-black"
						>
							<HighlightedPrice>{{ formatPrice(djClass.price, 2) }}</HighlightedPrice>
						</div>
						<div
							:class="[{'!text-xs !mt-0': chosen}]"
							class="transition-all duration-500 delay-300 text-sm line-through text-slate-400"
						>
							{{ formatPrice(djClass.price + 150, 2) }}
						</div>
						<div
							:class="[{'!text-xs !mt-0': chosen}]"
							class="transition-all duration-500 delay-300 text-xs text-slate-400"
						>
							{{ $t("mol.bookADjForm.label.Incl costs excl vat") }}
						</div>
						<div
							:class="[{'!text-xs !mt-0': chosen}]"
							class="transition-all duration-500 delay-300 text-sm text-tdc-green-500 font-bold mt-2"
						>
							{{
								$t("mol.bookADjForm.label.Save", {percentage: ((150 / (djClass.price + 150)) * 100).toFixed(0) + "%"})
							}}
						</div>
					</div>
				</div>
			</div>
			<div
				:class="{'!max-h-0 opacity-0 py-0': choiceMade}"
				class="max-h-[350px] w-auto transition-all transition-[max-height] duration-500 delay-300 flex flex-col md:flex-row justify-between items-stretch mt-auto"
			>
				<div class="md:mt-3 md:mr-3 md:!-mt-10">
					<ul class="text-sm md:text-base xl:text-sm my-5 md:!mb-0">
						<li class="mb-1 flex items-start">
							<font-awesome-icon :icon="['far', 'check']" class="text-tdc-green-500 mr-2 mt-1" />{{ djClass.usp1 }}
						</li>
						<li class="mb-1 flex items-start">
							<font-awesome-icon :icon="['far', 'check']" class="text-tdc-green-500 mr-2 mt-1" />{{ djClass.usp2 }}
						</li>
						<li class="mb-1 flex items-start">
							<font-awesome-icon :icon="['far', 'check']" class="text-tdc-green-500 mr-2 mt-1" />{{ djClass.usp3 }}
						</li>
					</ul>
				</div>
				<div class="md:mt-3 grow-0 flex flex-row-reverse">
					<Button
						v-if="!disableButton"
						@click.native="chooseDjClass(djClass)"
						arrow
						type="green"
						class="!w-full min-w-[230px] !mb-0"
					>
						{{ $t("mol.bookADjForm.button.Book now") }}
					</Button>
					<Button v-else disabled arrow type="green" class="!w-full min-w-[230px] !mb-0">
						{{ $t("mol.bookADjForm.button.Book now") }}
					</Button>
					<div v-if="disableButton" class="mt-2 mb-2 text-xs text-amber-600 md:max-w-[230px]">
						{{
							$t("common.pricing.message." + djClass.errorMessage, {phone: tdcPhoneNumber, whatsapp: tdcWhatsAppNumber})
						}}
					</div>
				</div>
			</div>
		</div>
		<div
			:class="{'!opacity-100 relative': chosen}"
			class="flex justify-center opacity-0 transition-opacity relative z-50 mb-2 md:mb-3 mt-[calc(-0.5rem)] md:mt-[calc(-0.75rem)]"
		>
			<div class="relative ml-[-55px]">
				<div
					class="w-0 h-0 border-r-[20px] border-l-[20px] border-l-transparent border-t-[20px] border-t-tdc-green-500 border-r-transparent absolute"
				></div>
				<div
					class="w-0 h-0 border-r-[20px] border-l-[20px] border-l-transparent border-t-[20px] border-t-white dark:border-t-slate-700 border-r-transparent absolute mt-[-1px]"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Button from "@/components/location/Button";
import HighlightedPrice from "@/components/location/HighlightedPrice";

export default {
	name: "DjClassBlock",
	components: {
		Button,
		HighlightedPrice,
	},
	props: {
		djClass: {
			type: Object,
			default: null,
		},
		chosenDjClass: {
			type: String,
			default: "",
		},
	},
	computed: {
		...mapGetters({
			tdcPhoneNumber: "getTdcPhoneNumber",
		}),
		tdcWhatsAppNumber() {
			return this.$t("mol.general.contact.whatsapp number");
		},
		choiceMade() {
			return this.chosenDjClass !== "";
		},
		chosen() {
			return this.chosenDjClass === this.djClass.id;
		},
		notChosen() {
			return this.chosenDjClass !== this.djClass.id && this.chosenDjClass !== "";
		},
		disableButton() {
			return this.djClass.errorMessage !== "";
		},
	},
	methods: {
		chooseDjClass(className) {
			this.$emit("djClassChosen", className);
		},
		formatPrice(price, precision = 2) {
			return this.$currency(price, precision).format().replace(",00", ",-");
		},
	},
};
</script>
