<template>
	<div class="pb-10">
		<div v-if="error.status === 404">{{ $t("moe.customerEvaluationForm.string.somethingWentwrong") }}</div>
		<div v-else>
			<div v-if="isStep1 && stepsCompleted" class="text-center">
				{{ $t("moe.customerEvaluationForm.string.alreadyDone") }}
			</div>
			<div v-if="isStep1 && !stepsCompleted">
				<InputItem
					inputName="evaluation.net_promoter_likert_scale__c"
					v-model="evaluation.net_promoter_likert_scale__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q1RecommendTDC')"
					:error="formErrors.evaluationError_net_promoter_likert_scale__c"
					:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
					@change="(formErrors.evaluationError_net_promoter_likert_scale__c = false), q1Chosen()"
					inputType="rating"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<div
					class="-mt-5 max-w-[900px] flex justify-center mx-auto overflow-hidden transition-all duration-700"
					:class="[
						{'max-h-32 ': evaluation.net_promoter_likert_scale__c},
						{'max-h-0': !evaluation.net_promoter_likert_scale__c},
					]"
				>
					<Alert type="success">
						<span v-if="q1Choice <= 7">{{ $t("moe.customerEvaluationForm.string.lowPromoterScoreMsg") }}</span>
						<span v-else>{{ $t("moe.customerEvaluationForm.string.highPromoterScoreMsg") }}</span>
					</Alert>
				</div>
				<InputItem
					inputName="evaluation.dj_played_music_i_requested_rating__c"
					v-model="evaluation.dj_played_music_i_requested_rating__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q2MusicChoiceDj', {dj: djName})"
					:error="formErrors.evaluationError_dj_played_music_i_requested_rating__c"
					:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
					@change="formErrors.evaluationError_dj_played_music_i_requested_rating__c = false"
					inputType="rating"
					ratingLabels="value"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<InputItem
					inputName="evaluation.dj_played_according_to_atmosphere_rating__c"
					v-model="evaluation.dj_played_according_to_atmosphere_rating__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q3DjResponseToMood', {dj: djName})"
					:error="formErrors.evaluationError_dj_played_according_to_atmosphere_rating__c"
					:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
					@change="formErrors.evaluationError_dj_played_according_to_atmosphere_rating__c = false"
					inputType="rating"
					ratingLabels="value"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<InputItem
					inputName="evaluation.dj_service_level_rating__c"
					v-model="evaluation.dj_service_level_rating__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q4DjFriendliness', {dj: djName})"
					:error="formErrors.evaluationError_dj_service_level_rating__c"
					:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
					@change="formErrors.evaluationError_dj_service_level_rating__c = false"
					inputType="rating"
					ratingLabels="value"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<InputItem
					inputName="evaluation.service_and_communication_prior__c"
					v-model="evaluation.service_and_communication_prior__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q5DjCommunicationAndService', {dj: djName})"
					:error="formErrors.evaluationError_service_and_communication_prior__c"
					:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
					@change="formErrors.evaluationError_service_and_communication_prior__c = false"
					inputType="rating"
					ratingLabels="value"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<InputItem
					inputName="evaluation.evaluation_quote__c"
					v-model="evaluation.evaluation_quote__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q6YourExperience5Words')"
					inputType="text"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
				/>
				<InputItem
					inputName="evaluation.general_comments_regarding_booking__c"
					v-model="evaluation.general_comments_regarding_booking__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.q7YourStory')"
					:inputSubLabel="$t('moe.customerEvaluationForm.string.q7YourStoryInspiration')"
					inputType="textarea"
					inputLabelAlwaysTop
					extraSpaceBottom
					@focus="inputMilestone1 = true"
					@keyup="milestoneInput()"
					class="flex justify-center"
				/>
				<inputItem
					v-if="booking.booking_type__c == 'bruiloftsfeest'"
					inputName="evaluation.Role_of_evaluator__c"
					v-model="evaluation.Role_of_evaluator__c"
					:inputLabel="$t('moe.customerEvaluationForm.string.EvaluatorRole')"
					:inputSubLabel="$t('moe.customerEvaluationForm.string.EvaluatorRole2')"
					inputType="radio"
					:gridCols="1"
					:gridColsMd="1"
					inputLabelAlwaysTop
					extraSpaceBottom
					class="flex justify-center"
					:choices="[
						{value: 'bride', label: $t('moe.customerEvaluationForm.label.roleBride')},
						{value: 'groom', label: $t('moe.customerEvaluationForm.label.roleGroom')},
						{value: 'master of ceremony', label: $t('moe.customerEvaluationForm.label.roleMC')},
						{value: 'other', label: $t('moe.customerEvaluationForm.label.roleOther')},
					]"
				/>
				<div
					class="-mt-5 max-w-[900px] flex justify-center mx-auto overflow-hidden transition-all duration-700"
					:class="[{'max-h-32 ': inputMilestone1}, {'max-h-0': !inputMilestone1}]"
				>
					<Alert type="success" iconOff>
						<span v-if="inputMilestone4" class="flex items-center"
							><span class="mr-2 text-2xl">&#x1F929;</span
							>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage4") }}</span
						>
						<span v-else-if="inputMilestone3" class="flex items-center"
							><span class="mr-2 text-2xl">&#x1F604;</span
							>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage3") }}</span
						>
						<span v-else-if="inputMilestone2" class="flex items-center"
							><span class="mr-2 text-2xl">&#x1F601;</span
							>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage2") }}</span
						>
						<span v-else class="flex items-center"
							><span class="mr-2 text-2xl">&#x1F642;</span
							>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage1") }}</span
						>
					</Alert>
				</div>
				<div class="max-w-[900px] mx-auto mt-3">
					<div class="flex justify-center">
						<Button @click="saveEvaluation(evaluation)" type="green" arrowRight>{{
							$t("moe.customerEvaluationForm.label.submit1")
						}}</Button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isStep2">
			<div class="text-xl font-semibold mb-3 max-w-[900px] mx-auto">
				{{ $t("moe.customerEvaluationForm.title.evaluateVenue", {venue: booking.Location_Name_from_lookup__c}) }}
			</div>
			<InputItem
				inputName="evaluation.location_NPS_score__c"
				v-model="evaluation.location_NPS_score__c"
				:inputLabel="
					$t('moe.customerEvaluationForm.string.q1RecommendVenue', {venue: booking.Location_Name_from_lookup__c})
				"
				:error="formErrors.evaluationError_location_NPS_score__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_NPS_score__c = false"
				inputType="rating"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_evaluation_quote__c"
				v-model="evaluation.location_evaluation_quote__c"
				:inputLabel="$t('moe.customerEvaluationForm.string.q2YourExperience5Words')"
				inputType="text"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_general_comments__c"
				v-model="evaluation.location_general_comments__c"
				:inputLabel="$t('moe.customerEvaluationForm.string.q3YourStory')"
				:inputSubLabel="$t('moe.customerEvaluationForm.string.q3YourStoryInspiration')"
				:placeholder="$t('moe.customerEvaluationForm.placeholder.q3YourStory')"
				inputType="textarea"
				inputLabelAlwaysTop
				extraSpaceBottom
				@focus="inputMilestone1 = true"
				@keyup="milestoneInput()"
				class="flex justify-center"
			/>
			<div
				class="-mt-5 max-w-[900px] flex justify-center mx-auto overflow-hidden transition-all duration-700"
				:class="[{'max-h-32 ': inputMilestone1}, {'max-h-0': !inputMilestone1}]"
			>
				<Alert type="success" iconOff>
					<span v-if="inputMilestone4" class="flex items-center"
						><span class="mr-2 text-2xl">&#x1F929;</span
						>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage4") }}</span
					>
					<span v-else-if="inputMilestone3" class="flex items-center"
						><span class="mr-2 text-2xl">&#x1F604;</span
						>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage3") }}</span
					>
					<span v-else-if="inputMilestone2" class="flex items-center"
						><span class="mr-2 text-2xl">&#x1F601;</span
						>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage2") }}</span
					>
					<span v-else class="flex items-center"
						><span class="mr-2 text-2xl">&#x1F642;</span
						>{{ $t("moe.customerEvaluationForm.string.YourStoryMilestoneMessage1") }}</span
					>
				</Alert>
			</div>
			<div class="text-lg font-semibold my-3 max-w-[900px] mx-auto">
				{{ $t("moe.customerEvaluationForm.title.scoreVenue", {venue: booking.Location_Name_from_lookup__c}) }}
			</div>
			<InputItem
				inputName="evaluation.location_value__c"
				v-model="evaluation.location_value__c"
				:inputLabel="
					$t('moe.customerEvaluationForm.string.q4VenueValue', {venue: booking.Location_Name_from_lookup__c})
				"
				:error="formErrors.evaluationError_location_value__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_value__c = false"
				inputType="rating"
				ratingLabels="value"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_atmosphere__c"
				v-model="evaluation.location_atmosphere__c"
				:inputLabel="$t('moe.customerEvaluationForm.string.q5VenueMood', {venue: booking.Location_Name_from_lookup__c})"
				:error="formErrors.evaluationError_location_atmosphere__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_atmosphere__c = false"
				inputType="rating"
				ratingLabels="value"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_service__c"
				v-model="evaluation.location_service__c"
				:inputLabel="
					$t('moe.customerEvaluationForm.string.q6VenueService', {venue: booking.Location_Name_from_lookup__c})
				"
				:error="formErrors.evaluationError_location_service__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_service__c = false"
				inputType="rating"
				ratingLabels="value"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_food_and_drinks__c"
				v-model="evaluation.location_food_and_drinks__c"
				:inputLabel="
					$t('moe.customerEvaluationForm.string.q7VenueFoodDrinks', {venue: booking.Location_Name_from_lookup__c})
				"
				:error="formErrors.evaluationError_location_food_and_drinks__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_food_and_drinks__c = false"
				inputType="rating"
				ratingLabels="value"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<InputItem
				inputName="evaluation.location_reliability__c"
				v-model="evaluation.location_reliability__c"
				:inputLabel="
					$t('moe.customerEvaluationForm.string.q8Reliability', {venue: booking.Location_Name_from_lookup__c})
				"
				:error="formErrors.evaluationError_location_reliability__c"
				:errorMessage="$t('moe.customerEvaluationForm.message.PleaseMakeAChoice')"
				@change="formErrors.evaluationError_location_reliability__c = false"
				inputType="rating"
				ratingLabels="value"
				inputLabelAlwaysTop
				extraSpaceBottom
				class="flex justify-center"
			/>
			<div class="max-w-[900px] mx-auto mt-3">
				<div class="flex justify-center">
					<Button @click="saveEvaluation(evaluation)" type="green" arrowRight>{{
						$t("moe.customerEvaluationForm.label.submit2")
					}}</Button>
				</div>
				<div class="font-semibold mt-2 text-center">{{ $t("moe.customerEvaluationForm.string.win") }}</div>
			</div>
		</div>
		<div v-if="isThankYouStep">
			<div v-if="evaluation.External_review_partner__c">
				<div class="text-xl font-semibold">{{ $t("moe.customerEvaluationForm.title.thanksOnelastThing") }}</div>
				<p>{{ $t("moe.customerEvaluationForm.string.thanksOnelastThing", {page: shareName}) }}</p>
				<p class="font-bold">{{ $t("moe.customerEvaluationForm.string.itsEasy") }}</p>
				<ol class="list-disc list-inside mb-7">
					<li>{{ $t("moe.customerEvaluationForm.string.copyBelow") }}</li>
					<li>{{ $t("moe.customerEvaluationForm.string.clickButton") }}</li>
					<li>{{ $t("moe.customerEvaluationForm.string.chooseStars") }}</li>
					<li>{{ $t("moe.customerEvaluationForm.string.paste") }}</li>
				</ol>
				<Button
					@click.native="copyReviewToClipboard()"
					:label="$t('moe.customerEvaluationForm.label.copyToClipboard')"
					arrowPosition="before"
					icon="clipboard"
				/>
				<div>
					<div class="relative max-w-lg">
						<textarea
							rows="6"
							ref="reviewToCopy"
							@focus="copyReviewToClipboard()"
							:value="evaluation.general_comments_regarding_booking__c"
							class="transition-colors duration-500 text-sm md:text-base p-2 w-full ring-1 ring-slate-900/10 shadow-sm rounded focus:outline-none focus:ring-2 focus:ring-tdc-blue-500 caret-tdc-blue-500 dark:bg-slate-500 dark:ring-0 dark:highlight-white/5 dark:focus:ring-2 dark:focus:ring-tdc-blue-500 dark:focus:bg-slate-600"
							:class="{
								'focus:ring-tdc-green-500 caret-tdc-green-500 dark:focus:ring-tdc-green-500 bg-tdc-green-50 dark:focus:bg-tdc-green-200 dark:focus:text-slate-900':
									reviewCopied,
							}"
						/>
						<div
							class="overflow-hidden max-h-0 transition-max-height duration-700"
							:class="{'max-h-[900px]': reviewCopied}"
						>
							<Alert type="success" icon="check">
								{{ $t("moe.customerEvaluationForm.label.copied") }}
							</Alert>
						</div>
					</div>
				</div>
				<a :href="shareUrl" target="_blank">
					<Button
						type="green"
						:label="$t('moe.customerEvaluationForm.label.shareTo', {page: shareName})"
						arrowPosition="before"
						icon="share-nodes"
					/>
				</a>
				<p>{{ $t("moe.customerEvaluationForm.string.thankYou") }}</p>
			</div>
			<div v-else>
				<div class="text-xl font-semibold">{{ $t("moe.customerEvaluationForm.title.thanks") }}</div>
				<p>{{ $t("moe.customerEvaluationForm.string.thanks") }}</p>
				<p>{{ $t("moe.customerEvaluationForm.string.fromTheDJCompanyTeam") }}</p>
			</div>
		</div>
		<DisableTrengoChat />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import InputItem from "@/components/location/InputItem";
import Button from "@/components/location/Button";
import Alert from "@/components/location/Alert";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "EvaluationSteps",
	components: {
		InputItem,
		Button,
		Alert,
		DisableTrengoChat,
	},
	data() {
		return {
			formErrors: this.initialEvaluationFormErrors(),
			q1ChoiceMade: false,
			q1Choice: null,
			inputMilestone1: false,
			inputMilestone2: false,
			inputMilestone3: false,
			inputMilestone4: false,
			evaluationFetched: false,
			reviewCopied: false,
		};
	},
	computed: {
		...mapGetters({
			error: "getError",
			booking: "getBookingInformation",
			evaluation: "getEvaluationInformation",
			isLoading: "getLoadingState",
		}),
		routeName() {
			return this.$route.name;
		},
		isStep1() {
			return this.$route.name === "Evaluation";
		},
		isStep2() {
			return this.$route.name === "Venue";
		},
		isThankYouStep() {
			return this.$route.name === "ThankYou";
		},
		storedEvaluationId() {
			return localStorage && localStorage.evaluationId ? localStorage.evaluationId : "";
		},
		stepsCompleted() {
			return this.booking.Location_private_from_lookup__c === false
				? this.evaluation.ReviewStep3Completed__c === true
				: this.isStep2 && this.evaluation.ReviewStep2Completed__c === true;
		},
		shareUrl() {
			if (this.evaluation.External_review_partner__c === "Google") {
				return this.evaluation.External_review_partner_URL_Google__c;
			} else if (this.evaluation.External_review_partner__c === "Facebook") {
				return this.evaluation.External_review_partner_URL_Facebook__c;
			} else if (this.evaluation.External_review_partner__c === "TPW") {
				return this.evaluation.External_review_partner_URL_TPW__c;
			} else if (this.evaluation.External_review_partner__c === "Klantenvertellen") {
				return this.evaluation.External_review_partner_URL_KlantenVert__c;
			}
			return "";
		},
		shareName() {
			if (this.evaluation.External_review_partner__c === "TPW") {
				return "Klantenvertellen";
			}
			return this.evaluation.External_review_partner__c;
		},
		djName() {
			return this.booking.dj_first_name__c ? "DJ " + this.booking.dj_first_name__c : "DJ";
		},
	},
	methods: {
		copyReviewToClipboard() {
			console.log("copy");
			this.$refs.reviewToCopy.select();
			document.execCommand("copy");
			this.reviewCopied = true;
			this.delay = setTimeout(
				function () {
					this.reviewCopied = false;
				}.bind(this),
				2000
			);
		},
		validateStep1(evaluation) {
			this.resetFormErrors();
			if (evaluation.net_promoter_likert_scale__c === null || evaluation.net_promoter_likert_scale__c === undefined) {
				this.formErrors.evaluationError_net_promoter_likert_scale__c = true;
			}

			if (
				evaluation.dj_played_music_i_requested_rating__c === null ||
				evaluation.dj_played_music_i_requested_rating__c === undefined
			) {
				this.formErrors.evaluationError_dj_played_music_i_requested_rating__c = true;
			}

			if (
				evaluation.dj_played_according_to_atmosphere_rating__c === null ||
				evaluation.dj_played_according_to_atmosphere_rating__c === undefined
			) {
				this.formErrors.evaluationError_dj_played_according_to_atmosphere_rating__c = true;
			}

			if (evaluation.dj_service_level_rating__c === null || evaluation.dj_service_level_rating__c === undefined) {
				this.formErrors.evaluationError_dj_service_level_rating__c = true;
			}

			if (
				evaluation.service_and_communication_prior__c === null ||
				evaluation.service_and_communication_prior__c === undefined
			) {
				this.formErrors.evaluationError_service_and_communication_prior__c = true;
			}

			if (this.formErrors.evaluationError_net_promoter_likert_scale__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.net_promoter_likert_scale__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_dj_played_music_i_requested_rating__c) {
				this.formErrors.hasErrors = true;
				document
					.getElementById("evaluation.dj_played_music_i_requested_rating__c")
					.scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_dj_played_according_to_atmosphere_rating__c) {
				this.formErrors.hasErrors = true;
				document
					.getElementById("evaluation.dj_played_according_to_atmosphere_rating__c")
					.scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_dj_service_level_rating__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.dj_service_level_rating__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_service_and_communication_prior__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.service_and_communication_prior__c").scrollIntoView({behavior: "smooth"});
			}
		},
		validateStep2(evaluation) {
			this.resetFormErrors();
			if (evaluation.location_NPS_score__c === null || evaluation.location_NPS_score__c === undefined) {
				this.formErrors.evaluationError_location_NPS_score__c = true;
			}

			if (evaluation.location_value__c === null || evaluation.location_value__c === undefined) {
				this.formErrors.evaluationError_location_value__c = true;
			}

			if (evaluation.location_atmosphere__c === null || evaluation.location_atmosphere__c === undefined) {
				this.formErrors.evaluationError_location_atmosphere__c = true;
			}

			if (evaluation.location_service__c === null || evaluation.location_service__c === undefined) {
				this.formErrors.evaluationError_location_service__c = true;
			}

			if (evaluation.location_food_and_drinks__c === null || evaluation.location_food_and_drinks__c === undefined) {
				this.formErrors.evaluationError_location_food_and_drinks__c = true;
			}

			if (evaluation.location_reliability__c === null || evaluation.location_reliability__c === undefined) {
				this.formErrors.evaluationError_location_reliability__c = true;
			}

			if (this.formErrors.evaluationError_location_NPS_score__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_NPS_score__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_location_value__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_value__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_location_atmosphere__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_atmosphere__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_location_service__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_service__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_location_food_and_drinks__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_food_and_drinks__c").scrollIntoView({behavior: "smooth"});
			} else if (this.formErrors.evaluationError_location_reliability__c) {
				this.formErrors.hasErrors = true;
				document.getElementById("evaluation.location_reliability__c").scrollIntoView({behavior: "smooth"});
			}
		},
		resetFormErrors() {
			this.formErrors = this.initialEvaluationFormErrors();
		},
		resetEvaluation() {
			localStorage.evaluationId = "";
		},
		initialEvaluationFormErrors() {
			return {
				hasErrors: false,
				evaluationError_net_promoter_likert_scale__c: false,
				evaluationError_dj_played_music_i_requested_rating__c: false,
				evaluationError_dj_played_according_to_atmosphere_rating__c: false,
				evaluationError_dj_service_level_rating__c: false,
				evaluationError_service_and_communication_prior__c: false,
				evaluationError_location_NPS_score__c: false,
				evaluationError_location_value__c: false,
				evaluationError_location_atmosphere__c: false,
				evaluationError_location_service__c: false,
				evaluationError_location_food_and_drinks__c: false,
				evaluationError_location_reliability__c: false,
			};
		},
		q1Chosen() {
			if (!this.q1ChoiceMade) {
				this.q1Choice = this.evaluation.net_promoter_likert_scale__c;
				this.q1ChoiceMade = true;
			}
		},
		milestoneInput() {
			let charCount = this.evaluation.general_comments_regarding_booking__c.length;
			if (charCount > 50) {
				this.inputMilestone2 = true;
			}
			if (charCount > 100) {
				this.inputMilestone3 = true;
			}
			if (charCount > 200) {
				this.inputMilestone4 = true;
			}
		},
		saveEvaluation(evaluation) {
			if (this.isStep1) {
				this.validateStep1(evaluation);
			} else if (this.isStep2) {
				this.validateStep2(evaluation);
			}
			if (!this.formErrors.hasErrors) {
				if (this.isStep1) {
					evaluation.ReviewStep1Completed__c = true;
					if (this.booking.Location_private_from_lookup__c) {
						evaluation.ReviewStep2Completed__c = true;
					}
				} else if (this.isStep2) {
					evaluation.ReviewStep2Completed__c = true;
					evaluation.ReviewStep3Completed__c = true;
				}
				if (localStorage.evaluationId) {
					evaluation.Id = localStorage.evaluationId;
				}

				this.$store
					.dispatch("saveEvaluation", {
						action: "saveEvaluation",
						data: evaluation,
					})
					.then((data) => {
						if (localStorage.evaluationId) {
							this.updateEvaluation(data);
						} else {
							this.insertEvaluation(data);
						}
						if (!this.booking.Location_private_from_lookup__c && this.isStep1) {
							this.$router.push({name: "Venue"});
						} else {
							this.$router.push({name: "ThankYou"});
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		},
		updateEvaluation() {},
		insertEvaluation(data) {
			localStorage.evaluationId = data.data.Id;
			this.evaluation.Id === data.data.Id;
		},
		handleBrowserEvents() {
			if (!this.evaluationFetched) {
				return;
			}

			if (!this.isStep1 && !this.evaluation.ReviewStep1Completed__c) {
				this.$router.push({name: "Evaluation"});
			} else if (this.isStep2 && this.stepsCompleted) {
				this.resetEvaluation();
				this.$router.push({name: "Evaluation"});
			}
		},
	},
	mounted() {
		if (localStorage.evaluationId) {
			this.$store.dispatch("getEvaluation", {evaluationId: localStorage.evaluationId}).then(() => {
				this.evaluationFetched = true;
				if (!this.evaluation.Id && this.storedEvaluationId) {
					this.resetEvaluation();
				}
				this.handleBrowserEvents();
			});
		} else {
			this.evaluationFetched = true;
			this.$router.push({name: "Evaluation"});
		}
	},
	watch: {
		$route: {
			handler: function () {
				this.handleBrowserEvents();
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
