<template>
	<div id="app">
		<AppLayout>
			<slot />
		</AppLayout>
	</div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
export default {
	name: "app",
	components: {
		AppLayout,
	},
	mounted() {
		if (window.sessionStorage) {
			sessionStorage.setItem("wishesTabViewCount", 0);
			sessionStorage.setItem("overviewTabViewCount", 0);
		}
	},
};
</script>

<style lang="scss">
.b-overlay {
	position: fixed !important;
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;
	@extend .position-fixed;
	> div {
		@extend .position-fixed;
	}
}
.body-spacing {
	height: 85px !important;
	@include media-breakpoint-up(lg) {
		height: 30px !important;
	}
}
.mdc-booking-content-wrapper {
}
</style>
