<template>
	<div>
		<div v-if="historicBooking || isOrderInProgress">
			<Alert v-if="!isPaid" alertType="warning">
				Je hebt al een boeking. Ga naar jouw DJ boeking en
				<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
			</Alert>
			<Alert v-else alertType="notification">
				Je hebt al een boeking. Bekijk hier <router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>.
			</Alert>
		</div>
		<div v-else>
			<Alert v-if="!isPreQuote" isScarcity alertType="warning" dismissible />
			<PriceCalculator />
			<div
				v-if="isPreQuote ? preQuotePricing : prices"
				:class="{loading: loading}"
				class="py-2 d-flex flex-column justify-content-center align-items-stretch"
			>
				<div class="mb-3 card border-0 shadow" v-if="(isPreQuote ? preQuotePricing : prices)['p_junior']">
					<div class="d-flex flex-column card-body">
						<DjBlock
							template="DjBlockSummary"
							sf_name="Junior"
							:priceIncVat="(isPreQuote ? preQuotePricing : prices)['p_junior'].p_totalAmount"
							:priceExcVat="(isPreQuote ? preQuotePricing : prices)['p_junior'].p_exVatAmount"
						/>
					</div>
				</div>
				<div
					class="mb-3 card border-0 shadow most-selected border-success"
					v-if="(isPreQuote ? preQuotePricing : prices)['p_a_class']"
				>
					<div class="d-flex flex-column card-body">
						<DjBlock
							template="DjBlockSummary"
							sf_name="AClass"
							:priceIncVat="(isPreQuote ? preQuotePricing : prices)['p_a_class'].p_totalAmount"
							:priceExcVat="(isPreQuote ? preQuotePricing : prices)['p_a_class'].p_exVatAmount"
						/>
					</div>
				</div>
				<div class="mb-3 card border-0 shadow" v-if="(isPreQuote ? preQuotePricing : prices)['p_premium']">
					<div class="d-flex flex-column card-body">
						<DjBlock
							template="DjBlockSummary"
							sf_name="Premium"
							:priceIncVat="(isPreQuote ? preQuotePricing : prices)['p_premium'].p_totalAmount"
							:priceExcVat="(isPreQuote ? preQuotePricing : prices)['p_premium'].p_exVatAmount"
						/>
					</div>
				</div>
			</div>
			<PriceCalculator extendedStyling />
			<div class="calendly d-flex flex-column flex-sm-row justify-content-start">
				<font-awesome-icon :icon="['fal', 'calendar-clock']" style="color: #009fee" class="mr-3 icon" />
				<div>
					<span class="font-weight-bold mb-2 d-block">Hulp of advies nodig bij het boeken van de juiste DJ?</span>
					<small class="d-block"
						>Maak vrijblijvend een belafspraak
						<a href="https://calendly.com/thedjcompany/kennismaking" target="_blank">via de online kalender</a> of
						<a v-if="isBelgian" class="whatsapp-link" href="https://wa.me/3233002797" target="_blank"
							>stuur ons een WhatsApp</a
						>
						<a v-else class="whatsapp-link" href="https://wa.me/31854019438" target="_blank">stuur ons een WhatsApp</a
						>.</small
					>
				</div>
			</div>
			<BookingIncludes />
			<MyQuotes />
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import DjBlock from "@/components/customer/DjBlock";
import PriceCalculator from "@/components/customer/PriceCalculator";
import MyQuotes from "@/components/customer/MyQuotes";
import BookingIncludes from "@/components/customer/BookingIncludes";

export default {
	name: "Prices",
	components: {Alert, DjBlock, PriceCalculator, MyQuotes, BookingIncludes},
	computed: {
		...mapGetters({
			booking: "getBooking",
			prices: "getPrices",
			partyType: "getPartyType",
			isPreQuote: "isPreQuote",
			preQuotePricing: "getPreQuotePricing",
			isQuote: "isQuote",
			loading: "getLoadingState",
			isOrderInProgress: "isOrderInProgress",
			fixedPrices: "isFixed",
			isPaid: "getPaidStatus",
			isBelgian: "isBelgian",
			isDutch: "isDutch",
		}),
		historicBooking: function () {
			var historicDate = this.$dayjs("2021-10-01");
			return this.$dayjs(this.booking.CreatedDate).diff(historicDate) < 0;
		},
	},
};
</script>

<style lang="scss" scoped>
.calendly {
	margin-top: 10px;
	padding: 1em;
	border-radius: 4px;
	background-color: #eeeeee;
	display: block;

	.icon {
		display: none;

		@include media-breakpoint-up(sm) {
			display: block;
			height: 30px;
		}

		@include media-breakpoint-up(lg) {
			height: 40px;
		}
	}
}
</style>
