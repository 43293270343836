import {userTypeIsCustomer, userTypeIsLocation, userTypeIsCustomerEvaluation} from "@/mo";

import Vue from "vue";
import Router from "vue-router";
import Home from "./views/customer/Home";
import Overview from "./views/customer/Overview";
import Prices from "./views/customer/Prices";
import DirectBookingConfirmed from "./views/customer/DirectBookingConfirmed";
import DirectBookingPaid from "./views/customer/DirectBookingPaid";
import DirectBookingCart from "./views/customer/DirectBookingCart";
import Booking from "./views/customer/Booking";
import QuoteCart from "./views/customer/QuoteCart";
import QuoteConfirmed from "./views/customer/QuoteConfirmed";
import Quotes from "./views/customer/Quotes";
import Quote from "./views/customer/Quote";
import DJDetails from "./views/customer/DJDetails";
import PartyLocation from "./views/customer/PartyLocation";
import PartyWishes from "./views/customer/PartyWishes";
import Reviews from "./views/customer/Reviews";
import ContactDetails from "./views/customer/ContactDetails";
import Discounts from "./views/customer/Discounts";
import OptOut from "./views/customer/OptOut";

import CustomerEvaluationHome from "./views/customerEvaluation/EvaluationHome";
import CustomerEvaluationSteps from "./views/customerEvaluation/EvaluationSteps";
import CustomerPhotoSteps from "./views/customerEvaluation/PhotoSteps";

import LocationHome from "./views/location/Home";
import LocationProfile from "./views/location/Profile";
import LocationContacts from "./views/location/Contacts";
import LocationReviews from "./views/location/Reviews";
import LocationSpaces from "./views/location/Spaces";
import LocationDJBookings from "./views/location/Bookings";
import LocationBookADJ from "./views/location/BookADJ";
import LocationDJReservations from "./views/location/Reservations";
import LocationHowWeWork from "./views/location/HowWeWork";
import LocationContactUs from "./views/location/ContactUs";
import LocationReviewUs from "./views/location/ReviewUs";
import LocationInvoicing from "./views/location/Invoicing";
import LocationCouponCode from "./views/location/CouponCode";
import LocationNotFound from "./views/location/NotFound";

Vue.use(Router);

const routes = (() => {
	if (userTypeIsCustomerEvaluation()) {
		return [
			{
				path: "/",
				name: "Home",
				component: CustomerEvaluationHome,
			},
			{
				path: "/photo",
				name: "Photo",
				component: CustomerPhotoSteps,
			},
			{
				path: "/evaluation",
				name: "Evaluation",
				component: CustomerEvaluationSteps,
			},
			{
				path: "/venue",
				name: "Venue",
				component: CustomerEvaluationSteps,
			},
			{
				path: "/thankyou",
				name: "ThankYou",
				component: CustomerEvaluationSteps,
			},
		];
	} else if (userTypeIsLocation()) {
		return [
			{
				path: "/",
				name: "Home",
				component: LocationHome,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/profile",
				name: "Profile",
				component: LocationProfile,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/profile/:path",
				redirect: (to) => {
					return {
						path: "/profile",
						hash: "#" + to.params.path,
					};
				},
			},
			{
				path: "/contacts",
				name: "Contacts",
				component: LocationContacts,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/reviews",
				name: "Reviews",
				component: LocationReviews,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/spaces",
				name: "Spaces",
				component: LocationSpaces,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/bookings",
				name: "DJBookings",
				component: LocationDJBookings,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/bookings/:id",
				redirect: (to) => {
					return {
						path: "/bookings",
						hash: "#" + to.params.id,
					};
				},
			},
			{
				path: "/bookadj",
				name: "BookADJ",
				component: LocationBookADJ,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/reservations",
				name: "DJReservations",
				component: LocationDJReservations,
			},
			{
				path: "/howwework",
				name: "HowWeWork",
				component: LocationHowWeWork,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/contactus",
				name: "ContactUs",
				component: LocationContactUs,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/reviewus",
				name: "ReviewUs",
				component: LocationReviewUs,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/invoicing",
				name: "Invoicing",
				component: LocationInvoicing,
				meta: {
					footerWidth: "half",
				},
			},
			{
				path: "/coupon-code",
				name: "CouponCode",
				component: LocationCouponCode,
				meta: {
					footerWidth: "full",
				},
			},
			{
				path: "/404",
				component: LocationNotFound,
			},
			{
				path: "*",
				redirect: "/404",
				meta: {
					footerWidth: "full",
				},
			},
		];
	} else if (userTypeIsCustomer()) {
		return [
			{
				path: "/",
				name: "Home",
				component: Overview,
				meta: {
					sectionColor: "blue",
					metaTitle: "Mijn DJ Company - Overzicht (m)",
					mobilePageTitle: "Overzicht",
					subNav: false,
					sideBar: false,
				},
			},
			{
				path: "/oldhome",
				name: "Oldome",
				component: Home,
				meta: {
					sectionColor: "blue",
					metaTitle: "Mijn DJ Company - Overzicht (m)",
					mobilePageTitle: "Overzicht",
					subNav: false,
					sideBar: false,
				},
			},
			{
				path: "/dj-huren/prijzen",
				name: "Prices",
				component: Prices,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Prijzen (m)",
					mobilePageTitle: "Prijzen berekenen",
					sidebarAdditionalContent: true,
					subNav: {
						name: "BookingNav",
					},
					sideBar: true,
					backButton: false,
				},
			},
			{
				path: "/dj-huren/boeken/winkelwagen",
				name: "DirectBookingCart",
				component: DirectBookingCart,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Winkelwagen (m)",
					mobilePageTitle: "",
					subNav: false,
					backButton: {
						backTo: "Prices",
						backLabel: "meer prijzen berekenen",
					},
				},
			},
			{
				path: "/dj-huren/boeken/bevestiging",
				name: "DirectBookingConfirmed",
				component: DirectBookingConfirmed,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Boeking bevestigd (m)",
					mobilePageTitle: "",
					subNav: false,
					gtmAdditionalEventData: {routeCategory: "Test group"},
				},
			},
			{
				path: "/dj-huren/boeken/betaald",
				name: "DirectBookingPaid",
				component: DirectBookingPaid,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Aanbetaling geslaagd (m)",
					subNav: false,
					gtmAdditionalEventData: {routeCategory: "Test group"},
				},
			},
			{
				path: "/dj-huren/boeking",
				name: "Booking",
				component: Booking,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Mijn boeking (m)",
					mobilePageTitle: "DJ Boeking",
					sidebarAdditionalContent: true,
					subNav: {
						name: "BookingNav",
					},
					sideBar: true,
				},
			},
			{
				path: "/dj-huren/offerte/winkelwagen",
				name: "QuoteCart",
				component: QuoteCart,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Offerte aanvragen (m)",
					mobilePageTitle: "Vraag jouw offerte aan",
					subNav: false,
					back: "Prices",
				},
			},
			{
				path: "/dj-huren/offerte/bevestiging/:id",
				name: "QuoteConfirmed",
				component: QuoteConfirmed,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Offerte aangevraagd (m)",
					mobilePageTitle: "Bedankt, je hebt nu een offerte mét prijsgarantie!",
					subNav: false,
					back: "Prices",
				},
			},
			{
				path: "/dj-huren/offerte/:id",
				name: "Quote",
				component: Quote,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Offerte aangevraagd (m)",
					mobilePageTitle: "Offerte",
					sidebarAdditionalContent: true,
					subNav: {
						name: "BookingNav",
					},
					sideBar: true,
					backButton: {
						backTo: "Quotes",
						backLabel: "terug naar offerte overzicht",
					},
				},
			},
			{
				path: "/dj-huren/prijzen/dj",
				name: "dj",
				component: DJDetails,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - DJ details (m)",
					mobilePageTitle: "DJ details",
					sidebarAdditionalContent: true,
					subNav: {
						name: "BookingNav",
					},
					backButton: {
						backTo: "Prices",
						backLabel: "terug naar alle prijzen",
					},
					sideBar: true,
				},
			},
			{
				path: "/feestlocatie",
				name: "PartyLocation",
				component: PartyLocation,
				meta: {
					sectionColor: "aqua",
					metaTitle: "Mijn DJ Company - Feestlocatie (m)",
					mobilePageTitle: "Feestlocatie",
					subNav: false,
					sideBar: false,
				},
			},
			{
				path: "/feestwensen",
				name: "PartyWishes",
				component: PartyWishes,
				meta: {
					sectionColor: "purple",
					metaTitle: "Mijn DJ Company - Feestwensen (m)",
					mobilePageTitle: "Feestwensen",
					subNav: {
						name: "PartyWishesNav",
						mobileVertical: false,
					},
					sidebarAdditionalContent: true,
					sideBar: false,
				},
			},
			{
				path: "/klantervaringen",
				name: "Reviews",
				component: Reviews,
				meta: {
					sectionColor: "orange",
					metaTitle: "Mijn DJ Company - Klantervaringen (m)",
					mobilePageTitle: "Onze klantervaringen",
					subNav: false,
					sideBar: false,
				},
			},
			{
				path: "/dj-huren/offertes",
				name: "Quotes",
				component: Quotes,
				meta: {
					sectionColor: "green",
					metaTitle: "Mijn DJ Company - Mijn offertes (m)",
					mobilePageTitle: "Mijn Offertes",
					sidebarAdditionalContent: true,
					subNav: {
						name: "BookingNav",
					},
					sideBar: true,
				},
			},
			{
				path: "/contactgegevens",
				name: "ContactDetails",
				component: ContactDetails,
				meta: {
					sectionColor: "blue",
					metaTitle: "Mijn DJ Company - Contactgegevens (m)",
					mobilePageTitle: "Contactgegevens",
					subNav: false,
				},
			},
			{
				path: "/kortingen",
				name: "Discounts",
				component: Discounts,
				meta: {
					sectionColor: "red",
					metaTitle: "Mijn DJ Company - Kortingen (m)",
					mobilePageTitle: "Kortingen",
					subNav: false,
					sideBar: false,
					backButton: true,
				},
			},
			{
				path: "/boeking",
				redirect: {name: "Prices"},
			},
			{
				path: "/optout",
				name: "Opt-out",
				component: OptOut,
				meta: {
					sectionColor: "red",
					metaTitle: "DJ Company - Opt-out",
					mobilePageTitle: "Opt-out",
					subNav: false,
					sideBar: false,
					backButton: false,
				},
			},
		];
	}
})();

const router = new Router({
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				selector: to.hash,
				y: 1,
				behavior: "smooth",
			};
		} else {
			return {
				x: 0,
				y: 1,
				behavior: "smooth",
			};
		}
	},
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	var currentUrl = window.location.href;

	//if url contains undecoded hashtag, replace it and redirect
	if (currentUrl.indexOf("%23") !== -1) {
		var newUrl = currentUrl.replace("%23", "#");
		window.location = newUrl;
	}

	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTitle);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.metaTitle;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.metaTitle;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

export default router;
