<template>
	<div>
		<PageTitle :title="title" :subTitle="subTitle" :breadcrumbs="breadcrumbs" :adaptSubnav="true" />
		<SubNav :subNavItems="subNavItems">
			<template slot="subNavTabContent">
				<div v-if="displayContent('')">
					{{ $t("mol.profilePage.string.Get started with your") }}
					<Ahref
						@click.native="$store.commit('SET_MOL_SUBNAV_HASH', '#upload-photos')"
						:label="$t('mol.profilePage.link.online profile')"
					/>.
				</div>
				<div v-if="displayContent('#upload-photos')">
					<draggable
						v-model="draggablePhotos"
						group="songs"
						v-bind="dragOptions"
						@start="drag = true"
						@end="drag = false"
						@change="saveSortOrder(draggablePhotos)"
						animation="true"
						:disabled="disableDraggable"
					>
						<transition-group
							type="transition"
							name="flip-list"
							class="grid grid-cols-2 sm:grid-cols-3 min-[460px]:grid-cols-3 min-[940px]:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-1 sm:gap-2 md:gap-2 [&>*:nth-child(1)]:!border-tdc-blue-500"
						>
							<div
								v-for="(file, key) in draggablePhotos"
								:key="file.Id"
								class="cursor-move w-100 bg-slate-300 aspect-square bg-cover bg-center bg-no-repeat border border-slate-200 relative first:border-2 rounded"
								:style="{backgroundImage: 'url(' + file.Thumbnail__c + ')'}"
							>
								<div class="absolute top-2 right-2">
									<Button @click.native="setRequestDeleteConfirmation(file.Id)" :arrow="false" type="red">
										<font-awesome-icon :icon="['far', 'trash']" class="text-white block w-4 h-4" />
									</Button>
								</div>
								<div class="absolute bottom-0 right-2">
									<Button btn-sm @click.native="setMainPhoto(file.Id)" :arrow="false" type="blue">
										<div v-if="key === 0" class="flex items-center">
											{{ $t("mol.profilePage.label.Main photo") }}
											<font-awesome-icon
												v-if="key === 0"
												:icon="['fas', 'star']"
												class="text-white block w-4 h-4 ml-2"
											/>
										</div>
										<font-awesome-icon v-else :icon="['far', 'star']" class="text-white block w-4 h-4" />
									</Button>
								</div>
								<div
									v-if="requestDeleteConfirmation == file.Id"
									@mouseleave="setRequestDeleteConfirmation()"
									class="absolute w-full h-full flex justify-center items-center bg-slate-400/70 dark:bg-slate-900/70"
								>
									<div class="flex flex-col justify-center gap-x-2">
										<Button
											@click.native="deletePhoto(file.Id)"
											type="red"
											class="mx-auto"
											:label="$t('mol.profilePage.button.Remove?')"
											icon="check"
										/>
										<Button @click.native="setRequestDeleteConfirmation()" type="cancel" class="mx-auto" />
									</div>
								</div>
							</div>
							<div
								v-if="!maxMediaCountReached"
								@click="openEditingPanel()"
								key="1"
								@mouseover="disableDraggable = true"
								@mouseleave="disableDraggable = false"
								class="cursor-pointer w-100 bg-white aspect-square bg-center bg-no-repeat border !border-slate-200 relative first:border-2 rounded items-center justify-center align-middle flex hover:bg-tdc-blue-500/20"
							>
								<font-awesome-icon
									:icon="['fas', 'plus']"
									class="text-tdc-blue-500 block w-10 h-10 sm:w-12 sm:h-12 md:w-16 md:h-16"
								/>
							</div>
						</transition-group>
					</draggable>
					<div class="text-right text-xs">
						{{ $t("mol.profilePage.string.Images uploaded out of max", {count: mediaCount, max: maxMediaCount}) }}
					</div>
				</div>
				<div v-if="displayContent('#upload-videos')">
					<p v-if="videoUrlCount == 0">
						{{ $t("mol.profilePage.string.You currently have no videos uploaded") }}
						<Ahref @click.native="openEditingPanel()" :label="$t('mol.profilePage.link.Add them now')" />.
					</p>
					<div v-else class="lg:mt-10">
						<div v-for="(url, index) in videoUrls" :key="index">
							<div v-if="parseVideo(url).type == 'youtube' || parseVideo(url).type == 'vimeo'">
								<iframe
									class="w-full aspect-video border border-slate-200 mb-3"
									:src="embedVideoIframe(url)"
									frameborder="0"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								></iframe>
							</div>
							<div v-else>
								<div
									class="w-full aspect-video border border-slate-200 bg-slate-700 flex justify-center flex-col items-center text-white mb-3 p-2 md:p-12 text-xs md:text-sm"
								>
									<font-awesome-icon :icon="['far', 'link-slash']" class="text-white block w-6 h-6 mb-3" />
									<div>
										{{ $t("mol.profilePage.string.Not a valid video URL", {url: url}) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="displayContent('#more-leads')">
					<div class="text-lg font-bold mb-2">
						{{ $t("mol.profilePage.title.Live by popular demand") }}
					</div>
					<p>{{ $t("mol.profilePage.string.For 6 years") }}</p>
					<div class="flex justify-center">
						<img
							src="~@/assets/images/tdc2ls.png"
							alt="LocatieSpotter"
							class="mr-3 h-40 object-scale-down mb-4 self-center"
						/>
					</div>
					<div class="p-2 md:p-4 lg:p-6 flex flex-col bg-[#f5dbd0] border-t-4 border-t-[#f0814c]">
						<div class="!text-[#f0814c] text-lg font-bold mb-2">
							{{ $t("mol.profilePage.title.The advantages at a glance") }}
						</div>
						<ul class="mb-4">
							<li class="text-base dark:text-slate-900 flex items-start justify-start mb-1 md:mb-2">
								<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-[#f0814c]" />
								{{ $t("mol.profilePage.usp.Quality requests", {location: location.Name}) }}
							</li>
							<li class="text-base dark:text-slate-900 flex items-start justify-start mb-1 md:mb-2">
								<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-[#f0814c]" />
								{{ $t("mol.profilePage.usp.Reach directly more than 5000 organizers per month") }}
							</li>
							<li class="text-base dark:text-slate-900 flex items-start justify-start mb-1 md:mb-2">
								<font-awesome-icon :icon="['fas', 'check']" class="mt-[5px] mr-2 text-[#f0814c]" />
								{{ $t("mol.profilePage.usp.Complete in 5 minutes", {location: location.Name}) }}
							</li>
						</ul>
						<img
							src="~@/assets/images/screens-locatiespotter.png"
							alt="LocatieSpotter"
							class="mr-3 h-60 object-scale-down mb-4"
						/>
						<a target="_blank" :href="locatieSpotterProfileUrl" class="flex">
							<Button customClasses="bg-[#f0814c] hover:bg-[#db6e3a]">{{
								$t("mol.profilePage.button.Get more leads", {location: location.Name})
							}}</Button>
						</a>
					</div>
				</div>
			</template>
			<template slot="subNavEditing">
				<SlideOutPanel :panelOpen="molEditingState">
					<div class="hidden lg:block">&nbsp;</div>
					<template slot="outerContent">
						<EditButton v-if="isDataEditable" :disabled="disableEditButton" @onClick="openEditingPanel()">
							<span
								v-if="displayContent('#upload-videos') && videoUrlCount == 0"
								v-text="$t('mol.profilePage.button.Add videos')"
							/>
							<span
								v-if="displayContent('#upload-videos') && videoUrlCount > 0"
								v-text="$t('mol.profilePage.button.Edit videos')"
							/>
							<span
								v-if="displayContent('#upload-photos') && mediaCount == 0"
								v-text="$t('mol.profilePage.button.Add photos')"
							/>
							<span
								v-if="displayContent('#upload-photos') && mediaCount > 0 && mediaCount < maxMediaCount"
								v-text="$t('mol.profilePage.button.Add more photos')"
							/>
							<span
								v-if="displayContent('#upload-photos') && mediaCount >= maxMediaCount"
								v-text="$t('mol.profilePage.button.Max image count reached')"
							/>
						</EditButton>
					</template>
					<template slot="innerContent">
						<div class="absolute right-0">
							<button
								@click="closeEditingPanel()"
								class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
							>
								<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
							</button>
						</div>
						<PageTitle :title="title" :subTitle="subTitle" :breadcrumbs="breadcrumbsEdit" />
						<div v-if="displayContent('#upload-videos')">
							<p v-if="videoUrlCount == 0">
								{{ $t("mol.profilePage.string.You currently have no videos uploaded") }}
							</p>
							<p v-if="videoUrlCount > 0">
								{{ $t("mol.profilePage.string.You have have videos uploaded", {num: videoUrlCount}) }}
							</p>
							<div>
								<div v-for="(url, index) in 5" :key="index">
									<InputItem
										v-model="profileDataToEdit[index]"
										inputName="Video"
										:placeholder="$t('mol.profileForm.string.Example Urls')"
										:inputLabel="$t('mol.profilePage.label.Video') + ' ' + (index + 1)"
										:error="formErrors.profileErrorVideo"
										:errorMessage="$t('mol.profileForm.message.Please enter a valid video URL')"
										@keypress="formErrors.profileErrorVideo = false"
									/>
								</div>
							</div>
							<FormGridWrapper>
								<template slot="right">
									<Alert>
										{{ $t("mol.profileForm.string.Only Vimeo or Youtube Urls") }}
									</Alert>
								</template>
							</FormGridWrapper>
						</div>
						<div v-if="displayContent('#upload-photos')">
							<div>
								<InputItem
									v-model="photos"
									inputType="file"
									:fileInputButtonLabel="$t('mol.general.button.Select files')"
									inputName="Photo"
									:inputLabel="$t('mol.profilePage.label.Photos')"
									:error="formErrors.profileErrorVideo"
									:errorMessage="$t('mol.general.message.Please choose a valid file format')"
									@fileschange="filesChange"
								/>
								<FormGridWrapper v-if="photoPreviews.length > 0">
									<template slot="left">
										<div>
											<span v-if="photoPreviews.length === 1">{{ $t("mol.general.string.1 file chosen") }}</span>
											<span v-else>{{ $t("mol.general.string.num Files chosen", {count: photoPreviews.length}) }}</span>
										</div>
									</template>
									<template slot="right">
										<div class="w-full">
											<div
												class="bg-slate-200/70 dark:bg-slate-700/70 mb-2 grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-1 sm:gap-2 md:gap-2 mr-2 p-2 rounded"
											>
												<div
													v-for="file in photoPreviews"
													:key="file"
													class="rounded w-100 bg-slate-300 dark:bg-slate-800 aspect-square bg-contain bg-center bg-no-repeat border border-slate-200 dark:border-slate-500 relative"
													:style="{backgroundImage: 'url(' + file + ')'}"
												></div>
											</div>
										</div>
									</template>
								</FormGridWrapper>
							</div>
						</div>
						<div class="md:grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
							<div class="md:col-start-2 lg:col-start-2 xl:col-start-2 md:col-span-3 lg:col-span-4 xl:col-span-5">
								<div
									v-if="displayContent('#upload-photos') && photoPreviews.length + mediaCount > maxMediaCount"
									class="w-full grow text-xs text-amber-600 py-2"
								>
									{{
										$t("mol.profileForm.message.Uploading these files will exceed the maximum count", {
											max: maxMediaCount,
										})
									}}
								</div>
								<div class="flex flex-row-reverse">
									<Button
										v-if="displayContent('#upload-photos')"
										@click.native="saveProfileData(profileDataToEdit)"
										type="green"
										icon="check"
										:disabled="photoPreviews.length === 0 || photoPreviews.length + mediaCount > maxMediaCount"
										class="mr-2 flex-grow"
										:label="$t('mol.profilePage.button.Save photos')"
									/>
									<Button
										v-if="displayContent('#upload-videos')"
										@click.native="saveProfileData(profileDataToEdit)"
										type="green"
										icon="check"
										class="mr-2 flex-grow"
										:label="$t('mol.profilePage.button.Save videos')"
									/>
									<Button @click.native="closeEditingPanel()" class="mr-2" type="cancel" />
								</div>
							</div>
						</div>
					</template>
				</SlideOutPanel>
			</template>
		</SubNav>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PageTitle from "@/components/location/PageTitle";
import SubNav from "@/components/location/SubNav";
import Button from "@/components/location/Button";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import EditButton from "@/components/location/EditButton";
import InputItem from "@/components/location/InputItem";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Ahref from "@/components/location/Ahref";
import Alert from "@/components/location/Alert";
import {postSalesForce} from "@/services/SalesforceService";
import axios from "axios";
import draggable from "vuedraggable";
import {emailRegex} from "@/utilities";

export default {
	name: "LocationProfile",
	components: {
		PageTitle,
		SubNav,
		Button,
		SlideOutPanel,
		EditButton,
		InputItem,
		Ahref,
		Alert,
		FormGridWrapper,
		draggable,
	},
	data() {
		return {
			disableDraggable: false,
			profileDataToEdit: [],
			formErrors: this.initialProfileFormErrors(),
			photos: [],
			draggablePhotos: [],
			photoPreviews: [],
			requestDeleteConfirmation: "",
			maxMediaCount: 18,
			maxVideoCount: 5,
		};
	},
	computed: {
		...mapGetters({
			molEditingState: "getMolEditingState",
			molSubnavHash: "getMolSubnavHash",
			location: "getLocationInformation",
			mainContact: "getLocationMainContactDetails",
			media: "getMedia",
			extId: "getLocationExternalId",
			countryCode: "getCountryCode",
		}),
		showLocatieSpotterNav() {
			if (this.countryCode === "BE") return false;
			return true;
		},
		subNavItems() {
			var locatieSpotterSubNavItem = {
				label: this.$t("mol.profilePage.subNav.More leads"),
				route: {name: "Profile", hash: "#more-leads"},
				title: this.$t("mol.profilePage.title.More leads more business"),
				subTitle: this.$t("mol.profilePage.title.Welcome to LocationSpotter"),
				icon: "line-chart",
			};
			var subNavItems = [
				{
					label: this.$t("mol.profilePage.subNav.Upload photos"),
					route: {name: "Profile", hash: "#upload-photos"},
					title: this.$t("mol.profilePage.title.Venue photos"),
					subTitle: this.$t("mol.profilePage.title.Add and edit photos"),
					icon: "images",
				},
				{
					label: this.$t("mol.profilePage.subNav.Upload videos"),
					route: {name: "Profile", hash: "#upload-videos"},
					title: this.$t("mol.profilePage.title.Venue videos"),
					subTitle: this.$t("mol.profilePage.title.Add and edit video"),
					icon: "video",
				},
			];
			if (this.countryCode === "NL") {
				subNavItems.push(locatieSpotterSubNavItem);
			}
			return subNavItems;
		},
		title() {
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).title;
			}

			return this.$t("mol.profilePage.title.Website profile");
		},
		subTitle() {
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				return this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).subTitle;
			}

			return this.$t("mol.profilePage.title.Manage your online profile");
		},
		breadcrumbs() {
			let items = [this.$t("mol.profilePage.title.Website profile")];
			if (this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash)) {
				items.push(this.subNavItems.find((subNavItems) => subNavItems.route.hash === this.molSubnavHash).label);
			}
			return items;
		},
		breadcrumbsEdit() {
			let editItem = this.$t("mol.general.label.Edit");
			let breadcrumbs = JSON.parse(JSON.stringify(this.breadcrumbs));
			breadcrumbs.push(editItem);
			return breadcrumbs;
		},
		isDataEditable() {
			return this.displayContent("#upload-videos") || this.displayContent("#upload-photos");
		},
		disableEditButton() {
			return this.displayContent("#upload-photos") && this.maxMediaCountReached;
		},
		locatieSpotterProfileUrl() {
			let Url = "";
			let mainContact = this.mainContact;
			if (mainContact.Email != "") {
				Url = "https://locatie-spotter.nl/activate/" + this.location.Id + "?email=" + mainContact.Email;
			} else {
				Url = "mailto:info@locatie-spotter.nl";
			}
			return Url;
		},
		videoUrls() {
			return this.location.videourls__c ? this.location.videourls__c.split(/\s|\n/) : [];
		},
		videoUrlCount() {
			return this.videoUrls.length;
		},
		mediaCount() {
			return this.media.length;
		},
		maxMediaCountReached() {
			return this.mediaCount >= this.maxMediaCount;
		},
		emptyVideoUrlCount() {
			return 3 - this.videoUrls.length;
		},
		dragOptions() {
			return {
				animation: 0,
				disabled: false,
				ghostClass: "tailwind-custom-ghosting",
			};
		},
	},
	methods: {
		displayContent(hash) {
			return this.molSubnavHash === hash;
		},
		closeEditingPanel() {
			this.$store.commit("SET_MOL_EDITING_STATE", false);
		},
		openEditingPanel() {
			if (!this.disableEditButton) this.$store.commit("SET_MOL_EDITING_STATE", true);
		},
		setProfileDataToEdit(data) {
			if (data == "#upload-videos") this.profileDataToEdit = JSON.parse(JSON.stringify(this.videoUrls));
		},
		validateProfileData(contact) {
			this.resetFormErrors();
			if (contact.FirstName == "") {
				this.formErrors.hasErrors = true;
				this.formErrors.contactErrorFirstName = true;
				document.getElementById("FirstName__c").scrollIntoView({behavior: "smooth"});
			} else if (contact.LastName == "") {
				this.formErrors.hasErrors = true;
				this.formErrors.contactErrorLastName = true;
				document.getElementById("LastName__c").scrollIntoView({behavior: "smooth"});
			} else if (!emailRegex.test(contact.Email)) {
				this.formErrors.hasErrors = true;
				this.formErrors.contactErrorEmail = true;
				document.getElementById("Email").scrollIntoView({behavior: "smooth"});
			}
		},
		filesChange(files) {
			var arr = Array.from(files);
			this.photoPreviews = [];

			arr.forEach((file) => {
				this.photoPreviews.push(URL.createObjectURL(file));
			});
			this.photos = files;
		},
		saveProfileData(data) {
			if (this.displayContent("#upload-photos")) {
				this.uploadPhotos();
			} else if (this.displayContent("#upload-videos")) {
				var videoString = "";
				data.forEach((url) => {
					if (url) videoString += url + "\n";
				});
				data["videourls__c"] = videoString;
				this.$store
					.dispatch("upsertSFData", {
						action: "updateLocationInformation",
						data: data,
						successMessage: this.$t("mol.profileForm.message.Videos saved"),
						errorMessage: this.$t("mol.profileForm.message.Videos not saved"),
					})
					.then(console.log(""))
					.catch((err) => {
						console.error(err);
					});
			}
		},
		resetFormErrors() {
			this.formErrors = this.initialProfileFormErrors();
		},
		initialProfileFormErrors() {
			return {
				hasErrors: false,
				contactErrorFirstName: false,
				contactErrorLastName: false,
				contactErrorEmail: false,
			};
		},
		parseVideo(url) {
			var type = null;
			var match = null;
			var videoId = null;
			var regExp = "";

			if (url.includes("youtube") || url.includes("youtu.be")) {
				regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
				match = url.match(regExp);
				type = "youtube";
				videoId = match && match[7].length == 11 ? match[7] : null;
			} else if (url.includes("vimeo")) {
				regExp = /vimeo.*\/(\d+)/i;
				match = url.match(regExp);
				type = "vimeo";
				videoId = match[1];
			}
			return {
				url: url,
				type: type,
				id: videoId,
			};
		},
		embedVideoIframe(url) {
			var videoObj = this.parseVideo(url);
			var videoId = videoObj.id;
			if (videoObj.type == "youtube") {
				return "https://www.youtube.com/embed/" + videoId;
			} else if (videoObj.type == "vimeo") {
				return "https://player.vimeo.com/video/" + videoId + "?h=18cdeb30bd";
			}
			return "";
		},
		saveSortOrder(sortArray) {
			let sortOrder = sortArray.map((image) => image.Id).join(",");
			this.$store.dispatch("updateMedia", {
				action: "updateMedia",
				data: {sortOrder},
				successMessage: this.$t("mol.profileForm.message.Sort saved"),
				errorMessage: this.$t("mol.profileForm.message.Sort not saved"),
			});
		},
		setRequestDeleteConfirmation(id) {
			this.requestDeleteConfirmation = id;
		},
		deletePhoto(id) {
			let sortOrder = this.media
				.filter((image) => image.Id !== id)
				.map((image) => image.Id)
				.join(",");
			this.$store.dispatch("updateMedia", {
				action: "updateMedia",
				data: {sortOrder},
				successMessage: this.$t("mol.profileForm.message.Image removed"),
				errorMessage: this.$t("mol.profileForm.message.Image not removed"),
			});
		},
		setMainPhoto(id) {
			let sortOrder =
				id +
				"," +
				this.media
					.filter((image) => image.Id !== id)
					.map((image) => image.Id)
					.join(",");
			this.$store.dispatch("updateMedia", {
				action: "updateMedia",
				data: {sortOrder},
				successMessage: this.$t("mol.profileForm.message.Main image saved"),
				errorMessage: this.$t("mol.profileForm.message.Main image not saved"),
			});
		},
		uploadPhotos() {
			if (!this.photos || !this.photos.length) {
				return;
			}
			this.$store.commit("SET_LOADING_STATE", true);
			let proms = [];
			for (let i = 0; i < this.photos.length; ++i) {
				const photo = this.photos[i];
				proms.push(
					postSalesForce({action: "imageAuth"})
						.then((response) => {
							const form = new FormData();
							form.append("file", photo);
							form.append("expire", response.data.expire);
							form.append("token", response.data.token);
							form.append("signature", response.data.signature);
							form.append("publicKey", process.env.VUE_APP_IMAGEKIT_PUBKEY);
							form.append("fileName", photo.name);
							form.append("folder", `/${process.env.NODE_ENV}/${this.extId}/`);
							return axios.post("https://upload.imagekit.io/api/v1/files/upload", form);
						})
						.then((response) => {
							return postSalesForce({
								action: "createMedia",
								data: {
									fileLink: response.data.url,
									thumbnail: response.data.thumbnailUrl,
									fileType: response.data.fileType,
									fileId: response.data.fileId,
								},
							});
						})
						.catch((err) => {
							console.error(err);
						})
				);
			}
			Promise.all(proms)
				.then(() => {
					this.$store.commit("SET_MOL_EDITING_STATE", false);
					this.$store.commit("SET_LOADING_STATE", false);
					return this.$store.dispatch("getMedia");
				})
				.then((response) => {
					let media = response.data;
					let sortOrder = media.map((image) => image.Id).join(",");
					return this.$store.dispatch("updateMedia", {
						action: "updateMedia",
						data: {sortOrder},
						successMessage: this.$t("mol.profileForm.message.Images saved"),
						errorMessage: this.$t("mol.profileForm.message.Images not saved"),
					});
				})
				.catch((err) => {
					console.error(err);
				});
		},
	},
	created() {
		this.$store.dispatch("getMedia");
	},
	watch: {
		molEditingState: function () {
			if (this.molEditingState) {
				this.setProfileDataToEdit(this.molSubnavHash);
				this.photoPreviews = [];
			}
		},
		molSubnavHash: function () {
			this.resetFormErrors();
		},
		media: function () {
			this.draggablePhotos = JSON.parse(JSON.stringify(this.media));
		},
	},
};
</script>
