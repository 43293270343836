<template>
	<div v-if="newBooking && mainContact && mainSpace">
		<PriceForm
			:formErrors="formErrors"
			:newBooking="priceBooking"
			:guestChoices="guestChoices"
			:partyTypeChoices="partyTypeChoices"
		/>
		<FormGridWrapper>
			<template slot="right">
				<div class="flex flex-row-reverse w-full">
					<Button @click.native="getPrices()" class="flex-grow mt-3">{{
						$t("mol.bookADjForm.button.Calculate price")
					}}</Button>
				</div>
			</template>
		</FormGridWrapper>
		<SlideOutPanel :panelOpen="showPrices">
			<template slot="innerContent">
				<div id="bookNow" class="scroll-m-[100px]">
					<div class="absolute right-0 z-50">
						<button
							@click="closePricesPanel()"
							class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
						>
							<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
						</button>
					</div>
					<PageTitle
						:title="componentTitle"
						:subTitle="componentSubTitle"
						:breadcrumbs="componentBreadcrumbs"
						class="transition-all duration-500 off-delay-150 max-h-[1000px]"
						:class="{'!max-h-[50px] opacity-0 py-0 !mb-0': hidePageTitle}"
					/>
					<div
						v-if="hasPrices"
						class="transition-all duration-500 off-delay-150 max-h-[2000px]"
						:class="{'!max-h-[0px] opacity-0 py-0 !mb-0 overflow-hidden': thankYou}"
					>
						<DjClassBlock
							v-for="(djClass, x) in djClasses"
							:key="x"
							:djClass="djClass"
							@djClassChosen="chooseDjClass"
							:chosenDjClass="newBooking.djClass"
						/>
						<div
							:class="{'!max-h-0 opacity-0 ': classChoiceHasBeenMade}"
							class="max-h-[1350px] relative transition-all duration-500 delay-300"
						>
							<div class="bg-slate-200 dark:bg-slate-900 p-4 rounded-lg">
								<FormGridWrapper>
									<template #right>
										<div class="text-slate-600 dark:text-slate-600 text-base md:text-lg md:mb-2">
											{{ $t("mol.bookADjForm.title.Change event details") }}
										</div>
									</template>
								</FormGridWrapper>
								<PriceForm
									:formErrors="formErrors"
									:newBooking="priceBooking"
									:guestChoices="guestChoices"
									:partyTypeChoices="partyTypeChoices"
								/>
								<FormGridWrapper>
									<template slot="right">
										<div class="flex flex-row-reverse w-full">
											<Button @click.native="getPrices()" class="flex-grow">{{
												$t("mol.bookADjForm.button.Calculate price again")
											}}</Button>
										</div>
									</template>
								</FormGridWrapper>
							</div>
						</div>
						<CollapsableStepBlock
							:stepTitle="$t('mol.bookADjForm.title.Event details')"
							@reopen="editEventDetails()"
							:stepCompleted="eventDetailsConfirmed"
							:previousStepCompleted="classChoiceHasBeenMade"
							:stepNumber="1"
							:of="3"
						>
							<DataItem
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Event date')"
								:dataValue="$date(newBooking.date).format('DD/MM/YYYY')"
							/>
							<DataItem
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Event times')"
								:dataValue="newBooking.startTime + ' - ' + newBooking.endTime"
							/>
							<DataItem
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Guests')"
								:dataValue="chosenGuestCount.label"
							/>
							<DataItem
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Event type')"
								:dataValue="chosenPartyType.label"
							/>
							<div class="flex mt-3 flex-row-reverse">
								<Button type="green" @click.native="confirmEventDetails()" class="flex-grow !mb-0">{{
									$t("mol.bookADjForm.button.Book now")
								}}</Button>
								<Button type="previous" @click.native="newBooking.djClass = ''" class="!mb-0 mr-2"></Button>
							</div>
						</CollapsableStepBlock>
						<CollapsableStepBlock
							:stepTitle="$t('mol.bookADjForm.title.Venue details')"
							@reopen="editVenueDetails()"
							:stepCompleted="venueDetailsConfirmed"
							:previousStepCompleted="eventDetailsConfirmed"
							:stepNumber="2"
							:of="3"
						>
							<DataItem :dataLabel="$t('mol.bookADjForm.label.Address')" alignLabelRight>
								{{ location.BillingStreet }}<br />{{ location.BillingPostalCode }} {{ location.BillingCity }}<br />{{
									location.BillingCountry
								}}
							</DataItem>

							<InputItem
								v-if="contactCount > 1"
								v-model="newBooking.contact"
								inputName="Location_Contact__c"
								inputType="select"
								:inputLabel="$t('mol.bookADjForm.label.Venue contact')"
								placeholder="Choose a venue contact"
								:choices="contactChoices"
							/>
							<DataItem
								v-else
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Venue contact')"
								:dataValue="contacts[0].FirstName + ' ' + contacts[0].LastName"
							/>
							<InputItem
								v-if="spaceCount > 1"
								v-model="newBooking.space"
								inputName="Location_Space__c"
								inputType="select"
								placeholder="Choose a venue space"
								:inputLabel="$t('mol.bookADjForm.label.Venue space')"
								:choices="spaceChoices"
							/>
							<DataItem
								v-else
								alignLabelRight
								:dataLabel="$t('mol.bookADjForm.label.Venue space')"
								:dataValue="spaces[0].Space_Name__c"
							/>
							<FormGridWrapper>
								<template slot="right">
									<Alert>{{ $t("mol.bookADjForm.string.Edit booking after completion") }}</Alert>
									<div class="flex flex-row-reverse w-full">
										<Button type="green" @click.native="confirmVenueDetails" class="flex-grow !mb-0">{{
											$t("mol.general.button.Continue")
										}}</Button>
										<Button type="previous" @click.native="eventDetailsConfirmed = false" class="!mb-0 mr-2"></Button>
									</div>
								</template>
							</FormGridWrapper>
						</CollapsableStepBlock>
						<CollapsableStepBlock
							:stepTitle="$t('mol.bookADjForm.title.Billing details')"
							@reopen="editBillingDetails()"
							:stepNumber="3"
							:of="3"
							:stepCompleted="billingDetailsConfirmed"
							:previousStepCompleted="venueDetailsConfirmed"
							lastStep
						>
							<DataItem :dataLabel="$t('mol.bookADjForm.label.Billing address')" alignLabelRight>
								{{ newBooking.billingStreet }}<br />{{ newBooking.billingPostalCode }} {{ newBooking.billingCity
								}}<br />{{ newBooking.billingCountry }}
							</DataItem>
							<!-- <FormGridWrapper>
								<template slot="right">
									<Ahref @click.native="openBillingAddressPanel()">{{
										$t("mol.bookADjForm.string.Different billing address?")
									}}</Ahref>
								</template>
							</FormGridWrapper> -->
							<ContentTitle adaptForm class="mt-5">{{ $t("mol.bookADjForm.title.Billing method") }} </ContentTitle>
							<InputItem
								v-model="newBooking.billingMethod"
								inputName="BillingMethod"
								inputType="radio"
								:inputLabel="$t('mol.bookADjForm.label.Billing preference')"
								:gridColsMd="2"
								:choices="[
									{
										value: 'now',
										label: $t('mol.bookADjForm.label.Settle invoice immediately'),
										sublabel: $t('mol.bookADjForm.label.Receive and settle immediately'),
									},
									{
										value: 'later',
										label: $t('mol.bookADjForm.label.Settle invoice later'),
										sublabel: $t('mol.bookADjForm.label.Receive and settle after event'),
									},
								]"
								:error="billingMethodError"
								:errorMessage="$t('mol.bookADjForm.message.Please choose a billing preference')"
								@click.native="billingMethodError = false"
							/>
							<FormGridWrapper>
								<template slot="right">
									<div class="flex flex-row-reverse w-full mt-3">
										<Button @click.native="confirmBillingDetails" class="flex-grow" type="green">{{
											$t("mol.bookADjForm.button.Complete booking")
										}}</Button>
										<Button type="previous" @click.native="venueDetailsConfirmed = false" class="!mb-0 mr-2"></Button>
									</div>
								</template>
							</FormGridWrapper>
						</CollapsableStepBlock>
					</div>
					<!-- Thank you -->
					<div
						class="transition-all duration-500 delay-500 max-h-[0px] opacity-0"
						:class="{'!max-h-[2000px] !opacity-100': thankYou}"
					>
						<p class="pb-3 md:pb-5 mb-3 md:mb-5">
							{{ $t("mol.bookADjForm.string.View your booking") }}
							<router-link class="inline-block" :to="{name: 'DJBookings', hash: '#' + createdBookingId}"
								><Ahref>{{ $t("mol.bookADjForm.label.here") }}</Ahref></router-link
							>.
						</p>
						<div
							class="flex items-center justify-start border-b border-slate-200 dark:border-slate-600 pb-3 md:pb-5 mb-3 md:mb-5"
						>
							<div class="mr-2 md:mr-4">
								<font-awesome-icon
									:icon="['fal', 'calendar-day']"
									class="w-7 h-7 md:w-8 md:h-8 bg-tdc-blue-500/20 p-3 rounded-full text-tdc-blue-500"
									fa-fw
								/>
							</div>
							<div>
								{{
									$t("mol.bookADjForm.string.Your has dj been booked for this day", {
										djclass: chosenDJClass.name,
										date: $date(newBooking.date).format("DD/MM/YYYY"),
									})
								}}
							</div>
						</div>
						<div
							class="flex items-center justify-start border-b border-slate-200 dark:border-slate-600 pb-3 md:pb-5 mb-3 md:mb-5"
						>
							<div class="mr-2 md:mr-4">
								<font-awesome-icon
									:icon="['fal', 'envelope']"
									class="w-7 h-7 md:w-8 md:h-8 bg-tdc-blue-500/20 p-3 rounded-full text-tdc-blue-500"
									fa-fw
								/>
							</div>
							<div>
								{{
									$t("mol.bookADjForm.string.We have sent a confirmation email", {
										email: location.Email__c,
									})
								}}
							</div>
						</div>
						<div class="flex items-center justify-start">
							<div class="mr-2 md:mr-4">
								<font-awesome-icon
									:icon="['fal', 'file-arrow-down']"
									class="w-7 h-7 md:w-8 md:h-8 bg-tdc-blue-500/20 p-3 rounded-full text-tdc-blue-500"
									fa-fw
								/>
							</div>
							<div v-if="newBooking.billingMethod == 'later'">
								{{ $t("mol.bookADjForm.string.You will receive an invoice for the full amount after the event") }}
							</div>
							<div v-if="newBooking.billingMethod == 'now'">
								{{
									$t("mol.bookADjForm.string.Download and settle the invoice immediately", {
										email: location.Email__c,
									})
								}}
							</div>
						</div>
						<div class="mt-7 flex flex-row-reverse">
							<router-link class="ml-2" :to="{name: 'DJBookings', hash: '#' + createdBookingId}">
								<Button class="flex-grow">{{ $t("mol.bookADjForm.button.View booking") }}</Button>
							</router-link>
							<Button
								class="flex-grow"
								type="slate"
								@click.native="closePricesPanel"
								arrowPosition="before"
								icon="arrow-left"
							>
								{{ $t("mol.bookADjForm.button.Return to prices") }}
							</Button>
						</div>
					</div>
					<BookingIncludes
						whatWeDo
						:class="{'!max-h-[1000px] !mt-9 !opacity-100': showWhatWeDo}"
						class="transition-all duration-500 off-delay-150 max-h-0 opacity-0 !py-0 mb-0 overflow-hidden"
					/>
					<div
						:class="{'!max-h-[1000px] !mt-9 !opacity-100': showWhatYouGet}"
						class="transition-all duration-500 off-delay-150 max-h-0 opacity-0 !py-0 mb-0 overflow-hidden"
					>
						<ContentTitle> {{ $t("mol.bookADjForm.title.Everything for your event") }} </ContentTitle>
						<BookingIncludes whatYouGet />
					</div>
				</div>
			</template>
		</SlideOutPanel>
		<SlideOutPanel :panelOpen="changeBillingAddress" zIndexClass="z-50">
			<template slot="innerContent">
				<div class="absolute right-0 z-50">
					<button
						@click="closeBillingAddressPanel()"
						class="top-11 left-1 inline-flex items-center p-2 ml-1 text-sm text-slate-100 bg-transparent rounded-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-slate-600 dark:text-slate-100 dark:hover:bg-slate-600 dark:focus:ring-slate-600"
					>
						<font-awesome-icon :icon="['far', 'times']" class="text-slate-400 h-6 w-6" />
					</button>
				</div>
				<ContentTitle adaptForm id="billingAddressForm"
					>{{ $t("mol.bookADjForm.title.Billing address") }}
				</ContentTitle>
				<InputItem
					v-model="newBillingAddress.billingStreet"
					inputName="BillingStreet"
					:inputLabel="$t('mol.bookADjForm.label.Address')"
					:error="billingAddressFormErrors.billingAddressErrorbillingStreet"
					:errorMessage="$t('mol.bookADjForm.message.Please enter an address')"
					@keypress="billingAddressFormErrors.billingAddressErrorbillingStreet = false"
				/>
				<InputItem
					v-model="newBillingAddress.billingPostalCode"
					inputName="BillingPostalCode"
					:inputLabel="$t('mol.bookADjForm.label.Postal code')"
					:error="billingAddressFormErrors.billingAddressErrorbillingPostalCode"
					:errorMessage="$t('mol.bookADjForm.message.Please enter a postal code')"
					@keypress="billingAddressFormErrors.billingAddressErrorbillingPostalCode = false"
				/>
				<InputItem
					v-model="newBillingAddress.billingCity"
					inputName="BillingCity"
					:inputLabel="$t('mol.bookADjForm.label.City')"
					:error="billingAddressFormErrors.billingAddressErrorbillingCity"
					:errorMessage="$t('mol.bookADjForm.message.Please enter a city')"
					@keypress="billingAddressFormErrors.billingAddressErrorbillingCity = false"
				/>
				<InputItem
					v-model="newBillingAddress.billingCountry"
					inputName="BillingCountry"
					:inputLabel="$t('mol.bookADjForm.label.Country')"
					:error="billingAddressFormErrors.billingAddressErrorbillingCountry"
					:errorMessage="$t('mol.bookADjForm.message.Please choose a country')"
					@keypress="billingAddressFormErrors.billingAddressErrorbillingCountry = false"
					extraSpaceBottom
				/>
				<FormGridWrapper>
					<template slot="right">
						<div class="flex flex-row w-full">
							<Button
								@click.native="saveNewBillingAddress()"
								class="mr-2 flex-grow"
								type="save"
								icon="check"
								:label="$t('mol.general.button.Save')"
							/>
							<Button @click.native="closeBillingAddressPanel()" type="cancel" />
						</div>
					</template>
				</FormGridWrapper>
			</template>
		</SlideOutPanel>
		<SlideOutPanel :panelOpen="confirmExitPanel" zIndexClass="z-50">
			<template slot="innerContent">
				<div class="h-full flex-col flex align-middle">
					<div class="mb-3 font-bold">
						{{ $t("mol.bookADjForm.title.Are you sure you want to cancel your booking") }}
					</div>
					<div class="mb-3">
						{{ $t("mol.bookADjForm.string.Your booking details will not be saved") }}
					</div>
					<div class="flex flex-row">
						<Button
							@click.native="closePricesPanel(true)"
							type="red"
							icon="trash"
							:label="$t('mol.bookADjForm.button.Cancel booking')"
						/>
						<Button
							@click.native="closeConfirmExitPanel()"
							class="ml-2 flex-grow"
							type="success"
							:label="$t('mol.bookADjForm.button.Continue with booking')"
						/>
					</div>
				</div>
			</template>
		</SlideOutPanel>
		<DisableTrengoChat v-if="disableTrengoChat" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import PriceForm from "@/components/location/PriceForm";
import CollapsableStepBlock from "@/components/location/CollapsableStepBlock";
import DataItem from "@/components/location/DataItem";
import InputItem from "@/components/location/InputItem";
import SlideOutPanel from "@/components/location/SlideOutPanel";
import PageTitle from "@/components/location/PageTitle";
import DjClassBlock from "@/components/location/DjClassBlock";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import Button from "@/components/location/Button";
import BookingIncludes from "@/components/location/BookingIncludes";
import ContentTitle from "@/components/location/ContentTitle";
import Alert from "@/components/location/Alert";
import Ahref from "@/components/location/Ahref";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";
import {copyObject, parseIsoDatetime, getLocalDateString} from "@/utilities";

export default {
	name: "LocationBookADJ",
	data() {
		return {
			bookingStep: "priceCalculator",
			hasPrices: false,
			showPrices: false,
			eventDetailsConfirmed: false,
			venueDetailsConfirmed: false,
			billingDetailsConfirmed: false,
			thankYou: false,
			newBooking: null,
			priceBooking: null,
			newBillingAddress: null,
			chosenDJClass: [],
			formErrors: this.initiateCalculatorErrors(),
			billingAddressFormErrors: this.initiateBillingAddressFormErrors(),
			billingMethodError: false,
			changeBillingAddress: false,
			confirmExitPanel: false,
			createdBookingId: null,
		};
	},
	components: {
		PriceForm,
		CollapsableStepBlock,
		DataItem,
		InputItem,
		SlideOutPanel,
		FormGridWrapper,
		DjClassBlock,
		Button,
		PageTitle,
		BookingIncludes,
		ContentTitle,
		Alert,
		Ahref,
		DisableTrengoChat,
	},
	computed: {
		...mapGetters({
			location: "getLocationInformation",
			spaces: "getLocationSpaces",
			mainSpace: "getLocationMainSpaceDetails",
			contacts: "getLocationContacts",
			mainContact: "getLocationMainContactDetails",
			spaceCount: "getLocationSpaceCount",
			contactCount: "getLocationContactCount",
			pricing: "getPricing",
			tdcPhoneNumber: "getTdcPhoneNumber",
		}),
		componentTitle() {
			if (this.thankYou) {
				return this.$t("mol.bookADjForm.title.Thank you");
			}
			return this.$t("mol.bookADjForm.title.Book a DJ");
		},
		componentSubTitle() {
			if (this.thankYou) {
				return this.$t("mol.bookADjForm.title.Thank you for this DJ booking en your trust in us");
			}
			return this.$t("mol.bookADjForm.title.Choose and book the right DJ for you");
		},
		componentBreadcrumbs() {
			if (this.bookingStep == "prices") {
				return [this.$t("mol.bookADjForm.title.Book a DJ"), this.$t("mol.bookADjForm.title.Prices")];
			}
			if (this.bookingStep == "eventDetails") {
				return [this.$t("mol.bookADjForm.title.Book a DJ"), this.$t("mol.bookADjForm.title.Event details")];
			}
			if (this.bookingStep == "locationDetails") {
				return [this.$t("mol.bookADjForm.title.Book a DJ"), this.$t("mol.bookADjForm.title.Venue details")];
			}
			if (this.thankYou) {
				return [this.$t("mol.bookADjForm.title.Book a DJ"), this.$t("mol.bookADjForm.title.Thank you")];
			}
			return [this.$t("mol.bookADjForm.title.Book a DJ"), this.$t("mol.bookADjForm.title.Prices")];
		},
		djClasses() {
			const djClasses = [
				{
					name: this.$t("mol.djClass.title.Junior Class DJ"),
					id: "junior",
					stars: 1,
					slogan: this.$t("mol.djClass.usp.Best value"),
					usp1: this.$t("mol.djClass.usp.Beginner DJ"),
					usp2: this.$t("mol.djClass.usp.Decent reviews"),
					usp3: this.$t("mol.djClass.usp.PA and lighting equipment"),
					price: this.pricing && this.pricing.junior.exVatAmount,
					errorMessage: this.pricing && this.validValue(this.pricing.junior.message) ? this.pricing.junior.message : "",
				},
				{
					name: this.$t("mol.djClass.title.A Class DJ"),
					id: "a",
					stars: 3,
					slogan: this.$t("mol.djClass.usp.Most chosen"),
					usp1: this.$t("mol.djClass.usp.DJ with good experience"),
					usp2: this.$t("mol.djClass.usp.Scores 8 and higher"),
					usp3: this.$t("mol.djClass.usp.Recommended for weddings"),
					price: this.pricing && this.pricing["a-class"].exVatAmount,
					errorMessage:
						this.pricing && this.validValue(this.pricing["a-class"].message) ? this.pricing["a-class"].message : "",
				},
				{
					name: this.$t("mol.djClass.title.Premium Class DJ"),
					id: "premium",
					stars: 5,
					slogan: this.$t("mol.djClass.usp.Safest choice"),
					usp1: this.$t("mol.djClass.usp.The Best DJ", {country: this.location.BillingCountry}),
					usp2: this.$t("mol.djClass.usp.Scores 9 and higher"),
					usp3: this.$t("mol.djClass.usp.Recommended for weddings"),
					price: this.pricing && this.pricing.premium.exVatAmount,
					errorMessage:
						this.pricing && this.validValue(this.pricing.premium.message) ? this.pricing.premium.message : "",
				},
			];
			return djClasses;
		},
		contactChoices() {
			return this.contacts.map((contact) => ({value: contact.Id, label: contact.FirstName + " " + contact.LastName}));
		},
		spaceChoices() {
			return this.spaces.map((space) => ({value: space.Id, label: space.Space_Name__c}));
		},
		classChoiceHasBeenMade() {
			return this.newBooking.djClass != "";
		},
		showWhatWeDo() {
			return this.hasPrices && !this.classChoiceHasBeenMade;
		},
		showWhatYouGet() {
			return this.hasPrices && this.classChoiceHasBeenMade && !this.eventDetailsConfirmed;
		},
		hidePageTitle() {
			return this.eventDetailsConfirmed && !this.thankYou;
		},
		nextBookingStep() {
			let bookingStep = this.bookingStep;
			if (bookingStep == "priceCalculator") return "prices";
			else if (bookingStep == "prices") return "eventDetails";
			else if (bookingStep == "eventDetails") return "locationDetails";
			else if (bookingStep == "locationDetails") return "billingDetails";
			else if (bookingStep == "billingDetails") return "thankYou";
			else if (bookingStep == "thankYou") return "priceCalculator";
			return "prices";
		},
		previousBookingStep() {
			let bookingStep = this.bookingStep;
			if (bookingStep == "priceCalculator") return "priceCalculator";
			else if (bookingStep == "eventDetails") return "prices";
			else if (bookingStep == "locationDetails") return "eventDetails";
			else if (bookingStep == "billingDetails") return "locationDetails";
			else if (bookingStep == "thankYou") return "billingDetails";
			return "priceCalculator";
		},
		disableTrengoChat() {
			return this.hasPrices;
		},
		chosenPartyType() {
			if (this.validValue(this.newBooking.partyType)) {
				return this.partyTypeChoices.find((type) => type.value === this.newBooking.partyType);
			}
			return "";
		},
		chosenGuestCount() {
			if (this.validValue(this.newBooking.guests)) {
				return this.guestChoices.find((type) => type.value == this.newBooking.guests);
			}
			return "";
		},
		mainContactId() {
			return this.mainContact.Id;
		},
		guestChoices() {
			return [
				{value: 40, label: this.$t("mol.bookADjForm.label.40 guests max")},
				{value: 50, label: this.$t("mol.bookADjForm.label.40 to 50 guests")},
				{value: 75, label: this.$t("mol.bookADjForm.label.50 to 75 guests")},
				{value: 100, label: this.$t("mol.bookADjForm.label.75 to 100 guests")},
				{value: 150, label: this.$t("mol.bookADjForm.label.100 to 150 guests")},
				{value: 200, label: this.$t("mol.bookADjForm.label.150 to 200 guests")},
				{value: 300, label: this.$t("mol.bookADjForm.label.200 to 300 guests")},
				{value: 301, label: this.$t("mol.bookADjForm.label.300 plus guests")},
			];
		},
		partyTypeChoices() {
			return [
				{value: "bruiloftsfeest", label: this.$t("mol.bookADjForm.label.Wedding party")},
				{value: "verjaardagsfeest", label: this.$t("mol.bookADjForm.label.Birthday party")},
				{value: "bedrijfsfeest", label: this.$t("mol.bookADjForm.label.Business party")},
				{value: "feest", label: this.$t("mol.bookADjForm.label.Other")},
			];
		},
		pricingErrorCode() {
			if (this.hasPrices) {
				if (
					this.pricing["junior"].errorCode &&
					this.pricing["a-class"].errorCode &&
					this.pricing["premium"].errorCode &&
					this.pricing["junior"].errorCode === this.pricing["a-class"].errorCode &&
					this.pricing["a-class"].errorCode === this.pricing["premium"].errorCode
				) {
					return this.pricing["junior"].errorCode;
				}
			}
			return "";
		},
		tdcWhatsAppNumber() {
			return this.$t("mol.general.contact.whatsapp number");
		},
	},
	methods: {
		editEventDetails() {
			this.eventDetailsConfirmed = false;
			this.venueDetailsConfirmed = false;
			this.billingDetailsConfirmed = false;
		},
		editVenueDetails() {
			this.venueDetailsConfirmed = false;
			this.billingDetailsConfirmed = false;
		},
		initiateCalculatorErrors() {
			return {
				hasErrors: false,
				errorMessage: "",
				bookingErrorDate: false,
				bookingErrorDateMessage: "",
				bookingErrorPartyType: false,
				bookingErrorPartyTypeMessage: "",
				bookingErrorPartyTimes: false,
				bookingErrorPartyTimesMessage: "",
				bookingErrorGuestCount: false,
				bookingErrorGuestCountMessage: "",
			};
		},
		initiateBillingAddressFormErrors() {
			return {
				hasErrors: false,
				billingAddressErrorbillingStreet: false,
				billingAddressErrorrbillingPostalCode: false,
				billingAddressErrorrbillingCity: false,
				billingAddressErrorrbillingCountry: false,
			};
		},
		initiateNewBooking() {
			return {
				//DATE MUST BE EMPTY, FOR TESTING
				//date: "2023-12-12",
				date: "",
				//PARTYTYPE MUST BE EMPTY, FOR TESTING
				//partyType: "feest",
				partyType: "",
				startTime: "20:00",
				endTime: "00:00",
				guests: "75",
				djClass: "",
				contact: this.mainContact.Id,
				space: this.mainSpace.Id,
				billingStreet: this.location.BillingStreet,
				billingPostalCode: this.location.BillingPostalCode,
				billingCity: this.location.BillingCity,
				billingCountry: this.location.BillingCountry,
				billingMethod: null,
			};
		},
		initiateNewBillingAddress() {
			return {
				billingStreet: "",
				billingPostalCode: "",
				billingCity: "",
				billingCountry: "",
			};
		},
		getPrices() {
			this.validatePriceForm();
			console.log(this.newBooking);
			if (this.formErrors.hasErrors) {
				return;
			}
			const duration =
				(parseFloat(this.priceBooking.endTime.replace(":30", ".5")) -
					parseFloat(this.priceBooking.startTime.replace(":30", ".5")) +
					24) %
				24;
			const parts = window.location.hostname.split(".");
			let tld = parts[parts.length - 1];
			if (tld.length != 2) {
				tld = "nl";
			}
			const query = {
				partyDate: this.priceBooking.date,
				duration: duration,
				nGuests: this.priceBooking.guests,
				partyType: this.priceBooking.partyType,
				business: true,
				countryCode: this.location.BillingCountryCode,
				domain: tld,
				locale: this.location.language_region__c,
			};
			this.newBooking = copyObject(this.priceBooking);
			this.$store
				.dispatch("getPricing", query)
				.then(() => {
					this.hasPrices = true;
					this.validatePrices();
					if (this.formErrors.hasErrors) {
						return;
					}
					this.scrollToTop();
					this.showPrices = true;
					this.bookingStep = this.nextBookingStep;
				})
				.catch((err) => {
					//.......
					console.error("Error", err);
				});
		},
		chooseDjClass(choice) {
			this.newBooking.djClass = choice.id;
			this.chosenDJClass = choice;
			this.bookingStep = this.nextBookingStep;
		},
		confirmEventDetails() {
			this.eventDetailsConfirmed = true;
			this.bookingStep = this.nextBookingStep;
		},
		confirmVenueDetails() {
			this.venueDetailsConfirmed = true;
			this.bookingStep = this.nextBookingStep;
		},
		confirmBillingDetails() {
			this.validateBillingMethod();
			if (this.billingMethodError) {
				return;
			}
			const payload = {
				action: "createBooking",
				data: {
					begin_time__c: this.newBooking.startTime,
					booking_type__c: this.newBooking.partyType,
					djClass: this.newBooking.djClass,
					end_time__c: this.newBooking.endTime,
					Location_Contact__c: this.newBooking.contact,
					Location_Space__c: this.newBooking.space,
					number_of_guests__c: this.newBooking.guests,
					Party_Date__c: getLocalDateString(parseIsoDatetime(this.newBooking.date)),
					billing: this.newBooking.billingMethod,
				},
			};
			this.$store
				.dispatch("createBooking", payload)
				.then((booking) => {
					this.createdBookingId = booking.Id;
					this.billingDetailsConfirmed = true;
					this.bookingStep = this.nextBookingStep;
					this.thankYou = true;
				})
				.catch((err) => {
					console.error("Error", err);
				});
		},
		resetDjClass() {
			this.newBooking.djClass = "";
			this.chosenDJClass = [];
			this.bookingStep = "priceCalculator";
		},
		resetBooking() {
			this.priceBooking = this.initiateNewBooking();
			this.newBooking = this.initiateNewBooking();
		},
		resetNewBillingAddress() {
			this.newBillingAddress = this.initiateNewBillingAddress();
		},
		resetFormErrors() {
			this.formErrors = this.initiateCalculatorErrors();
		},
		resetNewBillingAddressFormErrors() {
			this.billingAddressFormErrors = this.initiateBillingAddressFormErrors();
		},
		resetBillingMethod() {
			this.newBooking.billingMethod = null;
		},
		resetBillingMethodError() {
			this.billingMethodError = false;
		},
		validValue(value) {
			return value !== undefined && value !== null && value != "";
		},
		validatePriceForm() {
			this.resetFormErrors();
			if (!this.validValue(this.priceBooking.date)) {
				this.formErrors.bookingErrorDate = true;
				this.formErrors.bookingErrorDateMessage = this.$t("mol.bookADjForm.message.Please choose a valid date");
			}
			if (!this.validValue(this.priceBooking.partyType)) {
				this.formErrors.bookingErrorPartyType = true;
				this.formErrors.bookingErrorPartyTypeMessage = this.$t("mol.bookADjForm.message.Please choose an event type");
			}

			if (this.priceBooking.date == "") {
				this.formErrors.hasErrors = true;
				document.getElementById("date").scrollIntoView({behavior: "smooth"});
			} else if (this.priceBooking.partyType == "") {
				this.formErrors.hasErrors = true;
				document.getElementById("partyType").scrollIntoView({behavior: "smooth"});
			}
		},
		validatePrices() {
			this.resetFormErrors();
			if (this.pricingErrorCode !== "") {
				this.formErrors.hasErrors = true;
			}
			if (this.pricingErrorCode === "DateChosen") {
				this.formErrors.bookingErrorDate = true;
				this.formErrors.bookingErrorDateMessage = this.$t("common.pricing.message." + this.pricing["junior"].message, {
					phone: this.tdcPhoneNumber,
					whatsapp: this.tdcWhatsAppNumber,
				});
			} else if (this.pricingErrorCode === "HoursChosen") {
				this.formErrors.bookingErrorPartyTimes = true;
				this.formErrors.bookingErrorPartyTimesMessage = this.$t(
					"common.pricing.message." + this.pricing["junior"].message,
					{phone: this.tdcPhoneNumber, whatsapp: this.tdcWhatsAppNumber}
				);
			} else if (this.pricingErrorCode === "GuestCount") {
				this.formErrors.bookingErrorGuestCount = true;
				this.formErrors.bookingErrorGuestCountMessage = this.$t(
					"common.pricing.message." + this.pricing["junior"].message,
					{phone: this.tdcPhoneNumber, whatsapp: this.tdcWhatsAppNumber}
				);
			}
		},
		validateNewBillingAddress() {
			this.resetNewBillingAddressFormErrors();
			if (!this.validValue(this.newBillingAddress.billingStreet)) {
				this.billingAddressFormErrors.billingAddressErrorbillingStreet = true;
			}
			if (!this.validValue(this.newBillingAddress.billingPostalCode)) {
				this.billingAddressFormErrors.billingAddressErrorbillingPostalCode = true;
			}
			if (!this.validValue(this.newBillingAddress.billingCity)) {
				this.billingAddressFormErrors.billingAddressErrorbillingCity = true;
			}
			if (!this.validValue(this.newBillingAddress.billingCountry)) {
				this.billingAddressFormErrors.billingAddressErrorbillingCountry = true;
			}

			if (this.newBillingAddress.billingStreet == "") {
				this.billingAddressFormErrors.hasErrors = true;
				document.getElementById("BillingStreet").scrollIntoView({behavior: "smooth"});
			} else if (this.newBillingAddress.billingPostalCode == "") {
				this.billingAddressFormErrors.hasErrors = true;
				document.getElementById("BillingPostalCode").scrollIntoView({behavior: "smooth"});
			} else if (this.newBillingAddress.billingCity == "") {
				this.billingAddressFormErrors.hasErrors = true;
				document.getElementById("BillingCity").scrollIntoView({behavior: "smooth"});
			} else if (this.newBillingAddress.billingCountry == "") {
				this.billingAddressFormErrors.hasErrors = true;
				document.getElementById("BillingCountry").scrollIntoView({behavior: "smooth"});
			}
		},
		validateBillingMethod() {
			this.resetBillingMethodError();
			if (this.newBooking.billingMethod === null) {
				this.billingMethodError = true;
				document.getElementById("BillingMethod").scrollIntoView({behavior: "smooth"});
			}
		},
		closePricesPanel(force) {
			if (
				(this.bookingStep === "eventDetails" ||
					this.bookingStep === "locationDetails" ||
					this.bookingStep === "billingDetails") &&
				!force
			) {
				this.openConfirmExitPanel();
			} else {
				this.closeConfirmExitPanel();
				this.hasPrices = false;
				this.showPrices = false;
				this.eventDetailsConfirmed = false;
				this.venueDetailsConfirmed = false;
				this.billingDetailsConfirmed = false;
				this.thankYou = false;
				//this.resetBooking();
				this.resetBillingMethod();
				this.resetNewBillingAddress();
				this.resetDjClass();
				this.resetFormErrors();
				this.resetBillingMethodError();
			}
		},
		openBillingAddressPanel() {
			this.resetNewBillingAddressFormErrors();
			this.resetNewBillingAddress();
			this.changeBillingAddress = true;
		},
		closeBillingAddressPanel() {
			this.changeBillingAddress = false;
		},
		saveNewBillingAddress() {
			this.validateNewBillingAddress();
			if (!this.billingAddressFormErrors.hasErrors) {
				this.newBooking.billingStreet = this.newBillingAddress.billingStreet;
				this.newBooking.billingPostalCode = this.newBillingAddress.billingPostalCode;
				this.newBooking.billingCity = this.newBillingAddress.billingCity;
				this.newBooking.billingCountry = this.newBillingAddress.billingCountry;
				this.resetNewBillingAddress();
				this.closeBillingAddressPanel();
			}
		},
		openConfirmExitPanel() {
			this.confirmExitPanel = true;
		},
		closeConfirmExitPanel() {
			this.confirmExitPanel = false;
		},
		scrollToTop() {
			window.scrollTo(0, 0);
			document.getElementById("bookNow").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		},
	},
	mounted() {
		this.$store.dispatch("getLocationInformation").then(() => {
			this.newBillingAddress = this.initiateNewBillingAddress();
			// dit mag pas nadat de default space en contact bekend zijn
			this.resetBooking();
		});
	},
};
</script>
