<template>
	<div>
		<SocialReviews class="row-cols-2 row-cols-sm-4 mb-4" />
		<b-card class="mb-4">
			<div class="d-flex border-bottom py-2 mb-3">
				<div style="font-size: 32px" class="mr-2 font-weight-bold">9,2</div>
				<div class="d-flex flex-column">
					<StarRating :rating="9" class="stars h4 mb-0" />
					<small class="text-muted">gebasseerd op {{ reviewTotals.NumberOfReviews__c }} klantervaringen</small>
				</div>
			</div>
			<div class="d-flex flex-column" style="cursor: pointer">
				<div
					@click="getReviewsWithStars(5)"
					class="d-flex align-items-center w-100 alert mb-0 px-2 py-1 rating-option"
					:class="{'alert-warning': form.stars == 5}"
				>
					<StarRating :rating="10" class="stars flex-shrink-1 flex-nowrap" />
					<b-progress
						variant="success"
						style="height: 5px"
						class="mx-2 flex-grow-1"
						:value="starScore(5)"
						max="100"
					></b-progress>
					<small class="text-right text-primary" style="width: 30px" v-text="starScore(5) + ' %'"></small>
				</div>
				<div
					@click="getReviewsWithStars(4)"
					class="d-flex align-items-center w-100 alert mb-0 px-2 py-1 rating-option"
					:class="{'alert-warning': form.stars == 4}"
				>
					<StarRating :rating="8" class="stars flex-shrink-1 flex-nowrap" />
					<b-progress
						variant="success"
						style="height: 5px"
						class="mx-2 flex-grow-1 mb-0"
						:value="starScore(4)"
						max="100"
					></b-progress>
					<small class="text-right text-primary" style="width: 30px" v-text="starScore(4) + ' %'"></small>
				</div>
				<div
					@click="getReviewsWithStars(3)"
					class="d-flex align-items-center w-100 alert mb-0 px-2 py-1 rating-option"
					:class="{'alert-warning': form.stars == 3}"
				>
					<StarRating :rating="6" class="stars flex-shrink-1 flex-nowrap" />
					<b-progress
						variant="success"
						style="height: 5px"
						class="mx-2 flex-grow-1 mb-0"
						:value="starScore(3)"
						max="100"
					></b-progress>
					<small class="text-right text-primary" style="width: 30px" v-text="starScore(3) + ' %'"></small>
				</div>
				<div
					@click="getReviewsWithStars(2)"
					class="d-flex align-items-center w-100 alert mb-0 px-2 py-1 rating-option"
					:class="{'alert-warning': form.stars == 2}"
				>
					<StarRating :rating="4" class="stars flex-shrink-1 flex-nowrap" />
					<b-progress
						variant="success"
						style="height: 5px"
						class="mx-2 flex-grow-1"
						:value="starScore(2)"
						max="100"
					></b-progress>
					<small class="text-right text-primary" style="width: 30px" v-text="starScore(2) + ' %'"></small>
				</div>
				<div
					@click="getReviewsWithStars(1)"
					class="d-flex align-items-center w-100 alert mb-0 px-2 py-1 rating-option"
					:class="{'alert-warning': form.stars == 1}"
				>
					<StarRating :rating="2" class="stars" flex-shrink-1 flex-nowrap />
					<b-progress
						variant="success"
						style="height: 5px"
						class="mx-2 flex-grow-1"
						:value="starScore(1)"
						max="100"
					></b-progress>
					<small class="text-right text-primary" style="width: 30px" v-text="starScore(1) + ' %'"></small>
				</div>
			</div>
			<span class="d-flex justify-content-end my-2 mb-2">
				<small
					v-b-toggle.reviewExplanation
					class="btn-link btn-sm btn"
					@click.prevent="showReviewExplanation = !showReviewExplanation"
				>
					<span>Hoe worden onze klantervaringen verzameld?</span>
					<font-awesome-icon v-if="!showReviewExplanation" class="ml-2" :icon="['far', 'chevron-down']" />
					<font-awesome-icon v-if="showReviewExplanation" class="ml-2" :icon="['far', 'chevron-up']" />
				</small>
			</span>
			<b-collapse id="reviewExplanation">
				<small class="bg-light border p-2 d-block">
					Alle klantervaringen zijn gecontroleerd. Elke klant ontvangt een evaluatieformulier per e-mail. De
					e-mailadressen zijn altijd gecheckt en geverifieerd. Wij kunnen daarom alleen echte klantervaringen tonen.
					Evaluaties met een klacht of opmerking hebben een reactie van ons. Deze staat onder de klantervaring.
				</small>
			</b-collapse>
		</b-card>
		<b-overlay :show="isLoading" rounded="sm">
			<b-card class="mb-4">
				<b-modal
					id="filters"
					title="Filter reviews"
					ok-title-html="Filters toepassen"
					:ok-only="true"
					@ok="getReviews(true)"
					@hidden="getReviews(true)"
					scrollable
					header-bg-variant="light"
					footer-bg-variant="light"
					ok-variant="outline-primary"
				>
					<b-card class="mb-3">
						<b-form-group v-slot="{ariaDescribedby}" label="Feesttype">
							<b-form-checkbox
								v-for="partyType in partyTypes"
								v-model="form.partyType"
								:key="partyType.value"
								:value="partyType.value"
								:aria-describedby="ariaDescribedby"
								button
								size="sm"
								button-variant="light"
								name="check-button"
								class="d-block mb-1 mdc-radio-check"
							>
								{{ partyType.text }}
							</b-form-checkbox>
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group v-slot="{ariaDescribedby}" name="stars">
							<label>Aantal sterren</label>
							<b-form-checkbox
								v-for="star in stars"
								v-model="form.stars"
								:key="star.value"
								:value="star.value"
								:aria-describedby="ariaDescribedby"
								button
								size="sm"
								button-variant="light"
								name="check-button"
								class="d-flex mb-1 mdc-radio-check"
							>
								<StarRating
									:rating="star.value"
									:highestRating="5"
									class="stars flex-shrink-1 flex-nowrap d-inline-block mr-2"
								/>
								<span>{{ star.text }}</span>
							</b-form-checkbox>
						</b-form-group>
					</b-card>
					<b-card class="">
						<b-form-group v-slot="{ariaDescribedby}" name="reaction">
							<label>Reacties</label>
							<b-form-checkbox
								v-for="reactionChoice in reaction"
								v-model="form.reaction"
								:key="reactionChoice.value"
								:value="reactionChoice.value"
								:aria-describedby="ariaDescribedby"
								button
								size="sm"
								button-variant="light"
								name="radio-button"
								class="d-flex mb-1 mdc-radio-check"
							>
								<font-awesome-layers class="fa-lg mr-4" fixed-width>
									<font-awesome-icon
										:icon="['fal', 'ban']"
										:class="{'d-none': reactionChoice.with}"
										style="color: #b6bcc1"
										transform="grow-12"
									/>
									<font-awesome-icon :icon="['fas', 'comments']" class="text-muted" style="" transform="shrink-3" />
								</font-awesome-layers>
								{{ reactionChoice.text }}
							</b-form-checkbox>
						</b-form-group>
					</b-card>
				</b-modal>
				<label for="tags-basic">Gefilterd op:</label>
				<ul class="d-flex flex-wrap list-unstyled mb-0">
					<template v-for="filter in activeFilters">
						<li
							v-for="filter_item in filter"
							:key="filter_item.value"
							@click="removeFilter(filter_item)"
							class="border border-secondary mr-2 p-2 pl-3 mb-2 w-auto d-flex align-items-center badge badge-pill btn btn-outline-secondary"
							style="font-size: 14px; cursor: pointer"
						>
							<span v-text="filter_item.text"></span>
							<font-awesome-icon class="ml-2 text-danger" :icon="['fal', 'times']" />
						</li>
					</template>
					<li v-b-modal.filters>
						<span>
							<span
								style="font-size: 14px; cursor: pointer"
								class="border border-primary mr-3 p-2 pr-3 mb-2 w-auto d-flex align-items-center badge badge-pill btn btn-outline-primary"
							>
								<font-awesome-icon class="mr-2 ml-1" :icon="['far', 'sliders-h']" />
								<span class>Meer filters</span>
							</span>
						</span>
					</li>
				</ul>
			</b-card>
			<b-form-group class="mb-4 d-flex justify-content-end">
				<label for="sort-reviews" class="mr-2"
					>Sorteren <font-awesome-icon class="ml-2" :icon="['far', 'sort-alt']"
				/></label>
				<b-form-select
					id="sort-reviews"
					:plain="true"
					v-model="form.sort"
					@change="getReviews(true)"
					required
					class="d-inline-block w-auto"
					style="min-width: none !important"
				>
					<b-form-select-option value="newest">Nieuwste</b-form-select-option>
					<b-form-select-option value="oldest">Oudste</b-form-select-option>
					<b-form-select-option value="highest">Meeste sterren</b-form-select-option>
					<b-form-select-option value="lowest">Minste sterren</b-form-select-option>
				</b-form-select>
			</b-form-group>
			<Review v-for="review in reviews.evals" :review="review" :key="review.Id" />
			<template v-if="'total' in reviews && reviews.total > reviews.evals.length">
				<b-button block variant="primary" @click="nextPage()" class="w-auto mx-auto"> Toon 5 reviews meer </b-button>
			</template>
		</b-overlay>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import SocialReviews from "@/components/customer/SocialReviews";
import StarRating from "@/components/customer/StarRating";
import Review from "@/components/customer/Review";

export default {
	name: "Reviews",
	components: {
		SocialReviews,
		StarRating,
		Review,
	},
	computed: {
		...mapGetters({
			partyType: "getPartyType",
			hasGeo: "hasGeo",
			booking: "getBooking",
			reviews: "getCompanyReviews",
			reviewTotals: "getReviewTotals",
			isLoading: "getLoadingState",
		}),
		activeFilters() {
			var filters = [];
			if (this.form.partyType.length)
				filters.push(this.partyTypes.filter((type) => this.form.partyType.includes(type.value)));
			if (this.form.stars.length) filters.push(this.stars.filter((type) => this.form.stars.includes(type.value)));
			if (this.form.reaction.length)
				filters.push(this.reaction.filter((type) => this.form.reaction.includes(type.value)));
			if (this.form.location)
				filters.push([
					{text: "In de buurt van " + this.booking.Location_City_from_lookup__c, value: this.form.location},
				]);
			return filters;
		},
	},
	data() {
		return {
			showReviewExplanation: false,
			form: {
				partyType: [],
				location: "",
				stars: [],
				reaction: [],
				sort: "newest",
				limit: 5,
			},
			partyTypes: [
				{text: "Bruilofstfeesten", value: "bruiloftsfeest"},
				{text: "Verjaardagsfeesten", value: "verjaardagsfeest"},
				{text: "Bedrijfsfeesten", value: "bedrijfsfeest"},
				{text: "Andere feesten", value: "feest"},
			],
			stars: [
				{text: "5 sterren", value: 5},
				{text: "4 sterren", value: 4},
				{text: "3 sterren", value: 3},
				{text: "2 sterren", value: 2},
				{text: "1 ster", value: 1},
			],
			reaction: [
				{text: "Met reactie", value: "with_reaction", with: true},
				{text: "Zonder reactie", value: "without_reaction", with: false},
			],
		};
	},
	methods: {
		removeFilter(filter_item) {
			const filters = ["partyType", "stars", "reaction", "location"];
			filters.forEach((filter) => {
				if (filter == "location") {
					if (this.form.location == filter_item.value) this.form.location = "";
				} else {
					const index = this.form[filter].indexOf(filter_item.value);
					if (index > -1) {
						this.form[filter].splice(index, 1); // 2nd parameter means remove one item only
					}
				}
			});
			this.getReviews();
		},
		nextPage() {
			this.form.limit += 5;
			this.getReviews();
		},
		starScore(stars) {
			let number_of_reviews = stars > 1 ? this.reviewTotals["X" + stars + "Stars__c"] : this.reviewTotals.X1Star__c;
			if (number_of_reviews === 0) return 0;
			return Math.ceil((number_of_reviews / this.reviewTotals.NumberOfReviews__c) * 100);
		},
		getReviews(reset = false) {
			if (reset) this.form.limit = 5;
			this.$store.dispatch("getCompanyReviews", {
				action: "getEvaluations",
				data: this.form,
				setBooking: false,
			});
		},
		getReviewsWithStars(stars) {
			this.form.stars = [stars];
			this.form.partyType = [];
			this.form.location = "";
			this.form.reaction = [];
			this.form.sort = "newest";
			this.getReviews(true);
		},
	},
	mounted() {
		this.form.partyType = [this.partyType];
		this.form.location = this.hasGeo ? this.booking.Location_lookup__r.Location_City__c : "";
		if (Object.keys(this.reviews).length === 0) this.getReviews();
	},
};
</script>

<style lang="scss" scoped>
.rating-option {
	&:hover {
		background-color: #fff9e8;
	}
}
.mdc-radio-check ::v-deep .btn-light.focus:not(.active),
.mdc-radio-check ::v-deep .btn-light:not(.active):focus,
.mdc-radio-check ::v-deep .btn-light:not(.active):active {
	background-color: #f8f9fa !important;
	border-color: #f8f9fa !important;
	box-shadow: none;
	outline: none;
}
</style>
