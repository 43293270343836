<template>
	<div>
		{{ $t("moe.customerEvaluationHome.string.somethingWentwrong") }}
	</div>
</template>

<script>
export default {
	name: "Home",
};
</script>
