<template>
	<div v-if="quote">
		<DisableTrengoChat />
		<QuoteProgress
			v-if="!isOrderInProgress && quote.Status__c != 'Expired'"
			:step="1"
			:steps="4"
			:steps_type="'booking'"
		/>
		<Alert v-if="!isOrderInProgress && quote.Status__c != 'Expired'" alertType="info" dismissible class="pt-3 d-block">
			Jouw offerte blijft {{ daysAndHoursAndMinutesToTime }} geldig. Daarna kunnen prijzen omhoog gaan óf kan jouw datum
			volgeboekt zijn.
		</Alert>
		<div class="desktop-content-left-right-wrapper mb-4">
			<DjBlock
				template="DjBlockComplete"
				templateVariant="QuoteConfirmed"
				:quote="quote"
				:sf_name="quote.DJClass__c"
				:priceIncVat="quote.PriceIncVAT__c"
				:priceExcVat="quote.PriceExcVAT__c"
				:showDefaultCta="false"
				:showCustomCta="true"
				:hideAllLists="true"
			>
				<template slot="customCta">
					<QuoteExpiresOn
						v-if="!isOrderInProgress"
						:quote="quote"
						class="mb-4 d-flex justify-content-center justify-content-md-end"
					/>
					<span v-if="isOrderInProgress">
						<Alert v-if="quote.Status__c == 'Declined' && !isPaid" alertType="warning" reduced class="mb-n2">
							Je kunt deze offerte niet meer bevestigen omdat je hem hebt afgewezen. Ga naar jouw DJ boeking en
							<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
						</Alert>
						<Alert v-if="quote.Status__c != 'Accepted' && !isPaid" alertType="warning" reduced class="mb-n2">
							Je kunt deze offerte niet meer bevestigen omdat je een bevestigde DJ boeking hebt. Ga naar jouw DJ boeking
							en <router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
						</Alert>
						<Alert v-else-if="quote.Status__c != 'Accepted' && isPaid" alertType="info" reduced class="mb-n2">
							Je kunt deze offerte niet meer bevestigen omdat je een bevestigde DJ boeking hebt.
						</Alert>
						<Alert v-if="quote.Status__c == 'Accepted' && !isPaid" alertType="warning" reduced class="mb-n2">
							Deze offerte is al bevestigd. Ga naar jouw DJ boeking en
							<router-link :to="{name: 'Booking'}">betaal de waarborgsom</router-link>.
						</Alert>
						<Alert v-else-if="quote.Status__c == 'Accepted' && isPaid" alertType="info" reduced class="mb-n2">
							Deze offerte is al bevestigd. Ga naar <router-link :to="{name: 'Booking'}">jouw DJ boeking</router-link>
						</Alert>
					</span>
					<div class="d-flex flex-column align-items-center align-items-md-end">
						<b-overlay class="button-overlay w-100" :show="isLoading" rounded="sm" opacity="0.7">
							<ConfirmBookingButton
								v-if="!isOrderInProgress && quote.Status__c == 'Open'"
								bookingType="quote"
								:djClass="quote.DJClass__c"
								:quoteId="quote.Id"
							/>
						</b-overlay>
					</div>
				</template>
			</DjBlock>
		</div>
		<MyQuotes :currentQuote="quote.Id" />
		<BookingIncludes />
		<div
			v-if="!isOrderInProgress && quote.Status__c == 'Open'"
			class="d-flex flex-column align-items-center border-top border-success border-bottom py-4"
		>
			<div class="mx-auto" style="max-width: 450px">
				<b-overlay class="button-overlay w-100" :show="isLoading" rounded="sm" opacity="0.7">
					<ConfirmBookingButton
						bookingType="quote"
						placement="bottom"
						:djClass="quote.DJClass__c"
						:quoteId="quote.Id"
					/>
				</b-overlay>
			</div>
		</div>
		<Faqs />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import Alert from "@/components/customer/Alert";
import QuoteExpiresOn from "@/components/customer/QuoteExpiresOn";
import BookingIncludes from "@/components/customer/BookingIncludes";
import MyQuotes from "@/components/customer/MyQuotes";
import Faqs from "@/components/customer/Faqs";
import ConfirmBookingButton from "@/components/customer/ConfirmBookingButton";
import DjBlock from "@/components/customer/DjBlock";
import QuoteProgress from "@/components/customer/QuoteProgress";
import DisableTrengoChat from "@/components/common/DisableTrengoChat";

export default {
	name: "QuoteView",
	components: {
		Alert,
		QuoteExpiresOn,
		BookingIncludes,
		Faqs,
		ConfirmBookingButton,
		MyQuotes,
		DjBlock,
		QuoteProgress,
		DisableTrengoChat,
	},
	data() {
		return {
			quote: false,
			accept: false,
			submitted: false,
			minutes: "",
			hours: "",
			days: "",
		};
	},
	computed: {
		...mapGetters({
			isOrderInProgress: "isOrderInProgress",
			booking: "getBooking",
			quotes: "getQuotes",
			isPaid: "getPaidStatus",
			partyType: "getPartyType",
			isLoading: "getLoadingState",
		}),
		msToTime: function () {
			return Date.parse(this.quote.ExpirationDate__c) - Date.now();
		},
		daysAndHoursAndMinutesToTime: function () {
			var minutes = Math.floor((this.msToTime / (1000 * 60)) % 60);
			var hours = Math.floor(this.msToTime / (1000 * 60 * 60));
			var days = Math.floor(this.msToTime / (1000 * 60 * 60 * 24));
			if (days > 1) return days + " dagen ";
			else if (days == 1) return "1 dag ";
			else if (hours > 1) return hours + " uren ";
			else if (hours == 1) return "1 uur ";
			else if (minutes == 1) return "1 minuut ";
			else return minutes + " minuten ";
		},
	},

	methods: {
		bookQuote(quoteId) {
			this.submitted = true;
			if (this.accept)
				this.$store.dispatch("bookQuote", {
					action: "bookQuote",
					data: {
						quoteId: quoteId,
					},
					setBooking: true,
				});
		},
	},
	mounted() {
		this.quote = this.quotes.find((quote) => quote.Id == this.$route.params.id);
		if (Object.keys(this.$route.params).length === 0) this.$router.push({name: "Quotes"});
	},
};
</script>

<style lang="scss">
.button-overlay .b-overlay .spinner-border {
	display: none !important;
}
</style>
