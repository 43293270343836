<template>
	<div>
		<PageTitle
			:title="$t('mol.bookADjForm.title.Book a DJ')"
			:subTitle="$t('mol.bookADjForm.title.Choose and book the right DJ for you')"
			:breadcrumbs="[$t('mol.bookADjForm.title.Book a DJ')]"
		/>
		<BookADJ />
		<FormGridWrapper>
			<template slot="right">
				<BookingIncludes whatWeDo />
			</template>
		</FormGridWrapper>
	</div>
</template>

<script>
import PageTitle from "@/components/location/PageTitle";
import BookADJ from "@/components/location/BookADJ";
import FormGridWrapper from "@/components/location/FormGridWrapper";
import BookingIncludes from "@/components/location/BookingIncludes";

export default {
	name: "LocationBookADJ",
	components: {
		PageTitle,
		BookADJ,
		BookingIncludes,
		FormGridWrapper,
	},
};
</script>
